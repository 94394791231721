import { apiAxios } from "api";
import { AxiosResponse } from "axios";
import { ServerResponse } from "types";
import { EmployeeFormData } from "types/staff";
import { IUser } from "types/user";

export type StaffSortingBy = "user" | "email" | "role";

export interface fetchStaffListBody {
  search?: string | null;
  sorting: {
    sortBy: "user" | "email" | "role";
    order: "ASC" | "DESC";
  };
}

interface fetchStaffListResponse {
  admins: IUser[];
  totalAdmins: number;
}

interface createOrUpdateEmployeeResponse extends ServerResponse {
  admin: IUser;
}

export const fetchStaffList = (
  body: fetchStaffListBody
): Promise<AxiosResponse<fetchStaffListResponse>> => {
  return apiAxios.post("/admin/list?page=1&limit=9999", body);
};

export const createEmployee = (
  body: EmployeeFormData
): Promise<AxiosResponse<createOrUpdateEmployeeResponse>> => {
  return apiAxios.post(`/admin`, body);
};

export const removeEmployee = (
  id: number
): Promise<AxiosResponse<ServerResponse>> => {
  return apiAxios.delete(`/admin?id=${id}`);
};

export const updateEmployee = (
  id: number,
  body: EmployeeFormData
): Promise<AxiosResponse<createOrUpdateEmployeeResponse>> => {
  return apiAxios.patch(`/admin?id=${id}`, body);
};

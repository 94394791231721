import React, { FC } from "react";
import styles from "./Statistics.module.scss";
import cn from "classnames";
import { ICONS } from "assets";

interface Props {
  persentValue: number;
}

export const DailyChangingLabel: FC<Props> = ({ persentValue }) => {
  return persentValue ? (
    <div
      className={cn(styles.statisticsPage_dailyChangingLabel, {
        [styles.statisticsPage_dailyChangingLabel__increase]: persentValue > 0,
        [styles.statisticsPage_dailyChangingLabel__decrease]: persentValue < 0,
      })}
    >
      {persentValue && persentValue > 0 ? (
        <ICONS.IncreaseStatistic />
      ) : (
        <ICONS.DecreaseStatistic />
      )}
      <p>{`${Math.floor(Math.abs(persentValue))}%`}</p>
    </div>
  ) : null;
};

import { FC } from "react";
import Input from "../Input";
import { checkExpiration } from "../../utils/checkExpiration";
import Select from "components/Select";
import { LICENCE_TYPES } from "../../constants";

type Props = {
  isCreate?: boolean;
  isDriverActive?: boolean;
  driverDocumentsInputsClassnames?: string;
  drivingLicenceExpiryDate: string;
  validattionErrors?: Array<{
    expiryDate?: string;
    type?: string;
    number?: string;
  }>;
  handleDrivingLicenceExpiryDateChange: (value: string) => void;
  PCOLicenceType: string;
  handlePCOLicenceTypeChange: (value: string) => void;
  PCOLicenceNumber: string;
  PCOLicenceNumberError?: string;
  handlePCOLicenceNumberChange: (value: string) => void;
  PCOLicenceExpiryDate: string;
  handlePCOLicenceExpiryDateChange: (value: string) => void;
};

const DriverDocumentMainInputs: FC<Props> = ({
  isCreate,
  isDriverActive = false,
  driverDocumentsInputsClassnames,
  validattionErrors,
  drivingLicenceExpiryDate,
  handleDrivingLicenceExpiryDateChange,
  PCOLicenceType,
  handlePCOLicenceTypeChange,
  PCOLicenceNumber,
  PCOLicenceNumberError = "",
  handlePCOLicenceNumberChange,
  PCOLicenceExpiryDate,
  handlePCOLicenceExpiryDateChange,
}) => {
  return (
    <div className={driverDocumentsInputsClassnames}>
      <Input
        type="date"
        label="Driving licence expiry date"
        value={drivingLicenceExpiryDate}
        onChange={handleDrivingLicenceExpiryDateChange}
        minDate={new Date()}
        expiring={isDriverActive && checkExpiration(drivingLicenceExpiryDate)}
        error={validattionErrors && validattionErrors[0]?.expiryDate}
      />
      <Select
        label="PCO licence type"
        data={LICENCE_TYPES}
        selectedValue={PCOLicenceType}
        handleSelect={handlePCOLicenceTypeChange}
        error={validattionErrors && validattionErrors[1]?.type}
      />
      <Input
        label="PCO licence number"
        value={PCOLicenceNumber}
        onChange={handlePCOLicenceNumberChange}
        error={
          PCOLicenceNumberError ||
          (validattionErrors && validattionErrors[1]?.number)
        }
      />
      <Input
        type="date"
        label="PCO licence expiry date"
        value={PCOLicenceExpiryDate}
        onChange={handlePCOLicenceExpiryDateChange}
        minDate={new Date()}
        expiring={isDriverActive && checkExpiration(PCOLicenceExpiryDate)}
        error={validattionErrors && validattionErrors[1]?.expiryDate}
      />
    </div>
  );
};

export default DriverDocumentMainInputs;

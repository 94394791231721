import React, { CSSProperties, FC, useEffect, useState } from "react";
import { StatisticRevenueItem } from "types/statistic";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { getFormattedDate } from "utils/helpers/getFormatedDate";
import styles from "./Statistics.module.scss";
import { separateNumberByComas } from "utils/helpers/separateNumberByComas";

interface Props {
  revenueData: StatisticRevenueItem[];
}

export const RevenueChart: FC<Props> = ({ revenueData }) => {
  const [position, setPosition] = useState<any>(null);

  useEffect(() => {
    const tooltip = document.querySelector(".recharts-tooltip-wrapper");
    if (tooltip) {
      // Init tooltip values
      const tooltipHeight = tooltip.getBoundingClientRect().height;
      const tooltipWidth = tooltip.getBoundingClientRect().width;

      // Rewrite tooltip styles
      //@ts-ignore
      tooltip.style = `
      transform: translate(${position?.data.x}px, ${position?.data.y}px);
      pointer-events: none;  position: absolute;
      top: -${tooltipHeight + 5}px;
      left: -${tooltipWidth / 2 - position?.data.width / 2}px;
      opacity: ${position?.show ? "1" : 0};
      transition: all 400ms ease 0s;
    `;
    }
  }, [position]);

  const getXTickLabels = (value: any): string => {
    switch (revenueData.length) {
      case 7:
        return getFormattedDate(value, "EEE");
      case 12:
        return getFormattedDate(value, "MMM");
      default:
        return getFormattedDate(value, "d");
    }
  };

  return (
    <ResponsiveContainer height={180}>
      <BarChart data={revenueData}>
        <XAxis
          className={styles.chartAxis}
          dataKey="date"
          tickFormatter={getXTickLabels}
          axisLine={false}
          tickLine={false}
        />
        <YAxis axisLine={false} tickLine={false} className={styles.chartAxis} />
        <Tooltip
          content={({ active, payload }) => {
            return active && payload && payload.length ? (
              <div className={styles.chartTooltip}>
                <p className={styles.chartTooltip_value}>
                  {separateNumberByComas(Math.floor(Number(payload[0].value)))}
                </p>
              </div>
            ) : null;
          }}
          cursor={{ fill: "transparent" }}
          position={{
            // Static position
            x: position?.data.x ?? 0,
            y: position?.data.y ?? 0,
          }}
        />
        <Bar
          dataKey="revenue"
          fill="#EDD5B5"
          radius={4}
          maxBarSize={70}
          activeBar={<Rectangle fill="#1C1C1C" radius={4} />}
          onMouseMove={(data) => setPosition({ data: data, show: true })}
          onMouseOut={(data) => setPosition({ data: data, show: false })}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

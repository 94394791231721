import PaymentsIcons from "assets/icons/payments";
import styles from "./BookingDetails.module.scss";
import { FC } from "react";
import { BookingReceipt } from "types/payments";
import { CardBrandLogo } from "../CardBrandLogo";

interface Props {
  receiptItem: BookingReceipt;
}

export const BookingPaymentItem: FC<Props> = ({ receiptItem }) => {
  const { card } = receiptItem;

  return (
    <div className={styles.paymentsList_item}>
      <CardBrandLogo cardBrand={card.brand} />

      <div>
        <p className={styles.paymentsList_itemTitle}>{`${
          card.brand[0].toUpperCase() + card.brand.slice(1)
        }  ···· ${card.last4}`}</p>
        <p className={styles.paymentsList_itemSubtitle}>
          Expires {card.expMonth}/{String(card.expYear).slice(-2)}
        </p>
      </div>
    </div>
  );
};

import React, { FC, useEffect } from "react";
import styles from "./style.module.scss";
import ChatLoader from "assets/images/chatLoader.gif";
import { useInView } from "react-intersection-observer";

interface Props {
  page: number;
  hasMoreMessages: boolean;
  isLoading: boolean;
  loadCallback: () => void;
}

export const ChatLoadControler: FC<Props> = ({
  page,
  hasMoreMessages,
  isLoading,
  loadCallback,
}) => {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  useEffect(() => {
    if (inView && !isLoading && hasMoreMessages && page !== 0) {
      loadCallback();
    }
  }, [inView]);

  return true ? (
    <div className={styles.loadController} ref={ref}>
      {isLoading && (
        <img src={ChatLoader} className={styles.loadController_spiner} />
      )}
    </div>
  ) : null;
};

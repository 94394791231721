import PaymentsIcons from "assets/icons/payments";
import React, { FC } from "react";

interface Props {
  cardBrand: string;
}

export const CardBrandLogo: FC<Props> = ({ cardBrand }) => {
  switch (cardBrand) {
    case "visa":
      return <PaymentsIcons.VisaIcon />;
    case "amex":
      return <PaymentsIcons.AmericanExpressIcon />;
    case "mastercard":
      return <PaymentsIcons.MasterCardIcon />;
    case "jcb":
      return <PaymentsIcons.JCBIcon />;
    case "diners":
      return <PaymentsIcons.DinersClubIcon />;
    case "unionpay":
      return <PaymentsIcons.UnionPayIcon />;
    case "discover":
      return <PaymentsIcons.DiscoverIcon />;
    default:
      return <PaymentsIcons.UnknownCardIcon />;
  }
};

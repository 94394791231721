import styles from "./styles.module.scss";
import ReactSelect from "react-select/async";
import { useDrivers } from "../../hooks/useDrivers";
import React, { useEffect, useMemo, useState } from "react";
import Avatar from "../Avatar";
import { driversAllApi, getDriverApi } from "api/drivers";
import { DriverItem } from "./DriverItem";
import { debounce } from "lodash";

export interface DriverSelectorOption {
  label: JSX.Element;
  value: number;
}

type Props = {
  selectedValue: string | null;
  setSelectedDriverId: (driverId: number | null) => void;
  error?: string;
};

const colourStyles = {
  control: (styles: any) => ({
    ...styles,
    backgroundColor: "white",
    height: 62,
    width: "100%",
    paddingLeft: 3,
    paddingRight: 15,
    borderRadius: 4,
    cursor: "pointer",
    fontWeight: 700,
  }),

  indicatorSeparator: () => ({
    display: "none",
  }),

  indicatorsContainer: () => ({
    top: "50%",
    backgroundColor: "#AA8768",
    borderRadius: 50,
    width: 24,
    height: 24,
  }),

  dropdownIndicator: (styles: any) => ({
    ...styles,
    color: "#FFF",
    marginTop: -6,
    transform: "scale(2)",
  }),

  menu: (baseStyles: any) => ({
    ...baseStyles,
    overflow: "hidden",
    borderColor: "#AA8768",
  }),
  menuList: (baseStyles: any) => ({
    ...baseStyles,
    padding: 0,
  }),
  option: (baseStyles: any) => ({
    ...baseStyles,
    cursor: "pointer",
  }),
};

const DriverSelector: React.FC<Props> = ({
  selectedValue,
  setSelectedDriverId,
  error,
}) => {
  const { drivers } = useDrivers(null, null, "");
  const [selectedOption, setSelectedOption] =
    useState<DriverSelectorOption | null>(null);

  const defaultOptions = useMemo(() => {
    return drivers.map((driver) => ({
      label: (
        <p className={styles.row}>
          <Avatar img={driver?.avatar} dimensions={{ width: 24, height: 24 }} />
          {`${driver.firstName} ${driver.lastName}`}
        </p>
      ),
      value: driver.id,
    }));
  }, [drivers]);

  const loadOptions = debounce((searchTerm, callback) => {
    driversAllApi(1, {
      sort: {},
      search: searchTerm,
    })
      .then(({ data }) =>
        callback(
          data.drivers.map((driver) => ({
            label: <DriverItem driver={driver} />,
            value: driver.id,
          }))
        )
      )
      .catch((error) => callback([], null));
  }, 500);

  const setPreviouslySelectedDriver = async () => {
    try {
      if (selectedValue) {
        const { data } = await getDriverApi(selectedValue);

        if (data.success) {
          setSelectedOption({
            label: <DriverItem driver={data.driver} />,
            value: data.driver.id,
          });
        }
      }
    } catch (error) {
      console.log("setPreviouslySelectedDriver ERROR: ", error);
    }
  };

  useEffect(() => {
    if (selectedValue && selectedValue !== "-1") {
      setPreviouslySelectedDriver();
    }
  }, []);

  return (
    <div className={styles.container}>
      <ReactSelect
        cacheOptions
        defaultOptions={defaultOptions}
        loadOptions={loadOptions}
        styles={colourStyles}
        theme={(theme) => ({
          ...theme,
          borderRadius: 4,
          colors: {
            ...theme.colors,
            primary: "#AA8768",
            primary25: "#d1b8a1",
            primary50: "#d1b8a1",
            danger: "#E53E3E",
            dangerLight: "#E53E3E",
            neutral20: error ? "red" : "#bbb2a8",
            neutral30: "#AA8768",
            neutral40: "#1C1C1C",
            neutral80: "#1C1C1C",
          },
        })}
        value={selectedOption}
        onChange={(option) => {
          setSelectedDriverId(Number(option?.value) || null);
          setSelectedOption(option);
        }}
        placeholder="Enter driver name and select"
      />
      {error && <p className={styles.container_error}>{error}</p>}
    </div>
  );
};

export default DriverSelector;

import { FC, useContext, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { SocketContext } from "context/socket";

interface Props {
  messageId: number;
  chatId: number;
  onView?: () => void;
  isChatOpen: boolean;
}

export const MessageViewedController: FC<Props> = ({
  messageId,
  chatId,
  onView,
  isChatOpen,
}) => {
  const { socket, isUserConnectedToSocket } = useContext(SocketContext);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  useEffect(() => {
    if (inView && socket && isUserConnectedToSocket && isChatOpen) {
      socket.emit("viewedMessages", {
        chatId,
        lastViewedMessageId: messageId,
      });

      if (onView) {
        onView();
      }
    }
  }, [inView, isUserConnectedToSocket]);

  return <div ref={ref} />;
};

import { disableDriverApi } from "../api/drivers";
import { toast } from "react-hot-toast";
import { toastServerError } from "utils/helpers";
import { successToastOptions } from "utils/toasterStyles";

const useDisableDrivers = () => {
  const disableDrivers = async (driversId: number[]) => {
    try {
      await disableDriverApi(driversId);
      toast("Drivers disabled successfully!", successToastOptions);
    } catch (error) {
      toastServerError(error, "Something went wrong with drivers disabling");
    }
  };

  return { disableDrivers };
};

export default useDisableDrivers;

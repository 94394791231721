import React, { FC } from "react";
import { Category } from "types/drivers";
import styles from "./VehicleCategory.module.scss";
import cn from "classnames";
import { VehicleCategory } from "types/vehicles";

interface Props {
  category: VehicleCategory;
}

export const VehicleCategoryLabel: FC<Props> = ({ category }) => {
  return (
    <div
      className={cn(styles.categoryLabel, {
        [styles.categoryLabel__orange]: category === Category.BUSINESS_PLUS,
        [styles.categoryLabel__blue]: category === Category.FIRST_CLASS,
        [styles.categoryLabel__purple]: category === Category.BUSINESS_XL,
      })}
    >
      {category}
    </div>
  );
};

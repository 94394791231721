import React, { FC } from "react";
import styles from "./OperatingAreas.module.scss";
import { Area } from "types/areas";
import classNames from "classnames";
interface Props {
  columnsFlexes: number[];
  area: Area;
}

export const AreaRowItem: FC<Props> = ({ columnsFlexes, area }) => {
  return (
    <>
      <div
        style={{ flex: columnsFlexes[0] }}
        className={styles.areaRowItem_nameCell}
      >
        {area.name}
      </div>
      <div style={{ flex: columnsFlexes[1] }}>{area.postcodes.join(", ")}</div>
      <div
        style={{ flex: columnsFlexes[2], textAlign: "right" }}
        className={styles.areaRowItem_statusCell}
      >
        <p
          className={classNames(styles.areaRowItem_statusLabel, {
            [styles.areaRowItem_statusLabel__active]: area.status === "Active",
          })}
        >
          {area.status}
        </p>
      </div>
    </>
  );
};

import { ICONS } from "assets";
import styles from "./style.module.scss";
import { FC, useRef } from "react";
import useOutsideClick from "hooks/useOutsideClick";

interface Props {
  handleClose: () => void;
  handleDelete: () => void;
  handleEdit: () => void;
}

export const RowItemOptions: FC<Props> = ({
  handleClose,
  handleDelete,
  handleEdit,
}) => {
  const optionsRef = useRef(null);

  useOutsideClick(optionsRef, handleClose);

  return (
    <div ref={optionsRef} className={styles.options}>
      <button className={styles.optionBtn} onClick={handleEdit}>
        <ICONS.EditPen className={styles.optionBtn_icon} />
        <span>Edit</span>
      </button>

      <button
        className={`${styles.optionBtn} ${styles.optionBtn__delete}`}
        onClick={handleDelete}
      >
        <ICONS.DeleteTrash className={styles.optionBtn_icon} />
        <span>Delete</span>
      </button>
    </div>
  );
};

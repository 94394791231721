import React, { CSSProperties, FC, useEffect, useState } from "react";
import styles from "./ChatMessage.module.scss";
import { ChatMessageItem } from "types/chat";
import cn from "classnames";
import { getTimeFromDate } from "utils/helpers/getTimeFromDate";
import { getFilesDowloadLinks } from "api/documents";
import Skeleton from "react-loading-skeleton";
import ChatIcons from "assets/icons/chat";

interface Props {
  messageItem: ChatMessageItem;
  isMyMessage?: boolean;
  withViewedIndicator?: boolean;
}

export const ChatMessage: FC<Props> = ({
  messageItem,
  isMyMessage = false,
  withViewedIndicator = false,
}) => {
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [hasLoadingError, setHasLoadingError] = useState(false);

  const getPrivateImageUrl = async (publicUrl: string) => {
    try {
      setIsImageLoading(true);
      setHasLoadingError(false);
      const { data } = await getFilesDowloadLinks([publicUrl], "message");
      console.log("getFilesDowloadLinks rsp: ", data);
      if (data.success) {
        setImageUrl(data.links[0].downloadLink);
      }
    } catch (error) {
      console.log("getPrivateImageUrl ERROR: ", error);
      setHasLoadingError(true);
      setIsImageLoading(false);
    }
  };

  useEffect(() => {
    if (
      messageItem.contentType === "image" &&
      typeof messageItem.content !== "string"
    ) {
      setImageUrl(URL.createObjectURL(messageItem.content));
    }

    if (
      messageItem.contentType === "image" &&
      typeof messageItem.content === "string"
    ) {
      getPrivateImageUrl(messageItem.content);
    }
  }, []);

  return (
    <div
      className={cn(styles.chatMessage, {
        [styles.chatMessage__myMessage]: isMyMessage,
      })}
    >
      <div
        className={cn(styles.chatMessage_body, {
          [styles.chatMessage_body__myMessage]: isMyMessage,
        })}
        style={{
          paddingBottom:
            typeof messageItem.content === "string" &&
            messageItem.content.length < 5
              ? 10
              : 18,
        }}
      >
        {messageItem.contentType === "text" &&
          typeof messageItem.content === "string" && (
            <pre
              className={cn(styles.chatMessage_textContent, {
                [styles.chatMessage_textContent__myMessage]: isMyMessage,
              })}
            >
              {messageItem.content}
            </pre>
          )}
        {messageItem.contentType === "image" && (
          <div className={styles.chatMessage_imageContainer}>
            {imageUrl && (
              <a
                href={imageUrl}
                className={styles.chatMessage_imageLink}
                target="_blank"
              >
                <img
                  className={styles.chatMessage_image}
                  src={imageUrl}
                  onLoad={() => setIsImageLoading(false)}
                />
                {isImageLoading && (
                  <Skeleton
                    height={180}
                    className={styles.chatMessage_imageSkeleton}
                  />
                )}
              </a>
            )}
          </div>
        )}
        <div
          className={cn(styles.chatMessage_timeAndStatus, {
            [styles.chatMessage_timeAndStatus__myMessage]: isMyMessage,
          })}
        >
          {messageItem.isSending
            ? "Sending..."
            : getTimeFromDate(messageItem.createdAt)}

          {withViewedIndicator && !messageItem.isSending && isMyMessage ? (
            messageItem.isViewed ? (
              <ChatIcons.DoubleCheck />
            ) : (
              <ChatIcons.Check />
            )
          ) : null}
        </div>
      </div>
    </div>
  );
};

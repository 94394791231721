import React, { FC, Fragment } from "react";
import { ChatListItemWithClientName } from "types/chat";
import styles from "./SuportChatWidget.module.scss";
import { ChatLoadControler } from "components/Chat/ChatLoaderController";
import { getFormattedDate } from "utils/helpers/getFormatedDate";
import { getTimeFromDate } from "utils/helpers/getTimeFromDate";

interface Props {
  chatsList: ChatListItemWithClientName[];
  isLoading: boolean;
  page: number;
  hasMoreChats: boolean;
  onChatClick: React.Dispatch<
    React.SetStateAction<ChatListItemWithClientName | null>
  >;
  onLoadMore: () => void;
}

export const ChatItemsList: FC<Props> = ({
  chatsList,
  isLoading,
  page,
  hasMoreChats,
  onLoadMore,
  onChatClick,
}) => {
  return (
    <div className={styles.chatItemsList}>
      {chatsList.map((chatItem) => (
        <div
          className={styles.chatItemsList_item}
          key={chatItem.id}
          onClick={() => onChatClick(chatItem)}
        >
          <div className={styles.chatItemsList_item_details}>
            <div className={styles.user_info}>{chatItem.clientName}</div>

            <div className={styles.time_info}>
              {chatItem.isCompleted
                ? `Ended ${getFormattedDate(chatItem.updatedAt)}`
                : getTimeFromDate(chatItem.updatedAt)}
            </div>
          </div>
          <div className={styles.chatItemsList_item_details}>
            <div className={styles.booking_id}>
              {chatItem.orderId ? `Booking ID: ${chatItem.orderId}` : null}
            </div>
            {chatItem.unreadMessages ? (
              <span className={styles.status_icon}>
                <p>{chatItem.unreadMessages}</p>
              </span>
            ) : null}
          </div>
        </div>
      ))}

      <ChatLoadControler
        isLoading={isLoading}
        page={page}
        hasMoreMessages={hasMoreChats}
        loadCallback={onLoadMore}
      />
    </div>
  );
};

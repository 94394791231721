import { apiAxios } from "api";
import { AxiosResponse } from "axios";
import { ServerResponse } from "types";
import { BookingReceipt } from "types/payments";

interface fetchBookingPaymentsReseiptsResponse extends ServerResponse {
  receipts: BookingReceipt[];
}

export const fetchBookingPaymentsReseipts = (
  orderId: number
): Promise<AxiosResponse<fetchBookingPaymentsReseiptsResponse>> => {
  return apiAxios.get(`/payment/order-receipt?orderId=${orderId}`);
};

import { useEffect, useState } from "react";
import styles from "./Staff.module.scss";
import Header from "components/Header";
import { Button, TableHeader } from "components";
import { ICONS } from "assets";
import { VariantButton } from "types/enums";
import { PageContentContainer } from "components/PageContentContainer";
import SearchInput from "components/SearchInput";
import { useDebounce } from "hooks/useDebounce";
import { StaffRowItem } from "./StaffRowItem";
import { RowItemOptions } from "components/RowItemOptions";
import { IUser } from "types/user";
import { toast } from "react-hot-toast";
import { successToastOptions } from "utils/toasterStyles";
import { ConfirmAlert } from "components/ConfirmAlert";
import { confirmAlert } from "react-confirm-alert";
import { Modal } from "components/Modal";
import { StaffForm } from "./StaffForm";
import {
  StaffSortingBy,
  fetchStaffList,
  fetchStaffListBody,
  removeEmployee,
} from "api/staff";
import Skeleton from "react-loading-skeleton";
import { EmptyTablePlaceholder } from "components/EmptyTablePlaceholder";
import { toastServerError } from "utils/helpers";

const columnsFlexes = [35.5, 32.9, 31.62];

const sortingMap: { [key: string]: StaffSortingBy } = {
  "0": "user",
  "1": "email",
  "2": "role",
};

export const Staff = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [editableEmployee, setEditableEmployee] = useState<IUser | null>(null);
  const [staffList, setStaffList] = useState<IUser[]>([]);
  const [search, setSearch] = useState("");
  const [sortedIndex, setSortedIndex] = useState<number>(0);
  const [sortedType, setSortedType] = useState<"desc" | "asc">("asc");
  const debouncedSearch = useDebounce(search, 500);
  const [openedItemOptionsIndex, setOpenedItemOptionsIndex] = useState<
    number | null
  >(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleOptionsClose = () => {
    setOpenedItemOptionsIndex(null);
  };

  const handleOptionsBtnClick = (rowIndex: number) => {
    if (openedItemOptionsIndex === null) {
      setOpenedItemOptionsIndex(rowIndex);
    } else {
      setOpenedItemOptionsIndex(null);
    }
  };

  const handleRemoveRowItem = (rowIndex: number) => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmAlert
          question="Are you sure, that you want to delete employee?"
          onConfirm={async () => {
            try {
              const { data } = await removeEmployee(staffList[rowIndex].id);

              if (data.success) {
                setStaffList((prev) =>
                  prev.filter(
                    (priceItem) => priceItem.id !== staffList[rowIndex].id
                  )
                );

                toast("Employee successfuly removed!", successToastOptions);
                onClose();
                setOpenedItemOptionsIndex(null);
              }
            } catch (error) {
              toastServerError(
                error,
                "Something went wrong with employee removing!"
              );
            }
          }}
          onClose={() => {
            onClose();
          }}
        />
      ),
    });
  };

  const handeEditRowItem = (rowIndex: number) => {
    setEditableEmployee(staffList[rowIndex]);
    setOpenedItemOptionsIndex(null);
    setIsFormOpen(true);
  };

  const getStaffList = async () => {
    try {
      setIsLoading(true);

      const body: fetchStaffListBody = {
        search: search || null,
        sorting: {
          sortBy: sortingMap[String(sortedIndex)],
          order: sortedType.toUpperCase() as "ASC" | "DESC",
        },
      };

      const { data } = await fetchStaffList(body);

      setStaffList(data.admins);
    } catch (error) {
      console.log("getStaffList ERROR: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getStaffList();
  }, [debouncedSearch, sortedIndex, sortedType]);

  return (
    <div className={styles.staffPage}>
      <Header title="Staff Management">
        <Button
          title="Create New"
          onClick={() => setIsFormOpen(true)}
          variant={VariantButton.BLACK}
        >
          <ICONS.Plus />
        </Button>
      </Header>

      <PageContentContainer>
        <div className={styles.staffPage_content}>
          <div className={styles.staffPage_search}>
            <SearchInput
              value={search}
              onChange={setSearch}
              placeholder="Search by name or email..."
            />
          </div>

          <div>
            <TableHeader
              items={["User", "Email", "Role"]}
              flexes={columnsFlexes}
              sortIndexes={[0, 1, 2]}
              selectedIndex={sortedIndex}
              sortedType={sortedType}
              onChangeSorted={(val) => {
                setSortedIndex((prev) => (val === null ? prev : val));
              }}
              onChangeType={(order) => {
                setSortedType(order === null ? "asc" : order);
              }}
              gap={24}
            />

            {staffList.length !== 0 && !isLoading ? (
              staffList.map((item, index) => (
                <div className={styles.staffRowItem} key={item.id}>
                  <StaffRowItem
                    staffItem={item}
                    columnsFlexes={columnsFlexes}
                  />
                  <button
                    className={styles.staffRowItem_dotsBtn}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOptionsBtnClick(index);
                    }}
                  >
                    <ICONS.ThreeDotsVertical />
                  </button>

                  {openedItemOptionsIndex === index && (
                    <RowItemOptions
                      handleClose={handleOptionsClose}
                      handleDelete={() => handleRemoveRowItem(index)}
                      handleEdit={() => handeEditRowItem(index)}
                    />
                  )}
                </div>
              ))
            ) : isLoading ? (
              <Skeleton
                count={10}
                height={66}
                style={{ marginBottom: 2, marginTop: 2, borderRadius: 10 }}
              />
            ) : (
              <EmptyTablePlaceholder text="There is no employees mathching your choise" />
            )}
          </div>
        </div>
      </PageContentContainer>
      <Modal
        isOpen={isFormOpen}
        onClose={() => {
          setIsFormOpen(false);
          setEditableEmployee(null);
        }}
      >
        <StaffForm
          editableEmployee={editableEmployee}
          closeForm={() => {
            setIsFormOpen(false);
            setEditableEmployee(null);
          }}
          refreshStafflist={() => getStaffList()}
        />
      </Modal>
    </div>
  );
};

import { BodySortDriver } from "hooks/useDrivers";
import {
  CreateDriverData,
  IDriver,
  IDriverItem,
  UpdateDriverData,
} from "../../types/drivers";
import { apiAxios } from "../index";
import { AxiosResponse } from "axios";
import { ServerResponse } from "types";
import { VehicleCategory } from "types/vehicles";

interface driversAllApiResponse extends ServerResponse {
  drivers: IDriver[];
  totalDrivers: number;
}

interface fetchAvalibleDriversForBookingResponse {
  drivers: IDriverItem[];
}

interface fetchLocatorDriversListResp extends ServerResponse {
  drivers: IDriverItem[];
}

export const driversAllApi = (
  page: number,
  body: BodySortDriver
): Promise<AxiosResponse<driversAllApiResponse>> => {
  return apiAxios.post(`driver/all?page=${page}&limit=10`, body);
};

export const getDriverApi = (
  id: string | number
): Promise<AxiosResponse<{ success: boolean; driver: IDriverItem }>> => {
  return apiAxios(`driver?driverId=${id}`);
};

export const createDriverApi = (body: CreateDriverData) => {
  return apiAxios.post("driver/create", body);
};

export const updateDriverApi = (id: string, body: UpdateDriverData) => {
  return apiAxios.patch(`driver/update?driverId=${id}`, body);
};

export const updateDriverStatusApi = (
  id: number,
  body: { isActive: boolean }
) => {
  return apiAxios.patch(`driver/update?driverId=${id}`, body);
};

export const disableDriverApi = (driversId: number[]) => {
  return apiAxios.patch("/driver/disable", {
    driversIdList: driversId,
  });
};

export const fetchAvalibleDriversForBooking = (
  vehicleCategory: VehicleCategory
): Promise<AxiosResponse<fetchAvalibleDriversForBookingResponse>> => {
  return apiAxios.get(
    `/driver/list-by-category?vehicleCategory=${vehicleCategory}`
  );
};

export const fetchLocatorDriversList = (body: {
  sorting: { sortBy: string; order: string };
}): Promise<AxiosResponse<fetchLocatorDriversListResp>> => {
  return apiAxios.post("/driver/locator/list", body);
};

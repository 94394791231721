import { driversAllApi } from "api/drivers";
import { useEffect, useState } from "react";
import { IDriver, IDriverItem } from "types/drivers";
import { getTokens } from "utils/tokenService";

interface Sort {
  [key: string]: string;
}

export interface BodySortDriver {
  sort: Sort;
  search?: string;
}

export const useDrivers = (
  sortType: "desc" | "asc" | null,
  sortedIndex: number | null,
  search: string
) => {
  const [drivers, setDrivers] = useState<IDriver[] | []>([]);
  const [totalDrivers, setTotalDrivers] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const sortDrivers = () => {
    const body: BodySortDriver = {
      sort: {},
      search: search || undefined,
    };
    const indexToKey: { [key: number]: string } = {
      0: "driver",
      1: "category",
      2: "email",
    };

    if (sortType) {
      const keyToUpdate = indexToKey[sortedIndex ? sortedIndex - 1 : 0];
      body.sort[keyToUpdate] = sortType.toUpperCase();
    }

    return body;
  };

  const getAllDrivers = async () => {
    try {
      setIsLoading(true);
      const newBody = sortDrivers();
      const { data } = await driversAllApi(currentPage, newBody);

      if (data.success) {
        setDrivers(data.drivers);
        setTotalDrivers(data.totalDrivers);
        setIsLoading(false);
        return data.drivers;
      }
    } catch (e) {
      console.log("error getting all drivers", e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllDrivers();
  }, [currentPage, sortType, sortedIndex, search]);

  return {
    drivers,
    setDrivers,
    totalDrivers,
    currentPage,
    setCurrentPage,
    isLoading,
    getAllDrivers,
  };
};

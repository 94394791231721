import { Marker } from "@react-google-maps/api";
import { FC, useMemo } from "react";
import { PostcodePoint } from "types/areas";
import styles from "./OperatingAreas.module.scss";

interface Props {
  point: PostcodePoint;
}

export const PostcodeMarker: FC<Props> = ({ point }) => {
  // const markerIcon = useMemo(() => {
  //   switch (driver.driver.vehicle?.category) {
  //     case "Business Plus":
  //       return require("assets/Map/BussinessPlusMarker.png");
  //     case "Business XL":
  //       return require("assets/Map/BussinessXLMarker.png");
  //     default:
  //       return require("assets/Map/FirstClassMarker.png");
  //   }
  // }, [driver.driver.vehicle?.category]);

  const markerSize = useMemo(() => {
    return new google.maps.Size(36, 43);
  }, []);

  return (
    <>
      <Marker
        icon={{
          url: require("assets/Map/BussinessPlusMarker.png"),
          scaledSize: markerSize,
        }}
        position={{ ...point }}
        options={{
          label: {
            text: point.label + "\n",
            className: styles.markerLabel,
            fontSize: "12px",
            color: "white",
          },
        }}
      ></Marker>
    </>
  );
};

import cls from "classnames";
import { CSSProperties, FC, ReactNode } from "react";
import styles from "./styles.module.scss";

import { VariantButton } from "../../types/enums";

type Props = {
  title: string;
  disable?: boolean;
  variant?: VariantButton;
  onClick: () => void;
  children?: ReactNode;
  extraStyles?: CSSProperties;
};

const Button: FC<Props> = ({
  title,
  disable = false,
  variant = VariantButton.General,
  onClick,
  children,
  extraStyles,
}) => {
  return (
    <button
      className={cls(styles.button, {
        [styles.button_black]: variant === VariantButton.BLACK,
        [styles.button_red]: variant === VariantButton.Red,
        [styles.button_disable]: disable,
      })}
      style={extraStyles}
      disabled={disable}
      onClick={onClick}
      type="button"
    >
      {children}
      {title}
    </button>
  );
};

export default Button;

import { checkDocumentsExpiry } from "api/documents";
import { ReactNode, createContext, useEffect, useState } from "react";

interface ContextProps {
  driverDocumentsAlert: boolean;
  vehicleDocumentsAlert: boolean;
  updateExpiryAlerts: () => void;
}

export const ExpiryAlertsContext = createContext<ContextProps>({
  driverDocumentsAlert: false,
  vehicleDocumentsAlert: false,
  updateExpiryAlerts: () => {},
});

export const ExpiryAlertsProvider = ({ children }: { children: ReactNode }) => {
  const [driverDocumentsAlert, setDriverDocumentsAlert] = useState(false);
  const [vehicleDocumentsAlert, setVehicleDocumentsAlert] = useState(false);

  const updateExpiryAlerts = async () => {
    try {
      const { data } = await checkDocumentsExpiry();

      if (data.success) {
        setDriverDocumentsAlert(data.driverDocuments);
        setVehicleDocumentsAlert(data.vehicleDocuments);
      }
    } catch (error) {
      console.log("updateExpiryAlerts ERROR: ", error);
    }
  };

  useEffect(() => {
    updateExpiryAlerts();
  }, []);

  return (
    <ExpiryAlertsContext.Provider
      value={{
        driverDocumentsAlert,
        vehicleDocumentsAlert,
        updateExpiryAlerts,
      }}
    >
      {children}
    </ExpiryAlertsContext.Provider>
  );
};

import { Fragment, useEffect, useState } from "react";
import styles from "./Feedback.module.scss";
import Header from "components/Header";
import { PageContentContainer } from "components/PageContentContainer";
import SearchInput from "components/SearchInput";
import { Input, TableHeader } from "components";
import { FeedbackRowItem } from "./FeedbackRowItem";
import { Modal } from "components/Modal";
import { FeedBackModal } from "./FeedBackModal";
import {
  FeedbackSortBy,
  fetchFeedbackList,
  fetchFeedbackListBody,
} from "api/feedbacks";
import { IFeedback } from "types/feedbacks";
import Skeleton from "react-loading-skeleton";
import { EmptyTablePlaceholder } from "components/EmptyTablePlaceholder";
import { useDebounce } from "hooks/useDebounce";
import { toastServerError } from "utils/helpers";

const columnsFlexes = [28.92, 35.98, 12.57, 22.53];

function getInitialDateRange() {
  const currentDate = new Date(); // Get current date
  const threeMonthsAgo = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 3,
    currentDate.getDate(),
    0,
    0,
    0
  ); // Date 3 months ago at 00:00:00
  const fromDate = threeMonthsAgo.toISOString().split("T")[0]; // Get the date in YYYY-MM-DD format

  const currentDateFormatted = currentDate.toISOString().split("T")[0]; // Current date in YYYY-MM-DD format

  return {
    from: fromDate,
    to: currentDateFormatted,
  };
}

const sortingMap: { [key: string]: FeedbackSortBy } = {
  "0": "client",
  "2": "orderId",
  "3": "createdAt",
};

export const Feedback = ({}) => {
  const [selectedFeedback, setSelectedFeedback] = useState<IFeedback | null>(
    null
  );
  const [feedbacksList, setFeedbacksList] = useState<IFeedback[]>([]);
  const [search, setSearch] = useState("");
  const [dateRange, setDateRange] = useState(getInitialDateRange());
  const debouncedSearch = useDebounce(search, 500);
  const [sortedIndex, setSortedIndex] = useState<number>(3);
  const [sortedType, setSortedType] = useState<"desc" | "asc">("desc");
  const [isLoading, setIsLoading] = useState(true);

  const getFeedbackList = async () => {
    try {
      setIsLoading(true);
      let fromDate = new Date();
      fromDate.setMonth(fromDate.getMonth() - 3);
      const requestBody: fetchFeedbackListBody = {
        search: search || undefined,
        sorting: {
          sortBy: sortingMap[String(sortedIndex)],
          order: sortedType.toUpperCase() as "ASC" | "DESC",
        },
        dateRange,
      };

      const { data } = await fetchFeedbackList(requestBody);

      setFeedbacksList(data.feedbacks);
    } catch (error) {
      toastServerError(error, "Something went wrong with feedbacks loading");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getFeedbackList();
  }, [debouncedSearch, sortedIndex, sortedType, dateRange]);

  return (
    <div className={styles.feedbackPage}>
      <Header title="Feedback" />

      <PageContentContainer>
        <div className={styles.feedbackPage_content}>
          <div className={styles.feedbackPage_topInputs}>
            <div className={styles.feedbackPage_search}>
              <SearchInput
                value={search}
                onChange={setSearch}
                placeholder="Search by booking id..."
              />
            </div>

            <div className={styles.feedbackPage_datePikers}>
              <Input
                type="date"
                label="From date"
                value={dateRange.from}
                maxDate={new Date()}
                onChange={(val) =>
                  setDateRange((prev) => ({ ...prev, from: val }))
                }
              />

              <Input
                type="date"
                label="To date"
                value={dateRange.to}
                minDate={new Date(dateRange.from)}
                maxDate={new Date()}
                onChange={(val) =>
                  setDateRange((prev) => ({ ...prev, to: val }))
                }
              />
            </div>
          </div>

          <div className={styles.feedbackPage_table}>
            <TableHeader
              flexes={columnsFlexes}
              items={[
                "Reporter",
                "Comment",
                "Booking ID",
                "Report date and time",
              ]}
              sortedType={sortedType}
              selectedIndex={sortedIndex}
              onChangeSorted={(val) => {
                setSortedIndex((prev) => (val === null ? prev : val));
              }}
              onChangeType={(order) => {
                setSortedType(order === null ? "asc" : order);
              }}
              sortIndexes={[0, 2, 3]}
            />

            {feedbacksList.length !== 0 && !isLoading ? (
              feedbacksList.map((feedback) => (
                <Fragment key={feedback.id}>
                  <FeedbackRowItem
                    feedback={feedback}
                    columnsFlexes={columnsFlexes}
                    onClick={() => setSelectedFeedback(feedback)}
                  />
                </Fragment>
              ))
            ) : isLoading ? (
              <Skeleton
                count={10}
                height={66}
                style={{ marginBottom: 2, marginTop: 2, borderRadius: 10 }}
              />
            ) : (
              <EmptyTablePlaceholder text="There are no feedbacks matching your choise" />
            )}
          </div>

          {/* <div>
            <Pagination
              currentPage={page}
              limit={20}
              count={totalItems}
              onChange={setPage}
            />
          </div> */}
        </div>
        <Modal
          isOpen={Boolean(selectedFeedback)}
          onClose={() => {
            setSelectedFeedback(null);
          }}
        >
          {selectedFeedback && <FeedBackModal feedback={selectedFeedback} />}
        </Modal>
      </PageContentContainer>
    </div>
  );
};

import { FC } from "react";
import { BookingPoint } from "types/bookings";
import RouteIcons from "assets/icons/routePoints";
import styles from "./BookingDetails.module.scss";

interface Props {
  points: BookingPoint[];
}

export const BookingRoute: FC<Props> = ({ points }) => {
  const LeftIcon: FC<{ index: number }> = ({ index }) => (
    <div className={styles.mainInfo_pointRowIcon}>
      {index === 0 && <RouteIcons.PickupPoint />}

      {index !== 0 && index !== points.length - 1 && <RouteIcons.RoutePoint />}

      {index !== 0 && index === points.length - 1 && (
        <RouteIcons.RouteEndPoint width={20} height={20} />
      )}

      {index !== points.length - 1 && (
        <RouteIcons.RouteDots
          style={{ position: "absolute", top: 31, left: 9 }}
        />
      )}
    </div>
  );

  return (
    <>
      {points.map((point, index) => {
        return (
          <div className={styles.mainInfo_pointRow} key={point.id}>
            <LeftIcon index={index} />
            <p className={styles.mainInfo_mainAddress}>{point.mainAddress}</p>
            <p className={styles.mainInfo_secondaryAddress}>
              {point.secondaryAddress}
            </p>
          </div>
        );
      })}
    </>
  );
};

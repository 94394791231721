import React, { FC } from "react";
import styles from "./Feedback.module.scss";
import { getFormattedDate } from "utils/helpers/getFormatedDate";
import { ICONS } from "assets";
import cn from "classnames";
import { IFeedback } from "types/feedbacks";

interface Props {
  feedback: IFeedback;
  columnsFlexes: number[];
  onClick: () => void;
}

export const FeedbackRowItem: FC<Props> = ({
  feedback,
  columnsFlexes,
  onClick,
}) => {
  return (
    <div className={styles.feedbackRowItem} onClick={onClick}>
      <div
        className={styles.feedbackRowItem_reporterCell}
        style={{ flex: columnsFlexes[0] }}
      >
        {`${feedback.client.firstName} ${feedback.client.lastName}`}
      </div>
      <div
        className={styles.feedbackRowItem_commonCell}
        style={{ flex: columnsFlexes[1] }}
      >
        {feedback.description}
      </div>
      <div
        className={styles.feedbackRowItem_commonCell}
        style={{ flex: columnsFlexes[2] }}
      >
        {feedback.orderId}
      </div>
      <div
        className={cn(
          styles.feedbackRowItem_commonCell,
          styles.feedbackRowItem_lastCell
        )}
        style={{ flex: columnsFlexes[3] }}
      >
        {getFormattedDate(feedback.createdAt, "MMM dd, yyyy, HH:mm")}
        <ICONS.GoNextIcon />
      </div>
    </div>
  );
};

import { fetchSuportChatById, updateChatStatus } from "api/chats";
import { getFileUploadLink } from "api/documents";
import axios from "axios";
import { SocketContext } from "context/socket";
import { UserContext } from "context/user";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ChatListItemWithClientName, ChatMessageItem } from "types/chat";
import { CHAT_COMPLETED_MESSAGE_TEXT } from "utils/constants";
import { toastServerError } from "utils/helpers";

export const useSupportChat = () => {
  const { user } = useContext(UserContext);
  const { socket, isUserConnectedToSocket } = useContext(SocketContext);
  const [selectedChat, setSelectedChat] =
    useState<ChatListItemWithClientName | null>(null);
  const [messages, setMessages] = useState<ChatMessageItem[]>([]);
  const [sendingMessages, setSendingMessages] = useState<ChatMessageItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [newMessageText, setNewMessageText] = useState("");
  const [isSelectedChatStatusUpdating, setIsSelectedChatStatusUpdating] =
    useState(false);

  const allMessagesList = useMemo(() => {
    return [...sendingMessages, ...messages];
  }, [sendingMessages, messages]);

  const loadSelectedChat = async () => {
    try {
      if (selectedChat && hasMoreMessages) {
        setIsLoading(true);
        const { data } = await fetchSuportChatById(selectedChat.id, 1);

        if (data.success) {
          setMessages(data.chat.messages);
          setHasMoreMessages(data.chat.messages.length < data.totalMessages);
          setPage(1);
        }
      }
    } catch (error) {
      toastServerError(error, "Something went wrong with chat loading");
    } finally {
      setIsLoading(false);
    }
  };

  const loadMoreMessages = useCallback(async () => {
    try {
      if (selectedChat && !isLoading && hasMoreMessages) {
        setIsLoading(true);

        const { data } = await fetchSuportChatById(selectedChat.id, page + 1);

        if (data.success) {
          setMessages((prev) => [...prev, ...data.chat.messages]);
          setHasMoreMessages(
            messages.length + data.chat.messages.length < data.totalMessages
          );
          setPage((prev) => prev + 1);
        }
      }
    } catch (error) {
      toastServerError(
        error,
        "Something went wrong with next messages page loading"
      );
    } finally {
      setIsLoading(false);
    }
  }, [selectedChat, hasMoreMessages, messages, isLoading]);

  const handleSendMessage = (extraMessage?: string) => {
    if (!isUserConnectedToSocket) {
      toastServerError(
        undefined,
        extraMessage || "Something went wrong with conection"
      );
      return;
    }

    if (user && selectedChat) {
      setNewMessageText("");
      const tempMessage: ChatMessageItem = {
        id: Math.random(),
        authorType: "admin",
        authorId: user?.id,
        chatId: selectedChat?.id,
        contentType: "text",
        content: extraMessage || newMessageText.trim(),
        isViewed: false,
        createdAt: String(new Date()),
        isSending: true,
      };

      setSendingMessages((prev) => [tempMessage, ...prev]);

      socket?.emit("supportMessage", {
        chatId: selectedChat.id,
        contentType: "text",
        content: extraMessage || newMessageText.trim(),
      });
    }
  };

  const handleSendImages = async (images: FileList | null) => {
    if (!images || images.length === 0) {
      return;
    }

    if (user && selectedChat) {
      try {
        const imagesArray = Array.from(images);

        setSendingMessages((prev) => {
          const newTempMessages: ChatMessageItem[] = imagesArray.map(
            (image) => ({
              id: Math.random(),
              authorType: "admin",
              authorId: user.id,
              chatId: selectedChat.id,
              contentType: "image",
              content: image,
              isViewed: false,
              createdAt: String(new Date()),
              isSending: true,
            })
          );

          return [...newTempMessages, ...prev];
        });

        const filesLinks = await getFileUploadLink(
          imagesArray.map((image) => image.name),
          "message"
        );

        if (!filesLinks) {
          throw new Error("Links Loading error");
        }

        let blobs = await Promise.all(
          imagesArray.map((imageFile) =>
            fetch(URL.createObjectURL(imageFile)).then((r) => r.blob())
          )
        );

        const responses = await Promise.all(
          filesLinks.map((fileLink, index) =>
            axios.put(fileLink.uploadLink, blobs[index], {
              headers: {
                "Content-Type": "application/octet-stream",
              },
            })
          )
        );

        responses.map((response, index) => {
          if (response.status === 200) {
            socket?.emit("message", {
              chatId: selectedChat.id,
              contentType: "image",
              content: filesLinks[index].downloadLink,
            });
          }
        });
      } catch (error) {
        toastServerError(error, "Something went wrong with images uploading");
      }
    }
  };

  const handleCompleteChatClick = async () => {
    try {
      if (selectedChat) {
        setIsSelectedChatStatusUpdating(true);
        const { data } = await updateChatStatus(selectedChat.id, "completed");

        if (data.success) {
          handleSendMessage(CHAT_COMPLETED_MESSAGE_TEXT);
        }
      }
    } catch (error) {
      toastServerError(error, "Something went wrong with chat status updating");
    } finally {
      setIsSelectedChatStatusUpdating(false);
    }
  };

  useEffect(() => {
    if (selectedChat) {
      loadSelectedChat();
    } else {
      setHasMoreMessages(true);
      setPage(1);
      setMessages([]);
      setNewMessageText("");
      setSendingMessages([]);
    }
  }, [selectedChat]);

  return {
    handleSendMessage,
    handleSendImages,
    newMessageText,
    setNewMessageText,
    selectedChat,
    setSelectedChat,
    sendingMessages,
    setSendingMessages,
    messages,
    setMessages,
    messagesPage: page,
    hasMoreMessages,
    loadMoreMessages,
    allMessagesList,
    isMessagesLoading: isLoading,
    isSelectedChatStatusUpdating,
    handleCompleteChatClick,
  };
};

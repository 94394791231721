import { apiAxios } from "api";
import { AxiosResponse } from "axios";
import { ServerResponse } from "types";
import { IFeedback } from "types/feedbacks";
export type FeedbackSortBy = "client" | "orderId" | "createdAt";

export interface fetchFeedbackListBody {
  search?: string;
  sorting: {
    sortBy: "client" | "orderId" | "createdAt";
    order: "ASC" | "DESC";
  };
  dateRange: {
    from: string;
    to: string;
  };
}

interface fetchFeedbackListResponse extends ServerResponse {
  feedbacks: IFeedback[];
}

export const fetchFeedbackList = (
  body: fetchFeedbackListBody
): Promise<AxiosResponse<fetchFeedbackListResponse>> => {
  console.log("requestBody ==> ", body);
  return apiAxios.post("/feedback/list", body);
};

import { object, string } from "yup";

export const staffValidationSchema = object({
  avatar: string().nullable(),
  firstName: string().required("Required field"),
  lastName: string().required("Required field"),
  email: string().email("Incorrect email address").required("Required field"),
  password: string()
    .min(8, "Password must have at least 8 characters")
    .matches(/[A-Z]/, "Password must contain at least 1 uppercase letter")
    .matches(/[a-z]/, "Password must contain at least 1 lowercase letter")
    .matches(/[0-9]/, "Password must contain at least 1 number")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Password must contain at least 1 symbol"
    )
    .matches(
      /^[A-Za-z0-9!@#$%^&*(),.?":{}|<>]+$/,
      "Password letters can only be Latin characters"
    )
    .required("Required field"),
  role: string().required("Required field"),
});

import { ReactComponent as VisaIcon } from "./Visa.svg";
import { ReactComponent as AmericanExpressIcon } from "./AmericanExpress.svg";
import { ReactComponent as DinersClubIcon } from "./DinersClub.svg";
import { ReactComponent as DiscoverIcon } from "./Discover.svg";
import { ReactComponent as MasterCardIcon } from "./MasterCard.svg";
import { ReactComponent as JCBIcon } from "./JCB.svg";
import { ReactComponent as UnionPayIcon } from "./UnionPay.svg";
import { ReactComponent as UnknownCardIcon } from "./UnknownCard.svg";

const PaymentsIcons = {
  VisaIcon,
  AmericanExpressIcon,
  DinersClubIcon,
  DiscoverIcon,
  MasterCardIcon,
  JCBIcon,
  UnionPayIcon,
  UnknownCardIcon,
};

export default PaymentsIcons;

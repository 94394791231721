import axios from "axios";
import { toastServerError } from "utils/helpers";
import { apiAxios } from "../api";
import { getFileUploadLink } from "../api/documents";
import { useLoading } from "../context/loading";

export const useAvatar = () => {
  const { setIsLoading } = useLoading();

  const deleteAvatar = async (downloadLink: string, driverId: number) => {
    try {
      await apiAxios.post("file/delete/avatar", {
        downloadLinks: [downloadLink],
      });
      await apiAxios.patch(`driver/update?driverId=${driverId}`, {
        avatar: null,
      });

      return true;
    } catch (err) {
      console.log("[ERROR delete avatar] => ", err);
    }
  };

  const uploadAvatar = async (fileName: string | null, file: File | null) => {
    if (fileName && file) {
      try {
        const links = await getFileUploadLink([fileName], "avatar");

        if (!links) {
          throw new Error("LinksLoading error");
        }

        let blob = await fetch(URL.createObjectURL(file)).then((r) => r.blob());

        const response = await axios.put(links[0].uploadLink, blob, {
          headers: {
            "Content-Type": "application/octet-stream",
          },
        });

        return links[0].downloadLink;
      } catch (error) {
        toastServerError(error, "Something went wrong with avatar upload");
        setIsLoading(false);
      }
    } else {
      return null;
    }
  };

  return { deleteAvatar, uploadAvatar };
};

import { Button, TableHeader } from "components";
import styles from "./style.module.scss";
import { HolidaySurcharge } from "types/prices";
import { FieldArray, Formik } from "formik";
import { HolidayFormItem } from "./HolidayFormItem";
import { ICONS } from "assets";
import { VariantButton } from "types/enums";
import { Fragment, useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { ConfirmAlert } from "components/ConfirmAlert";
import {
  createNewHoliday,
  fetchHolidays,
  removeHoliday,
  updateHolidays,
} from "api/prices";
import { toast } from "react-hot-toast";
import { successToastOptions } from "utils/toasterStyles";
import Skeleton from "react-loading-skeleton";
import LoaderScreen from "components/LoaderScreen";
import { holidaysValidationSchema } from "utils/validation/Prices";
import { toastServerError } from "utils/helpers";

const columnsFlexes = [0.27, 0.11, 0.112, 0.218, 0.279];

export const HolidayContent = () => {
  const [canSave, setCanSave] = useState(false);
  const [holidayItems, setHolidayItems] = useState<HolidaySurcharge[] | null>(
    null
  );
  const [isHolidaysFetching, setIsHolidaysFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit = async ({
    holidays,
  }: {
    holidays: HolidaySurcharge[];
  }) => {
    try {
      const holidaysForUploading = holidays.map((holiday) => ({
        id: holiday.id,
        holiday: holiday.holiday,
        action: holiday.action,
        factorValue: +holiday.factorValue,
        from: holiday.from,
        to: holiday.to,
      }));

      const { data } = await updateHolidays({ holidays: holidaysForUploading });

      if (data.success) {
        setHolidayItems(holidays);
        toast(
          "Holidays Surcharges upadated successfully!",
          successToastOptions
        );
      }
    } catch (error) {
      toastServerError(error, "Something went wrong with holidays updating!");
    }
  };

  const getHolidays = async () => {
    try {
      const { data } = await fetchHolidays();
      if (data.success) {
        const preperedHolidays = data.holidays.map((holiday) => ({
          ...holiday,
          holiday: holiday.holiday || "",
          from: holiday.from || "",
          to: holiday.to || "",
        }));
        setHolidayItems(preperedHolidays);
      }
    } catch (error) {
      toastServerError(error, "Something went wrong with holidays loading");
    }
  };

  useEffect(() => {
    getHolidays();
  }, []);

  return (
    <div className={styles.content}>
      <span className={styles.content_description}>
        This option allows to set different rates at times of the day(s) eg.
        "Holidays" and "Rush hours".
        <br />
        To increase the price the Factor value has to be set to more than 1. eg.
        1.5 will result in a 50% increase.
        <br />
        To decrease the price the Factor value has to be set to less than 1. eg.
        0.9 will result in a 10% decrease.
        <br />
      </span>

      <TableHeader
        items={["Holiday", "Action", "Factor value", "From", "To"]}
        flexes={columnsFlexes}
        gap={24}
      />

      {holidayItems && !isHolidaysFetching ? (
        <Formik
          initialValues={{ holidays: holidayItems }}
          validationSchema={holidaysValidationSchema}
          validateOnChange={false}
          onSubmit={handleFormSubmit}
        >
          {({ values, errors, handleSubmit, validateForm }) => {
            return (
              <FieldArray
                name="holidays"
                render={({ remove, unshift }: any) => {
                  const handleAddHolidayClick = async () => {
                    try {
                      setIsLoading(true);
                      const validateResult = await validateForm();

                      if (Object.values(validateResult).length === 0) {
                        const { data } = await createNewHoliday();
                        if (data.success) {
                          const newHoliday = {
                            ...data.holiday,
                            holiday: data.holiday.holiday || "",
                            from: data.holiday.from || "",
                            to: data.holiday.to || "",
                          };
                          unshift(newHoliday);
                        }
                      }
                    } catch (error) {
                      toastServerError(
                        error,
                        "Something went wrong with new holiday creating"
                      );
                    } finally {
                      setIsLoading(false);
                    }
                  };

                  return (
                    <div className={styles.holidaysForm}>
                      <div className={styles.holidaysForm_rows}>
                        {values.holidays.map((holidayItem, index) => (
                          <Fragment key={holidayItem.id}>
                            <HolidayFormItem
                              itemIndex={index}
                              columnsFlexes={columnsFlexes}
                              handleDelete={() => {
                                confirmAlert({
                                  customUI: ({ onClose }) => (
                                    <ConfirmAlert
                                      question="Are you sure, that you want to delete Holiday?"
                                      onConfirm={async () => {
                                        try {
                                          const { data } = await removeHoliday(
                                            holidayItem.id
                                          );

                                          if (data.success) {
                                            remove(index);
                                            onClose();
                                            toast(
                                              "Holiday removed successfully!",
                                              successToastOptions
                                            );
                                          }
                                        } catch (error) {
                                          toastServerError(
                                            error,
                                            "Something went wrong with new holiday removing"
                                          );
                                        }
                                      }}
                                      onClose={() => {
                                        onClose();
                                      }}
                                    />
                                  ),
                                });
                              }}
                              setCanSave={setCanSave}
                            />
                          </Fragment>
                        ))}
                      </div>
                      <div className={styles.holidaysForm_buttons}>
                        <Button
                          title="Save changes"
                          variant={VariantButton.BLACK}
                          onClick={handleSubmit}
                          disable={!canSave}
                        />

                        <Button
                          title="Add surcharge"
                          onClick={handleAddHolidayClick}
                        >
                          <ICONS.Plus />
                        </Button>
                      </div>
                    </div>
                  );
                }}
              />
            );
          }}
        </Formik>
      ) : (
        <Skeleton
          height={74}
          style={{ marginBottom: 2, marginTop: 2, borderRadius: 5 }}
          count={3}
        />
      )}
      {isLoading && <LoaderScreen />}
    </div>
  );
};

import { FC } from "react";
import classNames from "classnames";

import styles from "./style.module.scss";
import { ICONS } from "assets";

interface Props {
  value: string;
  step: number;
  onChange: (value: string) => void;
  error?: string;
}

export const CounterInput: FC<Props> = ({ value, step, onChange, error }) => {
  const handleIncrease = () => {
    onChange(String(+value + step));
  };

  const handleDecrease = () => {
    onChange(String(+value - step));
  };

  return (
    <div className={styles.counterInput}>
      <input
        className={classNames(styles.counterInput_input, {
          [styles.counterInput_input__error]: error,
        })}
        type="number"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      <div className={styles.counterInput_buttons}>
        <button className={styles.counterInput_button} onClick={handleIncrease}>
          <ICONS.CounterUp />
        </button>
        <button className={styles.counterInput_button} onClick={handleDecrease}>
          <ICONS.CounterDown />
        </button>
      </div>
      {error && <p className={styles.counterInput_error}>{error}</p>}
    </div>
  );
};

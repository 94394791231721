import React, { FC } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import styles from "./Statistics.module.scss";
import { StatisticBookingsItem } from "types/statistic";

interface Props {
  vehicleBookingsStat: StatisticBookingsItem;
  bookingsTotal: number;
}
export const VehiclesColumn: FC<Props> = ({
  vehicleBookingsStat,
  bookingsTotal,
}) => {
  const percentValue = Math.round(
    (vehicleBookingsStat.totalOrders * 100) / bookingsTotal
  );

  return (
    <div className={styles.vehiclesColumn}>
      <div className={styles.vehiclesColumn_header}>
        <h5 className={styles.vehiclesColumn_category}>
          {vehicleBookingsStat.vehicleCategory}
        </h5>
        <p>{vehicleBookingsStat.totalOrders}</p>
      </div>
      <p className={styles.vehiclesColumn_percentLabel}>{`${percentValue}%`}</p>
      <ProgressBar
        completed={percentValue}
        isLabelVisible={false}
        maxCompleted={100}
        height="8px"
        borderRadius="4px"
        baseBgColor="#E4E4E4"
        bgColor="#1C1C1C"
      />
    </div>
  );
};

import Header from "components/Header";
import { PageContentContainer } from "components/PageContentContainer";
import { TabLink } from "components/TabLink";
import { useParams } from "react-router-dom";
import { ActivePricesContent } from "./ActivePricesContent";
import { FixedPricesContent } from "./FixedPricesContent";
import { HolidayContent } from "./HolidayContent";
import { ROUTES } from "utils/constants";
import { AirportFeeContent } from "./AirportFeeContent";
import styles from "./Prices.module.scss";

export const Prices = () => {
  const { activeTab } = useParams();

  return (
    <div className={styles.prices}>
      <Header title={"Prices"} />

      <PageContentContainer>
        <nav className={styles.prices_navigation}>
          {ROUTES.prices.map((routeItem) => (
            <TabLink
              key={routeItem.route}
              route={`/${routeItem.route}`}
              label={routeItem.label}
            />
          ))}
        </nav>

        {activeTab === "active" && <ActivePricesContent />}
        {activeTab === "fixed" && <FixedPricesContent />}
        {activeTab === "holiday_surcharge" && <HolidayContent />}
        {activeTab === "as_directed_airport_pick-up" && (
          <AirportFeeContent type="By The Hour Airport Pick-Up" />
        )}
        {activeTab === "one_way_airport_pick-up" && (
          <AirportFeeContent type="One Way Airport Pick-Up" />
        )}
        {activeTab === "one_way_airport_drop-off" && (
          <AirportFeeContent type="One Way Airport Drop-Off" />
        )}
      </PageContentContainer>
    </div>
  );
};

import { FC } from "react";
import Modal from "react-modal";
import styles from "./styles.module.scss";
import GeneralInformationForm from "../GeneralInformationForm";
import { ICONS } from "../../assets";
import { useCreateDriver } from "../../hooks/useCreateDriver";
import { CreateDriverData, IDriver } from "../../types/drivers";

const customStyles = {
  content: {
    marginLeft: "auto",
    marginRight: "auto",
    width: 572,
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: 1,
  },
};

type Props = {
  setDrivers: (drivers: IDriver[]) => void;
  isOpen: boolean;
  onClose: () => void;
};

const CreateDriverModal: FC<Props> = ({ isOpen, onClose, setDrivers }) => {
  const { createDriver } = useCreateDriver();

  const handleSubmit = (data: CreateDriverData) => {
    return createDriver(data);
  };

  return (
    <Modal
      isOpen={isOpen}
      //@ts-ignore
      style={customStyles}
      ariaHideApp={false}
      onRequestClose={onClose}
    >
      <div className={styles.wrapper}>
        <button className={styles.wrapper_closeButton} onClick={onClose}>
          <ICONS.CloseCross />
        </button>
        <h5>New Driver</h5>
        <GeneralInformationForm
          setDrivers={setDrivers}
          isCreate={true}
          onSubmit={handleSubmit}
          onCancel={onClose}
        />
      </div>
    </Modal>
  );
};

export default CreateDriverModal;

import ReactSelect from "react-select/async";
import { useParams } from "react-router-dom";
import { Booking, BookingStatus } from "types/bookings";
import Skeleton from "react-loading-skeleton";
import { FC, useEffect, useMemo, useState } from "react";
import { fetchAvalibleDriversForBooking, getDriverApi } from "api/drivers";
import { VehicleCategory } from "types/vehicles";
import { DriverSelectorOption } from "components/DriverSelector";
import { DriverItem } from "./DriverItem";
import { reserveOrderForDriver } from "api/bookings";
import { useLoading } from "context/loading";
import LoaderScreen from "components/LoaderScreen";
import { toastServerError } from "utils/helpers";

const getSelectorStyles = (isDisabled: boolean) => ({
  control: (styles: any) => ({
    ...styles,
    backgroundColor: "white",
    minHeight: 94,
    width: "100%",
    paddingLeft: 3,
    paddingRight: 15,
    borderRadius: 4,
    cursor: "pointer",
  }),

  indicatorSeparator: () => ({
    display: "none",
  }),

  indicatorsContainer: () => ({
    top: "50%",
    backgroundColor: "#AA8768",
    borderRadius: 50,
    width: 24,
    height: 24,
    display: isDisabled ? "none" : "block",
  }),

  dropdownIndicator: (styles: any) => ({
    ...styles,
    color: "#FFF",
    marginTop: -6,
    transform: "scale(2)",
  }),

  menu: (baseStyles: any) => ({
    ...baseStyles,
    overflow: "hidden",
    borderColor: "#AA8768",
  }),
  menuList: (baseStyles: any) => ({
    ...baseStyles,
    padding: 0,
  }),
  option: (baseStyles: any) => ({
    ...baseStyles,
    cursor: "pointer",
  }),
});

interface Props {
  driverId: number | null;
  vehicleCategory: VehicleCategory;
  bookingStatus: BookingStatus;
  setBooking: React.Dispatch<React.SetStateAction<Booking | null>>;
}

export const BookingDriverSelector: FC<Props> = ({
  driverId,
  vehicleCategory,
  bookingStatus,
  setBooking,
}) => {
  const { bookingId } = useParams();
  const [selectedOption, setSelectedOption] =
    useState<DriverSelectorOption | null>(null);
  const [options, setOptions] = useState<DriverSelectorOption[]>([]);
  const [isPrevDirverLoading, setIsPrevDriverLoading] = useState(
    Boolean(driverId)
  );
  const { isLoading, setIsLoading } = useLoading();

  const isSelectorDisabled = useMemo(
    () =>
      bookingStatus === "No show" ||
      bookingStatus === "Cancelled" ||
      bookingStatus === "On board" ||
      bookingStatus === "Completed",
    [bookingStatus]
  );

  const selectorStyles = useMemo(
    () => getSelectorStyles(isSelectorDisabled),
    [isSelectorDisabled]
  );

  const getPrevDriver = async () => {
    if (driverId) {
      try {
        setIsPrevDriverLoading(true);
        const { data } = await getDriverApi(driverId);

        if (data.success) {
          setSelectedOption({
            label: <DriverItem driver={data.driver} />,
            value: data.driver.id,
          });
        }
      } catch (error) {
        toastServerError(error, "Something went wrong with driver loading");
      } finally {
        setIsPrevDriverLoading(false);
      }
    }
  };

  const getOptions = async () => {
    try {
      const { data } = await fetchAvalibleDriversForBooking(vehicleCategory);

      if (data.drivers.length !== 0) {
        setOptions(
          data.drivers.map((driver) => ({
            label: <DriverItem driver={driver} />,
            value: driver.id,
          }))
        );
      }
    } catch (error) {
      toastServerError(
        error,
        "Something went wrong with available drivers loading"
      );
    }
  };

  const handleDriverChange = async (option: DriverSelectorOption) => {
    try {
      if (driverId === option.value) {
        return;
      }

      setIsLoading(true);
      const { data } = await reserveOrderForDriver({
        driverId: option.value,
        orderId: Number(bookingId),
      });
      if (data.success) {
        setBooking(data.offer);
        setSelectedOption(option);
      }
    } catch (error) {
      toastServerError(error, "Something went wrong with driver changing");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (driverId) {
      getPrevDriver();
    }
  }, []);

  useEffect(() => {
    getOptions();
  }, [selectedOption]);

  return (
    <>
      {driverId && isPrevDirverLoading ? (
        <Skeleton height={94} />
      ) : (
        <ReactSelect
          isDisabled={isSelectorDisabled}
          isSearchable={false}
          defaultOptions={options}
          styles={selectorStyles}
          theme={(theme) => ({
            ...theme,
            borderRadius: 4,
            colors: {
              ...theme.colors,
              primary: "#AA8768",
              primary25: "#d1b8a1",
              primary50: "#d1b8a1",
              danger: "#E53E3E",
              dangerLight: "#E53E3E",
              neutral20: "#bbb2a8",
              neutral30: "#AA8768",
              neutral40: "#1C1C1C",
              neutral80: "#1C1C1C",
            },
          })}
          value={selectedOption}
          onChange={(option) => {
            handleDriverChange(option as DriverSelectorOption);
          }}
          placeholder={
            isSelectorDisabled
              ? "None of the drivers have accepted an order"
              : "Select available driver"
          }
        />
      )}
      {isLoading && <LoaderScreen />}
    </>
  );
};

import React, { FC } from "react";
import Avatar from "components/Avatar";
import styles from "./styles.module.scss";
import { IDriver, IDriverItem } from "types/drivers";

interface Props {
  driver: IDriver | IDriverItem;
}

export const DriverItem: FC<Props> = ({ driver }) => {
  return (
    <p className={styles.row}>
      <Avatar img={driver?.avatar} dimensions={{ width: 24, height: 24 }} />
      {`${driver.firstName} ${driver.lastName}`}
    </p>
  );
};

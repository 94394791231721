import React, { FC, useEffect, useMemo, useState } from "react";
import { Pagination, TableHeader } from "components";
import styles from "../Users.module.scss";
import { PassengerItem } from "types/users";
import { PassengerItemRow } from "./PassengerItemRow";
import { fetchPassengers, fetchUsersBody } from "api/users";
import { USERS_LOADING_LIMIT } from "utils/constants";
import Skeleton from "react-loading-skeleton";
import { EmptyTablePlaceholder } from "components/EmptyTablePlaceholder";
import { useLocation } from "react-router-dom";

const columnsFlexes = [29.4, 17.3, 29.9, 23.2];

interface Props {
  search: string;
  setCustomersTotal: React.Dispatch<React.SetStateAction<number>>;
  setPassengersTotal: React.Dispatch<React.SetStateAction<number>>;
}

export const PassengersTable: FC<Props> = ({
  search,
  setCustomersTotal,
  setPassengersTotal,
}) => {
  const { state } = useLocation();
  const [passenegers, setPassengers] = useState<PassengerItem[]>([]);
  const [sortedIndex, setSortedIndex] = useState<number>(3);
  const [sortedType, setSortedType] = useState<"desc" | "asc">("desc");
  const [page, setPage] = useState(state?.page || 1);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const getPassengersList = async () => {
    try {
      if (true) {
        setIsLoading(true);

        const requestBody: fetchUsersBody = {
          search: search || null,
          sorting: {
            sortBy: "createdAt",
            order: sortedType.toUpperCase() as "ASC" | "DESC",
          },
        };

        const { data } = await fetchPassengers(requestBody, page);

        if (data.success) {
          setPassengers(data.guests);
          setCustomersTotal(data.totalCustomers);
          setPassengersTotal(data.totalPassengers);
          setTotalItems(data.totalGuests);
        }
      }
    } catch (error) {
      console.log("getPassengersList ERROR: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPassengersList();
  }, [page, sortedType, sortedIndex]);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (page !== 1) {
      setPage(1);
    } else {
      getPassengersList();
    }
  }, [search]);

  return (
    <>
      <div className={styles.users_table}>
        <TableHeader
          items={[
            "Customer",
            "Phone number",
            "Account holder",
            "Registration date",
          ]}
          flexes={columnsFlexes}
          selectedIndex={sortedIndex}
          sortedType={sortedType}
          onChangeSorted={(val) => {
            setSortedIndex((prev) => (val === null ? prev : val));
          }}
          onChangeType={(order) => {
            setSortedType(order === null ? "asc" : order);
          }}
          sortIndexes={[3]}
          gap={24}
        />

        {passenegers.length !== 0 && !isLoading ? (
          passenegers.map((passenger) => (
            <PassengerItemRow
              passenger={passenger}
              columsFlexes={columnsFlexes}
              page={page}
            />
          ))
        ) : isLoading ? (
          <Skeleton
            height={90}
            style={{ marginBottom: 2, marginTop: 2, borderRadius: 10 }}
            count={10}
          />
        ) : (
          <EmptyTablePlaceholder text="There are no passengers matching your choice" />
        )}
      </div>

      {totalItems !== 0 && (
        <div className={styles.users_pagination}>
          <Pagination
            currentPage={page}
            count={totalItems}
            limit={USERS_LOADING_LIMIT}
            onChange={setPage}
          />
        </div>
      )}
    </>
  );
};

import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";
import styles from "./SuportChatWidget.module.scss";
import { ChatBubble } from "./ChatBubble";

import classNames from "classnames";
import { ChatHeader } from "./ChatHeader";
import { ChatType, useSupportChatsList } from "hooks/useSupportChatsList";
import { ChatItemsList } from "./ChatItemsList";
import { ChatCategoriesFooter } from "./ChatCategoriesFooter";
import { useSupportChat } from "hooks/useSupportChat";
import { ChatMessagesList } from "./ChatMessagesList";
import { NewMessageFooter } from "./NewMessageFooter";
import { SocketContext } from "context/socket";
import { ChatListItemWithClientName } from "types/chat";
import { UserContext } from "context/user";
import { getFilteredSendingMessages } from "utils/helpers/getFilteredSendingMessages";
import {
  ChangeTypingStatusEventData,
  SuportMessageEventData,
  SupportChatStatusChangedEventData,
  ViewedMessageEventData,
} from "types/socketDataTypes";
import { fetchClientsNamesAndPhonesByIds } from "api/users";
import LoaderScreen from "components/LoaderScreen";
import { CHAT_COMPLETED_MESSAGE_TEXT } from "utils/constants";
import { toast } from "react-hot-toast";
import { chatMessageToastOptions } from "utils/toasterStyles";

interface IProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const SupportChatWidget: React.FC<IProps> = ({ isOpen, setIsOpen }) => {
  const { user } = useContext(UserContext);
  const { socket, isUserConnectedToSocket } = useContext(SocketContext);

  const {
    selectedChatsType,
    setSelectedChatsType,
    chatsList,
    setChatsList,
    hasMoreChats,
    isLoading,
    page,
    loadMoreChatItems,
    loadChatForNewMessage,
    allUnreadCounter,
    setAllUnreadCounter,
  } = useSupportChatsList();

  const {
    selectedChat,
    setSelectedChat,
    setMessages,
    allMessagesList,
    setSendingMessages,
    isMessagesLoading,
    hasMoreMessages,
    messagesPage,
    newMessageText,
    setNewMessageText,
    handleSendMessage,
    handleSendImages,
    loadMoreMessages,
    isSelectedChatStatusUpdating,
    handleCompleteChatClick,
  } = useSupportChat();

  const selectedChatRef = useRef<ChatListItemWithClientName | null>(null);
  const selectedChatsTypeRef = useRef<ChatType>(selectedChatsType);
  const chatsListRef = useRef(chatsList);
  const isOpenRef = useRef(isOpen);

  const handleChatViewed = useCallback(
    (viewedMessageId: number) => {
      setChatsList((prev) =>
        prev.map((chatItem) => {
          if (chatItem.id === selectedChat?.id) {
            setAllUnreadCounter(
              (prevCounterValue) =>
                prevCounterValue - Number(chatItem.unreadMessages) || 0
            );
          }

          return chatItem.id === selectedChat?.id
            ? { ...chatItem, unreadMessages: 0 }
            : chatItem;
        })
      );

      setMessages((prev) =>
        prev.map((message) =>
          message.authorType === "admin"
            ? message
            : {
                ...message,
                isViewed:
                  message.id <= viewedMessageId ? true : message.isViewed,
              }
        )
      );
    },
    [selectedChat?.id]
  );

  useEffect(() => {
    selectedChatsTypeRef.current = selectedChatsType;
  }, [selectedChatsType]);

  useEffect(() => {
    selectedChatRef.current = selectedChat;
  }, [selectedChat]);

  useEffect(() => {
    chatsListRef.current = chatsList;
  }, [chatsList]);

  useEffect(() => {
    isOpenRef.current = isOpen;
  }, [isOpen]);

  useEffect(() => {
    if (isUserConnectedToSocket) {
      socket?.on(
        "supportMessage",
        async ({ message, allUnreadMessages }: SuportMessageEventData) => {
          setAllUnreadCounter(allUnreadMessages || 0);

          if (message.authorType !== "admin") {
            const audio = new Audio(
              require("../../assets/sounds/toast_sound.mp3")
            );
            audio.play();

            if (!isOpenRef.current) {
              toast(
                "You have a new message from a client.",
                chatMessageToastOptions
              );
            }
          }

          if (
            selectedChatRef.current &&
            selectedChatRef.current.id === message.chatId
          ) {
            let localImageCopy: File | null = null;

            if (user?.id === message.authorId) {
              setSendingMessages((prev) => {
                const filterResult = getFilteredSendingMessages(prev, message);

                localImageCopy = filterResult.localImage || null;

                return filterResult.filteredMessages;
              });
            }

            setMessages((prev) => [
              { ...message, content: localImageCopy || message.content },
              ...prev,
            ]);
          }

          if (selectedChatsTypeRef.current === "ongoing") {
            if (message.content === CHAT_COMPLETED_MESSAGE_TEXT) {
              return;
            }

            const isChatAlreadyLoaded = chatsListRef.current.some(
              (chatItem) => chatItem.id === message.chatId
            );

            if (isChatAlreadyLoaded) {
              setChatsList((prevList) =>
                prevList.reduce(
                  (newList: ChatListItemWithClientName[], chatItem) => {
                    if (chatItem.id !== message.chatId) {
                      return [...newList, chatItem];
                    } else {
                      return [
                        {
                          ...chatItem,
                          unreadMessages:
                            message.authorType !== "admin"
                              ? (chatItem.unreadMessages || 0) + 1
                              : chatItem.unreadMessages,
                          updatedAt: message.createdAt,
                        },
                        ...newList,
                      ];
                    }
                  },
                  []
                )
              );
            } else {
              loadChatForNewMessage(message);
            }
          }
        }
      );

      socket?.on("viewedMessages", (data: ViewedMessageEventData) => {
        if (
          selectedChatRef.current?.id === data.chatId &&
          data.role !== "admin"
        ) {
          setMessages((prev) =>
            prev.map((message) =>
              message.id <= data.lastViewedMessageId
                ? { ...message, isViewed: true }
                : message
            )
          );
        }
      });

      socket?.on("startTyping", (data: ChangeTypingStatusEventData) => {
        if (
          selectedChatRef.current?.id === data.chatId &&
          data.role === "client"
        ) {
          setSelectedChat((prev) =>
            prev ? { ...prev, isClientTyping: true } : prev
          );
        }
      });

      socket?.on("stopTyping", (data: ChangeTypingStatusEventData) => {
        if (
          selectedChatRef.current?.id === data.chatId &&
          data.role === "client"
        ) {
          setSelectedChat((prev) =>
            prev ? { ...prev, isClientTyping: false } : prev
          );
        }
      });

      socket?.on(
        "supportChatStatusChanged",
        async ({ chat }: SupportChatStatusChangedEventData) => {
          if (chat.isCompleted) {
            if (selectedChatsTypeRef.current === "completed") {
              const { data: clientsData } =
                await fetchClientsNamesAndPhonesByIds([chat.clientId]);
              const clientName = `${clientsData.clients[0].firstName} ${clientsData.clients[0].lastName}`;

              setChatsList((prev) => [{ ...chat, clientName }, ...prev]);
            } else {
              setChatsList((prev) =>
                prev.filter((chatItem) => chatItem.id !== chat.id)
              );
            }
          } else if (selectedChatsTypeRef.current === "completed") {
            setChatsList((prev) =>
              prev.filter((chatItem) => chatItem.id !== chat.id)
            );
          }

          if (selectedChatRef.current?.id === chat.id) {
            setSelectedChat((prev) =>
              prev ? { ...prev, isCompleted: chat.isCompleted } : prev
            );
          }
        }
      );
    }

    return () => {
      socket?.off("supportMessage");
      socket?.off("viewedMessages");
      socket?.off("startTyping");
      socket?.off("stopTyping");
      socket?.off("supportChatStatusChanged");
    };
  }, [isUserConnectedToSocket]);

  return (
    <>
      <div className={styles.chatWidget_bubleContainer}>
        <ChatBubble isOpen={isOpen} setIsOpen={setIsOpen} />
        {allUnreadCounter !== 0 && (
          <div className={styles.chatWidget_allUnreadCounter}>
            {allUnreadCounter}
          </div>
        )}
      </div>

      <div
        className={classNames(styles.chatWidget_container, {
          [styles.displayed]: isOpen,
        })}
      >
        <ChatHeader
          selectedChatsType={selectedChatsType}
          selectedChat={selectedChat}
          hadleBackClick={() => setSelectedChat(null)}
          handleCompleteClick={handleCompleteChatClick}
        />

        <div
          className={classNames(styles.chatWidget_mainContent, {
            [styles.chatWidget_mainContent__forMessages]: selectedChat,
          })}
        >
          {selectedChat ? (
            <ChatMessagesList
              isChatOpen={isOpen}
              selectedChat={selectedChat}
              messages={allMessagesList}
              isLoading={isMessagesLoading}
              page={messagesPage}
              hasMoreMessages={hasMoreMessages}
              loadMoreMessages={loadMoreMessages}
              onMessagesViewed={handleChatViewed}
            />
          ) : (
            <ChatItemsList
              chatsList={chatsList}
              isLoading={isLoading}
              page={page}
              hasMoreChats={hasMoreChats}
              onChatClick={setSelectedChat}
              onLoadMore={loadMoreChatItems}
            />
          )}
        </div>

        {selectedChat ? (
          !selectedChat.isCompleted ? (
            <NewMessageFooter
              chatId={selectedChat.id}
              newMessageText={newMessageText}
              setNewMessageText={setNewMessageText}
              handleSendMessage={handleSendMessage}
              handleSendImages={handleSendImages}
            />
          ) : null
        ) : (
          <ChatCategoriesFooter
            setSelectedChatsType={setSelectedChatsType}
            selectedChatsType={selectedChatsType}
          />
        )}
      </div>
      {isSelectedChatStatusUpdating && <LoaderScreen />}
    </>
  );
};

import { apiAxios } from "api";
import { AxiosResponse } from "axios";
import { ServerResponse } from "types";
import {
  ActivePrice,
  AirportPrice,
  FixedPrice,
  HolidaySurcharge,
  HolidaySurchargeFromServer,
} from "types/prices";

interface fetchActivePricesRespose extends ServerResponse {
  prices: ActivePrice[];
}

interface fetchFixedPricesRespose extends ServerResponse {
  fixedPrices: FixedPrice[];
  totalFixedPrices: number;
}

interface createFixedPriceReasponse extends ServerResponse {
  fixedPrice: FixedPrice;
  totalFixedPrices: number;
}

interface updateFixedPriceResponse extends ServerResponse {
  fixedPrice: FixedPrice;
  totalFixedPrices: number;
}

interface fetchHolidaysRespose extends ServerResponse {
  holidays: HolidaySurchargeFromServer[];
  totalHolidays: number;
}

interface createNewHolidayResponse extends ServerResponse {
  holiday: HolidaySurchargeFromServer;
}

export interface fetchAirportFeeBody {
  sorting: {
    sortBy: "name" | "createdAt";
    order: "ASC" | "DESC";
  };
  search?: string;
}

export interface fetchAirportPricesResponse extends ServerResponse {
  fixedPrices: AirportPrice[];
  totalFixedPrices: number;
}

interface createAirportPriceResponse extends ServerResponse {
  fixedPrice: AirportPrice;
}

interface updateAirportPriceResponse extends ServerResponse {
  fixedPrice: AirportPrice;
}

export const fetchActivePrices = (): Promise<
  AxiosResponse<fetchActivePricesRespose>
> => {
  return apiAxios.get("/price/active");
};

export const updateActivePrices = (body: {
  prices: ActivePrice[];
}): Promise<AxiosResponse<Response>> => {
  return apiAxios.patch("/price/active", body);
};

export const fetchFixedPrices = (
  page = 1,
  limit = 999
): Promise<AxiosResponse<fetchFixedPricesRespose>> => {
  return apiAxios.get(`/price/fixed?page=${page}&limit=${limit}`);
};

export const createFixedPrice = (
  body: Omit<FixedPrice, "id">
): Promise<AxiosResponse<createFixedPriceReasponse>> => {
  return apiAxios.post("/price/fixed", body);
};

export const updateFixedPrice = (
  priceId: number,
  body: Omit<FixedPrice, "id">
): Promise<AxiosResponse<updateFixedPriceResponse>> => {
  return apiAxios.put(`/price/fixed?id=${priceId}`, body);
};

export const removeFixedPrive = (
  priceId: number
): Promise<AxiosResponse<ServerResponse>> => {
  return apiAxios.delete(`/price/fixed?id=${priceId}`);
};

export const fetchHolidays = (): Promise<
  AxiosResponse<fetchHolidaysRespose>
> => {
  return apiAxios.get(`/price/holiday`);
};

export const createNewHoliday = (): Promise<
  AxiosResponse<createNewHolidayResponse>
> => {
  return apiAxios.post(`/price/holiday`);
};

export const removeHoliday = (
  holidayId: number
): Promise<AxiosResponse<ServerResponse>> => {
  return apiAxios.delete(`/price/holiday?id=${holidayId}`);
};

export const updateHolidays = (body: {
  holidays: HolidaySurcharge[];
}): Promise<AxiosResponse<ServerResponse>> => {
  console.log("Request body: ", body);
  return apiAxios.put("/price/holiday", body);
};

export const fetchAsDirectedAirportPickUpPrices = (
  body: fetchAirportFeeBody,
  page = 1,
  limit = 9999
): Promise<AxiosResponse<fetchAirportPricesResponse>> => {
  return apiAxios.post(
    `/price/as-directed-airport-fixed/list?page=${page}&limit=${limit}`,
    body
  );
};

export const fetchOneWayAirportPickUpPrices = (
  body: fetchAirportFeeBody,
  page = 1,
  limit = 9999
): Promise<AxiosResponse<fetchAirportPricesResponse>> => {
  return apiAxios.post(
    `/price/oneway-airport-pickup-fixed/list?page=${page}&limit=${limit}`,
    body
  );
};

export const fetchOneWayAirportDropOffPrices = (
  body: fetchAirportFeeBody,
  page = 1,
  limit = 9999
): Promise<AxiosResponse<fetchAirportPricesResponse>> => {
  return apiAxios.post(
    `/price/oneway-airport-dropoff-fixed/list?page=${page}&limit=${limit}`,
    body
  );
};

export const createAsDirectedAirportPickUpPrice = (
  body: Omit<AirportPrice, "id">
): Promise<AxiosResponse<createAirportPriceResponse>> => {
  return apiAxios.post(`/price/as-directed-airport-fixed`, body);
};

export const createOneWayAirportPickUpPrice = (
  body: Omit<AirportPrice, "id">
): Promise<AxiosResponse<createAirportPriceResponse>> => {
  return apiAxios.post(`/price/oneway-airport-pickup-fixed`, body);
};

export const createOneWayAirportDropOffPrice = (
  body: Omit<AirportPrice, "id">
): Promise<AxiosResponse<createAirportPriceResponse>> => {
  return apiAxios.post(`/price/oneway-airport-dropoff-fixed`, body);
};

export const updateAsDirectedAirportPickUpPrice = (
  priceId: number,
  body: Omit<AirportPrice, "id">
): Promise<AxiosResponse<updateAirportPriceResponse>> => {
  return apiAxios.patch(`/price/as-directed-airport-fixed?id=${priceId}`, body);
};

export const updateOneWayAirportPickUpPrice = (
  priceId: number,
  body: Omit<AirportPrice, "id">
): Promise<AxiosResponse<updateAirportPriceResponse>> => {
  return apiAxios.patch(
    `/price/oneway-airport-pickup-fixed?id=${priceId}`,
    body
  );
};

export const updateOneWayAirportDropOffPrice = (
  priceId: number,
  body: Omit<AirportPrice, "id">
): Promise<AxiosResponse<updateAirportPriceResponse>> => {
  return apiAxios.patch(
    `/price/oneway-airport-dropoff-fixed?id=${priceId}`,
    body
  );
};

export const removeAsDirectedAirportPickUpPrice = (
  priceId: number
): Promise<AxiosResponse<ServerResponse>> => {
  return apiAxios.delete(`/price/as-directed-airport-fixed?id=${priceId}`);
};

export const removeOneWayAirportPickUpPrice = (
  priceId: number
): Promise<AxiosResponse<ServerResponse>> => {
  return apiAxios.delete(`/price/oneway-airport-pickup-fixed?id=${priceId}`);
};

export const removeOneWayAirportDropOffPrice = (
  priceId: number
): Promise<AxiosResponse<ServerResponse>> => {
  return apiAxios.delete(`/price/oneway-airport-dropoff-fixed?id=${priceId}`);
};

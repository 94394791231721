import { FC, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styles from "./styles.module.scss";
import { ICONS } from "assets";

type Props = {
  img?: string | null;
  dimensions?: { width: number; height: number };
};

const Avatar: FC<Props> = ({ img, dimensions = { width: 44, height: 44 } }) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(img ? false : true);

  return (
    <div className={styles.wrapper} style={{ ...dimensions }}>
      {img ? (
        <img
          className={styles.wrapper_image}
          src={img}
          onLoad={() => setIsLoaded(true)}
          alt="avatar"
        />
      ) : (
        <ICONS.AvatarPlaceholder {...dimensions} />
      )}
      {!isLoaded && (
        <div className={styles.wrapper_skeletone}>
          <Skeleton
            containerClassName="flex-1"
            height={dimensions.height}
            width={dimensions.width}
            circle
          />
        </div>
      )}
    </div>
  );
};

export default Avatar;

import { Form, Formik, useFormik } from "formik";
import styles from "./style.module.scss";
import { Button, TableHeader } from "components";
import { ActivePrice, ActivePriceInForm } from "types/prices";
import { ActivePricesFormRow } from "./ActivePricesFormRow";
import { VariantButton } from "types/enums";
import { Fragment, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { errorToastOptions, successToastOptions } from "utils/toasterStyles";
import { fetchActivePrices, updateActivePrices } from "api/prices";
import Skeleton from "react-loading-skeleton";
import LoaderScreen from "components/LoaderScreen";
import { activePriceValidationSchema } from "utils/validation/Prices";

const columnsFlexes = [0.159, 0.168, 0.168, 0.168, 0.168, 0.168];

export const ActivePricesContent = () => {
  const [isUpdatingLoading, setIsUpdatingLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [savedPrices, setSavedPrices] = useState<ActivePriceInForm[] | null>(
    null
  );

  const handleFormSubmit = async ({
    prices,
  }: {
    prices: ActivePriceInForm[];
  }) => {
    try {
      setIsUpdatingLoading(true);
      const updatedPrices: ActivePrice[] = prices.map((price) => ({
        category: price.category,
        minimumFare: +price.minimumFare,
        perMile: +price.perMile,
        perMinute: +price.perMinute,
        driversCommission: +price.driversCommission,
        childSeat: price.childSeat === null ? null : +price.childSeat,
      }));

      await updateActivePrices({ prices: updatedPrices });

      toast("Active prices updated successfully!", successToastOptions);
    } catch (error) {
      console.log("[getActivePrices ERROR]: ", error);
      toast(
        "Somewhere went wrong with active prices updating!",
        errorToastOptions
      );
    } finally {
      setIsUpdatingLoading(false);
    }
  };

  const getActivePrices = async () => {
    try {
      setIsLoading(true);
      const { data } = await fetchActivePrices();

      if (data.success) {
        const formData = data.prices.map((price) => {
          return Object.entries(price).reduce((acc, [key, value]) => {
            //@ts-ignore
            acc[key] = typeof value === "number" ? String(value) : value;
            return acc;
          }, {});
        });

        setSavedPrices(formData as ActivePriceInForm[]);
      }
    } catch (error) {
      console.log("[getActivePrices ERROR]: ", error);
      toast(
        "Somewhere went wrong with active prices loading",
        errorToastOptions
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getActivePrices();
  }, []);

  return (
    <div className={styles.content}>
      <span className={styles.content_description}>
        All prices calculated and displayed in GBP (£)
      </span>

      <TableHeader
        items={[
          "Category",
          "Minimum fare",
          "Per mile",
          "Per minute",
          "Driver’s commission",
          "Child/booster seat",
        ]}
        flexes={[0.159, 0.168, 0.168, 0.168, 0.168, 0.168]}
        gap={24}
      />

      {savedPrices && !isLoading ? (
        <Formik
          initialValues={{ prices: savedPrices }}
          validationSchema={activePriceValidationSchema}
          onSubmit={handleFormSubmit}
          validateOnChange={false}
          validateOnBlur={true}
        >
          {({ values, touched, errors, handleSubmit, handleReset }) => {
            console.log("errors: ", errors);
            return (
              <div className={styles.form}>
                {values.prices.map((price, index) => {
                  return (
                    <Fragment key={price.category}>
                      <ActivePricesFormRow
                        rowIndex={index}
                        columnsFlexes={columnsFlexes}
                      />
                    </Fragment>
                  );
                })}

                <div className={styles.form_buttons}>
                  <Button
                    title="Change prices"
                    onClick={handleSubmit}
                    variant={VariantButton.BLACK}
                  />
                  <Button title="Cancel" onClick={handleReset} />
                </div>
              </div>
            );
          }}
        </Formik>
      ) : isLoading ? (
        <Skeleton
          height={74}
          style={{ marginBottom: 2, marginTop: 2, borderRadius: 5 }}
          count={3}
        />
      ) : null}
      {isUpdatingLoading && <LoaderScreen />}
    </div>
  );
};

import { UserContext } from "context/user";
import React, { useContext } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";

const PrivateRoute: React.FC<any> = ({ children }) => {
  const { user } = useContext(UserContext);
  const { pathname } = useLocation();
  let token = localStorage.getItem("@accessToken");

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  if (user?.role !== "Admin" && pathname === "/staff_management") {
    return <Navigate to="/" replace />;
  }

  // If user is authenticated, render the private route
  return children;
};

export default PrivateRoute;

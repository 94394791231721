import { ICONS } from "assets";
import styles from "./UserDetails.module.scss";
import Header from "components/Header";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "utils/constants";
import { Button } from "components";
import { VariantButton } from "types/enums";
import { PageContentContainer } from "components/PageContentContainer";
import { TabLink } from "components/TabLink";
import { BookingsTable } from "components/BookingsTable";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { successToastOptions } from "utils/toasterStyles";
import {
  deleteUsersByIds,
  fetchClientById,
  fetchCustomersByIds,
  updateUsersBlockStatusByIds,
} from "api/users";
import { useUserBookingsList } from "hooks/useUserBookingsList";
import { Client, PassengerItem } from "types/users";
import { UserDataSkeleton } from "./UserDataSkeleton";
import LoaderScreen from "components/LoaderScreen";
import { confirmAlert } from "react-confirm-alert";
import { ConfirmAlert } from "components/ConfirmAlert";
import { toastServerError } from "utils/helpers";

export const UserDetails = () => {
  const { state } = useLocation();
  const {
    setPage,
    setSortedIndex,
    setSortedType,
    getBookingsItems,
    sortedIndex,
    sortedType,
    isLoading,
    totalItems,
    bookingsItems,
    page,
  } = useUserBookingsList();

  const navigate = useNavigate();
  const { selectedUsersType, userId } = useParams();
  const [customer, setCustomer] = useState<Client | null>(null);
  const [passenger, setPassenger] = useState<PassengerItem | null>(null);
  const [isUserLoading, setIsUserLoading] = useState(true);
  const [isUserUpdating, setIsUserUpdating] = useState(false);

  const getCustomer = async () => {
    try {
      setIsUserLoading(true);

      const { data } = await fetchClientById(Number(userId));

      if (data.success) {
        setCustomer(data.client);
      }
    } catch (error) {
      toastServerError(error, "Something went wrong with user loading");
    } finally {
      setIsUserLoading(false);
    }
  };

  const getPassenger = async () => {
    try {
      setIsUserLoading(true);

      const { data } = await fetchCustomersByIds([Number(userId)]);

      setPassenger(data.guests[0]);
    } catch (error) {
      toastServerError(error, "Something went wrong with passenger loading");
    } finally {
      setIsUserLoading(false);
    }
  };

  const changeCustomerBlockStatus = async () => {
    try {
      if (customer) {
        setIsUserUpdating(true);

        const { data } = await updateUsersBlockStatusByIds(
          [customer.id],
          !customer.isBlocked
        );

        if (data.success) {
          setCustomer((prev) =>
            prev ? { ...prev, isBlocked: !prev?.isBlocked } : prev
          );
        }
      }
    } catch (error) {
      toastServerError(
        error,
        "Something went wrong with user block status updating"
      );
    } finally {
      setIsUserUpdating(false);
    }
  };

  const deleteCustomer = async () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmAlert
          question="Are you sure, that you want to delete user?"
          onConfirm={async () => {
            try {
              if (customer) {
                const { data } = await deleteUsersByIds([customer.id]);

                if (data.success) {
                  onClose();
                  toast("User deleted successfully!", successToastOptions);
                }
              }
            } catch (error) {
              toastServerError(
                error,
                "Something went wrong with user deleting"
              );
            }
          }}
          onClose={() => {
            onClose();
          }}
        />
      ),
    });
  };

  const handleHolderClick = () => {
    if (passenger) {
      navigate(`/users/customers/${passenger.holder.id}/upcoming`);
      setPassenger(null);
    }
  };

  useEffect(() => {
    setPassenger(null);
    setCustomer(null);

    if (selectedUsersType === "customers") {
      getCustomer();
    } else if (selectedUsersType === "passengers") {
      getPassenger();
    }
  }, [userId, selectedUsersType]);

  return (
    <div className={styles.userPage}>
      <Header>
        <div className={styles.userPage_header}>
          <button
            className={styles.userPage_backBtn}
            onClick={() =>
              navigate(`/users/${selectedUsersType}`, {
                state: { page: state?.page },
              })
            }
          >
            <ICONS.BackArrow />
          </button>

          <h3 className={styles.userPage_title}>
            {selectedUsersType === "customers" ? "Customer" : "Passenger"}
          </h3>

          {selectedUsersType === "customers" && customer && (
            <div className={styles.userPage_headerButtons}>
              <Button
                variant={VariantButton.White}
                title={customer?.isBlocked ? "Unblock" : "Block"}
                onClick={changeCustomerBlockStatus}
              />

              <Button
                variant={VariantButton.Red}
                title={`Delete`}
                onClick={deleteCustomer}
              />
            </div>
          )}
        </div>
      </Header>

      <PageContentContainer>
        <div className={styles.userPage_contentContainer}>
          <div className={styles.userData}>
            {!isUserLoading && (customer || passenger) ? (
              <>
                {customer && (
                  <div className={styles.userData_userBlock}>
                    <p
                      className={styles.userData_userName}
                    >{`${customer.firstName} ${customer.lastName}`}</p>
                    {customer?.email && (
                      <p className={styles.userData_commonText}>
                        {customer.email}
                      </p>
                    )}
                    <p className={styles.userData_commonText}>
                      {customer.phoneNumber}
                    </p>
                  </div>
                )}

                {passenger && (
                  <div className={styles.userData_userBlock}>
                    <p
                      className={styles.userData_userName}
                    >{`${passenger.firstName} ${passenger.lastName}`}</p>
                    <p className={styles.userData_commonText}>
                      {passenger.phoneNumber}
                    </p>
                  </div>
                )}

                {selectedUsersType === "passengers" && passenger?.holder && (
                  <div
                    className={styles.holderBlock}
                    onClick={handleHolderClick}
                  >
                    <div>
                      <h5 className={styles.holderBlock_title}>
                        Account holder
                      </h5>
                      <p
                        className={styles.holderBlock_name}
                      >{`${passenger.holder.firstName} ${passenger.holder.lastName}`}</p>
                      <p className={styles.userData_commonText}>
                        {passenger.holder.email}
                      </p>
                      <p className={styles.userData_commonText}>
                        {passenger.holder.phoneNumber}
                      </p>
                    </div>

                    <ICONS.GoNextIcon />
                  </div>
                )}
              </>
            ) : isUserLoading ? (
              <UserDataSkeleton />
            ) : null}
          </div>

          <div>
            {ROUTES.userDetails.map((routeItem) => (
              <TabLink
                route={`/users/${selectedUsersType}/${userId}/${routeItem.route}`}
                label={routeItem.label}
                key={routeItem.label}
              />
            ))}
          </div>

          <BookingsTable
            sortedIndex={sortedIndex}
            sortedType={sortedType}
            setSortedType={setSortedType}
            setSortedIndex={setSortedIndex}
            refetchBookings={getBookingsItems}
            isLoading={isLoading}
            bookingsItems={bookingsItems}
            totalItems={totalItems}
            page={page}
            setPage={setPage}
          />
        </div>
      </PageContentContainer>
      {isUserUpdating && <LoaderScreen />}
    </div>
  );
};

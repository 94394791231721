import axios from "axios";
import { clearTokens, getTokens } from "./../utils/tokenService";
import { toast } from "react-hot-toast";
import { errorToastOptions } from "utils/toasterStyles";

export const apiAxios = axios.create({
  headers: {
    "content-type": "application/json",
  },
  baseURL: "https://api.preevo.co.uk/",
});

apiAxios.interceptors.request.use(async (config) => {
  //token
  const token = await getTokens();
  if (token) {
    console.log("token", JSON.parse(token));
    let t = JSON.parse(token);
    config.headers.Authorization = `Bearer ${JSON.parse(token)}`;
  }

  //abort with SetTimeout 10sec
  const abortController = new AbortController();
  const timeout = 10000;

  setTimeout(() => {
    abortController.abort();
  }, timeout);

  config.timeout = timeout;

  config.cancelToken = new axios.CancelToken((cancel) => {
    abortController.signal.addEventListener("abort", () => {
      cancel("Request cancelled due to timeout");
    });
  });

  return config;
});

//   apiAxios.interceptors.response.use(
//     response => {
//       clearTimeout(response.config.timeout);
//       return response;
//     },
//     error => {
//       if (error.code === 'ABORTED') {
//         // Handle timeout error
//         Alert.alert('Request timed out');
//         throw new Error('Request timed out');
//       }
//       throw error;
//     },
//   );

export const handleUnauthorized = async () => {
  // Handle any cleanup or logout logic you need
  // then navigate to the login page
  await clearTokens();
  window.location.assign("/login");
};

// Add response interceptor
apiAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    // Check if the response status is 401 and if there is a refresh token available
    console.log("jjjjjjjjwwwwwtttt", error.response);
    // if (error.response === undefined) {
    //   toast("Network error!", errorToastOptions);
    // }
    if (
      error.response.data.message === "Token is not available" ||
      error.response.data.message === "jwt expired"
    ) {
      handleUnauthorized();
      console.log("status 404");
      // Add your logic here to refresh the access token using the refresh token
      // try {
      //   const oldToken = await AsyncStorage.getItem("refresh_token");
      //   if (oldToken) {
      //     const { data } = await refreshAccessToken(oldToken);
      //     if (data.accessToken) {
      //       originalRequest.headers.Authorization = `Bearer ${data.accessToken}`;
      //       await AsyncStorage.setItem("access_token", data.accessToken as any);
      //       // await AsyncStorage.setItem('user_session', '');
      //     }
      //   }
      //   // Update the original request headers with the new access token
      //   // Retry the original request with the updated headers
      //   return apiAxios(originalRequest);
      // } catch (refreshError) {
      //   // Handle the token refresh error
      //   console.error("Error refreshing access token:", refreshError);
      //   // Redirect or handle the unauthorized request appropriately
      //   throw refreshError;
      // }
    }

    return Promise.reject(error);
  }
);

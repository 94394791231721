import { ICONS } from "assets";
import { useNavigate } from "react-router-dom";
import { Booking } from "types/bookings";
import styles from "./BookingDetails.module.scss";
import { FC, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { fetchClientById } from "api/users";
import { Client } from "types/users";
import { ROUTES } from "utils/constants";
import { toastServerError } from "utils/helpers";

interface Props {
  booking: Booking;
}

export const BookingClientData: FC<Props> = ({ booking }) => {
  const { clientId, guestFirstName, guestLastName, guestPhoneNumber } = booking;

  const [client, setClient] = useState<Client | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const getClientData = async () => {
    try {
      setIsLoading(true);

      const { data } = await fetchClientById(clientId);

      if (data.success) {
        setClient(data.client);
      }
    } catch (error) {
      toastServerError(error, "Something went wrong with client data fetching");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getClientData();
  }, []);

  const handleClientClick = () => {
    if (!isLoading && client) {
      if (guestFirstName && guestLastName && guestPhoneNumber) {
        navigate(`/${ROUTES.users[1].route}/${clientId}/upcoming`);
      } else {
        navigate(`/${ROUTES.users[0].route}/${clientId}/upcoming`);
      }
    }
  };

  return !isLoading && client ? (
    <div className={styles.mainInfo_clientInfoRow} onClick={handleClientClick}>
      <div className={styles.mainInfo_clientDataBlock}>
        <ICONS.PersonIcon width={20} height={20} />
        <div>
          <p className={styles.mainInfo_clientPrimarydata}>
            {guestFirstName && guestLastName
              ? `${guestFirstName} ${guestLastName} `
              : `${client.firstName} ${client.lastName} `}
          </p>
          {client ? (
            <p className={styles.mainInfo_clientSecondaryData}>
              {client.email}
            </p>
          ) : (
            <Skeleton height={16} />
          )}
          <p className={styles.mainInfo_clientSecondaryData}>
            {guestPhoneNumber || client.phoneNumber}
          </p>
        </div>
      </div>

      <ICONS.GoNextIcon />
    </div>
  ) : isLoading ? (
    <Skeleton height={80} style={{ position: "relative", top: -1 }} />
  ) : (
    <div className={styles.mainInfo_clientInfoRow}>
      <p>There is no information about client</p>
    </div>
  );
};

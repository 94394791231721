import Avatar from "components/Avatar";
import BackHeader from "components/BackHeader";
import { useGetDriver } from "hooks/useGetDriver";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import ButtonState from "../../../../components/common/ButtonState";
import Tabs from "../../../../components/Tabs";
import { useUpdateDriver } from "../../../../hooks/useUpdateDriver";
import Documents from "./Documents";
import styles from "./driver.module.scss";
import GeneralInformation from "../../../../components/GeneralInformationForm";
import { UpdateDriverData } from "../../../../types/drivers";
import { useDateFormat } from "../../../../hooks/useDateFormat";
import { updateDriverStatusApi } from "../../../../api/drivers";

import toast from "react-hot-toast";
import { ICONS } from "../../../../assets";
import LoaderScreen from "../../../../components/LoaderScreen";
import { useLoading } from "../../../../context/loading";
import { checkExpiration } from "utils/checkExpiration";
import { errorToastOptions, successToastOptions } from "utils/toasterStyles";
import { ExpiryAlertsContext } from "context/expiryAlerts";

export const DriverItem = () => {
  const { driverId } = useParams();
  const { driver, getDriverInfo } = useGetDriver(driverId);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [drivingLicenceExpiryDate, setDrivingLicenceExpiryDate] = useState<
    string | null
  >(null);
  const [PCOLicenceType, setPCOLicenceType] = useState<string | null>(null);
  const [PCOLicenceNumber, setPCOLicenceNumber] = useState<string | null>(null);
  const [PCOLicenceNumberError, setPCOLicenceNumberError] =
    useState<string>("");
  const [PCOLicenceExpiryDate, setPCOLicenceExpiryDate] = useState<
    string | null
  >(null);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);
  const { format } = useDateFormat();
  const { updateExpiryAlerts } = useContext(ExpiryAlertsContext);

  useEffect(() => {
    if (driver?.isActive) {
      setIsActive(driver.isActive);
    }
  }, [driver?.isActive]);

  const handleIsActiveChange = async () => {
    if (driver?.id) {
      try {
        await updateDriverStatusApi(driver.id, { isActive: !isActive });
        setIsActive((prevState) => !prevState);
        toast("Driver status successfully updated", successToastOptions);
        updateExpiryAlerts();
      } catch (err) {
        console.log("[ERROR updating status of driver] => ", err);
        toast(
          "Somthing went wrong with driver status updating",
          errorToastOptions
        );
      }
    }
  };

  const hasExpiringItem = useMemo(
    () =>
      [drivingLicenceExpiryDate, PCOLicenceExpiryDate].some((date) =>
        checkExpiration(date)
      ),
    [drivingLicenceExpiryDate, PCOLicenceExpiryDate]
  );

  useEffect(() => {
    const expiryDate = driver?.documentDetails[0].expiryDate;
    if (expiryDate) {
      const formattedExpiryDate = format(expiryDate);
      setDrivingLicenceExpiryDate(formattedExpiryDate);
    }
  }, [driver?.documentDetails[0].expiryDate]);

  useEffect(() => {
    const number = driver?.documentDetails[1].number;
    if (number) {
      setPCOLicenceNumber(number);
    }
  }, [driver?.documentDetails[1].number]);

  useEffect(() => {
    const type = driver?.documentDetails[1].type;
    if (type) {
      setPCOLicenceType(type);
    }
  }, [driver?.documentDetails[1].type]);

  useEffect(() => {
    const expiryDate = driver?.documentDetails[1].expiryDate;
    if (expiryDate) {
      const formattedExpiryDate = format(expiryDate);
      setPCOLicenceExpiryDate(formattedExpiryDate);
    }
  }, [driver?.documentDetails[1].expiryDate]);

  const { updatedDriver, updateDriver } = useUpdateDriver(driverId);

  const handleSubmit = async (
    data: Omit<UpdateDriverData, "documentDetails">
  ) => {
    setIsLoading(true);
    const message = await updateDriver({
      ...data,
      documentDetails: [
        {
          id: driver?.documentDetails[0].id || 0,
          category: "Driving",
          type: "TFL",
          expiryDate: drivingLicenceExpiryDate || "",
        },
        {
          id: driver?.documentDetails[1].id || 0,
          category: "PCO",
          type: PCOLicenceType || "",
          number: PCOLicenceNumber || "",
          expiryDate: PCOLicenceExpiryDate || "",
        },
      ],
    });

    if (message) {
      setIsLoading(false);
      return message ===
        '"documentDetails[1].number" is not allowed to be empty'
        ? "PCO Licence number is required"
        : message;
    }

    const response = await getDriverInfo();

    setIsLoading(false);
    return response;
  };

  const renderTab = useCallback(() => {
    switch (currentTab) {
      case 0:
        return driver ? (
          <GeneralInformation
            isCreate={false}
            driverData={driver}
            onSubmit={handleSubmit}
            onCancel={() => {}}
            setAvatarUrl={setAvatarUrl}
          />
        ) : null;
      case 1:
        console.log(PCOLicenceType);
        return driver ? (
          <Documents
            isCreate={false}
            driverDocumentsMainInputsClassnames={
              styles.wrapper_documentsMainInputs
            }
            drivingLicenceExpiryDate={drivingLicenceExpiryDate || ""}
            handleDrivingLicenceExpiryDateChange={(val) =>
              setDrivingLicenceExpiryDate(val)
            }
            PCOLicenceType={PCOLicenceType || ""}
            handlePCOLicenceTypeChange={(val) => setPCOLicenceType(val)}
            PCOLicenceNumber={PCOLicenceNumber || ""}
            PCOLicenceNumberError={PCOLicenceNumberError}
            handlePCOLicenceNumberChange={(val) => {
              if (val === "") {
                setPCOLicenceNumberError("Field is required!");
              } else {
                setPCOLicenceNumberError("");
              }
              setPCOLicenceNumber(val);
            }}
            PCOLicenceExpiryDate={PCOLicenceExpiryDate || ""}
            handlePCOLicenceExpiryDateChange={(val) =>
              setPCOLicenceExpiryDate(val)
            }
            driverId={driver.id}
            isDriverActive={isActive}
          />
        ) : null;
      default:
        return <p style={{ textAlign: "center" }}>default</p>;
    }
  }, [currentTab, driver, updateDriver]);

  return (
    <>
      <BackHeader
        title={"Driver"}
        onToggle={handleIsActiveChange}
        toggleActive={isActive}
      />
      {driver ? (
        <header className={styles.wrapper_header}>
          <div className={styles.wrapper_header_info}>
            <div className={styles.wrapper_header_info_avatar}>
              <Avatar
                dimensions={{ width: 100, height: 100 }}
                img={driver.avatar}
              />
            </div>
            <div className={styles.wrapper_header_text}>
              <div className={styles.wrapper_header_info_name}>
                <span>
                  {driver.firstName} {driver.lastName}
                </span>
                <ButtonState isActive={isActive} />
              </div>
              <span className={styles.wrapper_header_info_location}>
                {driver.id}
              </span>
            </div>
          </div>
        </header>
      ) : null}
      <Tabs
        tabs={[
          { title: "General Information" },
          { title: "Documents", attention: hasExpiringItem && isActive },
        ]}
        currentIndex={currentTab}
        onChange={setCurrentTab}
      />
      {renderTab()}
      {isLoading ? <LoaderScreen /> : null}
    </>
  );
};

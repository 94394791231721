import React from "react";

import styles from "./BookingDetails.module.scss";

interface Props {
  type: "Time" | "Distance";
  titleValue: string;
  rateValue: string;
  priceValue: number;
}

export const SummaryDistanceTimeRow = ({
  type,
  titleValue,
  rateValue,
  priceValue,
}: Props) => {
  return (
    <div className={styles.summaryBlock_row}>
      <p className={styles.summaryBlock_rowTitle}>
        <p>{`${type} - ${titleValue}`}</p>
        <p className={styles.summaryBlock_rowTitle_subtitle}>
          {`Rate: £${rateValue}`}
        </p>
      </p>

      <p className={styles.summaryBlock_rowValue}>
        {`£${priceValue.toFixed(2)}`}
      </p>
    </div>
  );
};

import Header from "components/Header";
import SearchInput from "components/SearchInput";
import {
  FC,
  useCallback,
  useState,
  CSSProperties,
  useMemo,
  useContext,
} from "react";
import { ICONS } from "../../../assets";
import { Button } from "../../../components";
import { VariantButton } from "../../../types/enums";
import styles from "./drivers.module.scss";
import DriversData from "./DriversData";
import useDisableDrivers from "../../../hooks/useDisableDrivers";
import { useMassStatusChanging } from "hooks/useMassStatusChanging";
import { ExpiryAlertsContext } from "context/expiryAlerts";

const Drivers: FC = () => {
  const { updateExpiryAlerts } = useContext(ExpiryAlertsContext);
  const [search, setSearch] = useState<string>("");
  const [count, setCount] = useState<number | null>(null);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [update, setUpdate] = useState(false);
  const { disableDrivers } = useDisableDrivers();
  const { selectedItems, selectedActiveIds, setSelectedItems } =
    useMassStatusChanging();

  const handleAddNewDriver = () => {
    setIsOpenModal(true);
  };

  const handleOnPressDisable = useCallback(async () => {
    await disableDrivers(selectedActiveIds);
    setUpdate((prevState) => !prevState);
    setSelectedItems([]);
    updateExpiryAlerts();
  }, [selectedItems]);

  return (
    <>
      <Header title={"Drivers"} count={count}>
        <Button
          title="Add new driver"
          onClick={handleAddNewDriver}
          variant={VariantButton.BLACK}
        >
          <ICONS.Plus />
        </Button>
      </Header>

      <div className={styles.driversContent}>
        <header className={styles.wrapper_header}>
          <div className={styles.wrapper_header_input}>
            <SearchInput
              placeholder="Search by driver’s details..."
              value={search}
              onChange={setSearch}
            />
          </div>
          {selectedActiveIds.length !== 0 && (
            <Button
              title={`Disable (${selectedActiveIds.length})`}
              onClick={handleOnPressDisable}
              variant={VariantButton.Red}
            />
          )}
        </header>

        <DriversData
          update={update}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          search={search}
          setCount={setCount}
          isModalOpen={isOpenModal}
          setIsModalOpen={setIsOpenModal}
        />
      </div>
    </>
  );
};

export default Drivers;

import ReactDOM from "react-dom/client";
import "reset-css";
import "./index.scss";
import App from "./App";
import { UserProvider } from "context/user";
import { SocketProvider } from "context/socket";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <UserProvider>
    <SocketProvider>
      <App />
    </SocketProvider>
  </UserProvider>
);

import React, { CSSProperties } from "react";
import { ScaleLoader } from "react-spinners";
import { defaultStyles } from "react-modal";
import styles from "./styles.module.scss";

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
};

const LoaderScreen = () => {
  return (
    <div className={styles.loader_container}>
      <ScaleLoader
        color={"#aa8768"}
        loading={true}
        cssOverride={override}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

export default LoaderScreen;

import { ReactComponent as Bookings } from "./Bookings.svg";
import { ReactComponent as Drivers } from "./Drivers.svg";
import { ReactComponent as Feedback } from "./Feedback.svg";
import { ReactComponent as Location } from "./location.svg";
import { ReactComponent as Prices } from "./Prices.svg";
import { ReactComponent as Staff } from "./Staff.svg";
import { ReactComponent as Statistics } from "./Statistics.svg";
import { ReactComponent as Users } from "./Users.svg";
import { ReactComponent as Vehicles } from "./Vehicles.svg";
import { ReactComponent as Areas } from "./Areas.svg";

const Nav = {
  Bookings,
  Feedback,
  Drivers,
  Staff,
  Statistics,
  Prices,
  Location,
  Users,
  Vehicles,
  Areas,
};

export default Nav;

import { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { ConfirmAlert } from "components/ConfirmAlert";
import styles from "./BookingDetails.module.scss";
import Header from "components/Header";
import { useNavigate, useParams } from "react-router-dom";
import { ICONS } from "assets";
import { PageContentContainer } from "components/PageContentContainer";
import cn from "classnames";
import { BookingMainInfo } from "./BookingMainInfo";
import { Booking } from "types/bookings";
import { BookingSummary } from "./BookingSummary";
import { BookingPaymentList } from "./BookingPaymentList";
import { BookingChat } from "./BookingChat";
import Skeleton from "react-loading-skeleton";
import {
  cancelBookingById,
  deleteBooking,
  fetchBookingById,
} from "api/bookings";
import { BookingDriverSelector } from "./BookingDriverSelector";
import { Button } from "components";
import { VariantButton } from "types/enums";
import { toastServerError } from "utils/helpers";

export const BookingDetails = () => {
  const navigate = useNavigate();
  const { bookingId } = useParams();
  const [booking, setBooking] = useState<Booking | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const canCancel =
    booking &&
    booking.rideStatus !== "Cancelled" &&
    booking.rideStatus !== "Completed" &&
    booking.rideStatus !== "No show" &&
    booking.rideStatus !== "On board";

  const onCancelClick = () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmAlert
          question="Are you sure, that you want to cancel this booking?"
          onConfirm={async () => {
            try {
              if (booking) {
                await cancelBookingById(booking?.id);

                onClose();
                navigate(-1);
              }
            } catch (error) {
              toastServerError(
                error,
                "Something went wrong with booking canceling!"
              );
            }
          }}
          onClose={() => {
            onClose();
          }}
        />
      ),
    });
  };

  const onDeleteClick = () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmAlert
          question="Are you sure, that you want to delete this booking from database?"
          onConfirm={async () => {
            try {
              if (booking) {
                await deleteBooking(booking.id);
                onClose();
                navigate(-1);
              }
            } catch (error) {
              toastServerError(
                error,
                "Something went wrong with booking removing!"
              );
            }
          }}
          onClose={() => {
            onClose();
          }}
        />
      ),
    });
  };

  const getBooking = async () => {
    try {
      const { data } = await fetchBookingById(Number(bookingId));

      setBooking(data.order);
    } catch (error) {
      toastServerError(error, "Something went wrong with booking loading");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getBooking();
  }, []);

  return (
    <div className={styles.bookingPage}>
      <Header>
        <div className={styles.bookingPage_header}>
          <button
            className={styles.bookingPage_backBtn}
            onClick={() => navigate(-1)}
          >
            <ICONS.BackArrow />
          </button>
          <div className={styles.bookingPage_titleBlock}>
            <h3 className={styles.bookingPage_title}>Booking details</h3>
            <p className={styles.bookingPage_id}>ID: {bookingId}</p>
          </div>
        </div>

        <div className={styles.bookingPage_headerBtns}>
          {canCancel && (
            <Button
              extraStyles={{ color: "#E53E3E" }}
              title="Cancel Booking"
              onClick={onCancelClick}
            />
          )}
          <Button
            title="Delete Booking"
            onClick={onDeleteClick}
            variant={VariantButton.Red}
          />
        </div>
      </Header>
      <div className={styles.bookingPage_contentContainer}>
        <div
          className={cn(
            styles.bookingPage_contentColumn,
            styles.bookingPage_contentColumn__main
          )}
        >
          <PageContentContainer>
            {booking ? (
              <div className={styles.bookingPage_mainContent}>
                <BookingMainInfo
                  booking={booking}
                  onStatusChange={setBooking}
                />

                <div>
                  <h4 className={styles.bookingPage_mainContentSubtitle}>
                    Driver and Vehicle
                  </h4>
                  <BookingDriverSelector
                    driverId={booking.driverId}
                    vehicleCategory={booking.vehicleCategory}
                    bookingStatus={booking.rideStatus}
                    setBooking={setBooking}
                  />
                </div>

                <div>
                  <h4 className={styles.bookingPage_mainContentSubtitle}>
                    Client’s notes
                  </h4>
                  <div className={styles.bookingPage_mainContentNoteBlock}>
                    <p
                      className={cn(styles.bookingPage_mainContentNote, {
                        [styles.bookingPage_mainContentNote__empty]:
                          !booking.note,
                      })}
                    >
                      {booking.note ||
                        "The customer has not left any notes for this trip"}
                    </p>
                  </div>
                </div>

                <div>
                  <h4 className={styles.bookingPage_mainContentSubtitle}>
                    Booking summary
                  </h4>
                  <BookingSummary booking={booking} />
                </div>

                <div>
                  <h4 className={styles.bookingPage_mainContentSubtitle}>
                    Payment method
                  </h4>

                  <BookingPaymentList />
                </div>
              </div>
            ) : isLoading ? (
              <Skeleton height={"100vh"} />
            ) : null}
          </PageContentContainer>
        </div>
        <div
          className={cn(
            styles.bookingPage_contentColumn,
            styles.bookingPage_contentColumn__chat
          )}
        >
          <BookingChat />
        </div>
      </div>
    </div>
  );
};

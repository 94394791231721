import { Button, Input } from "components";
import styles from "./Bookings.module.scss";
import { useState } from "react";
import { VariantButton } from "types/enums";
import { useParams } from "react-router-dom";
import LoaderScreen from "components/LoaderScreen";
import { downloadBookingsFile } from "api/bookings";
import { bookingTypes } from "hooks/useBookingsList";
import FileSaver from "file-saver";
import { toastServerError } from "utils/helpers";

function getInitialDateRange() {
  const currentDate = new Date(); // Get current date
  const oneWeekAgo = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - 6,
    0,
    0,
    0
  ); // Date 3 months ago at 00:00:00
  const fromDate = oneWeekAgo.toISOString().split("T")[0]; // Get the date in YYYY-MM-DD format

  const currentDateFormatted = currentDate.toISOString().split("T")[0]; // Current date in YYYY-MM-DD format

  return {
    from: fromDate,
    to: currentDateFormatted,
  };
}

export const ExportModalContent = () => {
  const { activeTab } = useParams();
  const [dateRange, setDateRange] = useState(getInitialDateRange());
  const [isLoading, setIsLoading] = useState(false);

  const getBookingsFile = async () => {
    try {
      setIsLoading(true);

      const { data } = await downloadBookingsFile(
        bookingTypes[activeTab as string],
        dateRange
      );

      await FileSaver.saveAs(
        data,
        `bookings_${activeTab}_${dateRange.from}_${dateRange.to}.xlsx`
      );
    } catch (error) {
      toastServerError(
        error,
        "Something went wrong with bookings .xlsx file downloading"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.exportModal}>
      <div className={styles.exportModal_datePikerContainer}>
        <div className={styles.exportModal_datePicker}>
          <Input
            type="date"
            label="From date"
            value={dateRange.from}
            maxDate={new Date()}
            onChange={(val) => setDateRange((prev) => ({ ...prev, from: val }))}
          />
        </div>

        <div className={styles.exportModal_datePicker}>
          <Input
            type="date"
            label="To date"
            value={dateRange.to}
            minDate={new Date(dateRange.from)}
            maxDate={new Date()}
            onChange={(val) => setDateRange((prev) => ({ ...prev, to: val }))}
          />
        </div>
      </div>

      <Button
        title="Export"
        onClick={getBookingsFile}
        variant={VariantButton.BLACK}
        extraStyles={{ width: 300, margin: "0px auto" }}
      />
      {isLoading && <LoaderScreen />}
    </div>
  );
};

import { getDriverApi } from "api/drivers";
import { useEffect, useState } from "react";
import { IDriverItem } from "types/drivers";
import {IVehicle} from "../types/vehicles";
import {getVehicleApi} from "../api/vehicles";

export const useGetVehicle = (id: string | undefined) => {
  const [vehicle, setVehicle] = useState<IVehicle | null>();


  const getVehicleInfo = async () => {
    try {
      if (id) {
        const { data } = await getVehicleApi(id);

        console.log(data);

        if (data.success) {
          setVehicle(data.vehicle);
        }
      }
    } catch (e) {
      console.log("Error getting driver", e);
    }
  };

  useEffect(() => {
    getVehicleInfo();
  }, []);

  return {
    vehicle
  };
};

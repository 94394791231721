import * as yup from "yup";

export const LoginSchema = yup.object({
  email: yup
    .string()
    .max(80, "Max size 80")
    .matches(/([a-z0-9._]{3,80})@([a-z]{3,80})\.[a-z]{2,3}/, `incorrect email`),
  password: yup.string().required(`Required`).min(6, `Min symbols 6`),
});

export const DriverSchema = yup.object({
  firstName: yup
    .string()
    .min(2, "Minimum 2 symbols")
    .max(15, "Max 15 symbols")
    .required("Required field"),
  lastName: yup
    .string()
    .min(2, "Minimum 2 symbols")
    .max(15, "Max size 15 symbols")
    .required("Required field"),
  phoneNumber: yup
    .string()
    .length(13, "The number should have a country code and 10 digits")
    .test("Incorect country code", "Country code must be +44", (value) => {
      return value?.startsWith("+44");
    })
    .matches(new RegExp("^[0-9+]{10,20}$"), "Incorrect number")
    .required("Required field"),
  email: yup
    .string()
    .min(10, "Minimum 10 symblos")
    .max(80, "Max 80 symbols")
    .matches(/([a-z0-9._]{3,80})@([a-z]{3,80})\.[a-z]{2,3}/, "Incorrect email")
    .required("Required field"),
  homeAddress: yup.object({
    street: yup.string().required("Required field"),
    city: yup.string().required("Required field"),
    postalCode: yup.string().required("Required field"),
    country: yup.string().required("Required field"),
  }),
  taxInformation: yup.string().required("Required field"),
  bankDetails: yup.object({
    accountHoldersName: yup.string().required("Required field"),
    sortCode: yup.string().required("Required field"),
    accountNumber: yup.string().required("Required field"),
  }),
  newAvatarFile: yup.mixed().notRequired(),
  createDriverDocumentDetails: yup.array().of(
    yup.object({
      category: yup.string().required("Required field"),
      expiryDate: yup.string().required("Required field"),
      type: yup.string().required("Required field"),
      number: yup
        .mixed()
        .nullable()
        .test("non-empty", "Required field", (value) => {
          if (value === null) {
            return true; // Allow undefined
          }

          return typeof value === "string" && value.trim().length > 0;
        }),
    })
  ),
  documentFormDetails: yup.object({
    expiryDate: yup.date(),
    PCO: yup.string(),
    PCOExpireDate: yup.date(),
  }),
});

export const VehicleSchema = yup.object({
  category: yup.string().required("Required field"),
  colour: yup.string().required("Required field"),
  make: yup.string().required("Required field"),
  model: yup.string().required("Required field"),
  registrationMark: yup.string().required("Required field"),
  registeredKeeper: yup.string().required("Required field"),
  driver: yup
    .object({
      id: yup.number().test("not-empty", "Required field", (value) => {
        return value !== -1;
      }),
    })
    .required("Required field"),
  documentDetails: yup.array().of(
    yup.object({
      category: yup.string().required("Required field"),
      expiryDate: yup.string().required("Required field"),
      type: yup.string().required("Required field"),
      number: yup
        .mixed()
        .nullable()
        .test("non-empty", "Required field", (value) => {
          if (value === null) {
            return true; // Allow undefined
          }

          return typeof value === "string" && value.trim().length > 0;
        }),
    })
  ),
});

import styles from "./SuportChatWidget.module.scss";
import { FC } from "react";
import { ChatType } from "hooks/useSupportChatsList";
import ChatIcons from "assets/icons/chat";
import cn from "classnames";

interface Props {
  selectedChatsType: ChatType;
  setSelectedChatsType: (chat: ChatType) => void;
}

export const ChatCategoriesFooter: FC<Props> = ({
  selectedChatsType,
  setSelectedChatsType,
}) => {
  return (
    <div className={styles.categoriesFooter}>
      <span
        onClick={() => setSelectedChatsType("ongoing")}
        className={cn(styles.categoriesFooter_item, {
          [styles.categoriesFooter_item__selected]:
            selectedChatsType === "ongoing",
        })}
      >
        <ChatIcons.OngoingChat />
        <p>Ongoing</p>
      </span>
      <span
        onClick={() => setSelectedChatsType("completed")}
        className={cn(styles.categoriesFooter_item, {
          [styles.categoriesFooter_item__selected]:
            selectedChatsType === "completed",
        })}
      >
        <ChatIcons.Completed />
        <p>Completed</p>
      </span>
    </div>
  );
};

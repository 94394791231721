import { apiAxios } from "api";
import { AxiosResponse } from "axios";
import { ServerResponse } from "types";
import {
  BookingsStatisticData,
  CustomersStatisticData,
  DriverStatisticData,
} from "types/statistic";

export type StatisticPeriod = "week" | "month" | "year";

export const fetchBookingsStatisticByPeriod = (
  period: StatisticPeriod
): Promise<AxiosResponse<ServerResponse & BookingsStatisticData>> => {
  return apiAxios.get(`/order/statistic?period=${period}`);
};

export const fetchDriverStatisticByPeriod = (
  period: StatisticPeriod
): Promise<AxiosResponse<ServerResponse & DriverStatisticData>> => {
  return apiAxios.get(`/driver/statistic?period=${period}`);
};

export const fetchCustomersStatisticByPeriod = (
  period: StatisticPeriod
): Promise<AxiosResponse<ServerResponse & CustomersStatisticData>> => {
  return apiAxios.get(`/client/statistic?period=${period}`);
};

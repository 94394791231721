import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Socket, io } from "socket.io-client";
import { UserContext } from "./user";
import { getTokens } from "utils/tokenService";

interface ContextProps {
  socket: Socket | null;
  isUserConnectedToSocket: boolean;
}

export const SocketContext = createContext<ContextProps>({
  socket: null,
  isUserConnectedToSocket: false,
});

export const SocketProvider = ({ children }: { children: ReactNode }) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [isUserConnectedToSocket, setIsUserConnectedToSocket] = useState(false);
  const { user } = useContext(UserContext);

  const connectToSocketChannel = async () => {
    const token = getTokens();

    if (token) {
      setSocket(
        io("wss://api.preevo.co.uk/", {
          extraHeaders: {
            Authorization: `Bearer ${JSON.parse(token)}`,
          },
        })
      );
    }
  };

  const closeSocketChanel = () => {
    if (socket) {
      console.log("I AM CLOSING SOCKET CHANEL!");
      socket.close();
      setSocket(null);
    }
  };

  useEffect(() => {
    if (socket) {
      socket.on("connect", () => {
        console.log("Socked connected");
      });

      socket.on("disconnect", (e) => {
        console.log("Socked disconnected!!!!", e);
        setIsUserConnectedToSocket(false);
      });

      socket.on("error", (error) => {
        console.log("Socked Error", error);
      });
      socket.on("result", (data) => {
        console.log("Result:", data);
        if (data.message === "User is online") {
          setIsUserConnectedToSocket(true);
        }
      });
    }
  }, [socket]);

  useEffect(() => {
    if (user) {
      connectToSocketChannel();
    } else {
      closeSocketChanel();
    }
  }, [user]);

  return (
    <SocketContext.Provider value={{ socket, isUserConnectedToSocket }}>
      {children}
    </SocketContext.Provider>
  );
};

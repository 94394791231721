import React, { FC } from "react";

import { NavLink, To } from "react-router-dom";

import styles from "./styles.module.scss";
import cn from "classnames";

interface Props {
  value: string;
  handleClick: (value: string) => void;
  isActive: boolean;
  label: string;
}

export const RadioButton: FC<Props> = ({
  isActive,
  label,
  value,
  handleClick,
}) => {
  return (
    <button
      className={cn(styles.radioBtn, { [styles.radioBtn__active]: isActive })}
      onClick={() => handleClick(value)}
      disabled={isActive}
    >
      <span>{label}</span>
    </button>
  );
};

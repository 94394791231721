import { FC } from "react";
import styles from './styles.module.scss';
import cls from "classnames";

type Props = {
  onToggle?: () => void;
  active?: boolean;
};

const Toggle: FC<Props> = ({onToggle, active}) => {
  return (
    <div className={cls(styles.toggle, {
      [styles.toggle_active]: active,
      [styles.toggle_inactive]: !active,
    })} onClick={onToggle}>
      <div className={styles.toggle_slider}></div>
    </div>
  );
};

export default Toggle;

import { ICONS } from "assets";
import Avatar from "components/Avatar";
import ButtonState from "components/common/ButtonState";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Category, IDriver } from "types/drivers";
import styles from "./styles.module.scss";
import Checkbox from "../../../../../components/Checkbox";
import cls from "classnames";
import { Tooltip } from "react-tooltip";
import { toast } from "react-hot-toast";
import { ItemForStatusChanging } from "hooks/useMassStatusChanging";

interface ItemProps {
  onPressCheckbox: (item: ItemForStatusChanging) => void;
  item: IDriver;
  selected: boolean;
}

const Item: FC<ItemProps> = ({ item, onPressCheckbox, selected }) => {
  const navigate = useNavigate();

  const handleItemClick = () => {
    navigate(`/drivers/${item.id}`);
  };

  return (
    <>
      <div className={styles.wrapper} onClick={handleItemClick}>
        <div className={styles.wrapper_checkbox}>
          <Checkbox
            onPress={() =>
              onPressCheckbox({ id: item.id, isActive: item.isActive })
            }
            active={selected}
          />
        </div>

        <div className={styles.wrapper_info}>
          <div className={styles.wrapper_info_avatar}>
            <Avatar img={item.avatar} />
          </div>
          <div>
            <p className={styles.wrapper_info_name}>
              {item.firstName} {item.lastName}
            </p>
          </div>
        </div>

        <div
          className={cls(styles.wrapper_category, {
            [styles.wrapper_category_orange]:
              item.vehicle?.category === Category.BUSINESS_PLUS,
            [styles.wrapper_category_blue]:
              item.vehicle?.category === Category.FIRST_CLASS,
            [styles.wrapper_category_purple]:
              item.vehicle?.category === Category.BUSINESS_XL,
          })}
        >
          {item.vehicle?.category || ""}
        </div>

        <div className={styles.wrapper_email}>
          <div
            className={styles.wrapper_emailLink}
            data-tooltip-id="my-tooltip"
            data-tooltip-content={item.email}
          >
            {item.email}
          </div>
        </div>

        <div className={styles.wrapper_phone}>
          <span
            data-tooltip-id="my-tooltip"
            data-tooltip-content={item.phoneNumber}
            onClick={(e) => {
              e.stopPropagation();
              navigator.clipboard.writeText(item.phoneNumber);
              toast("Phone number was copied", { duration: 1000 });
            }}
          >
            {item.phoneNumber}
          </span>
        </div>

        <div className={styles.wrapper_btn}>
          <div className={styles.wrapper_btn_status}>
            <ButtonState isActive={item.isActive} />
            {item.expired && item.isActive && <ICONS.ExclamationMark />}
          </div>
          <div className={styles.wrapper_btn_wrapper}>
            <ICONS.RightArrow />
          </div>
        </div>
      </div>
      <Tooltip id="my-tooltip" place="top" clickable delayShow={300} />
    </>
  );
};

export default Item;

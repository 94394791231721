import { fetchSuportChatById, fetchSuportChatsList } from "api/chats";
import { fetchClientsNamesAndPhonesByIds } from "api/users";
import { useEffect, useState } from "react";
import { ChatListItemWithClientName, ChatMessageItem } from "types/chat";
import { toastServerError } from "utils/helpers";

export type ChatType = "completed" | "ongoing";

export const useSupportChatsList = () => {
  const [selectedChatsType, setSelectedChatsType] =
    useState<ChatType>("ongoing");
  const [chatsList, setChatsList] = useState<ChatListItemWithClientName[]>([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [hasMoreChats, setHasMoreChats] = useState(true);
  const [allUnreadCounter, setAllUnreadCounter] = useState(0);
  let loadingChatsIds: number[] = [];

  const loadChatsList = async () => {
    try {
      setIsLoading(true);
      setChatsList([]);
      setPage(1);

      const { data: chatData } = await fetchSuportChatsList(
        selectedChatsType,
        1
      );
      setAllUnreadCounter(chatData.allUnreadMessages);
      if (chatData.success && chatData.chats.length !== 0) {
        const { data: clientsData } = await fetchClientsNamesAndPhonesByIds(
          chatData.chats.map((chat) => chat.clientId)
        );
        setChatsList(
          chatData.chats.map((chat) => {
            const chatClient = clientsData.clients.find(
              (client) => client.id === chat.clientId
            );

            const clientName = chatClient
              ? `${chatClient.firstName} ${chatClient.lastName}`
              : "Unknown name";

            return { ...chat, clientName };
          })
        );
      }
      setHasMoreChats(chatData.chats.length < chatData.totalChats);
    } catch (error) {
      toastServerError(error, "Something went wrong with chats loading");
    } finally {
      setIsLoading(false);
    }
  };

  const loadMoreChatItems = async () => {
    try {
      if (!isLoading && hasMoreChats) {
        setIsLoading(true);

        const { data: chatData } = await fetchSuportChatsList(
          selectedChatsType,
          page + 1
        );

        if (chatData.success) {
          const { data: clientsData } = await fetchClientsNamesAndPhonesByIds(
            chatData.chats.map((chat) => chat.clientId)
          );

          setChatsList((prev) => {
            const chatsWithoutDuplicates = chatData.chats.filter(
              (loadedChat) =>
                !prev.some(
                  (prevLoadedChat) => prevLoadedChat.id === loadedChat.id
                )
            );

            setHasMoreChats(
              prev.length + chatsWithoutDuplicates.length < chatData.totalChats
            );

            return [
              ...prev,
              ...chatsWithoutDuplicates.map((chat) => {
                const chatClient = clientsData.clients.find(
                  (client) => client.id === chat.clientId
                );

                const clientName = chatClient
                  ? `${chatClient.firstName} ${chatClient.lastName}`
                  : "Unknown name";

                return { ...chat, clientName };
              }),
            ];
          });
          setPage((prev) => prev + 1);
        }
      }
    } catch (error) {
      toastServerError(
        error,
        "Something went wrong with next chats page loading"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const loadChatForNewMessage = async (message: ChatMessageItem) => {
    if (!loadingChatsIds.includes(message.chatId)) {
      try {
        loadingChatsIds = [...loadingChatsIds, message.chatId];
        const { data } = await fetchSuportChatById(message.chatId, 1, 1);
        if (data.success) {
          const { data: clientData } = await fetchClientsNamesAndPhonesByIds([
            data.chat.clientId,
          ]);
          const clientName = `${clientData.clients[0].firstName} ${clientData.clients[0].lastName}`;

          setChatsList((prev) => [{ ...data.chat, clientName }, ...prev]);
        }
      } catch (error) {
        console.log("Error with new message chat loading: ", error);
      } finally {
        loadingChatsIds = loadingChatsIds.filter((id) => id !== message.chatId);
      }
    }
  };

  useEffect(() => {
    loadChatsList();
  }, [selectedChatsType]);

  return {
    selectedChatsType,
    setSelectedChatsType,
    chatsList,
    setChatsList,
    isLoading,
    page,
    hasMoreChats,
    loadMoreChatItems,
    loadChatForNewMessage,
    allUnreadCounter,
    setAllUnreadCounter,
  };
};

import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Skeleton from "react-loading-skeleton";
import styles from "./style.module.scss";

import { Pagination, TableHeader } from "components";
import { useDebounce } from "hooks/useDebounce";
import { useVehicles } from "../../../../hooks/useVehicles";
import Item from "./Item";
import Checkbox from "../../../../components/Checkbox";
import CreateVehicleModal from "components/CreateVehicleModal";
import { toast } from "react-hot-toast";
import { successToastOptions } from "utils/toasterStyles";
import { ItemForStatusChanging } from "hooks/useMassStatusChanging";

const LIMIT = 10;

type Props = {
  search: string;
  setCount: (value: number | null) => void;
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  selectedItems: ItemForStatusChanging[];
  setSelectedItems: Dispatch<SetStateAction<ItemForStatusChanging[]>>;
  refreshToggler: boolean;
};
const VehiclesData: FC<Props> = ({
  search,
  setCount,
  isModalOpen,
  setIsModalOpen,
  selectedItems,
  setSelectedItems,
  refreshToggler,
}) => {
  const [sortedIndex, setSortedIndex] = useState<null | number>(null);
  const [sortedType, setSortedType] = useState<"desc" | "asc" | null>(null);
  const {
    vehicles,
    setVehicles,
    totalVehicles,
    currentPage,
    setCurrentPage,
    isLoading,
  } = useVehicles(sortedType, sortedIndex, search, refreshToggler);

  const isAllSelected = useMemo(() => {
    return (
      selectedItems.length !== 0 &&
      vehicles.every((driver) =>
        selectedItems.some((item) => item.id === driver.id)
      )
    );
  }, [selectedItems]);

  useEffect(() => {
    setCount(totalVehicles);
  }, [vehicles]);

  const onPressCheckbox = useCallback(
    (vehicle: ItemForStatusChanging) => {
      if (selectedItems.some((item) => item.id === vehicle.id)) {
        setSelectedItems((prevItems) =>
          prevItems.filter((item) => item.id !== vehicle.id)
        );
      } else {
        setSelectedItems((prevItems) => [...prevItems, vehicle]);
      }
    },
    [selectedItems, setSelectedItems]
  );

  const onPressSelectAll = useCallback(() => {
    if (selectedItems.length === vehicles.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(
        vehicles.map((vehicle) => ({
          id: vehicle.id,
          isActive: vehicle.isActive,
        }))
      );
    }
  }, [selectedItems, vehicles, isAllSelected]);

  const renderReports = () => {
    if (vehicles.length > 0) {
      return vehicles.map((vehicle) => (
        <Item
          key={vehicle.id}
          onPressCheckbox={onPressCheckbox}
          selected={selectedItems.some(
            (selectedItem) => selectedItem.id === vehicle.id
          )}
          item={vehicle}
        />
      ));
    }
    return <p className={styles.wrapper_empty}>No vehicles yet</p>;
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_items}>
        <TableHeader
          items={[
            <Checkbox onPress={onPressSelectAll} active={isAllSelected} />,
            "Vehicle",
            "Category",
            "Registration mark",
            "Driver",
            false,
          ]}
          flexes={[undefined, 0.8, 0.4, 0.5, 0.7, 0.5]}
          selectedIndex={sortedIndex}
          sortedType={sortedType}
          onChangeSorted={setSortedIndex}
          onChangeType={setSortedType}
          sortIndexes={[2]}
        />

        {isLoading ? (
          <Skeleton
            height={50}
            style={{ marginBottom: 2, marginTop: 2, borderRadius: 10 }}
            count={10}
          />
        ) : (
          renderReports()
        )}
      </div>

      {totalVehicles !== 0 && (
        <div className={styles.wrapper_pagination}>
          <Pagination
            currentPage={currentPage}
            count={totalVehicles || 0}
            limit={LIMIT}
            onChange={setCurrentPage}
          />
        </div>
      )}

      <CreateVehicleModal
        setVehicles={(vehicles) => {
          setVehicles(vehicles);
          toast("Vehicle succesfully created!", successToastOptions);
        }}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default VehiclesData;

import { useEffect, useState } from "react";
import styles from "./Users.module.scss";
import Header from "components/Header";
import { PageContentContainer } from "components/PageContentContainer";
import { ROUTES } from "utils/constants";
import { TabLink } from "components/TabLink";
import SearchInput from "components/SearchInput";
import { Button } from "components";
import { VariantButton } from "types/enums";
import { useParams } from "react-router-dom";
import { CustomersTable } from "./CustomersTable";
import { PassengersTable } from "./PassengersTable";
import { useDebounce } from "hooks/useDebounce";
import { toast } from "react-hot-toast";
import { successToastOptions } from "utils/toasterStyles";
import { deleteUsersByIds, updateUsersBlockStatusByIds } from "api/users";
import LoaderScreen from "components/LoaderScreen";
import { useUsersBlockAndDelete } from "hooks/useUsersBlockAndDelete";
import { ConfirmAlert } from "components/ConfirmAlert";
import { confirmAlert } from "react-confirm-alert";
import { toastServerError } from "utils/helpers";

export const Users = () => {
  const { selectedUsersType } = useParams();
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);
  const {
    selectedUsers,
    selectedNotBlockedUsersIds,
    selectedNotDeletedUsersIds,
    handleUserSelect,
    setSelectedUsers,
  } = useUsersBlockAndDelete();
  const [customersTotal, setCustomersTotal] = useState(0);
  const [passengersTotal, setPassengersTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshToggler, setRefreshToggler] = useState(false);

  const handleDeleteSelectedUsers = async () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmAlert
          question="Are you sure, that you want to delete selected users?"
          onConfirm={async () => {
            try {
              setIsLoading(true);

              const { data } = await deleteUsersByIds(
                selectedNotDeletedUsersIds
              );

              console.log("deleteUsersByIds DATA: ", data);

              if (data.success) {
                setSelectedUsers([]);
                setRefreshToggler((prev) => !prev);
                onClose();
                toast("Users are deleted successfully", successToastOptions);
              }
            } catch (error) {
              toastServerError(
                error,
                "Something went wrong with users deleting"
              );
            } finally {
              setIsLoading(false);
            }
          }}
          onClose={() => {
            onClose();
          }}
        />
      ),
    });
  };

  const handleBlockSelectedUsers = async () => {
    try {
      setIsLoading(true);

      const { data } = await updateUsersBlockStatusByIds(
        selectedNotBlockedUsersIds,
        true
      );

      console.log("handleBlockSelectedUsers DATA: ", data);

      if (data.success) {
        setSelectedUsers([]);
        setRefreshToggler((prev) => !prev);
        toast("Users are blocked successfully", successToastOptions);
      }
    } catch (error) {
      toastServerError(error, "Something went wrong with users blocking");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setSelectedUsers([]);
  }, [selectedUsersType]);

  return (
    <div className={styles.users}>
      <Header title={"Users"} count={customersTotal + passengersTotal} />
      <PageContentContainer>
        <div className={styles.users_content}>
          <nav>
            {ROUTES.users.map((routeItem) => (
              <TabLink
                route={`/${routeItem.route}`}
                label={routeItem.label}
                key={routeItem.label}
                counterValue={
                  routeItem.label === "Customers"
                    ? customersTotal
                    : passengersTotal
                }
              />
            ))}
          </nav>

          <div className={styles.users_searchLine}>
            <div className={styles.users_searchInput}>
              <SearchInput
                value={search}
                onChange={setSearch}
                placeholder="Search by name, phone number and email..."
              />
            </div>
            {selectedUsers.length !== 0 && (
              <div className={styles.users_actionButtons}>
                {selectedNotBlockedUsersIds.length !== 0 && (
                  <Button
                    variant={VariantButton.White}
                    title={`Block (${selectedNotBlockedUsersIds.length})`}
                    onClick={handleBlockSelectedUsers}
                  />
                )}

                {selectedNotDeletedUsersIds.length !== 0 && (
                  <Button
                    variant={VariantButton.Red}
                    title={`Delete (${selectedNotDeletedUsersIds.length})`}
                    onClick={handleDeleteSelectedUsers}
                  />
                )}
              </div>
            )}
          </div>

          {selectedUsersType === "customers" && (
            <CustomersTable
              onUserSelect={handleUserSelect}
              selectedUsers={selectedUsers}
              setCustomersTotal={setCustomersTotal}
              setPassengersTotal={setPassengersTotal}
              search={debouncedSearch}
              refreshToggler={refreshToggler}
            />
          )}
          {selectedUsersType === "passengers" && (
            <PassengersTable
              search={debouncedSearch}
              setCustomersTotal={setCustomersTotal}
              setPassengersTotal={setPassengersTotal}
            />
          )}
        </div>
      </PageContentContainer>

      {isLoading && <LoaderScreen />}
    </div>
  );
};

import { apiAxios } from "api";
import { AxiosResponse } from "axios";
import { ServerResponse } from "types";
import { Booking, BookingStatus } from "types/bookings";
import { BOOKINGS_LOADING_LIMIT } from "utils/constants";

export enum BookingsListsTypes {
  ALL = "all",
  NEXT_24_HOURS = "next24Hours",
  COMPLETED = "completed",
  CANCELLED = "cancelled",
  NO_SHOW = "noShow",
  DUE_IN_1_HOUR = "dueIn1Hour",
}

export enum UserBookingsListsStatuses {
  UPCOMING = "Upcoming",
  COMPLETED = "Completed",
  CANCELLED = "Cancelled",
  NO_SHOW = "No show",
}

export interface fetchBookingsBody {
  sorting: {
    sortBy?: "rideDate" | "price";
    order?: "ASC" | "DESC";
  };
  search?: string;
}

export interface fetchUserBookingsBody {
  status: UserBookingsListsStatuses;
  userType: "client" | "guest";
  sorting: {
    sortBy: "rideDate" | "price";
    order: "ASC" | "DESC";
  };
}

export interface ChangeBookingStatusBody {
  orderId: number;
  status: BookingStatus;
}

interface fetchBookingsResponse extends ServerResponse {
  orders: Booking[];
  totalOrders: number;
}

interface fetchBookingByIdResponse extends ServerResponse {
  order: Booking;
}

interface reserveOrderForDriverResponse extends ServerResponse {
  offer: Booking;
}

interface ChangeBookingStatusResponse extends ServerResponse {
  order: Booking;
}

export const fetchBookings = (
  body: fetchBookingsBody,
  type: BookingsListsTypes,
  page: number,
  limit: number = BOOKINGS_LOADING_LIMIT
): Promise<AxiosResponse<fetchBookingsResponse>> => {
  return apiAxios.post(`/order/list/${type}?page=${page}&limit=${limit}`, body);
};

export const fetchBookingById = (
  bookingId: number
): Promise<AxiosResponse<fetchBookingByIdResponse>> => {
  return apiAxios.get(`/order?orderId=${bookingId}`);
};

export const reserveOrderForDriver = (body: {
  orderId: number;
  driverId: number;
}): Promise<AxiosResponse<reserveOrderForDriverResponse>> => {
  return apiAxios.put("/order/reserve", body);
};

export const fetchUserBookings = (
  body: fetchUserBookingsBody,
  userId: number,
  page: number,
  limit: number = BOOKINGS_LOADING_LIMIT
): Promise<AxiosResponse<fetchBookingsResponse>> => {
  return apiAxios.post(
    `/order/user-orders?userId=${userId}&page=${page}&limit=${limit}`,
    body
  );
};

export const fetchActualBookingMode = (): Promise<
  AxiosResponse<ServerResponse & { manualMode: boolean }>
> => {
  return apiAxios.get("/order/config-mode");
};

export const setBookingMode = (body: {
  manualMode: boolean;
}): Promise<AxiosResponse<ServerResponse & { manualMode: boolean }>> => {
  return apiAxios.patch("/order/config-mode", body);
};

export const downloadBookingsFile = (
  type: BookingsListsTypes,
  dateRange: {
    from: string;
    to: string;
  }
) => {
  return apiAxios.post(
    `/order/export/${type}`,
    { dateRange },
    { responseType: "blob" }
  );
};

export const cancelBookingById = (orderId: number) => {
  return apiAxios.delete(`/order/cancel-by-admin?orderId=${orderId}`);
};

export const changeBookingStatus = (
  body: ChangeBookingStatusBody
): Promise<AxiosResponse<ChangeBookingStatusResponse>> => {
  return apiAxios.put("/order/changeStatus-by-admin", body);
};

export const deleteBooking = (
  orderId: number
): Promise<AxiosResponse<ServerResponse>> => {
  return apiAxios.delete(`/order/delete-by-admin?orderId=${orderId}`);
};

import { apiAxios } from "api";
import { AxiosResponse } from "axios";
import { ServerResponse } from "types";
import { Client, CustomerItem, PassengerItem } from "types/users";
import { USERS_LOADING_LIMIT } from "utils/constants";

interface fetchClientsNamesByIdsResponse {
  clients: Array<{
    id: number;
    firstName: string;
    lastName: string;
    phoneNumber: string;
  }>;
}

interface fetchClientByIdResponse extends ServerResponse {
  client: Client;
}

export interface fetchUsersBody {
  sorting: {
    sortBy: "createdAt";
    order: "ASC" | "DESC";
  };
  search?: string | null;
}

interface fetchClientsResponse extends ServerResponse {
  clients: CustomerItem[];
  totalClients: number;
  totalCustomers: number;
  totalPassengers: number;
}

interface fetchPassengersResponse extends ServerResponse {
  guests: PassengerItem[];
  totalGuests: number;
  totalCustomers: number;
  totalPassengers: number;
}

interface fetchCustomersByIdsResponse {
  guests: PassengerItem[];
}

export const fetchClientsNamesAndPhonesByIds = (
  clientsIds: number[]
): Promise<AxiosResponse<fetchClientsNamesByIdsResponse>> => {
  return apiAxios.post("/client/info", { clientsIdList: clientsIds });
};

export const fetchClientById = (
  clientId: number
): Promise<AxiosResponse<fetchClientByIdResponse>> => {
  return apiAxios.get(`/client?clientId=${clientId}`);
};

export const fetchCustomers = (
  body: fetchUsersBody,
  page: number,
  limit = USERS_LOADING_LIMIT
): Promise<AxiosResponse<fetchClientsResponse>> => {
  return apiAxios.post(`/client/list?page=${page}&limit=${limit}`, body);
};

export const fetchPassengers = (
  body: fetchUsersBody,
  page: number,
  limit = USERS_LOADING_LIMIT
): Promise<AxiosResponse<fetchPassengersResponse>> => {
  return apiAxios.post(`/guest/list?page=${page}&limit=${limit}`, body);
};

export const deleteUsersByIds = (
  clientsIdList: number[]
): Promise<AxiosResponse<ServerResponse>> => {
  return apiAxios.patch(`/client/delete`, { clientsIdList });
};

export const updateUsersBlockStatusByIds = (
  clientsIdList: number[],
  isBlocked: boolean
): Promise<AxiosResponse<ServerResponse>> => {
  return apiAxios.patch(`/client/block`, { clientsIdList, isBlocked });
};

export const fetchCustomersByIds = (
  idList: number[]
): Promise<AxiosResponse<fetchCustomersByIdsResponse>> => {
  return apiAxios.post("/guest/info", { guestsIdList: idList });
};

export const useDateFormat = () => {
    const format = (value: string) => {
        const originalDate = new Date(value);
        const year = originalDate.getUTCFullYear();
        const month = String(originalDate.getUTCMonth() + 1).padStart(2, "0");
        const day = String(originalDate.getUTCDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    }

    return {format};
};

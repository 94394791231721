import { useMemo, useRef } from "react";
import { Booking, BookingStatus } from "types/bookings";
import styles from "./BookingsTable.module.scss";
import useOutsideClick from "hooks/useOutsideClick";
import { useBoolean } from "hooks";
import { ICONS } from "assets";
import { getBookingStatusLabelStyles, toastServerError } from "utils/helpers";
import cn from "classnames";
import { ConfirmAlert } from "components/ConfirmAlert";
import { confirmAlert } from "react-confirm-alert";
import { changeBookingStatus } from "api/bookings";

interface Props {
  bookingId: number;
  status: BookingStatus;
  onStatusUpdate: (updatedBooking: Booking) => Promise<void> | void;
}

export const BookingStatusLabel = ({
  bookingId,
  status,
  onStatusUpdate,
}: Props) => {
  const dropdownRef = useRef(null);

  const {
    value: isSelectorOpen,
    toggle: toggleSelector,
    setFalse: closeSelector,
  } = useBoolean(false);

  useOutsideClick(dropdownRef, closeSelector);

  const isChangeStatusAvailable = useMemo(
    () =>
      status !== "Confirmed" &&
      status !== "Completed" &&
      status !== "Cancelled" &&
      status !== "No show",
    [status]
  );

  const optionsToChange: BookingStatus[] = useMemo(() => {
    switch (status) {
      case "Assigned":
        return ["Confirmed", "On the way"];
      case "On the way":
        return ["Assigned", "Arrived"];
      case "Arrived":
        return ["On the way", "On board", "No show"];
      case "On board":
        return ["Arrived", "Completed"];

      default:
        return [];
    }
  }, [status]);

  const onOtherStatusSelect = (selectedStatus: BookingStatus) => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmAlert
          question={`Are you sure you want to change status from ${status} to ${selectedStatus} ?`}
          onConfirm={async () => {
            try {
              const { data } = await changeBookingStatus({
                status: selectedStatus,
                orderId: bookingId,
              });

              if (data.success) {
                await onStatusUpdate(data.order);
              }
              onClose();
              closeSelector();
            } catch (error: any) {
              toastServerError(
                error,
                "Something went wrong with booking status change!"
              );
            }
          }}
          onClose={() => {
            onClose();
          }}
        />
      ),
    });
  };

  return (
    <div className={styles.status}>
      <button
        className={styles.status_labelBtn}
        style={getBookingStatusLabelStyles(status)}
        onClick={(e) => {
          e.stopPropagation();
          toggleSelector();
        }}
        disabled={!isChangeStatusAvailable}
      >
        {status}
        {isChangeStatusAvailable && <ICONS.SmallArow />}
      </button>

      {isSelectorOpen && (
        <div ref={dropdownRef} className={styles.status_dropdown}>
          {optionsToChange.map((option) => (
            <button
              className={cn(styles.status_labelBtn, styles.status_option)}
              style={getBookingStatusLabelStyles(option)}
              onClick={(e) => {
                e.stopPropagation();
                onOtherStatusSelect(option);
              }}
            >
              {option}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

import React, { FC } from "react";
import { ActivePrice, ActivePriceInForm } from "types/prices";
import styles from "./style.module.scss";
import { Input } from "components";
import { useFormikContext } from "formik";

interface Props {
  rowIndex: number;
  columnsFlexes: number[];
}

export const ActivePricesFormRow: FC<Props> = ({ rowIndex, columnsFlexes }) => {
  const { values, errors, setFieldValue, setFieldError, setFieldTouched } =
    useFormikContext<{
      prices: ActivePriceInForm[];
    }>();
  return (
    <div className={styles.priceRow}>
      {Object.entries(values.prices[rowIndex]).map(([key, cellData], index) => {
        return (
          <div
            className={styles.priceRowCell}
            style={{ flex: columnsFlexes[index] }}
            key={index}
          >
            {index === 0 && (
              <span className={styles.priceRowCell__category}>{cellData}</span>
            )}

            {index !== 0 && typeof cellData === "string" && (
              <Input
                type="number"
                value={cellData}
                onChange={(value) => {
                  setFieldValue(`prices[${rowIndex}][${key}]`, value);
                  setFieldError(`prices[${rowIndex}][${key}`, "");
                }}
                placeholder="Set price"
                error={
                  errors.prices && errors.prices[rowIndex]
                    ? //@ts-ignore
                      errors.prices[rowIndex][key]
                    : undefined
                }
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

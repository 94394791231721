import { FC, useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";

import avatar from "../avatar.png";

import { TokensService } from "utils";
import { ICONS } from "assets";
import { UserContext } from "context/user";
import Avatar from "components/Avatar";

const ProfileBlock: FC = () => {
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const divRef = useRef(null);
  // useOutsideClick({ ref: divRef, onClickOutside: () => setIsOpenModal(false) });

  const logout = () => {
    TokensService.clearTokens();
    navigate("/login");
  };

  return (
    <div
      ref={divRef}
      className={styles.profile}
      onClick={() => setIsOpenModal(!isOpenModal)}
    >
      <Avatar img={user?.avatar} />
      <div className={styles.profile_info}>
        <p className={styles.profile_info_name}>
          {user?.firstName} {user?.lastName}
        </p>
      </div>

      <ICONS.Arrows />

      {isOpenModal && (
        <div className={styles.modal}>
          <div className={styles.modal_wrapper}>
            <div className={styles.modal_wrapper_item} onClick={logout}>
              <div className={styles.modal_wrapper_item_icon}>
                <ICONS.Logout />
              </div>
              <p>Logout</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileBlock;

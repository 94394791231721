import { SocketContext } from "./../context/socket";
import { fetchBookingChat } from "api/chats";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Chat, ChatMessageItem } from "types/chat";
import { toastServerError } from "utils/helpers";

export const useBookingChat = () => {
  const { socket, isUserConnectedToSocket } = useContext(SocketContext);
  const { bookingId } = useParams();
  const [chat, setChat] = useState<Chat | null>(null);
  const [messages, setMessages] = useState<ChatMessageItem[]>([]);
  const [page, setPage] = useState(0);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const loadChat = async () => {
    try {
      if (bookingId && hasMoreMessages && !isLoading) {
        setIsLoading(true);
        const { data } = await fetchBookingChat(bookingId, 1);

        if (data.chat !== null) {
          setChat(data.chat);
          setMessages(data.chat.messages);
          setHasMoreMessages(data.chat.messages.length < data.totalMessages);
        } else {
          setHasMoreMessages(false);
        }
        setPage(1);
      }
    } catch (error) {
      toastServerError(error, "Something went wrong with chat loading");
    } finally {
      setIsLoading(false);
    }
  };

  const loadMoreMessages = async () => {
    if (bookingId && hasMoreMessages && !isLoading && page !== 0) {
      try {
        setIsLoading(true);
        const { data } = await fetchBookingChat(bookingId, page + 1);

        if (data.success && data.chat !== null) {
          setMessages((prev) => [
            ...prev,
            ...(data.chat ? data.chat.messages : []),
          ]);
          setPage((prev) => prev + 1);
          setHasMoreMessages(
            messages.length + data.chat.messages.length < data.totalMessages
          );
        }
      } catch (error) {
        toastServerError(
          error,
          "Something went wrong with chat messages loading"
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    loadChat();
  }, [socket]);

  useEffect(() => {
    if (chat?.id && socket && isUserConnectedToSocket) {
      console.log("JOIN AND SUBSCRIBE TO MESSAGES!!!!");
      socket.emit("join", { chatId: chat.id });

      socket.on("message", ({ message }: { message: ChatMessageItem }) => {
        setMessages((prev) => [message, ...prev]);
      });
    } else if (socket && isUserConnectedToSocket) {
      console.log("SUBSCRIBE TO CREATE!!!!");
      socket?.on(
        "chatCreated",
        ({ chat }: { chat: Omit<Chat, "messages"> }) => {
          setChat({ ...chat, messages: [] });
          setPage(1);
          console.log("CATCHED chatCreated!!! Data: ", chat);
        }
      );
    }

    return () => {
      if (chat?.id && socket && isUserConnectedToSocket) {
        socket.emit("leave", { chatId: chat?.id });
        socket.off("message");
      } else if (socket && isUserConnectedToSocket) {
        socket?.off("chatCreated");
      }
    };
  }, [chat?.id, socket, isUserConnectedToSocket]);

  return {
    chat,
    messages,
    hasMoreMessages,
    loadMoreMessages,
    isLoading,
    page,
  };
};

import React, { FC, useState } from "react";
import { Area, AreaInForm } from "types/areas";
import styles from "./OperatingAreas.module.scss";
import { Formik } from "formik";
import { RadioButton } from "components/RadioButton";
import { Button, Input } from "components";
import { PostcodesSelector } from "components/PostcodesSelector";
import { VariantButton } from "types/enums";
import { areaValidationSchema } from "utils/validation/OperatingAreas";
import { toast } from "react-hot-toast";
import { successToastOptions } from "utils/toasterStyles";
import { createOperatingArea, updateOperatingArea } from "api/operatingAreas";
import { AreaMap } from "./AreaMap";
import { toastServerError } from "utils/helpers";

interface Props {
  initialValues: AreaInForm;
  setAreaList: React.Dispatch<React.SetStateAction<Area[]>>;
  handleClose: () => void;
}

export const AreaForm: FC<Props> = ({
  initialValues,
  setAreaList,
  handleClose,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const formType = !initialValues.id ? "Create" : "Edit";

  const handleSubmit = async (values: AreaInForm) => {
    if (formType === "Create") {
      try {
        setIsLoading(true);

        const { data } = await createOperatingArea({ ...values });

        setAreaList((prev) => [data.area, ...prev]);
        toast("Operating area successfuly added!", successToastOptions);
        handleClose();
      } catch (error) {
        toastServerError(error, "Something went wrong with new area creating");
      } finally {
        setIsLoading(false);
      }
    } else {
      try {
        setIsLoading(true);

        const { data } = await updateOperatingArea(Number(values.id), {
          name: values.name,
          postcodes: values.postcodes,
          status: values.status,
        });

        setAreaList((prev) =>
          prev.map((price) => (price.id === data.area.id ? data.area : price))
        );

        toast("Operating Area successfully updated!", successToastOptions);
        handleClose();
      } catch (error) {
        toastServerError(error, "Something went wrong with new area updating");
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={areaValidationSchema}
      validateOnChange={false}
    >
      {({ values, errors, setFieldValue, setFieldError, handleSubmit }) => (
        <div className={styles.areaForm}>
          <div className={styles.areaForm_statusField}>
            <RadioButton
              label="Active"
              value="Active"
              handleClick={(value) => setFieldValue("status", value)}
              isActive={values.status === "Active"}
            />

            <RadioButton
              label="Inactive"
              value="Inactive"
              handleClick={(value) => setFieldValue("status", value)}
              isActive={values.status === "Inactive"}
            />
          </div>

          <Input
            label="Name"
            value={values.name}
            onChange={(val) => {
              setFieldValue("name", val);
              setFieldError("name", "");
            }}
            error={errors.name}
            placeholder="Enter a name"
          />

          <PostcodesSelector
            selectedValue={values.postcodes.map((postcode) => ({
              label: postcode,
              value: postcode,
            }))}
            handleSelect={(selectedOptions) => {
              setFieldValue(
                "postcodes",
                selectedOptions?.map((option) => option.value)
              );
              setFieldError("postcodes", "");
            }}
            label="Postcodes"
            error={errors.postcodes as string}
          />

          <div className={styles.areaForm_mapContainer}>
            <AreaMap postcodes={values.postcodes} />
          </div>

          <div className={styles.areaForm_buttonsContainer}>
            <Button
              title={`${formType} area`}
              variant={VariantButton.BLACK}
              onClick={handleSubmit}
            />

            <Button title={`Cancel`} onClick={handleClose} />
          </div>
        </div>
      )}
    </Formik>
  );
};

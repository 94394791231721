import { FC, ReactNode } from "react";
import ReactModal from "react-modal";
import styles from "./style.module.scss";
import { ICONS } from "assets";

interface Props {
  children?: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  extraModalStyles?: ReactModal.Styles;
}

export const Modal: FC<Props> = ({
  extraModalStyles,
  children,
  isOpen,
  title,
  onClose,
}) => {
  const ModalStyles: ReactModal.Styles = {
    content: {
      marginLeft: "auto",
      marginRight: "auto",
      width: "fit-content",
      height: "fit-content",
      maxHeight: "90vh",
      padding: 32,
      minHeight: 300,
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 1,
    },
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={{
        content: { ...ModalStyles.content, ...extraModalStyles?.content },
        overlay: { ...ModalStyles.overlay, ...extraModalStyles?.overlay },
      }}
      ariaHideApp={false}
      onRequestClose={onClose}
    >
      {title && <h5 className={styles.title}>{title}</h5>}
      <button className={styles.closeBtn} onClick={onClose}>
        <ICONS.CloseCross />
      </button>
      {children}
    </ReactModal>
  );
};

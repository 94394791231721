import {
  ChangeEvent,
  FC,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./SuportChatWidget.module.scss";
import TextareaAutosize from "react-textarea-autosize";
import cn from "classnames";
import ChatIcons from "assets/icons/chat";
import { SocketContext } from "context/socket";

interface Props {
  chatId: number;
  newMessageText: string;
  setNewMessageText: React.Dispatch<React.SetStateAction<string>>;
  handleSendMessage: (extraMessage?: string) => void;
  handleSendImages: (files: FileList | null) => void;
}

export const NewMessageFooter: FC<Props> = ({
  chatId,
  newMessageText,
  setNewMessageText,
  handleSendMessage,
  handleSendImages,
}) => {
  const { socket } = useContext(SocketContext);
  const [isTypingStatusSended, setIsTypingStatusSended] = useState(false);
  let typingStatusTimer = useRef<any>(null);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleTyping = (inputText: string) => {
    if (!isTypingStatusSended) {
      setIsTypingStatusSended(true);
    }

    clearTimeout(typingStatusTimer.current);

    typingStatusTimer.current = setTimeout(() => {
      setIsTypingStatusSended(false);
    }, 2000);

    setNewMessageText(inputText);
  };

  const hadleImagesSelect = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      handleSendImages(e.target.files);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const onSendMessageClick = () => {
    clearTimeout(typingStatusTimer.current);
    socket?.emit("stopTyping", { chatId });
    setIsTypingStatusSended(false);

    handleSendMessage();
  };

  useEffect(() => {
    if (isTypingStatusSended) {
      console.log("START TYPING!!!!!");
      socket?.emit("startTyping", { chatId });
    } else {
      console.log("STOP TYPING!!!!!");
      socket?.emit("stopTyping", { chatId });
    }
  }, [isTypingStatusSended]);

  useEffect(() => {
    return () => {
      clearTimeout(typingStatusTimer.current);
      socket?.emit("stopTyping", { chatId });
    };
  }, []);

  return (
    <div className={styles.newMessageFooter}>
      <TextareaAutosize
        className={styles.newMessageFooter_textarea}
        value={newMessageText}
        onChange={(e) => handleTyping(e.target.value)}
        minRows={1}
        maxRows={4}
        autoFocus
        cacheMeasurements
        placeholder="Write a message"
      />

      <label
        className={cn(
          styles.newMessageFooter_btn,
          styles.newMessageFooter_btn__image
        )}
      >
        <input
          ref={fileInputRef}
          type="file"
          multiple={true}
          accept="image/png, image/gif, image/jpeg, image/heic"
          onChange={hadleImagesSelect}
        />
        <ChatIcons.SelectImage />
      </label>
      <button
        className={cn(
          styles.newMessageFooter_btn,
          styles.newMessageFooter_btn__send
        )}
        disabled={newMessageText.length === 0}
        onClick={onSendMessageClick}
      >
        <ChatIcons.SendMessage />
      </button>
    </div>
  );
};

import React, { FC, Fragment, useCallback } from "react";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import mapCustomStyles from "assets/Map/preevo_map.json";
import { LONDON_COORDS } from "utils/constants";
import { DriverMarker } from "./DriverMarker";
import { LocatorDriverItem } from "types/locator";

const containerStyle = {
  width: "100%",
  height: "100%",
};

interface Props {
  driversList: LocatorDriverItem[];
  mapRef: React.MutableRefObject<google.maps.Map | null>;
  onMarkerClick: (driverItem: LocatorDriverItem) => void;
}

export const LocatorMap: FC<Props> = ({
  driversList,
  mapRef,
  onMarkerClick,
}) => {
  const onLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map;
  }, []);

  const onUnmount = useCallback(() => {
    mapRef.current = null;
  }, []);

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={LONDON_COORDS}
      zoom={10}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{
        styles: mapCustomStyles,
        disableDefaultUI: true,
        zoomControl: true,
        scrollwheel: false,
      }}
    >
      {driversList.length !== 0 &&
        driversList.map((driver) => (
          <Fragment key={driver.driverId}>
            <DriverMarker driver={driver} onClick={onMarkerClick} />
          </Fragment>
        ))}
    </GoogleMap>
  );
};

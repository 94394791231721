import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { BookingWithClientData } from "types/bookings";
import { fetchClientsNamesAndPhonesByIds } from "api/users";
import {
  UserBookingsListsStatuses,
  fetchUserBookings,
  fetchUserBookingsBody,
} from "api/bookings";
import { toastServerError } from "utils/helpers";

const bookingTypes: { [key: string]: UserBookingsListsStatuses } = {
  upcoming: UserBookingsListsStatuses.UPCOMING,
  completed: UserBookingsListsStatuses.COMPLETED,
  cancelled: UserBookingsListsStatuses.CANCELLED,
  no_show: UserBookingsListsStatuses.NO_SHOW,
};

export const useUserBookingsList = () => {
  const [bookingsItems, setBookingsItems] = useState<BookingWithClientData[]>(
    []
  );
  const [totalItems, setTotalItems] = useState<number>(0);
  const { selectedUsersType, activeBookingsTab, userId } = useParams();
  const [sortedIndex, setSortedIndex] = useState<number>(5);
  const [sortedType, setSortedType] = useState<"asc" | "desc">("desc");
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const page = useMemo(
    () => Number(searchParams.get("page")) || 1,
    [searchParams]
  );
  const setPage = useCallback(
    (page: number) => {
      setSearchParams({ page: String(page) });
    },
    [setSearchParams]
  );

  const getFetchUserBookingsBody = (
    activeBookingsTab: string
  ): fetchUserBookingsBody => {
    const sortByIndex: { [key: number]: "price" | "rideDate" } = {
      4: "price",
      5: "rideDate",
    };

    return {
      status: bookingTypes[activeBookingsTab],
      userType: selectedUsersType === "customers" ? "client" : "guest",
      sorting: {
        sortBy: sortByIndex[sortedIndex],
        order: sortedType.toUpperCase() as "ASC" | "DESC",
      },
    };
  };

  const getBookingsItems = async () => {
    try {
      setIsLoading(true);

      if (activeBookingsTab) {
        const requestBody = getFetchUserBookingsBody(activeBookingsTab);

        const { data: bookingsData } = await fetchUserBookings(
          requestBody,
          Number(userId),
          page
        );

        if (bookingsData.success) {
          if (bookingsData.orders.length === 0) {
            setBookingsItems([]);
            setTotalItems(0);
            return;
          }

          try {
            const { data: clientsData } = await fetchClientsNamesAndPhonesByIds(
              bookingsData.orders.map((booking) => booking.clientId)
            );

            setBookingsItems(
              bookingsData.orders.map((booking) => {
                const orderClient = clientsData.clients.find(
                  (client) => client.id === booking.clientId
                );

                const clientName = orderClient
                  ? `${orderClient.firstName} ${orderClient.lastName}`
                  : "Unknown client name";

                const clientPhoneNumber =
                  orderClient?.phoneNumber || "Unknown phone number";

                return {
                  ...booking,
                  clientName,
                  clientPhoneNumber,
                };
              })
            );
            setTotalItems(bookingsData.totalOrders);
          } catch (error) {
            toastServerError(
              error,
              "Something went wrong with bookings clients names loading"
            );
            setBookingsItems(
              bookingsData.orders.map((booking) => ({
                ...booking,
                clientName: "Unknown client",
                clientPhoneNumber: "Unknown phone number",
              }))
            );
            setTotalItems(bookingsData.totalOrders);
          }
        }
      }
    } catch (error) {
      toastServerError(error, "Something went wrong with bookings loading");
      setBookingsItems([]);
      setTotalItems(0);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getBookingsItems();
  }, [
    sortedIndex,
    sortedType,
    page,
    activeBookingsTab,
    selectedUsersType,
    userId,
  ]);

  return {
    bookingsItems,
    totalItems,
    sortedIndex,
    setSortedIndex,
    getBookingsItems,
    sortedType,
    setSortedType,
    page,
    setPage,
    isLoading,
  };
};

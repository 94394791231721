import { getFilesDowloadLinks } from "api/documents";

export type Document = {
  title: string;
  link?: string;
  id?: number;
};

export const getDocumentsWithDownloadLinks = async (documents: Document[]) => {
  const documentsWithDownloadedLinks: Document[] = [];

  for await (let doc of documents) {
    try {
      if (doc.link) {
        const { data } = await getFilesDowloadLinks([doc.link], "document");
        documentsWithDownloadedLinks.push({
          ...doc,
          link: data.links[0].downloadLink,
        });
      } else {
        documentsWithDownloadedLinks.push(doc);
      }
    } catch (error) {
      console.log("[DownloadLinks downloading error]", error);
      documentsWithDownloadedLinks.push({ ...doc, link: undefined });
    }
  }

  return documentsWithDownloadedLinks;
};

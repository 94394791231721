import React, { FC } from "react";
import styles from "./CustomersItemRow.module.scss";
import Checkbox from "components/Checkbox";
import { CustomerItem } from "types/users";
import { getFormattedDate } from "utils/helpers/getFormatedDate";
import { ICONS } from "assets";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "utils/constants";
import { UserForBlockOrDelete } from "hooks/useUsersBlockAndDelete";

interface Props {
  customer: CustomerItem;
  columsFlexes: Array<number | undefined>;
  isSelected: boolean;
  onSelect: (user: UserForBlockOrDelete) => void;
  page: number;
}

export const CustomersItemRow: FC<Props> = ({
  columsFlexes,
  customer,
  isSelected,
  onSelect,
  page,
}) => {
  const handleCheckboxClick = () => {
    onSelect({
      id: customer.id,
      isBlocked: customer.isBlocked,
      isDeleted: customer.isDeleted,
    });
  };
  const navigate = useNavigate();

  const handleItemClick = () => {
    navigate(
      `/${ROUTES.users[0].route}/${customer.id}/${ROUTES.userDetails[0].route}`,
      { state: { page } }
    );
  };

  return (
    <button className={styles.customer} onClick={handleItemClick}>
      <Checkbox active={isSelected} onPress={handleCheckboxClick} />
      <div
        className={styles.customer_dataCell}
        style={{ flex: columsFlexes[1] }}
      >
        <p className={styles.customer_name}>
          {`${customer.firstName} ${customer.lastName}`}
        </p>
      </div>
      <div
        className={styles.customer_dataCell}
        style={{ flex: columsFlexes[2] }}
      >
        <p>{customer.email}</p>
      </div>
      <div
        className={styles.customer_dataCell}
        style={{ flex: columsFlexes[3] }}
      >
        <p>{customer.phoneNumber}</p>
      </div>
      <div
        className={classNames(
          styles.customer_lastCell,
          styles.customer_dataCell
        )}
        style={{ flex: columsFlexes[4] }}
      >
        <p>{getFormattedDate(customer.createdAt, "d MMM, yyyy'")}</p>
        <ICONS.GoNextIcon />
      </div>
    </button>
  );
};

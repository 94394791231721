import { ChatListItem, ChatMessageItem } from "types/chat";

export const getFilteredSendingMessages = (
  sendingMessagesList: ChatMessageItem[],
  newMessage: Omit<ChatMessageItem, "content"> & { content: string }
): {
  filteredMessages: ChatMessageItem[];
  localImage?: File | null;
} => {
  if (newMessage.contentType === "text") {
    return {
      filteredMessages: sendingMessagesList.filter(
        (sendingMessage) => sendingMessage.content !== newMessage.content
      ),
    };
  } else {
    let localImage: File | null = null;

    const normalizeNewFileName = newMessage.content.replaceAll("%20", " ");

    const filteredMessages = sendingMessagesList.filter((sendingMessage) => {
      if (typeof sendingMessage.content === "string") {
        return true;
      }

      if (normalizeNewFileName.endsWith(sendingMessage.content.name)) {
        localImage = sendingMessage.content;
      }

      return !normalizeNewFileName.endsWith(sendingMessage.content.name);
    });

    return { filteredMessages, localImage };
  }
};

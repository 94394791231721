import React, { FC } from "react";
import styles from "./Staff.module.scss";
import Avatar from "components/Avatar";
import { IUser } from "types/user";

interface Props {
  staffItem: IUser;
  columnsFlexes: number[];
}
export const StaffRowItem: FC<Props> = ({ staffItem, columnsFlexes }) => {
  return (
    <>
      <div
        className={styles.staffRowItem_userCell}
        style={{ flex: columnsFlexes[0] }}
      >
        <Avatar img={staffItem.avatar} />
        {`${staffItem.firstName} ${staffItem.lastName}`}
      </div>
      <div
        className={styles.staffRowItem_emailCell}
        style={{ flex: columnsFlexes[1] }}
      >
        {staffItem.email}
      </div>
      <div
        className={styles.staffRowItem_roleCell}
        style={{ flex: columnsFlexes[2] }}
      >
        {staffItem.role}
      </div>
    </>
  );
};

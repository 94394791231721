import { createDriverApi } from "../api/drivers";
import { CreateDriverData } from "../types/drivers";

export const useCreateDriver = () => {
  const createDriver = async (createDriverData: CreateDriverData) => {
    try {
      const { data } = await createDriverApi(createDriverData);
      console.log("driver created", data);
      return data;
    } catch (e) {
      console.log("error creating driver", e);
      // @ts-ignore
      return e.response.data.message;
    }
  };

  return { createDriver };
};

import { Field, useFormikContext } from "formik";
import { Dispatch, FC, SetStateAction } from "react";
import { HolidaySurcharge } from "types/prices";
import styles from "./style.module.scss";
import { Input } from "components";
import { ICONS } from "assets";
import { CounterInput } from "components/CounterInput";

interface Props {
  itemIndex: number;
  columnsFlexes: number[];
  handleDelete: () => void;
  setCanSave: Dispatch<SetStateAction<boolean>>;
}

export const HolidayFormItem: FC<Props> = ({
  itemIndex,
  columnsFlexes,
  handleDelete,
  setCanSave,
}) => {
  const { values, setFieldValue, setFieldError } = useFormikContext<{
    holidays: HolidaySurcharge[];
  }>();

  const handleFieldChange = (fieldName: string, newValue: string) => {
    setCanSave(true);
    setFieldValue(fieldName, newValue);
  };

  return (
    <div className={styles.holidaysForm_item}>
      <Field name={`holidays[${itemIndex}].holiday`}>
        {({ meta, field }: any) => {
          return (
            <div style={{ flex: columnsFlexes[0] }}>
              <Input
                value={field.value}
                onChange={(newValue) => {
                  handleFieldChange(field.name, newValue);
                  setFieldError(field.name, "");
                }}
                error={meta.error}
              />
            </div>
          );
        }}
      </Field>

      <Field name={`holidays[${itemIndex}].action`}>
        {({ meta, field }: any) => (
          <div style={{ flex: columnsFlexes[1] }}>
            <Input
              value={field.value}
              onChange={(newValue) => handleFieldChange(field.name, newValue)}
              disabled={true}
            />
          </div>
        )}
      </Field>

      <Field name={`holidays[${itemIndex}].factorValue`}>
        {({ meta, field }: any) => (
          <div style={{ flex: columnsFlexes[2] }}>
            <CounterInput
              value={field.value}
              onChange={(newValue) => {
                handleFieldChange(field.name, newValue);
                setFieldError(field.name, "");
              }}
              step={1}
              error={meta.error}
            />
          </div>
        )}
      </Field>

      <Field name={`holidays[${itemIndex}].from`}>
        {({ meta, field }: any) => (
          <div style={{ flex: columnsFlexes[3] }}>
            <Input
              value={field.value}
              type="date"
              minDate={new Date()}
              expiring={false}
              onChange={(newValue) => {
                handleFieldChange(field.name, newValue);
                setFieldError(field.name, "");
              }}
              dateFormat="yyyy-MM-dd, hh:mm"
              showTimeInput
              error={meta.error}
            />
          </div>
        )}
      </Field>

      <Field name={`holidays[${itemIndex}].to`}>
        {({ meta, field }: any) => (
          <div
            style={{ flex: columnsFlexes[4], gap: 20 }}
            className={styles.holidaysForm_toDateCell}
          >
            <Input
              value={field.value}
              type="date"
              minDate={new Date()}
              expiring={false}
              onChange={(newValue) => {
                handleFieldChange(field.name, newValue);
                setFieldError(field.name, "");
              }}
              dateFormat="yyyy-MM-dd, hh:mm"
              showTimeInput
              error={meta.error}
            />
            <button
              className={styles.holidaysForm_deleteBtn}
              onClick={(e) => {
                e.stopPropagation();
                handleDelete();
              }}
            >
              <ICONS.DeleteTrashBolt />
            </button>
          </div>
        )}
      </Field>
    </div>
  );
};

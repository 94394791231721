import { FC } from "react";
import classNames from "classnames";
import { Booking } from "types/bookings";
import { SummaryDistanceTimeRow } from "./SummaryDistanceTimeRow";
import { convertMillisecondsToHoursAndMinutes } from "utils/helpers/convertMillisecondsToHoursAndMinutes";
import styles from "./BookingDetails.module.scss";

interface Props {
  booking: Booking;
}

export const BookingSummary: FC<Props> = ({ booking }) => {
  const {
    rideType,
    finalPrice,
    price,
    airportPickupPrice,
    airportDropOffPrice,
    finalDriverPrice,
    rideDuration,
    rideLength,
    pricePerMile,
    pricePerMinute,
    extraDuration,
    extraLength,
    finalDuration,
    finalLength,
    commission,
  } = booking;

  return (
    <div className={styles.summaryBlock}>
      {rideType === "As Directed" && (
        <div className={styles.summaryBlock_row}>
          <p className={styles.summaryBlock_rowTitle}>{`${
            rideDuration / 3600000
          } hours incl. ${rideLength} miles`}</p>

          <p className={styles.summaryBlock_rowValue}>
            {`£${(price - airportPickupPrice - airportDropOffPrice).toFixed(
              2
            )}`}
          </p>
        </div>
      )}

      {rideType === "One Way" && (
        <>
          <SummaryDistanceTimeRow
            type="Distance"
            titleValue={`${rideLength.toFixed(2)} miles`}
            rateValue={`${pricePerMile}/mile`}
            priceValue={rideLength * pricePerMile}
          />

          <SummaryDistanceTimeRow
            type="Time"
            titleValue={`${convertMillisecondsToHoursAndMinutes(rideDuration)}`}
            rateValue={`${pricePerMinute}/min`}
            priceValue={(rideDuration / 60000) * pricePerMinute}
          />
        </>
      )}

      <div className={styles.summaryBlock_row}>
        <p className={styles.summaryBlock_rowTitle}>{`Airport pick-up`}</p>

        <p className={styles.summaryBlock_rowValue}>
          {`£${airportPickupPrice}`}
        </p>
      </div>

      <div className={styles.summaryBlock_row}>
        <p className={styles.summaryBlock_rowTitle}>{`Airport drop-off`}</p>

        <p className={styles.summaryBlock_rowValue}>
          {`£${airportDropOffPrice}`}
        </p>
      </div>

      <div className={styles.summaryBlock_separatorLine} />

      {rideType === "As Directed" && (
        <>
          <div className={styles.summaryBlock_extraTitle}>Extra</div>

          <SummaryDistanceTimeRow
            type="Distance"
            titleValue={`${extraLength.toFixed(2)} miles`}
            rateValue={`${pricePerMile}/mile`}
            priceValue={extraLength * pricePerMile}
          />

          <SummaryDistanceTimeRow
            type="Time"
            titleValue={`${convertMillisecondsToHoursAndMinutes(
              extraDuration
            )}`}
            rateValue={`${pricePerMinute}/min`}
            priceValue={(extraDuration / 60000) * pricePerMinute}
          />

          <div className={styles.summaryBlock_separatorLine} />
        </>
      )}

      {finalDuration !== 0 && finalLength !== 0 && (
        <>
          <p className={styles.summaryBlock_rowTitle}>
            {`${finalLength.toFixed(2)} miles,
            ${convertMillisecondsToHoursAndMinutes(finalDuration)}`}
          </p>

          <div className={styles.summaryBlock_separatorLine} />
        </>
      )}

      <div className={styles.summaryBlock_row}>
        <p className={styles.summaryBlock_rowTitle}>Paid:</p>
        <p className={classNames(styles.summaryBlock_rowValue)}>
          {`£${price.toFixed(2)}`}
        </p>
      </div>

      <div className={styles.summaryBlock_row}>
        <p className={styles.summaryBlock_rowTitle}>Total:</p>
        <p
          className={classNames(
            styles.summaryBlock_rowValue,
            styles.summaryBlock_rowValue__accent
          )}
        >
          {`£${(finalPrice || price).toFixed(2)}`}
        </p>
      </div>

      <div className={styles.summaryBlock_separatorLine} />

      <div className={styles.summaryBlock_row}>
        <p className={styles.summaryBlock_rowTitle}>Сommission</p>
        <p className={styles.summaryBlock_rowValue}>
          {`£${commission.toFixed(2)}`}
        </p>
      </div>

      <div className={styles.summaryBlock_row}>
        <p className={styles.summaryBlock_rowTitle}>Driver’s income</p>
        <p className={styles.summaryBlock_rowValue}>
          {`£${finalDriverPrice?.toFixed(2)}`}
        </p>
      </div>
    </div>
  );
};

export const setLocalStorage = async (key: string, value: string) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getTokens = (): string | null => {
  const accessToken = localStorage.getItem("@accessToken");

  if (!accessToken) return null;

  return accessToken;
};

export const clearTokens = () => {
  localStorage.removeItem("@accessToken");
};

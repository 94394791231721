import { format, utcToZonedTime } from "date-fns-tz";

export const getFormattedDate = (
  utcDate: string,
  formatString: string = "EEE, d MMM, HH:mm"
) => {
  const londonDate = utcToZonedTime(utcDate, "Europe/London");

  return format(londonDate, formatString, { timeZone: "Europe/London" });
};

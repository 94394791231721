import { FC, ReactNode } from "react";
import styles from "./Header.module.scss";

type Props = {
  title?: string;
  count?: number | null;
  children?: ReactNode;
};

const Header: FC<Props> = ({ title, count, children }) => {
  return (
    <div className={styles.wrapper}>
      {title && (
        <div className={styles.wrapper_left}>
          <span className={styles.wrapper_title}>{title}</span>
          {count !== undefined && (
            <p className={styles.wrapper_count}>({count})</p>
          )}
        </div>
      )}
      {children}
    </div>
  );
};

export default Header;

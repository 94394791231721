import styles from "./BookingChat.module.scss";
import { ChatMessage } from "components/Chat/ChatMessage";
import { useBookingChat } from "hooks/useBookingChat";
import cn from "classnames";
import { ChatLoadControler } from "components/Chat/ChatLoaderController";

export const BookingChat = () => {
  const { messages, isLoading, hasMoreMessages, loadMoreMessages, page } =
    useBookingChat();

  return (
    <div className={styles.chat}>
      <h3 className={styles.chat_header}>Chat</h3>
      <div className={styles.chat_viewport}>
        <div className={styles.chat_messagesList}>
          {messages.length !== 0 &&
            messages.map((messageItem, index) => {
              const isSeparatorNeeded =
                !messages[index + 1] ||
                messages[index + 1]?.authorType !== messageItem.authorType;

              const bottomMarging =
                messages[index - 1]?.authorType !== messageItem.authorType
                  ? 10
                  : 2;

              return (
                <div
                  key={messageItem.id}
                  style={{ marginBottom: bottomMarging }}
                >
                  {isSeparatorNeeded && (
                    <p
                      className={cn(styles.chat_messagesGroupTitle, {
                        [styles.chat_messagesGroupTitle__customer]:
                          messageItem.authorType === "client",
                        [styles.chat_messagesGroupTitle__driver]:
                          messageItem.authorType === "driver",
                      })}
                    >
                      {messageItem.authorType == "client"
                        ? "Customer"
                        : "Driver"}
                    </p>
                  )}

                  <div>
                    <ChatMessage
                      messageItem={messageItem}
                      isMyMessage={messageItem.authorType === "client"}
                    />
                  </div>
                </div>
              );
            })}

          {messages.length === 0 && !hasMoreMessages && (
            <p className={styles.chat_empty}>Chat is empty</p>
          )}

          <ChatLoadControler
            page={page}
            isLoading={isLoading}
            hasMoreMessages={hasMoreMessages}
            loadCallback={loadMoreMessages}
          />
        </div>
      </div>
    </div>
  );
};

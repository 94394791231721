import React, { useMemo, useState } from "react";

export interface UserForBlockOrDelete {
  id: number;
  isBlocked: boolean;
  isDeleted: boolean;
}

export const useUsersBlockAndDelete = () => {
  const [selectedUsers, setSelectedUsers] = useState<UserForBlockOrDelete[]>(
    []
  );

  const selectedNotDeletedUsersIds = useMemo(
    () =>
      selectedUsers.filter((user) => !user.isDeleted).map((user) => user.id),
    [selectedUsers]
  );

  const selectedNotBlockedUsersIds = useMemo(
    () =>
      selectedUsers.filter((user) => !user.isBlocked).map((user) => user.id),
    [selectedUsers]
  );

  const handleUserSelect = (
    user: UserForBlockOrDelete | UserForBlockOrDelete[]
  ) => {
    if (Array.isArray(user)) {
      setSelectedUsers(user);
    } else {
      setSelectedUsers((prev) =>
        prev.some((prevUser) => prevUser.id === user.id)
          ? prev.filter((prevUser) => prevUser.id !== user.id)
          : [...prev, user]
      );
    }
  };

  return {
    selectedUsers,
    selectedNotBlockedUsersIds,
    selectedNotDeletedUsersIds,
    setSelectedUsers,
    handleUserSelect,
  };
};

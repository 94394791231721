import { useState } from "react";
import styles from "./Bookings.module.scss";
import Header from "components/Header";
import { PageContentContainer } from "components/PageContentContainer";
import { TabLink } from "components/TabLink";
import { ROUTES } from "utils/constants";
import SearchInput from "components/SearchInput";
import { BookingsTable } from "components/BookingsTable";
import { useBookingsList } from "hooks/useBookingsList";
import { ModeHandler } from "./ModeHandler";
import { Button } from "components";
import { ExportModalContent } from "./ExportModal";
import { Modal } from "components/Modal";

export const Bookings = () => {
  const {
    search,
    setSearch,
    setPage,
    setSortedIndex,
    setSortedType,
    getBookingsItems,
    sortedIndex,
    sortedType,
    isLoading,
    totalItems,
    bookingsItems,
    page,
  } = useBookingsList();

  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  return (
    <div className={styles.bookings}>
      <Header title={"Bookings"}>
        <ModeHandler />
      </Header>

      <PageContentContainer>
        <div className={styles.bookings_content}>
          <div className={styles.bookings_topLine}>
            <nav>
              {ROUTES.bookings.map((routeItem) => (
                <TabLink
                  route={`/${routeItem.route}`}
                  label={routeItem.label}
                  key={routeItem.label}
                />
              ))}
            </nav>
            <Button
              title="Export .xlsx"
              onClick={() => setIsExportModalOpen(true)}
            />
          </div>

          <div className={styles.bookings_search}>
            <SearchInput
              placeholder="Search by booking ID or type of service"
              value={search}
              onChange={setSearch}
            />
          </div>

          <BookingsTable
            sortedIndex={sortedIndex}
            sortedType={sortedType}
            setSortedType={setSortedType}
            setSortedIndex={setSortedIndex}
            refetchBookings={getBookingsItems}
            isLoading={isLoading}
            bookingsItems={bookingsItems}
            totalItems={totalItems}
            page={page}
            setPage={setPage}
          />
        </div>
      </PageContentContainer>

      <Modal
        title="Export bookings by period"
        isOpen={isExportModalOpen}
        onClose={() => setIsExportModalOpen(false)}
        extraModalStyles={{ content: { overflow: "visible" } }}
      >
        <ExportModalContent />
      </Modal>
    </div>
  );
};

import { ICONS } from "assets";
import Avatar from "components/Avatar";
import ButtonState from "components/common/ButtonState";
import { FC } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import { IVehicle } from "../../../../../types/vehicles";
import Checkbox from "../../../../../components/Checkbox";
import cls from "classnames";
import { Category } from "../../../../../types/drivers";
import { ItemForStatusChanging } from "hooks/useMassStatusChanging";

interface ItemProps {
  onPressCheckbox: (item: ItemForStatusChanging) => void;
  item: IVehicle;
  selected: boolean;
}

const Item: FC<ItemProps> = ({ item, onPressCheckbox, selected }) => {
  const navigate = useNavigate();

  const handleItemClick = () => {
    navigate(`/vehicles/${item.id}`);
  };

  return (
    <div className={styles.wrapper} onClick={handleItemClick}>
      <div className={styles.wrapper_checkbox}>
        <Checkbox
          onPress={() =>
            onPressCheckbox({ id: item.id, isActive: item.isActive })
          }
          active={selected}
        />
      </div>

      <div className={styles.wrapper_info}>
        <div>
          <p className={styles.wrapper_info_name}>
            {item.make} {item.model}, {item.colour}
          </p>
        </div>
      </div>

      <div
        className={cls(styles.wrapper_category, {
          [styles.wrapper_category_orange]:
            item?.category === Category.BUSINESS_PLUS,
          [styles.wrapper_category_blue]:
            item?.category === Category.FIRST_CLASS,
          [styles.wrapper_category_purple]:
            item?.category === Category.BUSINESS_XL,
        })}
      >
        {item.category || ""}
      </div>

      <div className={styles.wrapper_email}>
        <p className={styles.wrapper_email_name}>{item.registrationMark}</p>
      </div>

      {item?.driver ? (
        <div className={styles.wrapper_driver}>
          <Avatar img={item?.driver.avatar} />
          <p>
            {item?.driver.firstName} {item?.driver.lastName}
          </p>
        </div>
      ) : (
        <div className={styles.wrapper_driver} />
      )}

      <div className={styles.wrapper_btn}>
        <div className={styles.wrapper_btn_status}>
          <ButtonState isActive={item.isActive} />
          {item.expired && item.isActive && <ICONS.ExclamationMark />}
        </div>
        <div className={styles.wrapper_btn_wrapper}>
          <ICONS.RightArrow />
        </div>
      </div>
    </div>
  );
};

export default Item;

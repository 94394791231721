import { IVehicle, VehicleCategory } from "types/vehicles";

export interface DriversAllApi {
  success: boolean;
  drivers: IDriver[];
  totalDrivers: number;
}

export interface IDriver {
  id: number;
  avatar: string | null;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  isActive: boolean;
  expired: boolean;
  vehicle: { id: number; category: VehicleCategory } | null;
}

export enum Category {
  FIRST_CLASS = "First-Class",
  BUSINESS_PLUS = "Business Plus",
  BUSINESS_XL = "Business XL",
}

export interface IDriverItem {
  id: number;
  avatar: string | null;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  isActive: boolean;
  homeAddress: {
    country: string;
    city: string;
    street: string;
    postalCode: string;
  };
  taxInformation: string;
  bankDetails: {
    accountHoldersName: string;
    sortCode: string;
    accountNumber: string;
  };
  documentDetails: DrivingLicenceAndPCODocumentDetails;
  documents: IDocDetails[];
  vehicle: IVehicle | null;
  geoLocation?: {
    latitude: number;
    longitude: number;
    heading: number;
    orderId: number;
    status: string;
    updatedAt: string;
  };
}

export interface IDocDetails {
  title: string;
  link: string;
}

export type PCODocument = {
  id: number;
  category: "PCO";
  type: string;
  number: string;
  expiryDate: string;
};

export type DrivingLicence = {
  id: number;
  category: "Driving";
  type: "TFL";
  expiryDate: string;
  number?: number | null;
};

export type DrivingLicenceAndPCODocumentDetails = [DrivingLicence, PCODocument];

export type CreateDriverData = Omit<
  IDriverItem,
  "id" | "documents" | "vehicle" | "documentDetails"
> & { documentDetails: DrivingLicenceAndPCODocumentDetails };

export type UpdateDriverDocumentDetails = {
  id: number;
  type: string;
  number: string;
  expiryDate: string;
};

export type UpdateDriverData = Omit<
  IDriverItem,
  "id" | "documents" | "vehicle" | "documentDetails"
> & { documentDetails: DrivingLicenceAndPCODocumentDetails };

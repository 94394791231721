import { FC, useContext, useState } from "react";
import styles from "./Vehicles.module.scss";
import SearchInput from "../../../components/SearchInput";
import VehiclesData from "./VehiclesData";
import { Button } from "components";
import { useMassStatusChanging } from "hooks/useMassStatusChanging";
import { VariantButton } from "types/enums";
import { toast } from "react-hot-toast";
import { successToastOptions } from "utils/toasterStyles";
import { disableVehiclesById } from "api/vehicles";
import { useDebounce } from "hooks/useDebounce";
import { ExpiryAlertsContext } from "context/expiryAlerts";
import Header from "components/Header";
import { ICONS } from "assets";
import { toastServerError } from "utils/helpers";

const Vehicles: FC = () => {
  const { updateExpiryAlerts } = useContext(ExpiryAlertsContext);
  const [search, setSearch] = useState<string>("");
  const debouncedSearch = useDebounce(search, 500);
  const [count, setCount] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { selectedItems, selectedActiveIds, setSelectedItems } =
    useMassStatusChanging();
  const [isLoading, setIsLoading] = useState(false);
  const [refreshToggler, setRefreshToggler] = useState(false);

  const handleAddNewVehicle = () => {
    setIsModalOpen(true);
  };

  const handleOnPressDisable = async () => {
    try {
      setIsLoading(true);

      const { data } = await disableVehiclesById(selectedActiveIds);

      if (data.success) {
        setSelectedItems([]);
        setRefreshToggler((prev) => !prev);
        toast("Vehicles successfully disabled", successToastOptions);
        updateExpiryAlerts();
      }
    } catch (error) {
      toastServerError(error, "Something went wrong with vehicles disabling");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Header title={"Vehicles"} count={count}>
        <Button
          title="Add New Vehicle"
          onClick={handleAddNewVehicle}
          variant={VariantButton.BLACK}
        >
          <ICONS.Plus />
        </Button>
      </Header>
      <div className={styles.vehicles}>
        <div className={styles.vehicles_wrapper}>
          <div className={styles.vehicles_search}>
            <SearchInput
              placeholder="Search by driver or registration mark..."
              value={search}
              onChange={setSearch}
            />
          </div>

          {selectedActiveIds.length !== 0 && (
            <Button
              title={`Disable (${selectedActiveIds.length})`}
              onClick={handleOnPressDisable}
              variant={VariantButton.Red}
            />
          )}
        </div>

        <VehiclesData
          search={debouncedSearch}
          setCount={setCount}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          refreshToggler={refreshToggler}
        />
      </div>
    </>
  );
};

export default Vehicles;

import { FC } from "react";
import styles from "./AirportFeeContent.module.scss";
import { AirportPrice, FixedPrice } from "types/prices";

interface Props {
  columnsFlexes: number[];
  data: AirportPrice;
}

export const PriceRowItems: FC<Props> = ({ data, columnsFlexes }) => {
  return (
    <>
      <div
        className={`${styles.cell} ${styles.cell__boltText}`}
        style={{ flex: columnsFlexes[0], fontWeight: 700 }}
      >
        {data.name}
      </div>
      <div style={{ flex: columnsFlexes[1] }}>{data.postcodes.join(", ")}</div>
      <ul className={styles.priceCell} style={{ flex: columnsFlexes[2] }}>
        <li className={styles.priceCell_rowItem}>
          <span>Business Plus:</span>
          <span className={styles.priceCell_priceValue}>
            £{data.businessPlusPrice}
          </span>
        </li>
        <li className={styles.priceCell_rowItem}>
          <span>First-Class:</span>
          <span className={styles.priceCell_priceValue}>
            £{data.firstClassPrice}
          </span>
        </li>
        <li className={styles.priceCell_rowItem}>
          <span>Business XL:</span>
          <span className={styles.priceCell_priceValue}>
            £{data.businessXLPrice}
          </span>
        </li>
      </ul>
    </>
  );
};

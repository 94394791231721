import { Button, TableHeader } from "components";
import styles from "./style.module.scss";
import { FixedPrice, FixedPriceFormValue } from "types/prices";
import { useEffect, useState } from "react";
import { PriceRowItems } from "./PriceRowItems";
import { ICONS } from "assets";
import { Modal } from "components/Modal";
import { PriceForm } from "./PriceForm";
import { confirmAlert } from "react-confirm-alert";
import { ConfirmAlert } from "components/ConfirmAlert";
import { toast } from "react-hot-toast";
import { successToastOptions } from "utils/toasterStyles";
import { fetchFixedPrices, removeFixedPrive } from "api/prices";
import Skeleton from "react-loading-skeleton";
import { RowItemOptions } from "components/RowItemOptions";
import { toastServerError } from "utils/helpers";

const columnsFlexes = [21.82, 10.86, 21.82, 21.82, 23.68];

export const FixedPricesContent = () => {
  const [priceItems, setPriceItems] = useState<FixedPrice[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openedItemOptionsIndex, setOpenedItemOptionsIndex] = useState<
    number | null
  >(null);
  const [itemInModalForm, setItemInModalForm] =
    useState<FixedPriceFormValue | null>(null);

  const handleOptionsBtnClick = (rowIndex: number) => {
    if (openedItemOptionsIndex === null) {
      setOpenedItemOptionsIndex(rowIndex);
    } else {
      setOpenedItemOptionsIndex(null);
    }
  };

  const handleOptionsClose = () => {
    setOpenedItemOptionsIndex(null);
  };

  const handleRemoveRowItem = (rowIndex: number) => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmAlert
          question="Are you sure, that you want to delete price?"
          onConfirm={async () => {
            try {
              await removeFixedPrive(priceItems[rowIndex].id);

              setPriceItems((prev) =>
                prev.filter(
                  (priceItem) => priceItem.id !== priceItems[rowIndex].id
                )
              );

              toast("Fixed Price successfuly removed!", successToastOptions);
              onClose();
              setOpenedItemOptionsIndex(null);
            } catch (error) {
              toastServerError(
                error,
                "Something went wrong with fixed price removing!"
              );
            } finally {
            }
          }}
          onClose={() => {
            onClose();
          }}
        />
      ),
    });
  };

  const handleAddPriceItem = () => {
    const newPrice: FixedPriceFormValue = {
      name: "",
      from: [],
      to: [],
      type: "One-way",
      businessPlusPrice: "",
      firstClassPrice: "",
      businessXLPrice: "",
    };

    setItemInModalForm(newPrice);
  };

  const handleEditPriceItem = (itemIndex: number) => {
    const priceForEdit: FixedPriceFormValue = {
      ...priceItems[itemIndex],
      businessPlusPrice: String(priceItems[itemIndex].businessPlusPrice),
      firstClassPrice: String(priceItems[itemIndex].firstClassPrice),
      businessXLPrice: String(priceItems[itemIndex].businessXLPrice),
    };

    setItemInModalForm(priceForEdit);
    setOpenedItemOptionsIndex(null);
  };

  const getFixedPrices = async () => {
    try {
      setIsLoading(true);
      const { data } = await fetchFixedPrices();
      if (data.success) {
        setPriceItems(data.fixedPrices);
      }
    } catch (error) {
      toastServerError(
        error,
        "Something went wrong with fixed prices downloading"
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getFixedPrices();
  }, []);

  return (
    <div className={styles.content}>
      <span className={styles.content_description}>
        Fixed prices apply only to one-way transfers to and from airports
      </span>

      <TableHeader
        items={[
          "Name",
          "One/Both ways",
          "FROM Postcodes",
          "TO Postcodes",
          "Price",
        ]}
        flexes={columnsFlexes}
        gap={24}
      />

      {!isLoading ? (
        priceItems.map((priceRowData, index) => (
          <div className={styles.row} key={priceRowData.id}>
            <PriceRowItems columnsFlexes={columnsFlexes} data={priceRowData} />

            <button
              className={styles.row_dotsBtn}
              onClick={(e) => {
                e.stopPropagation();
                handleOptionsBtnClick(index);
              }}
            >
              <ICONS.ThreeDotsVertical />
            </button>

            {openedItemOptionsIndex === index && (
              <RowItemOptions
                handleClose={handleOptionsClose}
                handleDelete={() => handleRemoveRowItem(index)}
                handleEdit={() => handleEditPriceItem(index)}
              />
            )}
          </div>
        ))
      ) : (
        <Skeleton
          height={78}
          style={{ marginBottom: 2, marginTop: 2, borderRadius: 5 }}
          count={4}
        />
      )}

      <div className={styles.addItemBtn}>
        <Button title="Add price" onClick={handleAddPriceItem}>
          <ICONS.Plus className={styles.icon} />
        </Button>
      </div>

      <Modal
        isOpen={Boolean(itemInModalForm)}
        onClose={() => setItemInModalForm(null)}
        title="Fixed Price"
      >
        {itemInModalForm && (
          <PriceForm
            initialValues={itemInModalForm}
            setPriceItems={setPriceItems}
            handleClose={() => setItemInModalForm(null)}
          />
        )}
      </Modal>
    </div>
  );
};

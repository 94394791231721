import { BookingWithClientData } from "types/bookings";
import styles from "./BookingsTable.module.scss";
import { ICONS } from "assets";
import { useNavigate, useParams } from "react-router-dom";
import { getFormattedDate } from "utils/helpers/getFormatedDate";
import { BookingStatusLabel } from "components/BookingStatusLabel";

interface Props {
  item: BookingWithClientData;
  onStatusUpdate: () => Promise<void>;
  columnsFlexes: number[];
}

export const BookingRowItem = ({
  item,
  onStatusUpdate,
  columnsFlexes,
}: Props) => {
  const { activeTab } = useParams();
  const navigate = useNavigate();

  const handleItemClick = () => {
    navigate(`/bookings/${activeTab || "all"}/${item.id}`);
  };

  return (
    <div onClick={handleItemClick} className={styles.bookingRow}>
      <div
        className={styles.bookingRow_bookingDetailsCell}
        style={{ flex: columnsFlexes[0] }}
      >
        <p className={styles.bookingRow_primaryText}>{item.id}</p>
        <p className={styles.bookingRow_secondaryText}>{item.clientName}</p>
      </div>
      <div
        className={styles.bookingRow_rideDetailsCell}
        style={{ flex: columnsFlexes[1] }}
      >
        <p>{item.rideType === "As Directed" ? "By The Hour" : "One-Way"}</p>
        <p>{item.vehicleCategory}</p>
        {(item.childSeat !== 0 || item.boosterSeat !== 0) && (
          <div className={styles.bookingRow_detailsIcons}>
            {item.childSeat !== 0 && (
              <div className={styles.bookingRow_detailsIconItem}>CS</div>
            )}

            {item.boosterSeat !== 0 && (
              <div className={styles.bookingRow_detailsIconItem}>BS</div>
            )}
          </div>
        )}
      </div>
      <div style={{ flex: columnsFlexes[2] }}>
        <p className={styles.bookingRow_primaryText}>
          {item.points[0].mainAddress}
        </p>
        <p className={styles.bookingRow_secondaryText}>
          {item.points[0].secondaryAddress}
        </p>
      </div>
      <div style={{ flex: columnsFlexes[3] }}>
        <p className={styles.bookingRow_primaryText}>
          {item.points[item.points.length - 1].mainAddress}
        </p>
        <p className={styles.bookingRow_secondaryText}>
          {item.points[item.points.length - 1].secondaryAddress}
        </p>
      </div>
      <div style={{ flex: columnsFlexes[4] }}>
        <p className={styles.bookingRow_primaryText}>{`£${(
          item.finalPrice || item.price
        ).toFixed(2)}`}</p>
      </div>
      <div
        className={styles.bookingRow_lastCell}
        style={{ flex: columnsFlexes[5] }}
      >
        <div>
          <BookingStatusLabel
            bookingId={item.id}
            status={item.rideStatus}
            onStatusUpdate={onStatusUpdate}
          />

          <p className={styles.bookingRow_date}>
            {getFormattedDate(item.rideDate, `EEE, dd MMM, yyyy`)}
            <br />
            {getFormattedDate(item.rideDate, `HH:mm`)}
          </p>
        </div>
        <ICONS.GoNextIcon />
      </div>
    </div>
  );
};

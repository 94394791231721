import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import style from "./style.module.scss";
import { ICONS } from "../../../../../../../../assets";
import {
  getFileUploadLink,
  uploadDocument,
} from "../../../../../../../../api/documents";
import axios from "axios";
import { FileType } from "../../index";
import { Document } from "../../../index";
import { apiAxios } from "../../../../../../../../api";
import Skeleton from "react-loading-skeleton";
import { ConfirmAlert } from "components/ConfirmAlert";
import { confirmAlert } from "react-confirm-alert";

type Props = {
  type: "front" | "back";
  driverId?: number;
  mode: FileType;
  document: Document;
};

const convertBytesToMegabytes = (bytesSize: number) => {
  return bytesSize / (1024 * 1024);
};

const DocumentFile: FC<Props> = ({ document: doc, type, driverId, mode }) => {
  const ref = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [fileUrl, setFileUrl] = useState<string>("");
  const { title: documentName, link: documentLink, id } = doc;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (documentLink) {
      (async () => {
        setIsLoading(true);
        const response = await fetch(documentLink);
        const blob = await response.blob();
        const newFile = new File(
          [blob],
          documentLink
            .split("/")
            [documentLink.split("/").length - 1].split("_")[0],
          { type: "application/pdf" }
        );
        setFile(newFile);
        const url = URL.createObjectURL(newFile);
        setFileUrl(url);
        setIsLoading(false);
      })();
    }
  }, [documentLink]);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const newFile = e.target.files[0];
      setFile(newFile);

      if (newFile) {
        const url = URL.createObjectURL(newFile);

        setFileUrl(url);

        (async () => {
          try {
            const link = await getFileUploadLink(
              [url.split("/")[0]],
              "document"
            );

            let blob = await fetch(URL.createObjectURL(newFile)).then((r) =>
              r.blob()
            );

            if (!link) {
              throw new Error("LinksLoading error");
            }
            const response = await axios.put(link[0].uploadLink, blob, {
              headers: {
                "Content-Type": "application/octet-stream",
              },
            });

            if (response.status !== 200) {
              throw Error("Save file error");
              return;
            }

            if (driverId) {
              const resp = await uploadDocument(mode, driverId, {
                docList: [
                  { title: documentName, link: link[0].downloadLink as string },
                ],
              });

              console.log(resp);
            }
          } catch (e) {
            console.log("[ERROR file upload] => ", e);
          }
        })();
      } else {
        setFileUrl("");
      }
    }
  };

  const handleFileView = () => {
    window.open(fileUrl);
  };

  const handleFileDelete = async () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmAlert
          question="Are you sure, that you want to delete document?"
          onConfirm={async () => {
            if (documentLink) {
              try {
                await apiAxios.patch(`document/${mode}/delete?id=${driverId}`, {
                  docIdList: [id],
                });
              } catch (e) {
                console.log("[ERROR delete document] => ", e);
              }
            }

            setFile(null);
            if (ref.current) {
              ref.current.value = "";
            }
            onClose();
          }}
          onClose={() => {
            onClose();
          }}
        />
      ),
    });
  };

  return isLoading ? (
    <Skeleton
      height={77}
      style={{ marginBottom: 2, marginTop: 2, borderRadius: 10 }}
      count={4}
    />
  ) : (
    <div className={style.wrapper}>
      <div className={style.wrapper_name}>{documentName}</div>
      <div className={style.wrapper_file}>
        {file ? (
          <div className={style.wrapper_file_uploadedFileWrapper}>
            <div className={style.wrapper_file_uploadedFileWrapper_iconWrapper}>
              <ICONS.Document />
            </div>
            <div
              className={
                style.wrapper_file_uploadedFileWrapper_fileNameAndSizeWrapper
              }
            >
              <span>{file.name}</span>
              <span>{convertBytesToMegabytes(file.size).toFixed(1)} MB</span>
            </div>
          </div>
        ) : (
          <label htmlFor={documentName}>
            <ICONS.Attach />
            <span>Attach document</span>
          </label>
        )}
        <input
          ref={ref}
          id={documentName}
          type="file"
          accept="application/pdf"
          onChange={handleFileChange}
        />
        {file && (
          <div className={style.wrapper_viewAndDeleteWrapper}>
            <button onClick={handleFileView}>View document</button>
            <button onClick={handleFileDelete}>
              <ICONS.Trash />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default DocumentFile;

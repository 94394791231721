import axios, { AxiosResponse } from "axios";
import { apiAxios } from "../index";
import { ServerResponse } from "types";

interface checkDocumentsExpiryResponse extends ServerResponse {
  driverDocuments: boolean;
  vehicleDocuments: boolean;
}

export const uploadFile = async (fileUrl: string, localFile: any) => {
  try {
    const response = await axios.put(fileUrl, null, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      maxBodyLength: Infinity,
      maxContentLength: Infinity,
      data: localFile,
    });
  } catch (error) {
    console.error("Error uploading file", error);
  }
};

export const getFileUploadLink = async (
  fileNames: string[],
  fileType: "avatar" | "document" | "message"
): Promise<Array<{ uploadLink: string; downloadLink: string }> | undefined> => {
  try {
    const response = await apiAxios.post(`file/upload/${fileType}`, {
      fileNames: fileNames,
    });

    return response.data.links;
  } catch (e) {
    console.log("[ERROR get upload link] => ", e);
  }
};

export const uploadDocument = async (
  mode: "driver" | "vehicle",
  id: number,
  body: { docList: { title: string; link: string }[] }
) => {
  return await apiAxios.put(`document/${mode}?id=${id}`, body);
};

export const getFilesDowloadLinks = (
  downloadLinks: string[],
  fileType: "avatar" | "document" | "message"
): Promise<
  AxiosResponse<{ success: boolean; links: { downloadLink: string }[] }>
> => {
  return apiAxios.post(`file/download/${fileType}`, {
    downloadLinks: downloadLinks,
  });
};

export const deleteFile = () => {
  return apiAxios.post("");
};

export const checkDocumentsExpiry = (): Promise<
  AxiosResponse<checkDocumentsExpiryResponse>
> => {
  return apiAxios.get("document/expiry");
};

import React, { FC, useRef, useState } from "react";
import styles from "./style.module.scss";
import Button from "components/Button";
import { VariantButton } from "types/enums";
import useOutsideClick from "hooks/useOutsideClick";
import ReactModal from "react-modal";
import LoaderScreen from "components/LoaderScreen";

interface Props {
  question: string;
  onConfirm: () => any;
  onClose: () => void;
}
const ModalStyles: ReactModal.Styles = {
  content: {
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
    width: "fit-content",
    padding: 32,
    height: "fit-content",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export const ConfirmAlert: FC<Props> = ({ onConfirm, onClose, question }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirmClick = async () => {
    setIsLoading(true);

    await onConfirm();

    setIsLoading(false);
  };

  return (
    <ReactModal
      isOpen={true}
      style={ModalStyles}
      ariaHideApp={false}
      onRequestClose={onClose}
    >
      <div className={styles.alert}>
        <h2 className={styles.alert_question}>{question}</h2>
        <div className={styles.alert_buttons}>
          <Button
            title="Confirm"
            onClick={handleConfirmClick}
            variant={VariantButton.BLACK}
          />
          <Button title="Cancel" onClick={onClose} />
        </div>
      </div>

      {isLoading && <LoaderScreen />}
    </ReactModal>
  );
};

import { useFormik } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";

import { loginApi } from "api/login";
import { ICONS } from "assets";
import { Button, Input } from "components";
import { TokensService } from "utils";
import { LoginSchema } from "utils/validation";
import { UserContext } from "context/user";
import { VariantButton } from "types/enums";

const Login: FC = () => {
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);
  const [errorFromServer, setErrorFromServer] = useState("");

  const { values, errors, setFieldValue, setFieldError, handleSubmit } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      validationSchema: LoginSchema,
      validateOnChange: false,
      onSubmit: login,
    });

  async function login() {
    try {
      const { data } = await loginApi(values);
      console.log("data", data);
      if (data.success) {
        TokensService.setLocalStorage("@accessToken", data.accessToken);
        TokensService.setLocalStorage("@refreshToken", data.refreshToken);
        setUser(data.user);
        navigate("/");
      }
    } catch (e: any) {
      setErrorFromServer(
        e?.response.data.message || "Error from server. Something went wrong"
      );
    }
  }

  useEffect(() => {
    if (TokensService.getTokens()) {
      navigate("/Drivers");
    }
  }, []);

  const isDisableBtn = !values.email.trim() || !values.password.trim();

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_logo}>
        <ICONS.Logo />
      </div>
      <div className={styles.wrapper_box}>
        <div className={styles.wrapper_box_input}>
          <Input
            value={values.email}
            label="Email"
            error={errors.email}
            onChange={(val) => {
              if (errorFromServer) {
                setErrorFromServer("");
              }

              if (errors.email) {
                setFieldError("email", "");
              }
              setFieldValue("email", val);
            }}
          />
        </div>
        <div className={styles.wrapper_box_input}>
          <Input
            value={values.password}
            label="Password"
            isSucure
            error={errors.password}
            onChange={(val) => {
              if (errorFromServer) {
                setErrorFromServer("");
              }
              if (errors.password) {
                setFieldError("password", "");
              }
              setFieldValue("password", val);
            }}
          />
        </div>

        {errorFromServer && (
          <p className={styles.wrapper_error}>{errorFromServer}</p>
        )}

        <Button
          title="Login"
          variant={VariantButton.BLACK}
          disable={isDisableBtn} //  || loading || userLoading
          onClick={handleSubmit}
          extraStyles={{ width: "100%" }}
        />
      </div>
    </div>
  );
};

export default Login;

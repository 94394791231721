import React, { useMemo, useState } from "react";

export interface ItemForStatusChanging {
  id: number;
  isActive: boolean;
}

export const useMassStatusChanging = () => {
  const [selectedItems, setSelectedItems] = useState<ItemForStatusChanging[]>(
    []
  );

  const selectedActiveIds = useMemo(() => {
    return selectedItems.reduce(
      (acc: number[], item) => (item.isActive ? [...acc, item.id] : acc),
      []
    );
  }, [selectedItems]);

  const selectedDisabledIds = useMemo(() => {
    return selectedItems.reduce(
      (acc: number[], item) => (!item.isActive ? [...acc, item.id] : acc),
      []
    );
  }, [selectedItems]);

  return {
    selectedItems,
    setSelectedItems,
    selectedActiveIds,
    selectedDisabledIds,
  };
};

import { FC } from "react";
import cls from "classnames";
import styles from "./Tabs.module.scss";
import {ICONS} from "../../assets";

type Props = {
    tabs: Array<{ title: string; count?: number, attention?: boolean }>;
    currentIndex: number;
    onChange: (newIndex: number) => void;
};

const Tabs: FC<Props> = ({ tabs, currentIndex, onChange }) => {
    return (
        <div className={styles.wrapper}>
            {tabs.map((t, index) => {
                const isSelected = index === currentIndex;

                return (
                    <div
                        key={index}
                        className={cls(styles.wrapper_item, {
                            [styles.wrapper_item_active]: isSelected,
                        })}
                        onClick={() => onChange(index)}
                    >
                        <p
                            className={cls(styles.wrapper_item_title, {
                                [styles.wrapper_item_title_active]: isSelected,
                            })}
                        >
                            {t.title}
                        </p>

                        {t.count !== undefined && (
                            <div
                                className={cls(styles.wrapper_item_count, {
                                    [styles.wrapper_item_count_active]: isSelected,
                                })}
                            >
                                {t.count}
                            </div>
                        )}

                        {t.attention && (
                          <ICONS.ExclamationMark className={styles.wrapper_item_attention}/>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default Tabs;

import styles from "./style.module.scss";
import { Formik } from "formik";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { FixedPrice, FixedPriceFormValue } from "types/prices";
import { Button, Input } from "components";
import { VariantButton } from "types/enums";
import { toast } from "react-hot-toast";
import { errorToastOptions, successToastOptions } from "utils/toasterStyles";
import { createFixedPrice, updateFixedPrice } from "api/prices";
import LoaderScreen from "components/LoaderScreen";
import { fixedPriceValidationSchema } from "utils/validation/Prices";
import { RadioButton } from "components/RadioButton";
import { PostcodesSelector } from "components/PostcodesSelector";
import { fetchOperatingAreasList } from "api/operatingAreas";

interface Props {
  initialValues: FixedPriceFormValue;
  setPriceItems: Dispatch<SetStateAction<FixedPrice[]>>;
  handleClose: () => void;
}

export const PriceForm: FC<Props> = ({
  initialValues,
  setPriceItems,
  handleClose,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const formType = !initialValues.id ? "create" : "edit";
  // const [avaliblePostcodes, setAvaliblePostcodes] = useState<string[]>([]);
  // const [isAvaliblePostcodesLoading, setIsAvalabilisticPostcodesLoading] =
  //   useState(false);

  const handleSubmit = async (values: FixedPriceFormValue) => {
    setIsLoading(true);

    if (formType === "create") {
      try {
        const newPrice: Omit<FixedPrice, "id"> = {
          ...values,
          businessPlusPrice: +values.businessPlusPrice,
          firstClassPrice: +values.firstClassPrice,
          businessXLPrice: +values.businessXLPrice,
        };

        const { data } = await createFixedPrice(newPrice);

        if (data.success) {
          setPriceItems((prev) => [data.fixedPrice, ...prev]);

          toast("Fixed price successfuly updated!", successToastOptions);
          handleClose();
        }
      } catch (error) {
        toast("Somthing went wrong with price uploading!", errorToastOptions);
        console.log("[ADD FIXED PRICE ERROR]: ", error);
      } finally {
        setIsLoading(false);
      }
    } else {
      try {
        const updatedPrice: Omit<FixedPrice, "id"> = {
          name: values.name,
          from: values.from,
          to: values.to,
          type: values.type,
          businessPlusPrice: +values.businessPlusPrice,
          firstClassPrice: +values.firstClassPrice,
          businessXLPrice: +values.businessXLPrice,
        };

        const { data } = await updateFixedPrice(
          values.id as number,
          updatedPrice
        );

        if (data.success) {
          setPriceItems((prev) =>
            prev.map((price) =>
              price.id === data.fixedPrice.id ? data.fixedPrice : price
            )
          );

          toast("Fixed price successfuly updated!", successToastOptions);
          handleClose();
        }
      } catch (error) {
        toast("Somthing went wrong with price updating", errorToastOptions);
        console.log("[UPDATE FIXED PRICE ERROR]: ", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={fixedPriceValidationSchema}
      validateOnChange={false}
    >
      {({ values, errors, setFieldValue, setFieldError, handleSubmit }) => {
        return (
          <div className={styles.form}>
            <div>
              <RadioButton
                value="One-way"
                label="One-way"
                isActive={values.type === "One-way"}
                handleClick={(value) => setFieldValue("type", value)}
              />

              <RadioButton
                value="Both ways"
                label="Both ways"
                isActive={values.type === "Both ways"}
                handleClick={(value) => setFieldValue("type", value)}
              />
            </div>

            <Input
              label="Name"
              value={values.name}
              onChange={(val) => {
                setFieldValue("name", val);
                setFieldError("name", "");
              }}
              error={errors.name}
              placeholder="Enter a name"
            />

            <PostcodesSelector
              selectedValue={values.from.map((postcode) => ({
                label: postcode,
                value: postcode,
              }))}
              handleSelect={(selectedOptions) => {
                setFieldValue(
                  "from",
                  selectedOptions?.map((option) => option.value)
                );
                setFieldError("from", "");
              }}
              label="FROM Postcodes"
              error={errors.from as string}
              withDetailPostcodes={true}
            />

            <PostcodesSelector
              selectedValue={values.to.map((postcode) => ({
                label: postcode,
                value: postcode,
              }))}
              handleSelect={(selectedOptions) => {
                setFieldValue(
                  "to",
                  selectedOptions?.map((option) => option.value)
                );
                setFieldError("to", "");
              }}
              label="TO Postcodes"
              error={errors.from as string}
              withDetailPostcodes={true}
            />

            <div className={styles.prices}>
              <Input
                label="BUSINESS PLUS"
                type="number"
                value={values.businessPlusPrice}
                onChange={(value) => {
                  setFieldValue("businessPlusPrice", value);
                  setFieldError("businessPlusPrice", "");
                }}
                placeholder="Set price"
                error={errors.businessPlusPrice}
              />
              <Input
                label="FIRST-CLASS"
                type="number"
                value={values.firstClassPrice}
                onChange={(value) => {
                  setFieldValue("firstClassPrice", value);
                  setFieldError("firstClassPrice", "");
                }}
                placeholder="Set price"
                error={errors.firstClassPrice}
              />
              <Input
                label="BUSINESS XL"
                type="number"
                value={values.businessXLPrice}
                onChange={(value) => {
                  setFieldValue("businessXLPrice", value);
                  setFieldError("businessXLPrice", "");
                }}
                placeholder="Set price"
                error={errors.businessXLPrice}
              />
            </div>

            <div className={styles.modalFormBtns}>
              <Button
                title={
                  formType === "create"
                    ? "Create fixed price"
                    : "Edit fixed price"
                }
                onClick={handleSubmit}
                variant={VariantButton.BLACK}
              />
              <Button title="Cancel" onClick={handleClose} />
            </div>
            {isLoading && <LoaderScreen />}
          </div>
        );
      }}
    </Formik>
  );
};

import { ChatType } from "hooks/useSupportChatsList";
import styles from "./SuportChatWidget.module.scss";
import { ChatListItemWithClientName } from "types/chat";
import { ICONS } from "assets";
import ChatIcons from "assets/icons/chat";

interface Props {
  selectedChatsType: ChatType;
  selectedChat: ChatListItemWithClientName | null;
  hadleBackClick: () => void;
  handleCompleteClick: () => void;
}

export const ChatHeader: React.FC<Props> = ({
  selectedChatsType,
  selectedChat,
  hadleBackClick,
  handleCompleteClick,
}) => {
  return (
    <div className={styles.chatHeader}>
      {selectedChat ? (
        <>
          <button
            className={styles.chatHeader_backBtn}
            onClick={hadleBackClick}
          >
            <ChatIcons.LeaveChat />
          </button>

          <div className={styles.chatHeader_selectedChatData}>
            <p className={styles.chatHeader_clientName}>
              {selectedChat.clientName}
            </p>
            {selectedChat.orderId && (
              <p className={styles.chatHeader_bookingId}>
                Booking ID: {selectedChat.orderId}
              </p>
            )}
          </div>

          {!selectedChat.isCompleted && (
            <button
              className={styles.chatHeader_completeBtn}
              onClick={handleCompleteClick}
            >
              <ChatIcons.CompleteChat width={20} height={20} />
              Complete chat
            </button>
          )}
        </>
      ) : (
        <p className={styles.chatHeader_categoryLabel}>{selectedChatsType}</p>
      )}
    </div>
  );
};

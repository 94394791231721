import Avatar from "components/Avatar";
import { FC } from "react";
import { IDriverItem } from "types/drivers";
import styles from "./BookingDriverSelector.module.scss";

export const DriverItem: FC<{ driver: IDriverItem }> = ({ driver }) => {
  return (
    <div className={styles.driverAndVehicle_data}>
      <Avatar img={driver.avatar} dimensions={{ width: 64, height: 64 }} />

      <p className={styles.driverAndVehicle_name}>
        {`${driver.firstName} ${driver.lastName}`}
      </p>

      {driver.vehicle && (
        <div style={{ flexGrow: 2 }}>
          <p>
            {`${driver.vehicle.make} ${driver.vehicle.model}${
              driver.vehicle.colour ? `, ${driver.vehicle.colour}` : ""
            }`}
          </p>

          <p>{driver.vehicle.registrationMark}</p>
        </div>
      )}
    </div>
  );
};

import { useState } from "react";
import { CreateVehicle, IVehicle } from "../types/vehicles";
import { updateVehicleDataApi } from "../api/vehicles";

export const useUpdateVehicle = (id: string | undefined) => {
  const [updatedVehicle, setUpdatedVahicle] = useState<IVehicle | null>(null);

  const updateVehicle = async (updatedVehicleData: CreateVehicle) => {
    try {
      if (id) {
        const { data } = await updateVehicleDataApi(id, updatedVehicleData);
        if (data.success) {
          setUpdatedVahicle(data.vehicle);
        }
      }
    } catch (e) {
      console.log("error updating vehicle", e);
      // @ts-ignore
      return e.response.data.message;
    }
  };

  return { updatedVehicle, updateVehicle };
};

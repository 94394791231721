import { updateDriverApi } from "../api/drivers";
import { useState } from "react";
import { IDriverItem, UpdateDriverData } from "../types/drivers";

export const useUpdateDriver = (id: string | undefined) => {
  const [updatedDriver, setUpdatedDriver] = useState<IDriverItem | null>(null);

  const updateDriver = async (updateDriverData: UpdateDriverData) => {
    try {
      if (id) {
        const { data } = await updateDriverApi(id, updateDriverData);
        if (data.success) {
          setUpdatedDriver(data.driver);
        }
      }
    } catch (e) {
      console.log("error updating driver", e);
      // @ts-ignore
      return e.response.data.message;
    }
  };

  return { updatedDriver, updateDriver };
};

import { FC } from "react";
import { useNavigate } from "react-router-dom";
import cls from "classnames";
import styles from "./styles.module.scss";

import { ICONS } from "assets";
import Toggle from "../Toggle";

type Props = {
  title: string;
  links?: Array<string>;
  actions?: Array<React.ReactElement>;
  onToggle?: () => void;
  toggleActive?: boolean;
};

const BackHeader: FC<Props> = ({
  title,
  links,
  actions,
  toggleActive,
  onToggle,
}) => {
  const navigate = useNavigate();

  const handleBackClick = (index: number) => {
    if (!links || index === links.length - 1) return;

    navigate(index - (links.length - 1));
  };

  return (
    <header className={styles.header}>
      <div className={styles.header_container}>
        <div className={styles.header_arrow} onClick={() => navigate(-1)}>
          <ICONS.BackArrow />
        </div>

        {links && links.length > 0 ? (
          <div className={styles.header_links}>
            {links.map((link, index) => {
              const isLast = index === links.length - 1;

              return (
                <div
                  key={index}
                  className={cls(styles.header_links_item, {
                    [styles.header_links_item_active]: isLast,
                  })}
                  onClick={() => handleBackClick(index)}
                >
                  {link}
                </div>
              );
            })}
          </div>
        ) : (
          <p className={styles.header_title}>{title}</p>
        )}

        {actions && (
          <div className={styles.header_actions}>
            {actions.map((action, index) => (
              <div key={index} className={styles.header_actions_item}>
                {action}
              </div>
            ))}
          </div>
        )}
      </div>
      {onToggle ? (
        <div className={styles.header_switcher}>
          <div className={styles.header_switcher_container}>
            <p className={styles.header_switcherTitle}>
              {title} is {toggleActive ? "active" : "disabled"}
            </p>
            <p className={styles.header_switcherText}>
              Switch to {toggleActive ? "disable" : "activate"}
            </p>
          </div>
          <Toggle active={toggleActive} onToggle={onToggle} />
        </div>
      ) : null}
    </header>
  );
};

export default BackHeader;

import React, { FC } from "react";

import { NavLink, To } from "react-router-dom";

import styles from "./styles.module.scss";

interface Props {
  route: To;
  label: string;
  counterValue?: number;
}

export const TabLink: FC<Props> = ({ route, label, counterValue }) => {
  return (
    <NavLink
      to={route}
      className={({ isActive }) =>
        [styles.link, isActive ? styles.link__active : ""].join(" ")
      }
    >
      <span>{label}</span>
      {counterValue !== undefined && (
        <span className={styles.counterValue}>{counterValue}</span>
      )}
    </NavLink>
  );
};

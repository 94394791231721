import cls from "classnames";
import React, { FC } from "react";
import styles from "./styles.module.scss";

type Props = {
  currentPage: number;
  limit: number;
  count: number;
  onChange: (page: number) => void;
};

const Pagination: FC<Props> = ({ currentPage, limit, count, onChange }) => {
  const totalPages = Math.ceil(count / limit);

  const getPageNumbers = (): Array<number | string> => {
    const pageNumbers: Array<number | string> = [];
    const maxVisiblePages = 4;
    const maxVisibleDots = 1;

    if (totalPages <= maxVisiblePages) {
      // Display all page numbers
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      let startPage = 1;
      let endPage = maxVisiblePages;

      if (currentPage <= maxVisiblePages - maxVisibleDots) {
        // Show first group of pages
        pageNumbers.push(
          ...Array(endPage)
            .fill(null)
            .map((_, i) => i + 1)
        );
        pageNumbers.push("...");
        pageNumbers.push(totalPages);
      } else if (currentPage > totalPages - maxVisiblePages + maxVisibleDots) {
        // Show last group of pages
        pageNumbers.push(1);
        pageNumbers.push("...");
        pageNumbers.push(
          ...Array.from(Array(maxVisiblePages).keys()).map(
            (i) => totalPages - maxVisiblePages + 1 + i
          )
        );
      } else {
        // Show middle group of pages
        pageNumbers.push(1);
        pageNumbers.push("...");
        const middleStartPage =
          currentPage - Math.floor(maxVisiblePages / 2) + 1;
        const middleEndPage = currentPage + Math.floor(maxVisiblePages / 2);
        pageNumbers.push(
          ...Array(middleEndPage - middleStartPage + 1)
            .fill(null)
            .map((_, i) => middleStartPage + i)
        );
        pageNumbers.push("...");
        pageNumbers.push(totalPages);
      }
    }

    return pageNumbers;
  };

  return (
    <div className={styles.wrapper}>
      {currentPage !== 1 && (
        <button
          className={cls(styles.wrapper_button, styles.wrapper_button_prevNext)}
          disabled={currentPage === 1}
          onClick={() => onChange(currentPage - 1)}
        >
          Previous
        </button>
      )}

      {getPageNumbers().map((number, index) => (
        <React.Fragment key={index}>
          {number === "..." ? (
            <div
              className={cls(
                styles.wrapper_button,
                styles.wrapper_button_transparent
              )}
            >
              ...
            </div>
          ) : (
            <button
              className={cls(styles.wrapper_button, {
                [styles.wrapper_button_active]: number === currentPage,
              })}
              onClick={() => onChange(number as number)}
            >
              {number}
            </button>
          )}
        </React.Fragment>
      ))}

      {currentPage !== totalPages && (
        <button
          className={cls(styles.wrapper_button, styles.wrapper_button_prevNext)}
          disabled={currentPage === totalPages}
          onClick={() => onChange(currentPage + 1)}
        >
          Next
        </button>
      )}
    </div>
  );
};

export default Pagination;

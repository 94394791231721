import { Fragment, useEffect, useMemo, useState } from "react";
import styles from "./BookingDetails.module.scss";
import { BookingPaymentItem } from "./BookingPaymentItem";
import { BookingReceipt } from "types/payments";
import { fetchBookingPaymentsReseipts } from "api/payments";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { toastServerError } from "utils/helpers";

export const BookingPaymentList = () => {
  const { bookingId: id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [paymentReceipts, setPaymentReceipts] = useState<
    BookingReceipt[] | null
  >(null);

  const getPaymentReceipts = async () => {
    try {
      setIsLoading(true);
      const { data } = await fetchBookingPaymentsReseipts(Number(id));

      if (data.success) {
        setPaymentReceipts(data.receipts);
      }
    } catch (error) {
      toastServerError(
        error,
        "Something went wrong with Payment receipts loading"
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPaymentReceipts();
  }, []);

  const uniqCardReceipts = useMemo(() => {
    if (!paymentReceipts) {
      return null;
    }

    return paymentReceipts.reduce((acc: BookingReceipt[], receipt) => {
      if (
        acc.some((addedReceipt) => addedReceipt.card.id === receipt.card.id)
      ) {
        return acc;
      } else {
        return [...acc, receipt];
      }
    }, []);
  }, [paymentReceipts]);

  console.log(uniqCardReceipts);
  return (
    <div className={styles.paymentsList}>
      {isLoading && <Skeleton height={52} />}

      {uniqCardReceipts &&
        uniqCardReceipts.map((receiptItem) => (
          <Fragment key={receiptItem.id}>
            <BookingPaymentItem receiptItem={receiptItem} />
          </Fragment>
        ))}

      {!isLoading && (!paymentReceipts || !paymentReceipts.length) && (
        <p>There is no information about payments</p>
      )}
    </div>
  );
};

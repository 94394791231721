import { FC, memo } from "react";
import { ChatListItemWithClientName, ChatMessageItem } from "types/chat";
import { ChatMessage } from "components/Chat/ChatMessage";
import { ChatLoadControler } from "components/Chat/ChatLoaderController";
import { getFormattedDate } from "utils/helpers/getFormatedDate";
import { MessageViewedController } from "components/Chat/MessageViewedController";
import { TypingIndicator } from "components/Chat/TypingIndicator/TypingIndicator";
import styles from "./SuportChatWidget.module.scss";

interface Props {
  selectedChat: ChatListItemWithClientName;
  messages: ChatMessageItem[];
  isChatOpen: boolean;
  isLoading: boolean;
  page: number;
  hasMoreMessages: boolean;
  loadMoreMessages: () => void;
  onMessagesViewed: (vievedMessageId: number) => void;
}

export const ChatMessagesList: FC<Props> = memo(
  ({
    selectedChat,
    messages,
    isLoading,
    isChatOpen,
    page,
    hasMoreMessages,
    loadMoreMessages,
    onMessagesViewed,
  }) => {
    const lastClientUnreadMessageIndex = messages.findIndex(
      (message) => message.authorType !== "admin" && !message.isViewed
    );

    return (
      <div className={styles.messagesList}>
        <div className={styles.messagesList_container}>
          {selectedChat.isClientTyping && (
            <div className={styles.messagesList_typingIndicator}>
              <TypingIndicator typingUserName={selectedChat.clientName} />
            </div>
          )}
          {messages.length !== 0 &&
            messages.map((message, index) => {
              const bottomMarging =
                messages[index - 1]?.authorType !== message.authorType ? 10 : 2;

              return (
                <div style={{ marginBottom: bottomMarging }} key={message.id}>
                  <ChatMessage
                    messageItem={message}
                    isMyMessage={message.authorType === "admin"}
                    withViewedIndicator={true}
                  />
                  {index === lastClientUnreadMessageIndex && (
                    <MessageViewedController
                      messageId={message.id}
                      chatId={selectedChat.id}
                      onView={() => onMessagesViewed(message.id)}
                      isChatOpen={isChatOpen}
                    />
                  )}
                </div>
              );
            })}

          <p className={styles.messagesList_startDate}>
            {`Started ${getFormattedDate(
              selectedChat.createdAt,
              "dd MMM',' yyyy, HH:mm"
            )}`}
          </p>

          <ChatLoadControler
            page={page}
            isLoading={isLoading}
            hasMoreMessages={hasMoreMessages}
            loadCallback={loadMoreMessages}
          />
        </div>
      </div>
    );
  }
);

// export default memo(ChatMessagesList);

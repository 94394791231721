import { FC } from "react";
import style from "./style.module.scss";
import { Document } from "../../index";
import DocumentFile from "./DocumentFile";
import { FileType } from "../index";

type Props = {
  document: Document;
  driverId?: number;
  mode: "driver" | "vehicle";
};

const DocumentFileRow: FC<Props> = ({ document, driverId, mode }) => {
  return (
    <div className={style.wrapper}>
      <DocumentFile
        mode={mode}
        document={document}
        type="front"
        driverId={driverId}
      />
    </div>
  );
};

export default DocumentFileRow;

import { Accordion } from "@szhsin/react-accordion";
import cls from "classnames";
import {
  FC,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import styles from "./Main.module.scss";

import { ICONS } from "assets";
import Nav from "assets/icons/nav";
import AccordionItem from "components/AccordionItem";
import ProfileBlock from "./ProfileBlock";
import { ExpiryAlertsContext } from "context/expiryAlerts";
import { ROUTES } from "utils/constants";
import { SupportChatWidget } from "components/SupportChatWidget";
import { UserContext } from "context/user";

const Main: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const contentRef = useRef<HTMLDivElement>(null);
  const [isSuportChatOpen, setIsSuportChatOpen] = useState<boolean>(false);
  const { user } = useContext(UserContext);
  const { updateExpiryAlerts } = useContext(ExpiryAlertsContext);
  const { vehicleDocumentsAlert, driverDocumentsAlert } =
    useContext(ExpiryAlertsContext);

  const goTo = useCallback(
    (to: string, text: string) => (
      <NavLink
        to={`/${to}`}
        className={({ isActive }) => `${styles.wrapper_menu_nav_item}
      ${styles.wrapper_menu_nav_sub} 
      ${isActive && styles.wrapper_menu_nav_item_active__sub}`}
      >
        <p
          className={cls(
            styles.wrapper_menu_nav_item_openText,
            styles.wrapper_menu_nav_subText
          )}
        >
          {text}
        </p>
      </NavLink>
    ),
    []
  );

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/drivers");
    }
    updateExpiryAlerts();
  }, []);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTo(0, 0);
    }
  }, [location.pathname]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_menu}>
        <div className={styles.wrapper_menu_logo}>
          <ICONS.DarkLogo />
        </div>
        <nav className={styles.wrapper_menu_nav}>
          <Accordion transition transitionTimeout={250}>
            <NavLink
              to="/locator"
              className={({ isActive }) =>
                `${styles.wrapper_menu_nav_item} ${
                  isActive && styles.wrapper_menu_nav_item_active
                }`
              }
            >
              <div className={styles.wrapper_menu_nav_item_icon}>
                <Nav.Location height={24} />
              </div>
              <p className={styles.wrapper_menu_nav_item_text}>Locator</p>
            </NavLink>
            <NavLink
              to={ROUTES.statistics[0].route}
              className={cls(styles.wrapper_menu_nav_item, {
                [styles.wrapper_menu_nav_item_active]:
                  location.pathname.startsWith("/statistics"),
              })}
            >
              <div className={styles.wrapper_menu_nav_item_icon}>
                <Nav.Statistics height={24} />
              </div>
              <p className={styles.wrapper_menu_nav_item_text}>Statistics</p>
            </NavLink>
            <AccordionItem
              header={
                <div className={styles.wrapper_menu_nav_item_accordion_icon}>
                  <Nav.Bookings width={24} height={24} />
                  <p className={styles.wrapper_menu_nav_item_acordionText}>
                    Bookings
                  </p>
                </div>
              }
            >
              {ROUTES.bookings.map((routeItem) => (
                <Fragment key={routeItem.route}>
                  {goTo(routeItem.route, routeItem.label)}
                </Fragment>
              ))}
            </AccordionItem>
            <AccordionItem
              header={
                <div className={styles.wrapper_menu_nav_item_accordion_icon}>
                  <Nav.Users width={24} height={24} />
                  {
                    <p className={styles.wrapper_menu_nav_item_acordionText}>
                      Users
                    </p>
                  }
                </div>
              }
            >
              {ROUTES.users.map((routeItem) => (
                <Fragment key={routeItem.route}>
                  {goTo(routeItem.route, routeItem.label)}
                </Fragment>
              ))}
            </AccordionItem>
            <NavLink
              to="/drivers"
              className={({ isActive }) =>
                `${styles.wrapper_menu_nav_item} ${
                  isActive && styles.wrapper_menu_nav_item_active
                }`
              }
            >
              <div className={styles.wrapper_menu_nav_item_icon}>
                <Nav.Drivers height={24} />
              </div>
              <p className={styles.wrapper_menu_nav_item_text}>Drivers</p>
              <div className={styles.wrapper_menu_nav_item_alert}>
                {driverDocumentsAlert && <ICONS.ExclamationMark />}
              </div>
            </NavLink>
            <NavLink
              to="/vehicles"
              className={({ isActive }) =>
                `${styles.wrapper_menu_nav_item} ${
                  isActive && styles.wrapper_menu_nav_item_active
                }`
              }
            >
              <div className={styles.wrapper_menu_nav_item_icon}>
                <Nav.Vehicles height={24} />
              </div>
              <p className={styles.wrapper_menu_nav_item_text}>Vehicles</p>
              <div className={styles.wrapper_menu_nav_item_alert}>
                {vehicleDocumentsAlert && <ICONS.ExclamationMark />}
              </div>
            </NavLink>
            <AccordionItem
              header={
                <div className={styles.wrapper_menu_nav_item_accordion_icon}>
                  <Nav.Prices width={24} height={24} />
                  <p className={styles.wrapper_menu_nav_item_acordionText}>
                    Prices
                  </p>
                </div>
              }
            >
              {ROUTES.prices.map((routeItem) => (
                <Fragment key={routeItem.route}>
                  {goTo(routeItem.route, routeItem.label)}
                </Fragment>
              ))}
            </AccordionItem>

            <NavLink
              to="/operating_areas"
              className={({ isActive }) =>
                `${styles.wrapper_menu_nav_item} ${
                  isActive && styles.wrapper_menu_nav_item_active
                }`
              }
            >
              <div className={styles.wrapper_menu_nav_item_icon}>
                <Nav.Areas height={24} width={24} />
              </div>
              <p className={styles.wrapper_menu_nav_item_text}>
                Operating Areas
              </p>
            </NavLink>

            <NavLink
              to="/feedback"
              className={({ isActive }) =>
                `${styles.wrapper_menu_nav_item} ${
                  isActive && styles.wrapper_menu_nav_item_active
                }`
              }
            >
              <div className={styles.wrapper_menu_nav_item_icon}>
                <Nav.Feedback height={24} />
              </div>
              <p className={styles.wrapper_menu_nav_item_text}>Feedback</p>
            </NavLink>

            {user?.role === "Admin" && (
              <NavLink
                to="/staff_management"
                className={({ isActive }) =>
                  `${styles.wrapper_menu_nav_item} ${
                    isActive && styles.wrapper_menu_nav_item_active
                  }`
                }
              >
                <div className={styles.wrapper_menu_nav_item_icon}>
                  <Nav.Staff height={24} />
                </div>
                <p className={styles.wrapper_menu_nav_item_text}>
                  Staff Management
                </p>
              </NavLink>
            )}
          </Accordion>
        </nav>

        <ProfileBlock />
      </div>

      <div ref={contentRef} className={styles.wrapper_content}>
        <Outlet />
      </div>

      <SupportChatWidget
        isOpen={isSuportChatOpen}
        setIsOpen={setIsSuportChatOpen}
      />
    </div>
  );
};

export default Main;

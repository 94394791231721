import { ICONS } from "assets";
import cls from "classnames";
import React, { FC, ReactNode } from "react";
import styles from "./styles.module.scss";

type Props = {
  items: Array<ReactNode | string | false>;
  selectedIndex?: number | null;
  sortedType?: "desc" | "asc" | null;
  flexes?: Array<number | undefined>;
  onChangeSorted?: (index: number | null) => void;
  onChangeType?: (type: "desc" | "asc" | null) => void;
  sortIndexes?: number[];
  alignRightIndexes?: number[];
  gap?: number;
};

const TableHeader: FC<Props> = ({
  items,
  selectedIndex = null,
  sortedType = null,
  flexes = [],
  gap = 24,
  onChangeSorted = () => {},
  onChangeType = () => {},
  alignRightIndexes,
  sortIndexes = [],
}) => {
  const handleChangeSort = (index: number) => {
    if (index === selectedIndex && sortedType === "desc") {
      onChangeSorted(null);
      onChangeType(null);
      return;
    }

    if (index === selectedIndex) {
      onChangeType("desc");
      return;
    }

    onChangeSorted(index);
    onChangeType("asc");
  };

  const renderItems = () => {
    return items.map((item, index) => {
      const flex = flexes[index];
      const isSelected = selectedIndex === index;

      const canSortByItem = sortIndexes?.includes(index);
      const justifyContent = alignRightIndexes?.includes(index)
        ? "flex-end"
        : "flex-start";

      return (
        <div
          key={index}
          className={styles.wrapper_item}
          style={{
            flex: flex || undefined,
            justifyContent,
            cursor: canSortByItem ? "pointer" : "default",
          }}
          onClick={() =>
            typeof item === "string" && canSortByItem && handleChangeSort(index)
          }
        >
          {item && typeof item === "string" ? (
            <>
              <p
                className={cls(styles.wrapper_item_title, {
                  [styles.wrapper_item_title_active]: isSelected,
                })}
              >
                {item}
              </p>
              {sortIndexes ? (
                canSortByItem && (
                  <div
                    className={cls(styles.wrapper_item_arrow, {
                      [styles.wrapper_item_arrow_active]: isSelected,
                    })}
                    style={{
                      transform: `rotate(${
                        isSelected && sortedType === "desc" ? "180deg" : 0
                      })`,
                    }}
                  >
                    <ICONS.SmallArow />
                  </div>
                )
              ) : (
                <div
                  className={styles.wrapper_item_arrow}
                  style={{
                    transform: `rotate(${
                      isSelected && sortedType === "desc" ? "180deg" : 0
                    })`,
                  }}
                >
                  <ICONS.SmallArow />
                </div>
              )}
            </>
          ) : (
            item
          )}
        </div>
      );
    });
  };

  return (
    <div className={styles.wrapper} style={{ gap }}>
      {renderItems()}
    </div>
  );
};

export default TableHeader;

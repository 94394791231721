import { FC } from "react";
import Modal from "react-modal";
import styles from "./styles.module.scss";
import GeneralInformationForm from "../GeneralInformationForm";
import { ICONS } from "../../assets";
import { useCreateDriver } from "../../hooks/useCreateDriver";
import { CreateDriverData } from "../../types/drivers";
import VehicleInformationForm from "../VehicleInformationForm";
import { CreateVehicle, IVehicle } from "../../types/vehicles";
import { useCreateVehicle } from "../../hooks/useCreateVehicle";

const customStyles = {
  content: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "fit-content",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: 1,
  },
};

type Props = {
  setVehicles: (vehicles: IVehicle[]) => void;
  isOpen: boolean;
  onClose: () => void;
};

const CreateVehicleModal: FC<Props> = ({ isOpen, onClose, setVehicles }) => {
  const { createVehicle } = useCreateVehicle();

  const handleSubmit = (data: CreateVehicle) => {
    return createVehicle(data);
  };

  return (
    <Modal
      isOpen={isOpen}
      //@ts-ignore
      style={customStyles}
      ariaHideApp={false}
      onRequestClose={onClose}
    >
      <div className={styles.wrapper}>
        <button className={styles.wrapper_closeButton} onClick={onClose}>
          <ICONS.CloseCross />
        </button>
        <h5>New Vehicle</h5>
        <VehicleInformationForm
          setVehicles={setVehicles}
          isCreate={true}
          onSubmit={handleSubmit}
          onCancel={onClose}
        />
      </div>
    </Modal>
  );
};

export default CreateVehicleModal;

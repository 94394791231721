import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Skeleton from "react-loading-skeleton";
import styles from "./style.module.scss";

import { Pagination, TableHeader } from "components";
import { useDebounce } from "hooks/useDebounce";
import { useDrivers } from "hooks/useDrivers";
import Item from "./Item";
import Checkbox from "../../../../components/Checkbox";
import CreateDriverModal from "components/CreateDriverModal";
import { ItemForStatusChanging } from "hooks/useMassStatusChanging";

const LIMIT = 10;

type Props = {
  search: string;
  setCount: (value: number) => void;
  selectedItems: ItemForStatusChanging[];
  setSelectedItems: Dispatch<SetStateAction<ItemForStatusChanging[]>>;
  update?: boolean;
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
};
const DriversData: FC<Props> = ({
  search,
  setCount,
  selectedItems,
  setSelectedItems,
  update,
  isModalOpen,
  setIsModalOpen,
}) => {
  const [sortedIndex, setSortedIndex] = useState<null | number>(null);
  const [sortedType, setSortedType] = useState<"desc" | "asc" | null>(null);
  const debouncedSearch = useDebounce(search, 500);

  const {
    drivers,
    setDrivers,
    totalDrivers,
    currentPage,
    setCurrentPage,
    isLoading,
    getAllDrivers,
  } = useDrivers(sortedType, sortedIndex, debouncedSearch);

  useEffect(() => {
    getAllDrivers();
    setSelectedItems([]);
  }, [update]);

  useEffect(() => {
    setCount(totalDrivers || 0);
  }, [totalDrivers]);

  const isAllSelected = useMemo(() => {
    return (
      selectedItems.length !== 0 &&
      drivers.every((driver) =>
        selectedItems.some((item) => item.id === driver.id)
      )
    );
  }, [selectedItems]);

  const onPressCheckbox = useCallback(
    (driver: ItemForStatusChanging) => {
      if (selectedItems.some((item) => item.id === driver.id)) {
        setSelectedItems((prevItems) =>
          prevItems.filter((item) => item.id !== driver.id)
        );
      } else {
        setSelectedItems((prevItems) => [...prevItems, driver]);
      }
    },
    [selectedItems, setSelectedItems]
  );

  const onPressSelectAll = useCallback(() => {
    if (selectedItems.length === drivers.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(
        drivers.map((driver) => ({
          id: driver.id,
          isActive: driver.isActive,
        }))
      );
    }
  }, [selectedItems, drivers, isAllSelected]);

  const renderReports = () => {
    if (drivers.length > 0) {
      return drivers.map((driver) => (
        <Item
          key={driver.id}
          item={driver}
          onPressCheckbox={onPressCheckbox}
          selected={selectedItems.some((item) => item.id === driver.id)}
        />
      ));
    }
    return <p className={styles.wrapper_empty}>No drivers yet</p>;
  };

  return (
    <div className={styles.wrapper}>
      <div>
        <TableHeader
          items={[
            <Checkbox onPress={onPressSelectAll} active={isAllSelected} />,
            "Driver",
            "Category",
            "Email",
            "Phone number",
            false,
          ]}
          flexes={[undefined, 5, 2, 4, 3, 3]}
          selectedIndex={sortedIndex}
          sortedType={sortedType}
          onChangeSorted={setSortedIndex}
          onChangeType={setSortedType}
          sortIndexes={[0, 1, 2]}
        />
        {isLoading ? (
          <Skeleton
            height={50}
            style={{ marginBottom: 2, marginTop: 2, borderRadius: 10 }}
            count={10}
          />
        ) : (
          renderReports()
        )}
      </div>

      {totalDrivers !== 0 && (
        <div className={styles.wrapper_pagination}>
          <Pagination
            currentPage={currentPage}
            count={totalDrivers || 0}
            limit={LIMIT}
            onChange={setCurrentPage}
          />
        </div>
      )}

      <CreateDriverModal
        setDrivers={setDrivers}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default DriversData;

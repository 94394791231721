import { Marker, OverlayView } from "@react-google-maps/api";
import { FC, useMemo } from "react";
import { LocatorDriverItem } from "types/locator";
import styles from "../Locator.module.scss";

interface Props {
  driver: LocatorDriverItem;
  onClick: (driver: LocatorDriverItem) => void;
}

export const DriverMarker: FC<Props> = ({ driver, onClick }) => {
  const position = useMemo(
    () => ({
      lat: driver.point.latitude,
      lng: driver.point.longitude,
    }),
    [driver.point.latitude, driver.point.longitude]
  );

  const markerIcon = useMemo(() => {
    switch (driver.driver.vehicle?.category) {
      case "Business Plus":
        return require("assets/Map/BussinessPlusMarker.png");
      case "Business XL":
        return require("assets/Map/BussinessXLMarker.png");
      default:
        return require("assets/Map/FirstClassMarker.png");
    }
  }, [driver.driver.vehicle?.category]);

  const markerSize = useMemo(() => {
    return new google.maps.Size(36, 43);
  }, []);

  return (
    <>
      <Marker
        icon={{
          url: markerIcon,
          scaledSize: markerSize,
        }}
        position={position}
        options={{
          label: {
            text: String(driver.driverId + "\n"),
            className: styles.markerLabel,
            color:
              driver.driver.vehicle?.category === "Business Plus"
                ? "#1C1C1C"
                : "white",
          },
        }}
        onClick={() => onClick(driver)}
      ></Marker>
    </>
  );
};

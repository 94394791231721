import { useEffect, useMemo, useState } from "react";
import styles from "./AirportFeeContent.module.scss";
import SearchInput from "components/SearchInput";
import { Button, TableHeader } from "components";
import { ICONS } from "assets";
import { AirportPrice, AirportPickUpPriceFormValue } from "types/prices";
import Skeleton from "react-loading-skeleton";
import { Modal } from "components/Modal";
import { PriceForm } from "./PriceForm";
import { PriceRowItems } from "./PriceRowItems";
import { RowItemOptions } from "components/RowItemOptions";
import {
  fetchAsDirectedAirportPickUpPrices,
  fetchOneWayAirportDropOffPrices,
  fetchOneWayAirportPickUpPrices,
  removeAsDirectedAirportPickUpPrice,
  removeOneWayAirportDropOffPrice,
  removeOneWayAirportPickUpPrice,
} from "api/prices";
import { toast } from "react-hot-toast";
import { successToastOptions } from "utils/toasterStyles";
import { useDebounce } from "hooks/useDebounce";
import { confirmAlert } from "react-confirm-alert";
import { ConfirmAlert } from "components/ConfirmAlert";
import { toastServerError } from "utils/helpers";

const columnsFlexes = [37.5, 37.5, 25];

interface Props {
  type:
    | "By The Hour Airport Pick-Up"
    | "One Way Airport Pick-Up"
    | "One Way Airport Drop-Off";
}

export const AirportFeeContent = ({ type }: Props) => {
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);
  const [isLoading, setIsLoading] = useState(true);
  const [priceItems, setPriceItems] = useState<AirportPrice[]>([]);
  const [openedItemOptionsIndex, setOpenedItemOptionsIndex] = useState<
    number | null
  >(null);
  const [itemInModalForm, setItemInModalForm] =
    useState<AirportPickUpPriceFormValue | null>(null);
  const [sortedIndex, setSortedIndex] = useState<number | null>(null);
  const [sortedType, setSortedType] = useState<"asc" | "desc" | null>(null);

  const fetchFn = useMemo(() => {
    switch (type) {
      case "By The Hour Airport Pick-Up":
        return fetchAsDirectedAirportPickUpPrices;
      case "One Way Airport Pick-Up":
        return fetchOneWayAirportPickUpPrices;
      case "One Way Airport Drop-Off":
        return fetchOneWayAirportDropOffPrices;
    }
  }, []);

  const removeFn = useMemo(() => {
    switch (type) {
      case "By The Hour Airport Pick-Up":
        return removeAsDirectedAirportPickUpPrice;
      case "One Way Airport Pick-Up":
        return removeOneWayAirportPickUpPrice;
      case "One Way Airport Drop-Off":
        return removeOneWayAirportDropOffPrice;
    }
  }, []);

  const description = useMemo(() => {
    switch (type) {
      case "By The Hour Airport Pick-Up":
        return "Fixed prices apply only to by-the-hour transfers from airports";
      case "One Way Airport Pick-Up":
        return "Fixed prices apply only to one way transfers from airports";
      case "One Way Airport Drop-Off":
        return "Fixed prices apply only to one way transfers to airports";
    }
  }, []);

  const handleOptionsBtnClick = (rowIndex: number) => {
    if (openedItemOptionsIndex === null) {
      setOpenedItemOptionsIndex(rowIndex);
    } else {
      setOpenedItemOptionsIndex(null);
    }
  };

  const handleOptionsClose = () => {
    setOpenedItemOptionsIndex(null);
  };

  const handleRemoveRowItem = (rowIndex: number) => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmAlert
          question="Are you sure, that you want to delete price?"
          onConfirm={async () => {
            try {
              await removeFn(priceItems[rowIndex].id);

              setPriceItems((prev) =>
                prev.filter(
                  (priceItem) => priceItem.id !== priceItems[rowIndex].id
                )
              );

              toast(`${type} price successfully removed!`, successToastOptions);
              onClose();
              setOpenedItemOptionsIndex(null);
            } catch (error) {
              toastServerError(
                error,
                `Something went wrong with ${type} price removing!`
              );
            }
          }}
          onClose={() => {
            onClose();
          }}
        />
      ),
    });
  };

  const handleEditPriceItem = (itemIndex: number) => {
    const priceForEdit: AirportPickUpPriceFormValue = {
      ...priceItems[itemIndex],
      businessPlusPrice: String(priceItems[itemIndex].businessPlusPrice),
      firstClassPrice: String(priceItems[itemIndex].firstClassPrice),
      businessXLPrice: String(priceItems[itemIndex].businessXLPrice),
    };

    setItemInModalForm(priceForEdit);
    setOpenedItemOptionsIndex(null);
  };

  const handleAddPriceItem = () => {
    const newPrice: AirportPickUpPriceFormValue = {
      name: "",
      postcodes: [],
      businessPlusPrice: "",
      firstClassPrice: "",
      businessXLPrice: "",
    };

    setItemInModalForm(newPrice);
  };

  const getAirportFeePrices = async () => {
    try {
      setIsLoading(true);
      const { data } = await fetchFn({
        sorting: {
          sortBy: sortedIndex === null ? "createdAt" : "name",
          order: sortedType
            ? (sortedType.toUpperCase() as "ASC" | "DESC")
            : "DESC",
        },
        search: debouncedSearch || undefined,
      });

      if (data.success) {
        setPriceItems(data.fixedPrices);
      }
    } catch (error) {
      toastServerError(
        error,
        "Something went wrong with airport pick-up prices loading"
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAirportFeePrices();
  }, [debouncedSearch, sortedIndex, sortedType]);

  return (
    <div className={styles.content}>
      <div className={styles.content_search}>
        <SearchInput
          value={search}
          onChange={setSearch}
          placeholder="Search by airport name"
        />
      </div>
      <p className={styles.content_description}>{description}</p>

      <div>
        <TableHeader
          items={["Name", "FROM Postcodes", "Price"]}
          sortIndexes={[0]}
          sortedType={sortedType}
          selectedIndex={sortedIndex}
          onChangeSorted={setSortedIndex}
          onChangeType={setSortedType}
          gap={24}
          flexes={columnsFlexes}
        />
        {!isLoading ? (
          priceItems.map((priceRowData, index) => (
            <div className={styles.row} key={priceRowData.id}>
              <PriceRowItems
                columnsFlexes={columnsFlexes}
                data={priceRowData}
              />

              <button
                className={styles.row_dotsBtn}
                onClick={(e) => {
                  e.stopPropagation();
                  handleOptionsBtnClick(index);
                }}
              >
                <ICONS.ThreeDotsVertical />
              </button>

              {openedItemOptionsIndex === index && (
                <RowItemOptions
                  handleClose={handleOptionsClose}
                  handleDelete={() => handleRemoveRowItem(index)}
                  handleEdit={() => handleEditPriceItem(index)}
                />
              )}
            </div>
          ))
        ) : (
          <Skeleton
            height={90}
            style={{ marginBottom: 2, marginTop: 2, borderRadius: 5 }}
            count={4}
          />
        )}
      </div>

      <div>
        <Button title="Add price" onClick={handleAddPriceItem}>
          <ICONS.Plus className={styles.icon} />
        </Button>
      </div>

      <Modal
        isOpen={Boolean(itemInModalForm)}
        onClose={() => setItemInModalForm(null)}
        title={type}
      >
        {itemInModalForm && (
          <PriceForm
            initialValues={itemInModalForm}
            setPriceItems={setPriceItems}
            handleClose={() => setItemInModalForm(null)}
            type={type}
          />
        )}
      </Modal>
    </div>
  );
};

import { ICONS } from "assets";
import { Button, TableHeader } from "components";
import Header from "components/Header";
import { PageContentContainer } from "components/PageContentContainer";
import SearchInput from "components/SearchInput";
import React, { useEffect, useState } from "react";
import { VariantButton } from "types/enums";
import styles from "./OperatingAreas.module.scss";
import { AreaRowItem } from "./AreaRowItem";
import { useDebounce } from "hooks/useDebounce";
import { Area, AreaInForm } from "types/areas";
import { toast } from "react-hot-toast";
import { errorToastOptions, successToastOptions } from "utils/toasterStyles";
import {
  fetchOperatingAreasList,
  removeOperatingArea,
} from "api/operatingAreas";
import { EmptyTablePlaceholder } from "components/EmptyTablePlaceholder";
import Skeleton from "react-loading-skeleton";
import { RowItemOptions } from "components/RowItemOptions";
import { Modal } from "components/Modal";
import { AreaForm } from "./AreaForm";
import { confirmAlert } from "react-confirm-alert";
import { ConfirmAlert } from "components/ConfirmAlert";
import { toastServerError } from "utils/helpers";

const columnsFlexes = [39.7, 40.3, 20];

const sortMap: { [key: number]: string } = {
  0: "name",
  2: "status",
};

export const OperatingAreas = () => {
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);
  const [sortedIndex, setSortedIndex] = useState<number | null>(null);
  const [sortedType, setSortedType] = useState<"asc" | "desc" | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [areasList, setAreasList] = useState<Area[]>([]);
  const [openedItemOptionsIndex, setOpenedItemOptionsIndex] = useState<
    number | null
  >(null);
  const [itemInModalForm, setItemInModalForm] = useState<AreaInForm | null>(
    null
  );

  const handleOptionsClose = () => {
    setOpenedItemOptionsIndex(null);
  };

  const handleOptionsBtnClick = (rowIndex: number) => {
    if (openedItemOptionsIndex === null) {
      setOpenedItemOptionsIndex(rowIndex);
    } else {
      handleOptionsClose();
    }
  };

  const handleAddNewArea = () => {
    setItemInModalForm({
      name: "",
      postcodes: [],
      status: "Active",
    });
  };

  const handleRemoveAreaItem = async (index: number) => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmAlert
          question="Are you sure, that you want to delete price?"
          onConfirm={async () => {
            try {
              await removeOperatingArea(areasList[index].id);

              setAreasList((prev) =>
                prev.filter((priceItem) => priceItem.id !== areasList[index].id)
              );

              toast("Operating area successfuly removed!", successToastOptions);
              onClose();
              setOpenedItemOptionsIndex(null);
            } catch (error) {
              toastServerError(
                error,
                "Something went wrong with Airport Pick-Up price removing!"
              );
            }
          }}
          onClose={() => {
            onClose();
          }}
        />
      ),
    });
  };

  const handleEditAreaItem = async (index: number) => {
    setItemInModalForm(areasList[index]);
    setOpenedItemOptionsIndex(null);
  };

  const getAreasList = async () => {
    try {
      setIsLoading(true);

      const { data } = await fetchOperatingAreasList({
        sorting:
          sortedIndex !== null
            ? {
                sortBy: sortMap[sortedIndex],
                order: sortedType ? sortedType.toUpperCase() : undefined,
              }
            : undefined,
        search: debouncedSearch || undefined,
      });

      setAreasList(data.areas);
    } catch (error) {
      toastServerError(error, "Something went wrong with areas loading");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAreasList();
  }, [sortedIndex, sortedType, debouncedSearch]);

  return (
    <div className={styles.areasPage}>
      <Header title="Operating Areas">
        <Button
          title="Add New Area"
          onClick={handleAddNewArea}
          variant={VariantButton.BLACK}
        >
          <ICONS.Plus />
        </Button>
      </Header>

      <PageContentContainer>
        <div className={styles.areasPage_content}>
          <div className={styles.areasPage_search}>
            <SearchInput
              value={search}
              onChange={setSearch}
              placeholder="Search by area name"
            />
          </div>

          <div>
            <TableHeader
              items={["Name", "Postcodes", "Status"]}
              sortIndexes={[0, 2]}
              alignRightIndexes={[2]}
              gap={24}
              flexes={columnsFlexes}
              sortedType={sortedType}
              selectedIndex={sortedIndex}
              onChangeSorted={setSortedIndex}
              onChangeType={setSortedType}
            />
            {!isLoading && areasList.length !== 0 ? (
              areasList.map((area, index) => {
                return (
                  <div className={styles.areaRowItem} key={area.id}>
                    <AreaRowItem area={area} columnsFlexes={columnsFlexes} />

                    <button
                      className={styles.areaRowItem_dotsBtn}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOptionsBtnClick(index);
                      }}
                    >
                      <ICONS.ThreeDotsVertical />
                    </button>

                    {openedItemOptionsIndex === index && (
                      <RowItemOptions
                        handleClose={handleOptionsClose}
                        handleDelete={() => handleRemoveAreaItem(index)}
                        handleEdit={() => handleEditAreaItem(index)}
                      />
                    )}
                  </div>
                );
              })
            ) : !isLoading ? (
              <EmptyTablePlaceholder text="There are no operating areas matching your choise" />
            ) : (
              <Skeleton
                height={78}
                style={{ marginBottom: 2, marginTop: 2, borderRadius: 5 }}
                count={4}
              />
            )}
          </div>
        </div>
      </PageContentContainer>

      <Modal
        isOpen={Boolean(itemInModalForm)}
        onClose={() => setItemInModalForm(null)}
        title={`${itemInModalForm?.id ? "Edit" : "New"} area`}
      >
        {itemInModalForm && (
          <AreaForm
            initialValues={itemInModalForm}
            setAreaList={setAreasList}
            handleClose={() => setItemInModalForm(null)}
          />
        )}
      </Modal>
    </div>
  );
};

import { ICONS } from "assets";
import ChatIcons from "assets/icons/chat";
import { Toast } from "react-hot-toast";

export const successToastOptions:
  | Partial<
      Pick<
        Toast,
        | "id"
        | "icon"
        | "duration"
        | "ariaProps"
        | "className"
        | "style"
        | "position"
        | "iconTheme"
      >
    >
  | undefined = {
  icon: <ICONS.CheckInCircleIcon />,
  style: {
    backgroundColor: "#D5F4D3CC",
    color: "#141415",
    fontSize: 14,
    fontWeight: 400,
    fontFamily: "Eudoxus Sans",
    boxShadow: "none",
    paddingTop: 14,
    paddingBottom: 14,
    paddingRight: 20,
    paddingLeft: 20,
  },
};

export const errorToastOptions:
  | Partial<
      Pick<
        Toast,
        | "id"
        | "icon"
        | "duration"
        | "ariaProps"
        | "className"
        | "style"
        | "position"
        | "iconTheme"
      >
    >
  | undefined = {
  icon: <ICONS.CrossInCircle />,
  style: {
    backgroundColor: "rgba(254, 206, 206, 0.8)",
    color: "#141415",
    fontSize: 14,
    fontWeight: 400,
    fontFamily: "Eudoxus Sans",
    boxShadow: "none",
    paddingTop: 14,
    paddingBottom: 14,
    paddingRight: 20,
    paddingLeft: 20,
  },
};

export const chatMessageToastOptions:
  | Partial<
      Pick<
        Toast,
        | "id"
        | "icon"
        | "duration"
        | "ariaProps"
        | "className"
        | "style"
        | "position"
        | "iconTheme"
      >
    >
  | undefined = {
  icon: <ChatIcons.ClosedChat width={24} height={24} color="#141415" />,
  position: "top-right",
  duration: 3000,
  style: {
    backgroundColor: "#edd5b5",
    color: "#141415",
    fontSize: 14,
    fontWeight: 400,
    fontFamily: "Eudoxus Sans",
    boxShadow: "none",
    paddingTop: 14,
    paddingBottom: 14,
    paddingRight: 20,
    paddingLeft: 20,
  },
};

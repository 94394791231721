import React, { FC } from "react";
import styles from "./PassengerItemRow.module.scss";
import Checkbox from "components/Checkbox";
import { PassengerItem } from "types/users";
import { getFormattedDate } from "utils/helpers/getFormatedDate";
import { ICONS } from "assets";
import cn from "classnames";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "utils/constants";
import { UserForBlockOrDelete } from "hooks/useUsersBlockAndDelete";

interface Props {
  passenger: PassengerItem;
  columsFlexes: Array<number | undefined>;
  page: number;
}

export const PassengerItemRow: FC<Props> = ({
  columsFlexes,
  passenger,
  page,
}) => {
  const navigate = useNavigate();

  const handleItemClick = () => {
    navigate(
      `/${ROUTES.users[1].route}/${passenger.id}/${ROUTES.userDetails[0].route}`,
      { state: { page } }
    );
  };

  return (
    <div className={styles.passenger} onClick={handleItemClick}>
      <div
        className={styles.passenger_dataCell}
        style={{ flex: columsFlexes[0] }}
      >
        <p
          className={styles.passenger_name}
        >{`${passenger.firstName} ${passenger.lastName}`}</p>
      </div>
      <div
        className={styles.passenger_dataCell}
        style={{ flex: columsFlexes[1] }}
      >
        <p>{passenger.phoneNumber}</p>
      </div>
      <div
        className={cn(styles.passenger_dataCell, styles.passenger_holderCell)}
        style={{ flex: columsFlexes[2] }}
      >
        <p>{`${passenger.holder.firstName} ${passenger.holder.lastName}`}</p>
        <p>{passenger.holder.phoneNumber}</p>
        <p>{passenger.holder.email}</p>
      </div>
      <div
        className={cn(styles.passenger_lastCell, styles.passenger_dataCell)}
        style={{ flex: columsFlexes[3] }}
      >
        <p>{getFormattedDate(passenger.createdAt, "d MMM, yyyy'")}</p>
        <ICONS.GoNextIcon />
      </div>
    </div>
  );
};

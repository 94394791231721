import { BookingStatus } from "types/bookings";

export const getBookingStatusLabelStyles = (status: BookingStatus) => {
  switch (status) {
    case "Confirmed":
      return { backgroundColor: "#EDD5B5", color: "#000" };
    case "Assigned":
      return { backgroundColor: "#FFC0CB", color: "#FFF" };
    case "On the way":
      return { backgroundColor: "#AA8768", color: "#FFF" };
    case "Arrived":
      return { backgroundColor: "#3F8CFF", color: "#FFF" };
    case "On board":
      return { backgroundColor: "#1C1C1C", color: "#EDD5B5" };
    case "Completed":
      return { backgroundColor: "#4CC045", color: "#FFF" };
    case "No show":
      return { backgroundColor: "#BBB2A8", color: "#FFF" };
    case "Cancelled":
    default:
      return { backgroundColor: "#BBB2A8", color: "#FFF" };
  }
};

import { FC } from "react";
import Lottie from "lottie-react";
import animationData from "assets/animations/typing.json";
import styles from "./TypingIndicator.module.scss";

interface Props {
  typingUserName: string;
}

export const TypingIndicator: FC<Props> = ({ typingUserName }) => {
  return (
    <div className={styles.typingIndicator}>
      <Lottie
        animationData={animationData}
        className={styles.typingIndicator_animation}
      />
      <p className={styles.typingIndicator_userName}>
        {typingUserName} is typing
      </p>
    </div>
  );
};

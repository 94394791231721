import { getHours, getMinutes } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

export const getTimeFromDate = (date: string) => {
  const dateObj = utcToZonedTime(date, "Europe/London");
  let hours: number | string = getHours(dateObj);
  let minutes: number | string = getMinutes(dateObj);

  if (hours <= 9) {
    hours = `0${hours}`;
  }

  if (minutes <= 9) {
    minutes = `0${minutes}`;
  }

  return `${hours}:${minutes}`;
};

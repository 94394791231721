import { apiAxios } from "../../api";

interface ILogin {
  email: string;
  password: string;
}
export const loginApi = (body: ILogin) => {
  return apiAxios.post("auth/admin/signin", body);
};

export const fetchUserInfo = () => apiAxios.get("admin/info");

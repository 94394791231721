import { getDriverApi } from "api/drivers";
import { useEffect, useState } from "react";
import { IDriverItem } from "types/drivers";
import { toastServerError } from "utils/helpers";

export const useGetDriver = (id: string | number | undefined | null) => {
  const [driver, setDriver] = useState<IDriverItem | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const getDriverInfo = async () => {
    try {
      if (id) {
        setIsLoading(true);
        const { data } = await getDriverApi(id);

        if (data.success) {
          setDriver(data.driver);
        }
      }
    } catch (error) {
      toastServerError(error, "Something went wrong with driver loading");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDriverInfo();
  }, []);

  return {
    driver,
    getDriverInfo,
    isLoading,
  };
};

import { AccordionItem as Item } from "@szhsin/react-accordion";
import { ICONS } from "assets";
import { FC } from "react";
import { VariantButton } from "types/enums";
import styles from "./styles.module.scss";

type Props = {
  header: any;
  title?: string;
  disable?: boolean;
  variant?: VariantButton;
  onClick?: () => void;
  children: any;
};

const AccordionItem: FC<Props> = ({ header, ...rest }) => (
  <Item
    {...rest}
    header={
      <div className={styles.header}>
        {header}
        <ICONS.Chevron className={styles.item_chevron} />
      </div>
    }
    className={styles.item}
    buttonProps={{
      className: ({ isEnter }) => {
        return `${styles.item_itemBtn} ${
          isEnter && styles.item_itemBtnExpanded
        }`;
      },
    }}
    contentProps={{ className: styles.item_itemContent }}
    panelProps={{ className: styles.item_itemPanel }}
  />
);

export default AccordionItem;

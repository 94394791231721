import { Document } from "../pages/Dashboard/Drivers/DriverItem/Documents";

export const mergeData = (data: Document[], newData: Document[]) => {
  return data.map((initialItem) => {
    const backendItem = newData.find(
      (item) => item.title === initialItem.title
    );

    if (backendItem) {
      return backendItem;
    } else {
      return initialItem;
    }
  });
};

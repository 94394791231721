import { FC } from "react";
import ReactSelect from "react-select";
import styles from "./styles.module.scss";

type Props = {
  label: string;
  data: string[];
  selectedValue: string;
  handleSelect: (country: string) => void;
  error?: string;
  placeholder?: string;
  isSearchable?: boolean;
};

const colourStyles = {
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    backgroundColor: "transparent",
    height: 48,
    width: "100%",
    paddingLeft: 13,
    paddingRight: 2,
    cursor: "pointer",
    fontSize: 14,
    borderWidth: state.isFocused ? 1 : 1,
    borerColor: state.isFocused ? "#bbb2a8" : "#bbb2a8",
  }),
  dropdownIndicator: (baseStyles: any) => ({
    ...baseStyles,
    color: "#1C1C1C",
  }),
  valueContainer: (baseStyles: any) => ({
    ...baseStyles,
    padding: 0,
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  menu: (baseStyles: any) => ({
    ...baseStyles,
    overflow: "hidden",
  }),
  menuList: (baseStyles: any) => ({
    ...baseStyles,
    padding: 0,
  }),
  option: (baseStyles: any) => ({
    ...baseStyles,
    cursor: "pointer",
  }),
};

const Select: FC<Props> = ({
  label,
  data,
  selectedValue,
  handleSelect,
  error,
  placeholder = "",
  isSearchable = false,
}) => {
  const options = data
    .filter((item) => item !== undefined)
    .map((el) => ({ label: el, value: el }));

  return (
    <div className={styles.wrapper}>
      <p className={styles.wrapper_label}>{label}</p>
      <ReactSelect
        options={options}
        value={
          selectedValue ? { label: selectedValue, value: selectedValue } : null
        }
        styles={colourStyles}
        placeholder={placeholder}
        isSearchable={isSearchable}
        theme={(theme) => ({
          ...theme,
          borderRadius: 4,
          colors: {
            ...theme.colors,
            primary: "#AA8768",
            primary25: "#d1b8a1",
            primary50: "#d1b8a1",
            danger: "#E53E3E",
            dangerLight: "#E53E3E",
            neutral20: error ? "red" : "#bbb2a8",
            neutral30: "#AA8768",
            neutral40: "#AA8768",
            neutral80: "#1C1C1C",
          },
        })}
        onChange={(option) => handleSelect(option?.value || "")}
      />
      {error && <p className={styles.wrapper_error}>{error}</p>}
    </div>
  );
};

export default Select;

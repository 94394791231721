import TableHeader from "components/TableHeader";
import React, { FC } from "react";
import { BookingWithClientData } from "types/bookings";
import { BookingRowItem } from "./BookingRowItem";
import { EmptyTablePlaceholder } from "components/EmptyTablePlaceholder";
import Skeleton from "react-loading-skeleton";
import { BOOKINGS_LOADING_LIMIT } from "utils/constants";
import Pagination from "components/Pagination";
import styles from "./BookingsTable.module.scss";

const columnsFlexes = [17.8, 14.4, 20, 20, 9.1, 18.6];

interface Props {
  sortedIndex: number;
  sortedType: "desc" | "asc";
  setSortedIndex: React.Dispatch<React.SetStateAction<number>>;
  setSortedType: React.Dispatch<React.SetStateAction<"desc" | "asc">>;
  isLoading: boolean;
  bookingsItems: BookingWithClientData[];
  refetchBookings: () => Promise<void>;
  totalItems: number;
  page: number;
  setPage: (page: number) => void;
}

export const BookingsTable: FC<Props> = ({
  sortedIndex,
  sortedType,
  setSortedIndex,
  setSortedType,
  refetchBookings,
  isLoading,
  bookingsItems,
  totalItems,
  page,
  setPage,
}) => {
  return (
    <>
      <div className={styles.bookingsTable}>
        <TableHeader
          items={[
            "Booking details",
            "Ride details",
            "Pickup",
            "Destination",
            "Price",
            "Date and Time",
          ]}
          flexes={columnsFlexes}
          gap={24}
          selectedIndex={sortedIndex}
          sortedType={sortedType}
          onChangeSorted={(val) =>
            setSortedIndex((prev) => (val === null ? prev : val))
          }
          onChangeType={(order) =>
            setSortedType(order === null ? "asc" : order)
          }
          sortIndexes={[4, 5]}
        />

        {!isLoading ? (
          bookingsItems.length !== 0 ? (
            bookingsItems.map((booking) => (
              <BookingRowItem
                item={booking}
                columnsFlexes={columnsFlexes}
                key={booking.id}
                onStatusUpdate={refetchBookings}
              />
            ))
          ) : (
            <EmptyTablePlaceholder text="There are no bookings matching your choice" />
          )
        ) : (
          <Skeleton
            height={105}
            style={{ marginBottom: 2, marginTop: 2, borderRadius: 10 }}
            count={10}
          />
        )}
      </div>

      {totalItems > BOOKINGS_LOADING_LIMIT && (
        <div>
          <Pagination
            currentPage={page}
            count={totalItems}
            limit={BOOKINGS_LOADING_LIMIT}
            onChange={setPage}
          />
        </div>
      )}
    </>
  );
};

import { createVehicleApi } from "../api/vehicles";
import { CreateVehicle } from "../types/vehicles";

export const useCreateVehicle = () => {
  const createVehicle = async (createVehicleData: CreateVehicle) => {
    try {
      const { data } = await createVehicleApi(createVehicleData);
      console.log("Vehicle created", data);
      return data;
    } catch (e) {
      console.log("ERROR creating vehicle", e);
      // @ts-ignore
      return e.response.data.message;
    }
  };

  return { createVehicle };
};

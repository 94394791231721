import { AxiosResponse } from "axios";
import { BodySortDriver } from "hooks/useDrivers";
import { apiAxios } from "../index";
import { CreateDriverData, UpdateDriverData } from "../../types/drivers";
import { CreateVehicle } from "../../types/vehicles";
import { ServerResponse } from "types";

export const vehicleAllApi = (page: number, body: BodySortDriver) => {
  return apiAxios.post(`vehicle/all?page=${page}&limit=10`, body);
};

export const getVehicleApi = (id: string) => {
  return apiAxios(`vehicle?vehicleId=${id}`);
};

export const createVehicleApi = (body: CreateVehicle) => {
  return apiAxios.post("vehicle/create", body);
};

export const updateVehicleDataApi = (id: string, body: CreateVehicle) => {
  return apiAxios.patch(`vehicle/update?vehicleId=${id}`, body);
};

export const updateVehicleStatusApi = (
  id: number,
  body: { isActive: boolean }
) => {
  return apiAxios.patch(`vehicle/update?vehicleId=${id}`, body);
};

export const disableVehiclesById = (
  vehiclesIdList: number[]
): Promise<AxiosResponse<ServerResponse>> => {
  return apiAxios.patch(`vehicle/disable`, { vehiclesIdList });
};

import cls from "classnames";
import { FC, MouseEvent, ReactNode } from "react";
import styles from "./styles.module.scss";
import { ICONS } from "assets";
import classNames from "classnames";

interface Props {
  active?: boolean;
  onPress?: () => void;
  disabled?: boolean;
}

const Checkbox: FC<Props> = ({ active, onPress, disabled }) => {
  const handleCheckboxClick = (
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    e.stopPropagation();
    if (onPress) {
      onPress();
    }
  };
  return (
    <div
      className={cls(styles.checkbox, {
        [styles.checkbox__active]: active,
        [styles.checkbox__disabled]: disabled,
      })}
      onClick={handleCheckboxClick}
    >
      {active && <ICONS.CheckMark className={styles.checkbox_checkMark} />}
    </div>
  );
};

export default Checkbox;

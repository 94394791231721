export const MaxFileSizeInBytes = 10 * 1024 * 1024; // 10 MB

export const AIRPORTS = [
  "Heathrow Terminal 2",
  "Heathrow Terminal 3",
  "Heathrow Terminal 4",
  "Heathrow Terminal 5",
  "Heathrow - Windsor Suite",
  "Heathrow - Royal Suite",
  "Gatwick South Terminal",
  "Gatwick North Terminal",
  "London City Airport",
  "London City – Jet Centre",
  "Farnborough Airport",
  "Biggin Hill Airport",
  "Luton Airport",
  "Luton Airport - Harrods Aviation",
  "Stansted Airport",
  "Stansted – Jet Centre",
  "Battersea Heliport",
  "Northolt Airport",
  "Fairoaks Airport",
  "Elstree Aerodrome",
];

export const POSTCODES = {
  TW: [
    "TW1",
    "TW10",
    "TW11",
    "TW12",
    "TW13",
    "TW14",
    "TW15",
    "TW16",
    "TW17",
    "TW18",
    "TW19",
    "TW2",
    "TW20",
    "TW3",
    "TW4",
    "TW5",
    "TW6",
    "TW7",
    "TW8",
    "TW9",
  ],
  KT: [
    "KT1",
    "KT10",
    "KT11",
    "KT12",
    "KT13",
    "KT14",
    "KT15",
    "KT16",
    "KT17",
    "KT18",
    "KT19",
    "KT2",
    "KT20",
    "KT21",
    "KT22",
    "KT23",
    "KT24",
    "KT3",
    "KT4",
    "KT5",
    "KT6",
    "KT7",
    "KT8",
    "KT9",
  ],
  SM: ["SM1", "SM2", "SM3", "SM4", "SM5", "SM6", "SM7"],
  CR: [
    "CR0",
    "CR2",
    "CR3",
    "CR4",
    "CR44",
    "CR5",
    "CR6",
    "CR7",
    "CR8",
    "CR9",
    "CR90",
  ],
  BR: ["BR1", "BR2", "BR3", "BR4", "BR5", "BR6", "BR7", "BR8", "BR98"],
  DA: [
    "DA1",
    "DA10",
    "DA11",
    "DA12",
    "DA13",
    "DA14",
    "DA15",
    "DA16",
    "DA17",
    "DA18",
    "DA2",
    "DA3",
    "DA4",
    "DA5",
    "DA6",
    "DA7",
    "DA8",
    "DA9",
  ],
  RM: [
    "RM1",
    "RM10",
    "RM11",
    "RM12",
    "RM13",
    "RM14",
    "RM15",
    "RM16",
    "RM17",
    "RM18",
    "RM19",
    "RM2",
    "RM20",
    "RM3",
    "RM4",
    "RM5",
    "RM50",
    "RM6",
    "RM7",
    "RM8",
    "RM9",
  ],
  IG: [
    "IG1",
    "IG10",
    "IG11",
    "IG2",
    "IG3",
    "IG4",
    "IG5",
    "IG6",
    "IG7",
    "IG8",
    "IG9",
  ],
  EN: [
    "EN1",
    "EN10",
    "EN11",
    "EN2",
    "EN3",
    "EN4",
    "EN5",
    "EN6",
    "EN7",
    "EN77",
    "EN8",
    "EN9",
  ],
  HA: ["HA0", "HA1", "HA2", "HA3", "HA4", "HA5", "HA6", "HA7", "HA8", "HA9"],
  UB: [
    "UB1",
    "UB10",
    "UB11",
    "UB18",
    "UB2",
    "UB3",
    "UB4",
    "UB5",
    "UB6",
    "UB7",
    "UB8",
    "UB9",
  ],
  EC: [
    "EC1A",
    "EC1M",
    "EC1N",
    "EC1P",
    "EC1R",
    "EC1V",
    "EC1Y",
    "EC2A",
    "EC2M",
    "EC2N",
    "EC2P",
    "EC2R",
    "EC2V",
    "EC2Y",
    "EC3A",
    "EC3B",
    "EC3M",
    "EC3N",
    "EC3P",
    "EC3R",
    "EC3V",
    "EC4A",
    "EC4M",
    "EC4N",
    "EC4P",
    "EC4R",
    "EC4V",
    "EC4Y",
    "EC50",
    "EC88",
  ],
  WC: [
    "WC1A",
    "WC1B",
    "WC1E",
    "WC1H",
    "WC1N",
    "WC1R",
    "WC1V",
    "WC1X",
    "WC2A",
    "WC2B",
    "WC2E",
    "WC2H",
    "WC2N",
    "WC2R",
    "WC99",
  ],
  E: [
    "E1",
    "E10",
    "E11",
    "E12",
    "E13",
    "E14",
    "E15",
    "E16",
    "E17",
    "E18",
    "E1W",
    "E2",
    "E20",
    "E3",
    "E4",
    "E5",
    "E6",
    "E7",
    "E77",
    "E8",
    "E9",
    "E98",
  ],
  N: [
    "N1",
    "N10",
    "N11",
    "N12",
    "N13",
    "N14",
    "N15",
    "N16",
    "N17",
    "N18",
    "N19",
    "N1C",
    "N1P",
    "N2",
    "N20",
    "N21",
    "N22",
    "N3",
    "N4",
    "N5",
    "N6",
    "N7",
    "N8",
    "N81",
    "N9",
  ],
  NW: [
    "NW1",
    "NW10",
    "NW11",
    "NW1W",
    "NW2",
    "NW26",
    "NW3",
    "NW4",
    "NW5",
    "NW6",
    "NW7",
    "NW8",
    "NW9",
  ],
  SE: [
    "SE1",
    "SE10",
    "SE11",
    "SE12",
    "SE13",
    "SE14",
    "SE15",
    "SE16",
    "SE17",
    "SE18",
    "SE19",
    "SE1P",
    "SE2",
    "SE20",
    "SE21",
    "SE22",
    "SE23",
    "SE24",
    "SE25",
    "SE26",
    "SE27",
    "SE28",
    "SE3",
    "SE4",
    "SE5",
    "SE6",
    "SE7",
    "SE8",
    "SE9",
    "SE99",
  ],
  SW: [
    "SW10",
    "SW11",
    "SW12",
    "SW13",
    "SW14",
    "SW15",
    "SW16",
    "SW17",
    "SW18",
    "SW19",
    "SW2",
    "SW20",
    "SW3",
    "SW4",
    "SW5",
    "SW6",
    "SW7",
    "SW8",
    "SW9",
    "SW95",
  ],
  "SW CENTRAL": [
    "SW1A",
    "SW1E",
    "SW1H",
    "SW1P",
    "SW1V",
    "SW1W",
    "SW1X",
    "SW1Y",
  ],
  W: [
    "W10",
    "W11",
    "W12",
    "W13",
    "W14",
    "W2",
    "W3",
    "W4",
    "W5",
    "W6",
    "W7",
    "W8",
    "W9",
  ],
  "W CENTRAL": [
    "W1A",
    "W1B",
    "W1C",
    "W1D",
    "W1E",
    "W1F",
    "W1G",
    "W1H",
    "W1J",
    "W1K",
    "W1M",
    "W1N",
    "W1P",
    "W1R",
    "W1S",
    "W1T",
    "W1U",
    "W1V",
    "W1W",
    "W1X",
    "W1Y",
  ],
  BERKSHIRE: ["SL1", "SL2", "SL3", "SL4", "SL5", "SL6", "SL9"],
  SURREY: ["GU25"],
};

// export const POSTCODES_FOR_MULTISELECT = [
//   {
//     label: "TW",
//     options: [
//       {
//         label: "TW1",
//         value: "TW1",
//       },
//       {
//         label: "TW10",
//         value: "TW10",
//       },
//       {
//         label: "TW11",
//         value: "TW11",
//       },
//       {
//         label: "TW12",
//         value: "TW12",
//       },
//       {
//         label: "TW13",
//         value: "TW13",
//       },
//       {
//         label: "TW14",
//         value: "TW14",
//       },
//       {
//         label: "TW15",
//         value: "TW15",
//       },
//       {
//         label: "TW16",
//         value: "TW16",
//       },
//       {
//         label: "TW17",
//         value: "TW17",
//       },
//       {
//         label: "TW18",
//         value: "TW18",
//       },
//       {
//         label: "TW19",
//         value: "TW19",
//       },
//       {
//         label: "TW2",
//         value: "TW2",
//       },
//       {
//         label: "TW20",
//         value: "TW20",
//       },
//       {
//         label: "TW3",
//         value: "TW3",
//       },
//       {
//         label: "TW4",
//         value: "TW4",
//       },
//       {
//         label: "TW5",
//         value: "TW5",
//       },
//       {
//         label: "TW6",
//         value: "TW6",
//       },
//       {
//         label: "TW7",
//         value: "TW7",
//       },
//       {
//         label: "TW8",
//         value: "TW8",
//       },
//       {
//         label: "TW9",
//         value: "TW9",
//       },
//     ],
//   },
//   {
//     label: "KT",
//     options: [
//       {
//         label: "KT1",
//         value: "KT1",
//       },
//       {
//         label: "KT10",
//         value: "KT10",
//       },
//       {
//         label: "KT11",
//         value: "KT11",
//       },
//       {
//         label: "KT12",
//         value: "KT12",
//       },
//       {
//         label: "KT13",
//         value: "KT13",
//       },
//       {
//         label: "KT14",
//         value: "KT14",
//       },
//       {
//         label: "KT15",
//         value: "KT15",
//       },
//       {
//         label: "KT16",
//         value: "KT16",
//       },
//       {
//         label: "KT17",
//         value: "KT17",
//       },
//       {
//         label: "KT18",
//         value: "KT18",
//       },
//       {
//         label: "KT19",
//         value: "KT19",
//       },
//       {
//         label: "KT2",
//         value: "KT2",
//       },
//       {
//         label: "KT20",
//         value: "KT20",
//       },
//       {
//         label: "KT21",
//         value: "KT21",
//       },
//       {
//         label: "KT22",
//         value: "KT22",
//       },
//       {
//         label: "KT23",
//         value: "KT23",
//       },
//       {
//         label: "KT24",
//         value: "KT24",
//       },
//       {
//         label: "KT3",
//         value: "KT3",
//       },
//       {
//         label: "KT4",
//         value: "KT4",
//       },
//       {
//         label: "KT5",
//         value: "KT5",
//       },
//       {
//         label: "KT6",
//         value: "KT6",
//       },
//       {
//         label: "KT7",
//         value: "KT7",
//       },
//       {
//         label: "KT8",
//         value: "KT8",
//       },
//       {
//         label: "KT9",
//         value: "KT9",
//       },
//     ],
//   },
//   {
//     label: "SM",
//     options: [
//       {
//         label: "SM1",
//         value: "SM1",
//       },
//       {
//         label: "SM2",
//         value: "SM2",
//       },
//       {
//         label: "SM3",
//         value: "SM3",
//       },
//       {
//         label: "SM4",
//         value: "SM4",
//       },
//       {
//         label: "SM5",
//         value: "SM5",
//       },
//       {
//         label: "SM6",
//         value: "SM6",
//       },
//       {
//         label: "SM7",
//         value: "SM7",
//       },
//     ],
//   },
//   {
//     label: "CR",
//     options: [
//       {
//         label: "CR0",
//         value: "CR0",
//       },
//       {
//         label: "CR2",
//         value: "CR2",
//       },
//       {
//         label: "CR3",
//         value: "CR3",
//       },
//       {
//         label: "CR4",
//         value: "CR4",
//       },
//       {
//         label: "CR44",
//         value: "CR44",
//       },
//       {
//         label: "CR5",
//         value: "CR5",
//       },
//       {
//         label: "CR6",
//         value: "CR6",
//       },
//       {
//         label: "CR7",
//         value: "CR7",
//       },
//       {
//         label: "CR8",
//         value: "CR8",
//       },
//       {
//         label: "CR9",
//         value: "CR9",
//       },
//       {
//         label: "CR90",
//         value: "CR90",
//       },
//     ],
//   },
//   {
//     label: "BR",
//     options: [
//       {
//         label: "BR1",
//         value: "BR1",
//       },
//       {
//         label: "BR2",
//         value: "BR2",
//       },
//       {
//         label: "BR3",
//         value: "BR3",
//       },
//       {
//         label: "BR4",
//         value: "BR4",
//       },
//       {
//         label: "BR5",
//         value: "BR5",
//       },
//       {
//         label: "BR6",
//         value: "BR6",
//       },
//       {
//         label: "BR7",
//         value: "BR7",
//       },
//       {
//         label: "BR8",
//         value: "BR8",
//       },
//       {
//         label: "BR98",
//         value: "BR98",
//       },
//     ],
//   },
//   {
//     label: "DA",
//     options: [
//       {
//         label: "DA1",
//         value: "DA1",
//       },
//       {
//         label: "DA10",
//         value: "DA10",
//       },
//       {
//         label: "DA11",
//         value: "DA11",
//       },
//       {
//         label: "DA12",
//         value: "DA12",
//       },
//       {
//         label: "DA13",
//         value: "DA13",
//       },
//       {
//         label: "DA14",
//         value: "DA14",
//       },
//       {
//         label: "DA15",
//         value: "DA15",
//       },
//       {
//         label: "DA16",
//         value: "DA16",
//       },
//       {
//         label: "DA17",
//         value: "DA17",
//       },
//       {
//         label: "DA18",
//         value: "DA18",
//       },
//       {
//         label: "DA2",
//         value: "DA2",
//       },
//       {
//         label: "DA3",
//         value: "DA3",
//       },
//       {
//         label: "DA4",
//         value: "DA4",
//       },
//       {
//         label: "DA5",
//         value: "DA5",
//       },
//       {
//         label: "DA6",
//         value: "DA6",
//       },
//       {
//         label: "DA7",
//         value: "DA7",
//       },
//       {
//         label: "DA8",
//         value: "DA8",
//       },
//       {
//         label: "DA9",
//         value: "DA9",
//       },
//     ],
//   },
//   {
//     label: "RM",
//     options: [
//       {
//         label: "RM1",
//         value: "RM1",
//       },
//       {
//         label: "RM10",
//         value: "RM10",
//       },
//       {
//         label: "RM11",
//         value: "RM11",
//       },
//       {
//         label: "RM12",
//         value: "RM12",
//       },
//       {
//         label: "RM13",
//         value: "RM13",
//       },
//       {
//         label: "RM14",
//         value: "RM14",
//       },
//       {
//         label: "RM15",
//         value: "RM15",
//       },
//       {
//         label: "RM16",
//         value: "RM16",
//       },
//       {
//         label: "RM17",
//         value: "RM17",
//       },
//       {
//         label: "RM18",
//         value: "RM18",
//       },
//       {
//         label: "RM19",
//         value: "RM19",
//       },
//       {
//         label: "RM2",
//         value: "RM2",
//       },
//       {
//         label: "RM20",
//         value: "RM20",
//       },
//       {
//         label: "RM3",
//         value: "RM3",
//       },
//       {
//         label: "RM4",
//         value: "RM4",
//       },
//       {
//         label: "RM5",
//         value: "RM5",
//       },
//       {
//         label: "RM50",
//         value: "RM50",
//       },
//       {
//         label: "RM6",
//         value: "RM6",
//       },
//       {
//         label: "RM7",
//         value: "RM7",
//       },
//       {
//         label: "RM8",
//         value: "RM8",
//       },
//       {
//         label: "RM9",
//         value: "RM9",
//       },
//     ],
//   },
//   {
//     label: "IG",
//     options: [
//       {
//         label: "IG1",
//         value: "IG1",
//       },
//       {
//         label: "IG10",
//         value: "IG10",
//       },
//       {
//         label: "IG11",
//         value: "IG11",
//       },
//       {
//         label: "IG2",
//         value: "IG2",
//       },
//       {
//         label: "IG3",
//         value: "IG3",
//       },
//       {
//         label: "IG4",
//         value: "IG4",
//       },
//       {
//         label: "IG5",
//         value: "IG5",
//       },
//       {
//         label: "IG6",
//         value: "IG6",
//       },
//       {
//         label: "IG7",
//         value: "IG7",
//       },
//       {
//         label: "IG8",
//         value: "IG8",
//       },
//       {
//         label: "IG9",
//         value: "IG9",
//       },
//     ],
//   },
//   {
//     label: "EN",
//     options: [
//       {
//         label: "EN1",
//         value: "EN1",
//       },
//       {
//         label: "EN10",
//         value: "EN10",
//       },
//       {
//         label: "EN11",
//         value: "EN11",
//       },
//       {
//         label: "EN2",
//         value: "EN2",
//       },
//       {
//         label: "EN3",
//         value: "EN3",
//       },
//       {
//         label: "EN4",
//         value: "EN4",
//       },
//       {
//         label: "EN5",
//         value: "EN5",
//       },
//       {
//         label: "EN6",
//         value: "EN6",
//       },
//       {
//         label: "EN7",
//         value: "EN7",
//       },
//       {
//         label: "EN77",
//         value: "EN77",
//       },
//       {
//         label: "EN8",
//         value: "EN8",
//       },
//       {
//         label: "EN9",
//         value: "EN9",
//       },
//     ],
//   },
//   {
//     label: "HA",
//     options: [
//       {
//         label: "HA0",
//         value: "HA0",
//       },
//       {
//         label: "HA1",
//         value: "HA1",
//       },
//       {
//         label: "HA2",
//         value: "HA2",
//       },
//       {
//         label: "HA3",
//         value: "HA3",
//       },
//       {
//         label: "HA4",
//         value: "HA4",
//       },
//       {
//         label: "HA5",
//         value: "HA5",
//       },
//       {
//         label: "HA6",
//         value: "HA6",
//       },
//       {
//         label: "HA7",
//         value: "HA7",
//       },
//       {
//         label: "HA8",
//         value: "HA8",
//       },
//       {
//         label: "HA9",
//         value: "HA9",
//       },
//     ],
//   },
//   {
//     label: "UB",
//     options: [
//       {
//         label: "UB1",
//         value: "UB1",
//       },
//       {
//         label: "UB10",
//         value: "UB10",
//       },
//       {
//         label: "UB11",
//         value: "UB11",
//       },
//       {
//         label: "UB18",
//         value: "UB18",
//       },
//       {
//         label: "UB2",
//         value: "UB2",
//       },
//       {
//         label: "UB3",
//         value: "UB3",
//       },
//       {
//         label: "UB4",
//         value: "UB4",
//       },
//       {
//         label: "UB5",
//         value: "UB5",
//       },
//       {
//         label: "UB6",
//         value: "UB6",
//       },
//       {
//         label: "UB7",
//         value: "UB7",
//       },
//       {
//         label: "UB8",
//         value: "UB8",
//       },
//       {
//         label: "UB9",
//         value: "UB9",
//       },
//     ],
//   },
//   {
//     label: "EC",
//     options: [
//       {
//         label: "EC1A",
//         value: "EC1A",
//       },
//       {
//         label: "EC1M",
//         value: "EC1M",
//       },
//       {
//         label: "EC1N",
//         value: "EC1N",
//       },
//       {
//         label: "EC1P",
//         value: "EC1P",
//       },
//       {
//         label: "EC1R",
//         value: "EC1R",
//       },
//       {
//         label: "EC1V",
//         value: "EC1V",
//       },
//       {
//         label: "EC1Y",
//         value: "EC1Y",
//       },
//       {
//         label: "EC2A",
//         value: "EC2A",
//       },
//       {
//         label: "EC2M",
//         value: "EC2M",
//       },
//       {
//         label: "EC2N",
//         value: "EC2N",
//       },
//       {
//         label: "EC2P",
//         value: "EC2P",
//       },
//       {
//         label: "EC2R",
//         value: "EC2R",
//       },
//       {
//         label: "EC2V",
//         value: "EC2V",
//       },
//       {
//         label: "EC2Y",
//         value: "EC2Y",
//       },
//       {
//         label: "EC3A",
//         value: "EC3A",
//       },
//       {
//         label: "EC3B",
//         value: "EC3B",
//       },
//       {
//         label: "EC3M",
//         value: "EC3M",
//       },
//       {
//         label: "EC3N",
//         value: "EC3N",
//       },
//       {
//         label: "EC3P",
//         value: "EC3P",
//       },
//       {
//         label: "EC3R",
//         value: "EC3R",
//       },
//       {
//         label: "EC3V",
//         value: "EC3V",
//       },
//       {
//         label: "EC4A",
//         value: "EC4A",
//       },
//       {
//         label: "EC4M",
//         value: "EC4M",
//       },
//       {
//         label: "EC4N",
//         value: "EC4N",
//       },
//       {
//         label: "EC4P",
//         value: "EC4P",
//       },
//       {
//         label: "EC4R",
//         value: "EC4R",
//       },
//       {
//         label: "EC4V",
//         value: "EC4V",
//       },
//       {
//         label: "EC4Y",
//         value: "EC4Y",
//       },
//       {
//         label: "EC50",
//         value: "EC50",
//       },
//       {
//         label: "EC88",
//         value: "EC88",
//       },
//     ],
//   },
//   {
//     label: "WC",
//     options: [
//       {
//         label: "WC1A",
//         value: "WC1A",
//       },
//       {
//         label: "WC1B",
//         value: "WC1B",
//       },
//       {
//         label: "WC1E",
//         value: "WC1E",
//       },
//       {
//         label: "WC1H",
//         value: "WC1H",
//       },
//       {
//         label: "WC1N",
//         value: "WC1N",
//       },
//       {
//         label: "WC1R",
//         value: "WC1R",
//       },
//       {
//         label: "WC1V",
//         value: "WC1V",
//       },
//       {
//         label: "WC1X",
//         value: "WC1X",
//       },
//       {
//         label: "WC2A",
//         value: "WC2A",
//       },
//       {
//         label: "WC2B",
//         value: "WC2B",
//       },
//       {
//         label: "WC2E",
//         value: "WC2E",
//       },
//       {
//         label: "WC2H",
//         value: "WC2H",
//       },
//       {
//         label: "WC2N",
//         value: "WC2N",
//       },
//       {
//         label: "WC2R",
//         value: "WC2R",
//       },
//       {
//         label: "WC99",
//         value: "WC99",
//       },
//     ],
//   },
//   {
//     label: "E",
//     options: [
//       {
//         label: "E1",
//         value: "E1",
//       },
//       {
//         label: "E10",
//         value: "E10",
//       },
//       {
//         label: "E11",
//         value: "E11",
//       },
//       {
//         label: "E12",
//         value: "E12",
//       },
//       {
//         label: "E13",
//         value: "E13",
//       },
//       {
//         label: "E14",
//         value: "E14",
//       },
//       {
//         label: "E15",
//         value: "E15",
//       },
//       {
//         label: "E16",
//         value: "E16",
//       },
//       {
//         label: "E17",
//         value: "E17",
//       },
//       {
//         label: "E18",
//         value: "E18",
//       },
//       {
//         label: "E1W",
//         value: "E1W",
//       },
//       {
//         label: "E2",
//         value: "E2",
//       },
//       {
//         label: "E20",
//         value: "E20",
//       },
//       {
//         label: "E3",
//         value: "E3",
//       },
//       {
//         label: "E4",
//         value: "E4",
//       },
//       {
//         label: "E5",
//         value: "E5",
//       },
//       {
//         label: "E6",
//         value: "E6",
//       },
//       {
//         label: "E7",
//         value: "E7",
//       },
//       {
//         label: "E77",
//         value: "E77",
//       },
//       {
//         label: "E8",
//         value: "E8",
//       },
//       {
//         label: "E9",
//         value: "E9",
//       },
//       {
//         label: "E98",
//         value: "E98",
//       },
//     ],
//   },
//   {
//     label: "N",
//     options: [
//       {
//         label: "N1",
//         value: "N1",
//       },
//       {
//         label: "N10",
//         value: "N10",
//       },
//       {
//         label: "N11",
//         value: "N11",
//       },
//       {
//         label: "N12",
//         value: "N12",
//       },
//       {
//         label: "N13",
//         value: "N13",
//       },
//       {
//         label: "N14",
//         value: "N14",
//       },
//       {
//         label: "N15",
//         value: "N15",
//       },
//       {
//         label: "N16",
//         value: "N16",
//       },
//       {
//         label: "N17",
//         value: "N17",
//       },
//       {
//         label: "N18",
//         value: "N18",
//       },
//       {
//         label: "N19",
//         value: "N19",
//       },
//       {
//         label: "N1C",
//         value: "N1C",
//       },
//       {
//         label: "N1P",
//         value: "N1P",
//       },
//       {
//         label: "N2",
//         value: "N2",
//       },
//       {
//         label: "N20",
//         value: "N20",
//       },
//       {
//         label: "N21",
//         value: "N21",
//       },
//       {
//         label: "N22",
//         value: "N22",
//       },
//       {
//         label: "N3",
//         value: "N3",
//       },
//       {
//         label: "N4",
//         value: "N4",
//       },
//       {
//         label: "N5",
//         value: "N5",
//       },
//       {
//         label: "N6",
//         value: "N6",
//       },
//       {
//         label: "N7",
//         value: "N7",
//       },
//       {
//         label: "N8",
//         value: "N8",
//       },
//       {
//         label: "N81",
//         value: "N81",
//       },
//       {
//         label: "N9",
//         value: "N9",
//       },
//     ],
//   },
//   {
//     label: "NW",
//     options: [
//       {
//         label: "NW1",
//         value: "NW1",
//       },
//       {
//         label: "NW10",
//         value: "NW10",
//       },
//       {
//         label: "NW11",
//         value: "NW11",
//       },
//       {
//         label: "NW1W",
//         value: "NW1W",
//       },
//       {
//         label: "NW2",
//         value: "NW2",
//       },
//       {
//         label: "NW26",
//         value: "NW26",
//       },
//       {
//         label: "NW3",
//         value: "NW3",
//       },
//       {
//         label: "NW4",
//         value: "NW4",
//       },
//       {
//         label: "NW5",
//         value: "NW5",
//       },
//       {
//         label: "NW6",
//         value: "NW6",
//       },
//       {
//         label: "NW7",
//         value: "NW7",
//       },
//       {
//         label: "NW8",
//         value: "NW8",
//       },
//       {
//         label: "NW9",
//         value: "NW9",
//       },
//     ],
//   },
//   {
//     label: "SE",
//     options: [
//       {
//         label: "SE1",
//         value: "SE1",
//       },
//       {
//         label: "SE10",
//         value: "SE10",
//       },
//       {
//         label: "SE11",
//         value: "SE11",
//       },
//       {
//         label: "SE12",
//         value: "SE12",
//       },
//       {
//         label: "SE13",
//         value: "SE13",
//       },
//       {
//         label: "SE14",
//         value: "SE14",
//       },
//       {
//         label: "SE15",
//         value: "SE15",
//       },
//       {
//         label: "SE16",
//         value: "SE16",
//       },
//       {
//         label: "SE17",
//         value: "SE17",
//       },
//       {
//         label: "SE18",
//         value: "SE18",
//       },
//       {
//         label: "SE19",
//         value: "SE19",
//       },
//       {
//         label: "SE1P",
//         value: "SE1P",
//       },
//       {
//         label: "SE2",
//         value: "SE2",
//       },
//       {
//         label: "SE20",
//         value: "SE20",
//       },
//       {
//         label: "SE21",
//         value: "SE21",
//       },
//       {
//         label: "SE22",
//         value: "SE22",
//       },
//       {
//         label: "SE23",
//         value: "SE23",
//       },
//       {
//         label: "SE24",
//         value: "SE24",
//       },
//       {
//         label: "SE25",
//         value: "SE25",
//       },
//       {
//         label: "SE26",
//         value: "SE26",
//       },
//       {
//         label: "SE27",
//         value: "SE27",
//       },
//       {
//         label: "SE28",
//         value: "SE28",
//       },
//       {
//         label: "SE3",
//         value: "SE3",
//       },
//       {
//         label: "SE4",
//         value: "SE4",
//       },
//       {
//         label: "SE5",
//         value: "SE5",
//       },
//       {
//         label: "SE6",
//         value: "SE6",
//       },
//       {
//         label: "SE7",
//         value: "SE7",
//       },
//       {
//         label: "SE8",
//         value: "SE8",
//       },
//       {
//         label: "SE9",
//         value: "SE9",
//       },
//       {
//         label: "SE99",
//         value: "SE99",
//       },
//     ],
//   },
//   {
//     label: "SW",
//     options: [
//       {
//         label: "SW10",
//         value: "SW10",
//       },
//       {
//         label: "SW11",
//         value: "SW11",
//       },
//       {
//         label: "SW12",
//         value: "SW12",
//       },
//       {
//         label: "SW13",
//         value: "SW13",
//       },
//       {
//         label: "SW14",
//         value: "SW14",
//       },
//       {
//         label: "SW15",
//         value: "SW15",
//       },
//       {
//         label: "SW16",
//         value: "SW16",
//       },
//       {
//         label: "SW17",
//         value: "SW17",
//       },
//       {
//         label: "SW18",
//         value: "SW18",
//       },
//       {
//         label: "SW19",
//         value: "SW19",
//       },
//       {
//         label: "SW2",
//         value: "SW2",
//       },
//       {
//         label: "SW20",
//         value: "SW20",
//       },
//       {
//         label: "SW3",
//         value: "SW3",
//       },
//       {
//         label: "SW4",
//         value: "SW4",
//       },
//       {
//         label: "SW5",
//         value: "SW5",
//       },
//       {
//         label: "SW6",
//         value: "SW6",
//       },
//       {
//         label: "SW7",
//         value: "SW7",
//       },
//       {
//         label: "SW8",
//         value: "SW8",
//       },
//       {
//         label: "SW9",
//         value: "SW9",
//       },
//       {
//         label: "SW95",
//         value: "SW95",
//       },
//     ],
//   },
//   {
//     label: "SW CENTRAL",
//     options: [
//       {
//         label: "SW1A",
//         value: "SW1A",
//       },
//       {
//         label: "SW1E",
//         value: "SW1E",
//       },
//       {
//         label: "SW1H",
//         value: "SW1H",
//       },
//       {
//         label: "SW1P",
//         value: "SW1P",
//       },
//       {
//         label: "SW1V",
//         value: "SW1V",
//       },
//       {
//         label: "SW1W",
//         value: "SW1W",
//       },
//       {
//         label: "SW1X",
//         value: "SW1X",
//       },
//       {
//         label: "SW1Y",
//         value: "SW1Y",
//       },
//     ],
//   },
//   {
//     label: "W",
//     options: [
//       {
//         label: "W10",
//         value: "W10",
//       },
//       {
//         label: "W11",
//         value: "W11",
//       },
//       {
//         label: "W12",
//         value: "W12",
//       },
//       {
//         label: "W13",
//         value: "W13",
//       },
//       {
//         label: "W14",
//         value: "W14",
//       },
//       {
//         label: "W2",
//         value: "W2",
//       },
//       {
//         label: "W3",
//         value: "W3",
//       },
//       {
//         label: "W4",
//         value: "W4",
//       },
//       {
//         label: "W5",
//         value: "W5",
//       },
//       {
//         label: "W6",
//         value: "W6",
//       },
//       {
//         label: "W7",
//         value: "W7",
//       },
//       {
//         label: "W8",
//         value: "W8",
//       },
//       {
//         label: "W9",
//         value: "W9",
//       },
//     ],
//   },
//   {
//     label: "W CENTRAL",
//     options: [
//       {
//         label: "W1A",
//         value: "W1A",
//       },
//       {
//         label: "W1B",
//         value: "W1B",
//       },
//       {
//         label: "W1C",
//         value: "W1C",
//       },
//       {
//         label: "W1D",
//         value: "W1D",
//       },
//       {
//         label: "W1E",
//         value: "W1E",
//       },
//       {
//         label: "W1F",
//         value: "W1F",
//       },
//       {
//         label: "W1G",
//         value: "W1G",
//       },
//       {
//         label: "W1H",
//         value: "W1H",
//       },
//       {
//         label: "W1J",
//         value: "W1J",
//       },
//       {
//         label: "W1K",
//         value: "W1K",
//       },
//       {
//         label: "W1M",
//         value: "W1M",
//       },
//       {
//         label: "W1N",
//         value: "W1N",
//       },
//       {
//         label: "W1P",
//         value: "W1P",
//       },
//       {
//         label: "W1R",
//         value: "W1R",
//       },
//       {
//         label: "W1S",
//         value: "W1S",
//       },
//       {
//         label: "W1T",
//         value: "W1T",
//       },
//       {
//         label: "W1U",
//         value: "W1U",
//       },
//       {
//         label: "W1V",
//         value: "W1V",
//       },
//       {
//         label: "W1W",
//         value: "W1W",
//       },
//       {
//         label: "W1X",
//         value: "W1X",
//       },
//       {
//         label: "W1Y",
//         value: "W1Y",
//       },
//     ],
//   },
//   {
//     label: "BERKSHIRE",
//     options: [
//       {
//         label: "SL1",
//         value: "SL1",
//       },
//       {
//         label: "SL2",
//         value: "SL2",
//       },
//       {
//         label: "SL3",
//         value: "SL3",
//       },
//       {
//         label: "SL4",
//         value: "SL4",
//       },
//       {
//         label: "SL5",
//         value: "SL5",
//       },
//       {
//         label: "SL6",
//         value: "SL6",
//       },
//       {
//         label: "SL9",
//         value: "SL9",
//       },
//     ],
//   },
//   {
//     label: "SURREY",
//     options: [
//       {
//         label: "GU25",
//         value: "GU25",
//       },
//     ],
//   },
// ];

export const ROUTES = {
  prices: [
    {
      route: "prices/active",
      label: "Active Prices",
    },
    {
      route: "prices/fixed",
      label: "Fixed Prices",
    },
    {
      route: "prices/holiday_surcharge",
      label: "Holiday Surcharge",
    },
    {
      route: "prices/as_directed_airport_pick-up",
      label: "By The Hour Pick-Up",
    },
    {
      route: "prices/one_way_airport_pick-up",
      label: "One Way Pick-Up",
    },
    {
      route: "prices/one_way_airport_drop-off",
      label: "One Way Drop-Off",
    },
  ],

  statistics: [
    {
      route: "statistics/week",
      label: "Week",
    },
    {
      route: "statistics/month",
      label: "Month",
    },
    {
      route: "statistics/year",
      label: "Year",
    },
  ],
  bookings: [
    {
      route: "bookings/all",
      label: "All",
    },
    {
      route: "bookings/next_24_hours",
      label: "Next 24 hours",
    },
    {
      route: "bookings/completed",
      label: "Completed",
    },
    {
      route: "bookings/cancelled",
      label: "Cancelled",
    },
    {
      route: "bookings/no_show",
      label: "No show",
    },
    {
      route: "bookings/due_in_1_hour",
      label: "Due in 1 hour",
    },
  ],
  users: [
    {
      route: "users/customers",
      label: "Customers",
    },
    {
      route: "users/passengers",
      label: "Passengers",
    },
  ],
  userDetails: [
    { route: "upcoming", label: "Upcoming" },
    { route: "completed", label: "Completed" },
    { route: "cancelled", label: "Cancelled" },
    { route: "no_show", label: "No show" },
  ],
};

export const BOOKINGS_LOADING_LIMIT = 10;
export const USERS_LOADING_LIMIT = 10;
export const MESSAGES_LOADING_LIMIT = 20;
export const CHATS_LOADING_LIMIT = 20;

export const CHAT_COMPLETED_MESSAGE_TEXT =
  "The chat was closed. If you have any questions, please start the chat again.";

export const LONDON_COORDS = {
  lat: 51.509865,
  lng: -0.118092,
};

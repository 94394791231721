import { Dispatch, FC, SetStateAction } from "react";
import styles from "./SuportChatWidget.module.scss";
import ChatIcons from "assets/icons/chat";

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const ChatBubble: FC<Props> = ({ isOpen, setIsOpen }) => {
  return (
    <div
      onClick={() => setIsOpen((prev) => !prev)}
      className={styles.chatWidget_bubble}
    >
      {isOpen ? (
        <ChatIcons.OpenedChat />
      ) : (
        <ChatIcons.ClosedChat color="#EDD5B5" />
      )}
    </div>
  );
};

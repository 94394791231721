import React, { FC, useCallback } from "react";
import styles from "./Locator.module.scss";
import Avatar from "components/Avatar";
import { VehicleCategoryLabel } from "components/VehicleCategoryLabel/VehicleCategoryLabel";
import { LocatorDriverItem } from "types/locator";
import cn from "classnames";

interface Props {
  driverItem: LocatorDriverItem;
  columnsFlexes: number[];
  onClick: (driver: LocatorDriverItem) => void;
}

export const DriverRowItem: FC<Props> = ({
  columnsFlexes,
  driverItem,
  onClick,
}) => {
  const vehicle = driverItem.driver.vehicle;

  return (
    <div className={styles.driverRowItem} onClick={() => onClick(driverItem)}>
      <div
        className={styles.driverRowItem_idCell}
        style={{ flex: columnsFlexes[0] }}
      >
        {driverItem.driverId}
      </div>
      <div
        className={styles.driverRowItem_driverCell}
        style={{ flex: columnsFlexes[1] }}
      >
        <Avatar img={driverItem.driver.avatar} />
        {`${driverItem.driver.firstName} ${driverItem.driver.lastName}`}
      </div>

      <div
        className={styles.driverRowItem_categoryCell}
        style={{ flex: columnsFlexes[2] }}
      >
        {vehicle && <VehicleCategoryLabel category={vehicle.category} />}
      </div>

      <div
        className={styles.driverRowItem_commonCell}
        style={{ flex: columnsFlexes[3] }}
      >
        {vehicle && (
          <>
            {`${vehicle.make} ${vehicle.model}, ${vehicle.colour}`}
            <br />
            {`${vehicle.registrationMark}`}
          </>
        )}
      </div>

      <div
        className={styles.driverRowItem_commonCell}
        style={{ flex: columnsFlexes[4] }}
      >
        {driverItem.orderId || ""}
      </div>
      <div
        className={styles.driverRowItem_statusCell}
        style={{ flex: columnsFlexes[5] }}
      >
        <div
          className={cn(styles.driverRowItem_statusLabel, {
            [styles.driverRowItem_statusLabel__active]:
              driverItem.point.status !== "Free",
          })}
        >
          {driverItem.point.status}
        </div>
      </div>
    </div>
  );
};

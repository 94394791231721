import { fetchUserInfo } from "api/login";
import { ReactNode, createContext, useEffect, useState } from "react";
import { IUser } from "types/user";

interface ContextProps {
  user: IUser | null;
  setUser: (user: IUser | null) => void;
}

export const UserContext = createContext<ContextProps>({
  user: null,
  setUser: () => {},
});

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<IUser | null>(null);

  const getUserInfoByToken = async () => {
    try {
      let token = localStorage.getItem("@accessToken");

      if (token) {
        const { data } = await fetchUserInfo();

        if (data.success) {
          setUser(data.admin);
        }
      }
    } catch (error) {
      console.log("Checking user error", error);
    }
  };

  useEffect(() => {
    console.log("User in UserContext:", user);
  }, [user]);

  useEffect(() => {
    getUserInfoByToken();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

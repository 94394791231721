import { FC, useCallback, useEffect, useRef, useState } from "react";
import { GoogleMap } from "@react-google-maps/api";
import mapCustomStyles from "assets/Map/preevo_map.json";
import { LONDON_COORDS } from "utils/constants";
import {
  setDefaults,
  geocode,
  RequestType,
  OutputFormat,
  setRegion,
} from "react-geocode";
import { PostcodePoint } from "types/areas";
import { PostcodeMarker } from "./PostcodeMarker";

interface Props {
  postcodes: string[];
}

const containerStyle = {
  width: "100%",
  height: "100%",
};

setDefaults({
  key: "AIzaSyBVRgEKGbNyLvbT3CdStkQz6z-e35mEq7s",
  region: "uk",
  components: "country:uk",
  outputFormat: OutputFormat.JSON,
});

export const AreaMap: FC<Props> = ({ postcodes }) => {
  const mapRef = useRef<google.maps.Map | null>(null);
  const [points, setPoints] = useState<PostcodePoint[]>([]);

  const onLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map;
  }, []);

  const onUnmount = useCallback(() => {
    mapRef.current = null;
  }, []);

  const getPointByPostCode = async (poscode: string) => {
    try {
      const { results } = await geocode(RequestType.ADDRESS, poscode);
      const point: PostcodePoint = {
        label: results[0].address_components[0].short_name,
        ...results[0].geometry.location,
      };
      return point;
    } catch (error) {
      console.log("getPointByPostCode ERROR => ", error);
    }
  };

  useEffect(() => {
    if (postcodes.length) {
      if (postcodes.length < points.length) {
        setPoints((prev) =>
          prev.filter((point) => postcodes.includes(point.label))
        );
      } else {
        const notDisplayedPostcodes = postcodes.filter(
          (postcode) => !points.some((point) => point.label === postcode)
        );

        Promise.all(
          notDisplayedPostcodes.map((postcode) => getPointByPostCode(postcode))
        ).then((points) => {
          setPoints((prev) => [
            ...prev,
            ...(points.filter(Boolean) as PostcodePoint[]),
          ]);
        });
      }
    } else {
      setPoints([]);
    }
  }, [postcodes]);

  useEffect(() => {
    if (points.length) {
      const bounds = new window.google.maps.LatLngBounds();

      points.forEach((point) => {
        bounds.extend({ lat: point.lat, lng: point.lng });
      });

      if (points.length === 1) {
        mapRef.current?.moveCamera({ center: points[0] });
      } else {
        mapRef.current?.fitBounds(bounds);
      }
    }
  }, [points]);

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={LONDON_COORDS}
      zoom={10}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{
        styles: mapCustomStyles,
        disableDefaultUI: true,
        zoomControl: true,
        scrollwheel: false,
      }}
    >
      {points.map((point) => (
        <PostcodeMarker key={point.label} point={point} />
      ))}
    </GoogleMap>
  );
};

import { FC } from "react";
import cn from "classnames";
import { ICONS } from "assets";
import { getFormattedDate } from "utils/helpers/getFormatedDate";
import { BookingClientData } from "./BookingClientData";
import { Booking } from "types/bookings";
import { BookingRoute } from "./BookingRoute";
import styles from "./BookingDetails.module.scss";
import { BookingStatusLabel } from "components/BookingStatusLabel";

interface Props {
  booking: Booking;
  onStatusChange: (updatedBooking: Booking) => void;
}

export const BookingMainInfo: FC<Props> = ({ booking, onStatusChange }) => {
  const {
    rideDate,
    rideStatus,
    rideType,
    vehicleCategory,
    numberOfPassenger,
    largeLuggage,
    childSeat,
    boosterSeat,
    flightNumber,
  } = booking;

  return (
    <div className={styles.mainInfo}>
      <div className={styles.mainInfo_topRow}>
        <div className={styles.mainInfo_topRigthSubBlock}>
          <ICONS.Calendar />
          <p>{getFormattedDate(rideDate)}</p>
        </div>
        <BookingStatusLabel
          bookingId={booking.id}
          status={rideStatus}
          onStatusUpdate={onStatusChange}
        />
      </div>
      <div className={styles.mainInfo_topRow}>
        <div className={styles.mainInfo_topRigthSubBlock}>
          <ICONS.LongRightArrowIcon />
          <p>{rideType === "As Directed" ? "By The Hour" : "One-Way"}</p>
        </div>
        <div className={styles.mainInfo_topLeftSubBlock}>
          <p className={styles.mainInfo_vehicleCategory}>{vehicleCategory}</p>
          {(childSeat !== 0 || boosterSeat !== 0) && (
            <ul className={styles.mainInfo_optionsList}>
              {childSeat !== 0 && (
                <li className={styles.mainInfo_optionsItem}>CS</li>
              )}
              {boosterSeat !== 0 && (
                <li className={styles.mainInfo_optionsItem}>BS</li>
              )}
            </ul>
          )}
        </div>
      </div>
      <div
        className={cn(styles.mainInfo_topRow, styles.mainInfo_topRow__client)}
      >
        <BookingClientData booking={booking} />
      </div>
      <BookingRoute points={booking.points} />

      {flightNumber && (
        <div className={styles.mainInfo_flightNumberRow}>
          <ICONS.FlightNumberIcon />
          <div>
            <h3 className={styles.mainInfo_flightNumberRow_title}>
              Flight Number
            </h3>
            <p className={styles.mainInfo_flightNumberRow_value}>
              {booking.flightNumber}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

import Header from "components/Header";
import styles from "./Locator.module.scss";
import { PageContentContainer } from "components/PageContentContainer";
import { LocatorMap } from "./LocatorMap";
import { TableHeader } from "components";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { DriverRowItem } from "./DriverRowItem";
import { SocketContext } from "context/socket";
import { DriverGeolocationEventData } from "types/socketDataTypes";
import { LocatorDriverItem } from "types/locator";
import { fetchLocatorDriversList, getDriverApi } from "api/drivers";
import { EmptyTablePlaceholder } from "components/EmptyTablePlaceholder";
import Skeleton from "react-loading-skeleton";
import { toastServerError } from "utils/helpers";

const columnsFlexes = [3, 29.45, 15, 24, 16, 10.6];

const sortingMap: { [key: string]: string } = {
  "0": "id",
  "2": "category",
  "5": "status",
};

export const Locator = () => {
  const mapRef = useRef<google.maps.Map | null>(null);
  const { socket, isUserConnectedToSocket } = useContext(SocketContext);
  const [isDriversListLoaded, setIsDriverListLoaded] = useState(false);
  const [isDriversListUpdating, setIsDriversListUpdating] = useState(false);
  const [locatorDriversList, setLocatorDriversList] = useState<
    LocatorDriverItem[]
  >([]);
  const [sortedIndex, setSortedIndex] = useState<number>(0);
  const [sortedType, setSortedType] = useState<"desc" | "asc">("asc");
  let loadingDriversIds: number[] = [];

  const moveToMarker = useCallback(
    (driverItem: LocatorDriverItem) => {
      mapRef.current?.panTo({
        lat: driverItem.point.latitude,
        lng: driverItem.point.longitude,
      });

      mapRef.current?.setZoom(18);
    },
    [mapRef]
  );

  const loadNewDriver = async (locationData: DriverGeolocationEventData) => {
    try {
      if (!loadingDriversIds.includes(locationData.driverId)) {
        loadingDriversIds = [...loadingDriversIds, locationData.driverId];

        const { data } = await getDriverApi(locationData.driverId);

        setLocatorDriversList((prev) => [
          ...prev,
          { ...locationData, driver: data.driver },
        ]);
      }
    } catch (error) {
      console.log("loadNewDriver ERROR => ", error);
    } finally {
      loadingDriversIds = loadingDriversIds.filter(
        (id) => id !== locationData.driverId
      );
    }
  };

  const loadOnlineDriversList = async () => {
    try {
      setIsDriversListUpdating(true);

      const { data } = await fetchLocatorDriversList({
        sorting: {
          order: sortedType.toUpperCase(),
          sortBy: sortingMap[sortedIndex],
        },
      });

      setLocatorDriversList(
        data.drivers.map(({ geoLocation, ...rest }) => ({
          driver: rest,
          driverId: rest.id,
          point: {
            latitude: geoLocation?.latitude as number,
            longitude: geoLocation?.longitude as number,
            heading: geoLocation?.heading as number,
            status: geoLocation?.status as any,
          },
          orderId: geoLocation?.orderId,
        }))
      );
    } catch (error) {
      toastServerError(
        error,
        "Something went wrong with drivers list updating"
      );
    } finally {
      setIsDriversListUpdating(false);
      setIsDriverListLoaded(true);
    }
  };

  useEffect(() => {
    if (isUserConnectedToSocket && isDriversListLoaded) {
      socket?.on(
        "driverGeolocation",
        async (data: DriverGeolocationEventData) => {
          setLocatorDriversList((prevDriversList) => {
            if (
              prevDriversList.some(
                (locatorDriver) => locatorDriver.driverId === data.driverId
              )
            ) {
              return prevDriversList.map((prevLocatorDriver) =>
                prevLocatorDriver.driverId === data.driverId
                  ? { ...prevLocatorDriver, ...data }
                  : prevLocatorDriver
              );
            } else {
              loadNewDriver(data);
              return prevDriversList;
            }
          });
        }
      );

      socket?.on(
        "isDriverOnline",
        (data: { driverId: number; isOnline: boolean }) => {
          if (!data.isOnline) {
            setLocatorDriversList((prev) =>
              prev.filter(
                (driverInList) => driverInList.driverId !== data.driverId
              )
            );
          }
        }
      );
    }

    return () => {
      socket?.off("driverGeolocation");
      socket?.off("isDriverOnline");
    };
  }, [isUserConnectedToSocket, isDriversListLoaded]);

  useEffect(() => {
    loadOnlineDriversList();
  }, [sortedIndex, sortedType]);

  return (
    <div className={styles.locatorPage}>
      <Header title="Locator" />

      <PageContentContainer>
        <div className={styles.locatorPage_content}>
          <div className={styles.locatorPage_map}>
            <LocatorMap
              mapRef={mapRef}
              driversList={locatorDriversList}
              onMarkerClick={moveToMarker}
            />
          </div>
          <div className={styles.locatorPage_tablet}>
            <TableHeader
              items={[
                "#",
                "Driver",
                "Category",
                "Vehicle",
                "Booking ID",
                "Status",
              ]}
              gap={24}
              flexes={columnsFlexes}
              sortIndexes={[0, 2, 5]}
              alignRightIndexes={[5]}
              selectedIndex={sortedIndex}
              sortedType={sortedType}
              onChangeSorted={(val) => {
                setSortedIndex((prev) => (val === null ? prev : val));
              }}
              onChangeType={(order) => {
                setSortedType(order === null ? "asc" : order);
              }}
            />

            {locatorDriversList.length !== 0 && !isDriversListUpdating ? (
              locatorDriversList.map((driver) => (
                <DriverRowItem
                  key={driver.driverId}
                  driverItem={driver}
                  columnsFlexes={columnsFlexes}
                  onClick={moveToMarker}
                />
              ))
            ) : isDriversListUpdating ? (
              <Skeleton
                height={66}
                count={5}
                style={{ marginBottom: 2, marginTop: 2, borderRadius: 10 }}
              />
            ) : (
              <EmptyTablePlaceholder text="There are no drivers online" />
            )}
          </div>
        </div>
      </PageContentContainer>
    </div>
  );
};

import { apiAxios } from "api";
import { AxiosResponse } from "axios";
import { ServerResponse } from "http";
import { Area } from "types/areas";
export interface fetchOperatingAreasListRequestBody {
  sorting?: {
    sortBy?: string;
    order?: string;
  };
  search?: string;
}

interface fetchOperatingAreasListResponse extends ServerResponse {
  areas: Area[];
}

interface createOperatingAreaResponse extends ServerResponse {
  area: Area;
}

interface updateOperatingAreaResponse extends ServerResponse {
  area: Area;
}

export const fetchOperatingAreasList = (
  body: fetchOperatingAreasListRequestBody
): Promise<AxiosResponse<fetchOperatingAreasListResponse>> => {
  console.log("fetchOperatingAreasList BODY => ", body);
  return apiAxios.post(`/area/list-for-admin?page=1&limit=99999`, body);
};

export const createOperatingArea = (
  body: Omit<Area, "id">
): Promise<AxiosResponse<createOperatingAreaResponse>> => {
  return apiAxios.post("/area", body);
};

export const removeOperatingArea = (
  id: number
): Promise<AxiosResponse<ServerResponse>> => {
  return apiAxios.delete(`/area?id=${id}`);
};

export const updateOperatingArea = (
  id: number,
  body: Omit<Area, "id">
): Promise<AxiosResponse<updateOperatingAreaResponse>> => {
  return apiAxios.patch(`/area?id=${id}`, body);
};

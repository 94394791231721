import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { Login } from "./pages";

import PrivateRoute from "components/PrivateRoute";
import Drivers from "pages/Dashboard/Drivers";
import { DriverItem } from "pages/Dashboard/Drivers/DriverItem/DriverItem";
import "./App.css";
import Dashboard from "./pages/Dashboard";
import Vehicles from "./pages/Dashboard/Vehicles";
import { VehicleItem } from "./pages/Dashboard/Vehicles/VehicleItem/VehicleItem";
import { LoadingProvider } from "./context/loading";
import { NotFoundPage } from "pages/NotFound";
import { Prices } from "pages/Dashboard/Prices";
import { Toaster } from "react-hot-toast";
import { ExpiryAlertsProvider } from "context/expiryAlerts";
import { Bookings } from "pages/Dashboard/Bookings";
import { BookingDetails } from "pages/Dashboard/Bookings/BookingDetails";
import { Users } from "pages/Dashboard/Users";
import { UserDetails } from "pages/Dashboard/Users/UserDetails";
import { Staff } from "pages/Dashboard/Staff/indext";
import { Feedback } from "pages/Dashboard/Feedback";
import { Locator } from "pages/Dashboard/Locator";
import { Staistics } from "pages/Dashboard/Statistics";
import { OperatingAreas } from "pages/Dashboard/OperatingAreas";
import { LoadScript } from "@react-google-maps/api";
import LoaderScreen from "components/LoaderScreen";
import "react-loading-skeleton/dist/skeleton.css";

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: (
      <PrivateRoute>
        <Dashboard.Main />
      </PrivateRoute>
    ),
    children: [
      {
        path: "/locator",
        element: (
          <PrivateRoute>
            <Locator />
          </PrivateRoute>
        ),
      },
      {
        path: "statistics/:period",
        element: (
          <PrivateRoute>
            <Staistics />
          </PrivateRoute>
        ),
      },
      {
        path: "users/:selectedUsersType",
        element: (
          <PrivateRoute>
            <Users />
          </PrivateRoute>
        ),
      },
      {
        path: "users/:selectedUsersType/:userId/:activeBookingsTab",
        element: (
          <PrivateRoute>
            <UserDetails />
          </PrivateRoute>
        ),
      },
      {
        path: "drivers",
        element: (
          <PrivateRoute>
            <Drivers />
          </PrivateRoute>
        ),
      },
      {
        path: "drivers/:driverId",
        element: (
          <PrivateRoute>
            <DriverItem />
          </PrivateRoute>
        ),
      },
      {
        path: "vehicles",
        element: (
          <PrivateRoute>
            <Vehicles />
          </PrivateRoute>
        ),
      },
      {
        path: "bookings/:activeTab",
        element: (
          <PrivateRoute>
            <Bookings />
          </PrivateRoute>
        ),
      },
      {
        path: "bookings/:activeTab/:bookingId",
        element: (
          <PrivateRoute>
            <BookingDetails />
          </PrivateRoute>
        ),
      },
      {
        path: "prices/:activeTab",
        element: (
          <PrivateRoute>
            <Prices />
          </PrivateRoute>
        ),
      },

      {
        path: "operating_areas",
        element: (
          <PrivateRoute>
            <OperatingAreas />
          </PrivateRoute>
        ),
      },

      {
        path: "feedback",
        element: (
          <PrivateRoute>
            <Feedback />
          </PrivateRoute>
        ),
      },

      {
        path: "staff_management",
        element: (
          <PrivateRoute>
            <Staff />
          </PrivateRoute>
        ),
      },

      {
        path: "vehicles/:vehicleId",
        element: (
          <PrivateRoute>
            <VehicleItem />
          </PrivateRoute>
        ),
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },
    ],
  },
]);

function App() {
  return (
    <LoadScript
      googleMapsApiKey={"AIzaSyBVRgEKGbNyLvbT3CdStkQz6z-e35mEq7s"}
      libraries={[
        "maps",
        "places",
        "geometry",
        "marker",
        "visualization",
        "drawing",
        "geocoding",
      ]}
      loadingElement={<LoaderScreen />}
    >
      <LoadingProvider>
        <ExpiryAlertsProvider>
          <RouterProvider router={router} />
          <Toaster />
        </ExpiryAlertsProvider>
      </LoadingProvider>
    </LoadScript>
  );
}

export default App;

import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useDebounce } from "./useDebounce";
import { BookingWithClientData } from "types/bookings";
import { fetchClientsNamesAndPhonesByIds } from "api/users";
import {
  BookingsListsTypes,
  fetchBookings,
  fetchBookingsBody,
} from "api/bookings";
import { toastServerError } from "utils/helpers";

export const bookingTypes: { [key: string]: BookingsListsTypes } = {
  all: BookingsListsTypes.ALL,
  next_24_hours: BookingsListsTypes.NEXT_24_HOURS,
  completed: BookingsListsTypes.COMPLETED,
  cancelled: BookingsListsTypes.CANCELLED,
  no_show: BookingsListsTypes.NO_SHOW,
  due_in_1_hour: BookingsListsTypes.DUE_IN_1_HOUR,
};

export const useBookingsList = () => {
  const [bookingsItems, setBookingsItems] = useState<BookingWithClientData[]>(
    []
  );
  const [totalItems, setTotalItems] = useState<number>(0);
  const { activeTab } = useParams();
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);
  const [sortedIndex, setSortedIndex] = useState<number>(5);
  const [sortedType, setSortedType] = useState<"asc" | "desc">("desc");
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const page = useMemo(
    () => Number(searchParams.get("page")) || 1,
    [searchParams]
  );
  const setPage = useCallback(
    (page: number) => {
      setSearchParams({ page: String(page) });
    },
    [setSearchParams]
  );

  const getFetchBookingsBody = (): fetchBookingsBody => {
    const sortByIndex: { [key: number]: "price" | "rideDate" } = {
      4: "price",
      5: "rideDate",
    };

    return {
      sorting: {
        sortBy: sortByIndex[sortedIndex],
        order: sortedType.toUpperCase() as "ASC" | "DESC",
      },
      search: debouncedSearch || undefined,
    };
  };

  const getBookingsItems = async () => {
    try {
      setIsLoading(true);

      if (activeTab) {
        const sortingBody = getFetchBookingsBody();

        const { data: bookingsData } = await fetchBookings(
          sortingBody,
          bookingTypes[activeTab],
          page
        );

        if (bookingsData.success) {
          if (bookingsData.orders.length === 0) {
            setBookingsItems([]);
            setTotalItems(0);
            return;
          }

          try {
            const { data: clientsData } = await fetchClientsNamesAndPhonesByIds(
              bookingsData.orders.map((booking) => booking.clientId)
            );

            setBookingsItems(
              bookingsData.orders.map((booking) => {
                const orderClient = clientsData.clients.find(
                  (client) => client.id === booking.clientId
                );

                const clientName = orderClient
                  ? `${orderClient.firstName} ${orderClient.lastName}`
                  : "Unknown client name";

                const clientPhoneNumber =
                  orderClient?.phoneNumber || "Unknown phone number";

                return {
                  ...booking,
                  clientName,
                  clientPhoneNumber,
                };
              })
            );
            setTotalItems(bookingsData.totalOrders);
          } catch (error) {
            toastServerError(
              error,
              "Something went wrong with bookings clients names loading"
            );

            setBookingsItems(
              bookingsData.orders.map((booking) => ({
                ...booking,
                clientName: "Unknown client",
                clientPhoneNumber: "Unknown phone number",
              }))
            );
            setTotalItems(bookingsData.totalOrders);
          }
        }
      }
    } catch (error) {
      toastServerError(error, "Something went wrong with bookings loading");
      setBookingsItems([]);
      setTotalItems(0);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getBookingsItems();
  }, [sortedIndex, sortedType, page, debouncedSearch, activeTab]);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (page !== 1) {
      setPage(1);
    } else {
      getBookingsItems();
    }
  }, [debouncedSearch]);

  return {
    bookingsItems,
    totalItems,
    search,
    setSearch,
    sortedIndex,
    setSortedIndex,
    sortedType,
    setSortedType,
    getBookingsItems,
    page,
    setPage,
    isLoading,
  };
};

import React, { FC } from "react";
import styles from "./Feedback.module.scss";
import { Link } from "react-router-dom";
import { getFormattedDate } from "utils/helpers/getFormatedDate";
import { IFeedback } from "types/feedbacks";
import cn from "classnames";

interface Props {
  feedback: IFeedback;
}

export const FeedBackModal: FC<Props> = ({ feedback }) => {
  return (
    <div className={styles.feedbackModal}>
      <h3 className={styles.feedbackModal_title}>Feedback</h3>
      <Link
        to={`/bookings/all/${feedback.orderId}`}
        className={cn(styles.feedbackModal_link, styles.feedbackModal_booking)}
      >
        {`Booking ID: ${feedback.orderId}`}
      </Link>
      <div className={styles.feedbackModal_reporterAndDate}>
        <div className={styles.feedbackModal_reporter}>
          <h4 className={styles.feedbackModal_subTitle}>Reporter</h4>
          <Link
            to={`/users/customers/${feedback.clientId}/upcoming`}
            className={cn(
              styles.feedbackModal_link,
              styles.feedbackModal_reporterName
            )}
          >
            {`${feedback.client.firstName} ${feedback.client.lastName}`}
          </Link>
          <div className={styles.feedbackModal_reporterContacts}>
            <p>{feedback.client.phoneNumber}</p>
            <p>{feedback.client.email}</p>
          </div>
        </div>
        <div className={styles.feedbackModal_date}>
          {getFormattedDate(feedback.createdAt, "MMM dd, yyyy, HH:mm")}
        </div>
      </div>

      <div>
        <h4 className={styles.feedbackModal_subTitle}>Comment</h4>
        <p className={styles.feedbackModal_comment}>{feedback.description}</p>
      </div>
    </div>
  );
};

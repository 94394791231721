import { object, array, string, mixed, number, date } from "yup";

export const activePriceValidationSchema = object({
  prices: array().of(
    object({
      category: string().required("Field is required"),
      minimumFare: number()
        .moreThan(0, "Must be higher than 0")
        .required("Field is required"),
      perMile: number()
        .moreThan(0, "Must be higher than 0")
        .required("Field is required"),
      perMinute: number()
        .moreThan(0, "Must be higher than 0")
        .required("Field is required"),
      driversCommission: number()
        .moreThan(0, "Must be higher than 0")
        .required("Field is required"),
      childSeat: mixed()
        .nullable()
        .test("non-empty", "Field is required", (value) => {
          if (value === null) {
            return true; // Allow null
          }
          return typeof value === "string" && value.trim().length > 0;
        })
        .test("moreThan 0", "Must be higher than 0", (value) => {
          if (value === null) {
            return true; // Allow null
          }
          return Number(value) > 0;
        }),
    })
  ),
});

export const fixedPriceValidationSchema = object({
  name: string().required("Field is required!"),
  from: array().of(string()).min(1, "Select at least one postcode!"),
  to: array().of(string()).min(1, "Select at least one postcode!"),
  businessPlusPrice: number()
    .moreThan(0, "Must be higher than 0")
    .required("Field is required!"),
  firstClassPrice: number()
    .moreThan(0, "Must be higher than 0")
    .required("Field is required!"),
  businessXLPrice: number()
    .moreThan(0, "Must be higher than 0")
    .required("Field is required!"),
});

export const holidaysValidationSchema = object({
  holidays: array().of(
    object({
      holiday: string().required("Field is required!"),
      action: string().required("Field is required!"),
      factorValue: number().moreThan(1, "Must be higher than 1").required(),
      from: date().required("Field is required!"),
      to: date()
        .required("Field is required!")
        .when("from", (from: any, schema: any) => {
          return schema.test({
            test: function (to: any) {
              return new Date(to) > new Date(from);
            },
            message: "Must be later than the start date",
          });
        }),
    })
  ),
});

export const airportPickUpPriceValidationSchema = object({
  name: string().required("Field is required!"),
  postcodes: array()
    .of(string())
    .min(1, "Select at least one postcode!")
    .required("Select at least one postcode!"),
  businessPlusPrice: number()
    .moreThan(0, "Must be higher than 0")
    .required("Field is required!"),
  firstClassPrice: number()
    .moreThan(0, "Must be higher than 0")
    .required("Field is required!"),
  businessXLPrice: number()
    .moreThan(0, "Must be higher than 0")
    .required("Field is required!"),
});

import { ReactComponent as BackArrow } from "./BackArrow.svg";
import { ReactComponent as Chevron } from "./chevron.svg";
import { ReactComponent as DarkLogo } from "./dark_logo.svg";
import { ReactComponent as Logo } from "./logo.svg";
import { ReactComponent as RightArrow } from "./right-arrow.svg";
import { ReactComponent as Search } from "./Search.svg";
import { ReactComponent as SmallArow } from "./SmallArow.svg";
import { ReactComponent as CloseCross } from "./CloseCross.svg";
import { ReactComponent as Plus } from "./Plus.svg";
import { ReactComponent as Attach } from "./Attach.svg";
import { ReactComponent as Document } from "./Document.svg";
import { ReactComponent as Trash } from "./Trash.svg";
import { ReactComponent as CheckMark } from "./CheckMark.svg";
import { ReactComponent as ExclamationMark } from "./ExclamationMark.svg";
import { ReactComponent as Calendar } from "./Calendar.svg";
import { ReactComponent as CrossInCircle } from "./CrossInCircle.svg";
import { ReactComponent as Arrows } from "./Arrows.svg";
import { ReactComponent as Logout } from "./Logout.svg";
import { ReactComponent as ThreeDotsVertical } from "./ThreeDotsVertical.svg";
import { ReactComponent as EditPen } from "./EditPen.svg";
import { ReactComponent as DeleteTrash } from "./DeleteTrash.svg";
import { ReactComponent as DeleteTrashBolt } from "./DeleteTrashBolt.svg";
import { ReactComponent as CounterDown } from "./CounterDown.svg";
import { ReactComponent as CounterUp } from "./CounterUp.svg";
import { ReactComponent as CheckInCircleIcon } from "./CheckInCircle.svg";
import { ReactComponent as PersonIcon } from "./Person.svg";
import { ReactComponent as BagLuggageIcon } from "./BagLuggage.svg";
import { ReactComponent as ChildSeatsIcon } from "./ChildSeats.svg";
import { ReactComponent as GoNextIcon } from "./GoNext.svg";
import { ReactComponent as LongRightArrowIcon } from "./LongRightArrow.svg";
import { ReactComponent as AvatarPlaceholder } from "./AvatarPlaceholder.svg";
import { ReactComponent as IncreaseStatistic } from "./IncreaseStatistic.svg";
import { ReactComponent as DecreaseStatistic } from "./DecreaseStatistic.svg";
import { ReactComponent as FlightNumberIcon } from "./FlightNumber.svg";

const ICONS = {
  Logo,
  Chevron,
  DarkLogo,
  Search,
  RightArrow,
  BackArrow,
  SmallArow,
  CloseCross,
  Plus,
  Attach,
  Document,
  Trash,
  CheckMark,
  ExclamationMark,
  Calendar,
  CrossInCircle,
  Arrows,
  Logout,
  ThreeDotsVertical,
  EditPen,
  DeleteTrash,
  DeleteTrashBolt,
  CounterDown,
  CounterUp,
  CheckInCircleIcon,
  PersonIcon,
  BagLuggageIcon,
  ChildSeatsIcon,
  GoNextIcon,
  LongRightArrowIcon,
  AvatarPlaceholder,
  IncreaseStatistic,
  DecreaseStatistic,
  FlightNumberIcon,
};

export default ICONS;

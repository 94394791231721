import styles from "./AirportFeeContent.module.scss";
import { Formik } from "formik";
import { Dispatch, FC, SetStateAction, useMemo, useState } from "react";
import { AirportPrice, AirportPickUpPriceFormValue } from "types/prices";
import { Button, Input } from "components";
import { VariantButton } from "types/enums";
import { toast } from "react-hot-toast";
import { successToastOptions } from "utils/toasterStyles";
import {
  createAsDirectedAirportPickUpPrice,
  createOneWayAirportDropOffPrice,
  createOneWayAirportPickUpPrice,
  updateAsDirectedAirportPickUpPrice,
  updateOneWayAirportDropOffPrice,
  updateOneWayAirportPickUpPrice,
} from "api/prices";
import LoaderScreen from "components/LoaderScreen";
import { airportPickUpPriceValidationSchema } from "utils/validation/Prices";
import { PostcodesSelector } from "components/PostcodesSelector";
import { toastServerError } from "utils/helpers";

interface Props {
  initialValues: AirportPickUpPriceFormValue;
  setPriceItems: Dispatch<SetStateAction<AirportPrice[]>>;
  handleClose: () => void;
  type:
    | "By The Hour Airport Pick-Up"
    | "One Way Airport Pick-Up"
    | "One Way Airport Drop-Off";
}
export const PriceForm: FC<Props> = ({
  type,
  initialValues,
  setPriceItems,
  handleClose,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const formType = !initialValues.id ? "create" : "edit";

  const createFn = useMemo(() => {
    switch (type) {
      case "By The Hour Airport Pick-Up":
        return createAsDirectedAirportPickUpPrice;
      case "One Way Airport Pick-Up":
        return createOneWayAirportPickUpPrice;
      case "One Way Airport Drop-Off":
        return createOneWayAirportDropOffPrice;
    }
  }, [type]);

  const updateFn = useMemo(() => {
    switch (type) {
      case "By The Hour Airport Pick-Up":
        return updateAsDirectedAirportPickUpPrice;
      case "One Way Airport Pick-Up":
        return updateOneWayAirportPickUpPrice;
      case "One Way Airport Drop-Off":
        return updateOneWayAirportDropOffPrice;
    }
  }, [type]);

  const handleSubmit = async (values: AirportPickUpPriceFormValue) => {
    setIsLoading(true);
    if (formType === "create") {
      try {
        const newPrice: Omit<AirportPrice, "id"> = {
          ...values,
          businessPlusPrice: +values.businessPlusPrice,
          firstClassPrice: +values.firstClassPrice,
          businessXLPrice: +values.businessXLPrice,
        };

        const { data } = await createFn(newPrice);

        if (data.success) {
          setPriceItems((prev) => [data.fixedPrice, ...prev]);

          toast(`${type} price successfully updated!`, successToastOptions);

          handleClose();
        }
      } catch (error) {
        toastServerError(
          error,
          `Something went wrong with ${type} price uploading!`
        );
      } finally {
        setIsLoading(false);
      }
    } else {
      try {
        const updatedPrice: Omit<AirportPrice, "id"> = {
          name: values.name,
          postcodes: values.postcodes,
          businessPlusPrice: +values.businessPlusPrice,
          firstClassPrice: +values.firstClassPrice,
          businessXLPrice: +values.businessXLPrice,
        };

        const { data } = await updateFn(values.id as number, updatedPrice);

        if (data.success) {
          setPriceItems((prev) =>
            prev.map((price) =>
              price.id === data.fixedPrice.id ? data.fixedPrice : price
            )
          );

          toast(`${type} price successfully updated!`, successToastOptions);
          handleClose();
        }
      } catch (error) {
        toastServerError(
          error,
          `Something went wrong with ${type} price updating`
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={airportPickUpPriceValidationSchema}
      validateOnChange={false}
    >
      {({ values, errors, setFieldValue, setFieldError, handleSubmit }) => {
        return (
          <div className={styles.form}>
            <Input
              label="Name"
              placeholder="Enter name"
              value={values.name}
              onChange={(val) => {
                setFieldValue("name", val);
                setFieldError("name", "");
              }}
              error={errors.name}
            />

            <div className={styles.form_postcodesField}>
              <PostcodesSelector
                selectedValue={values.postcodes.map((postcode) => ({
                  label: postcode,
                  value: postcode,
                }))}
                handleSelect={(selectedOptions) => {
                  setFieldValue(
                    "postcodes",
                    selectedOptions?.map((option) => option.value)
                  );
                  setFieldError("postcodes", "");
                }}
                label="Postcodes"
                error={errors.postcodes as string}
                withDetailPostcodes={true}
                withoutPrefixes={true}
              />
            </div>

            <div className={styles.prices}>
              <Input
                label="BUSINESS PLUS"
                type="number"
                value={values.businessPlusPrice}
                onChange={(value) => {
                  setFieldValue("businessPlusPrice", value);
                  setFieldError("businessPlusPrice", "");
                }}
                placeholder="Set price"
                error={errors.businessPlusPrice}
              />
              <Input
                label="FIRST-CLASS"
                type="number"
                value={values.firstClassPrice}
                onChange={(value) => {
                  setFieldValue("firstClassPrice", value);
                  setFieldError("firstClassPrice", "");
                }}
                placeholder="Set price"
                error={errors.firstClassPrice}
              />
              <Input
                label="BUSINESS XL"
                type="number"
                value={values.businessXLPrice}
                onChange={(value) => {
                  setFieldValue("businessXLPrice", value);
                  setFieldError("businessXLPrice", "");
                }}
                placeholder="Set price"
                error={errors.businessXLPrice}
              />
            </div>

            <div className={styles.modalFormBtns}>
              <Button
                title={formType === "create" ? "Create" : "Edit"}
                onClick={handleSubmit}
                variant={VariantButton.BLACK}
              />
              <Button title="Cancel" onClick={handleClose} />
            </div>
            {isLoading && <LoaderScreen />}
          </div>
        );
      }}
    </Formik>
  );
};

import { ReactComponent as PickupPoint } from "./PickupPoint.svg";
import { ReactComponent as RoutePoint } from "./RoutePoint.svg";
import { ReactComponent as RouteDots } from "./RouteDots.svg";
import { ReactComponent as RouteEndPoint } from "./EndPoint.svg";

const RouteIcons = {
  PickupPoint,
  RoutePoint,
  RouteDots,
  RouteEndPoint,
};

export default RouteIcons;

export const checkExpiration = (date: string | null) => {
  if (!date) {
    return false;
  }

  const currentDate = new Date();

  const daysDifference = Math.floor(
    (new Date(date).getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24)
  );

  return daysDifference < 14;
};

import Skeleton from "react-loading-skeleton";
import styles from "./UserDetails.module.scss";
import { useParams } from "react-router-dom";
import { ICONS } from "assets";

export const UserDataSkeleton = () => {
  const { selectedUsersType } = useParams();

  return (
    <>
      <div className={styles.userData_userBlock}>
        <Skeleton
          width={240}
          height={23}
          style={{ marginBottom: 0, marginTop: 0 }}
        />
        {selectedUsersType === "customers" && (
          <Skeleton
            width={200}
            height={16}
            style={{ marginBottom: 0, marginTop: 0 }}
          />
        )}
        <Skeleton
          width={180}
          height={16}
          style={{ marginBottom: 0, marginTop: 0 }}
        />
      </div>

      {selectedUsersType === "passengers" && (
        <div className={styles.holderBlock}>
          <div>
            <h5 className={styles.holderBlock_title}>Account holder</h5>
            <Skeleton width={180} height={15} />
            <Skeleton width={140} height={15} />
            <Skeleton width={120} height={15} />
          </div>

          <ICONS.GoNextIcon />
        </div>
      )}
    </>
  );
};

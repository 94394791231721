import { FC, useEffect, useRef, useState } from "react";
import ReactSelect, {
  GroupBase,
  MultiValue,
  PropsValue,
  StylesConfig,
} from "react-select";
import styles from "./styles.module.scss";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import Select from "react-select/dist/declarations/src/Select";

type Props = {
  label: string;
  selectedValue: PropsValue<{
    label: {};
    value: {};
  }>;
  handleSelect: (
    value: MultiValue<{
      label: {};
      value: {};
    }>
  ) => void;
  error?: string;
  disabledPostcodes?: string[];
  avaliblePostcodePrefixes?: string[];
  isAvaliblePostcodesLoading?: boolean;
  withDetailPostcodes?: boolean;
  withoutPrefixes?: boolean;
};

const colourStyles: StylesConfig<
  {
    label: {};
    value: {};
  },
  true,
  GroupBase<{
    label: string;
    value: string;
  }>
> = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: "transparent",
    width: "100%",
    paddingLeft: 13,
    paddingRight: 2,
    paddingTop: 5,
    paddingBottom: 5,
    cursor: "pointer",
    fontSize: 14,
    borderWidth: 1,
    borerColor: state.isFocused ? "#bbb2a8" : "#bbb2a8",
  }),
  dropdownIndicator: (baseStyles) => ({
    ...baseStyles,
    color: "#1C1C1C",
  }),
  valueContainer: (baseStyles) => ({
    ...baseStyles,
    padding: 0,
    margin: 0,
    alignItems: "center",
    gap: 3,
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  clearIndicator: () => ({
    display: "none",
  }),
  menu: (baseStyles) => {
    return {
      ...baseStyles,
      overflow: "hidden",
    };
  },
  menuList: (baseStyles) => ({
    ...baseStyles,
    padding: 0,
  }),
  option: (baseStyles, { isDisabled }) => ({
    ...baseStyles,
    cursor: isDisabled ? "default" : "pointer",
    color: isDisabled ? "#808191" : "#1c1c1c",
  }),
  multiValue: (baseStyles) => ({
    ...baseStyles,
    margin: 0,
    padding: "4px 0 4px 6px",
    borderRadius: 8,
    backgroundColor: "#AA8768",
    color: "#FFF",
  }),
  multiValueLabel: () => ({
    fontFamily: "Inter",
    fontSize: 12,
    fontWeight: 500,
    textTransform: "uppercase",
    color: "#FFF",
  }),
  multiValueRemove: () => ({
    // ...baseStyles,
    display: "flex",
    alignItems: "center",
    paddingLeft: 3,
    paddingRight: 6,
    ":hover": {
      color: "#1C1C1C",
    },
  }),
};

export const PostcodesSelector: FC<Props> = ({
  label,
  selectedValue,
  handleSelect,
  error,
  withDetailPostcodes = false,
  withoutPrefixes = false,
}) => {
  const selectRef = useRef<any>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [options, setOptions] = useState<{ label: string; value: string }[]>(
    []
  );

  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    usePlacesService({
      apiKey: "AIzaSyBVRgEKGbNyLvbT3CdStkQz6z-e35mEq7s",
    });

  useEffect(() => {
    if (placePredictions.length) {
      setOptions(
        placePredictions
          .map((place) => ({
            label: place.structured_formatting.main_text,
            value: place.structured_formatting.main_text,
          }))
          .filter((place) =>
            withoutPrefixes ? place.value.includes(" ") : true
          )
      );
    }
  }, [placePredictions]);

  return (
    <div className={styles.wrapper}>
      <p className={styles.wrapper_label}>{label}</p>
      <ReactSelect
        ref={selectRef}
        options={options}
        isLoading={isPlacePredictionsLoading}
        onInputChange={(val) => {
          getPlacePredictions({
            input: val,
            region: "GB",
            componentRestrictions: { country: "uk" },
            language: "en",
            types: withDetailPostcodes
              ? ["postal_code"]
              : ["postal_code_prefix"],
          });
          setIsMenuOpen(Boolean(val));
        }}
        autoFocus={false}
        isMulti
        value={selectedValue}
        styles={colourStyles}
        menuIsOpen={isMenuOpen}
        placeholder={"Enter postcodes"}
        theme={(theme) => ({
          ...theme,
          borderRadius: 4,
          colors: {
            ...theme.colors,
            primary: "#AA8768",
            primary25: "#d1b8a1",
            primary50: "#d1b8a1",
            danger: "#E53E3E",
            dangerLight: "#E53E3E",
            neutral20: error ? "red" : "#bbb2a8",
            neutral30: "#AA8768",
            neutral40: "#AA8768",
            neutral80: "#1C1C1C",
          },
        })}
        onChange={(option) => handleSelect(option)}
      />
      {error && <p className={styles.wrapper_error}>{error}</p>}
    </div>
  );
};

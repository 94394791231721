import { FC, useEffect, useState } from "react";
import DocumentFileRow from "./DocumentFileRow";
import { Document } from "../index";
import style from "./style.module.scss";
import Skeleton from "react-loading-skeleton";
import { mergeData } from "utils/mergeDocumentsData";
import { getDocumentsWithDownloadLinks } from "utils/helpers/getDocumentsWithDownloadLinks";
import { getDriverApi } from "api/drivers";

export type FileType = "driver" | "vehicle";

type Props = {
  driverId?: number;
};

const documentsTemplate: Document[] = [
  {
    title: "Driving Licence ( front )",
  },
  {
    title: "Driving Licence ( back )",
  },
  {
    title: "PCO Licence ( front )",
  },
  {
    title: "PCO Licence ( back )",
  },
];

export const DriverDocumentFilesRows: FC<Props> = ({ driverId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [downloadedDocuments, setDownloadedDocuments] =
    useState<Document[]>(documentsTemplate);

  const getDriverInfo = async () => {
    try {
      setIsLoading(true);
      if (driverId) {
        const { data } = await getDriverApi(driverId);

        if (data.success) {
          const documentsWithDownloadedLinks =
            await getDocumentsWithDownloadLinks(
              mergeData(documentsTemplate, data.driver.documents)
            );

          setDownloadedDocuments(documentsWithDownloadedLinks);
        }
      }
    } catch (e) {
      console.log("Error getting driver", e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (driverId) {
      getDriverInfo();
    } else {
      setIsLoading(false);
    }
  }, []);

  return (
    <div>
      <div className={style.title}>
        <span>Document Title</span>
        <span>Document</span>
      </div>
      {driverId && !isLoading ? (
        downloadedDocuments.map((el) => (
          <DocumentFileRow
            key={el.title}
            document={el}
            driverId={driverId}
            mode={"driver"}
          />
        ))
      ) : driverId ? (
        <Skeleton
          height={77}
          style={{ marginBottom: 2, marginTop: 2, borderRadius: 10 }}
          count={4}
        />
      ) : (
        <p className={style.emptyListPlaceholder}>
          The vehicle is not assigned to any driver and doesn't have any
          document files
        </p>
      )}
    </div>
  );
};

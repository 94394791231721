import { useEffect, useState } from "react";
import styles from "./Statistics.module.scss";
import Header from "components/Header";
import { PageContentContainer } from "components/PageContentContainer";
import { ROUTES } from "utils/constants";
import { TabLink } from "components/TabLink";
import { useParams } from "react-router-dom";
import {
  StatisticPeriod,
  fetchBookingsStatisticByPeriod,
  fetchCustomersStatisticByPeriod,
  fetchDriverStatisticByPeriod,
} from "api/statistics";
import {
  BookingsStatisticData,
  CustomersStatisticData,
  DriverStatisticData,
} from "types/statistic";
import { DailyChangingLabel } from "./DailyChangingLabel";
import { separateNumberByComas } from "utils/helpers/separateNumberByComas";
import { VehiclesColumn } from "./VehiclesColumn";
import { RevenueChart } from "./RevenueChart";
import { toastServerError } from "utils/helpers";

const zeroDriverStatistic = {
  totalDrivers: 0,
  driversToday: 0,
  driversPercent: 0,
};

const zeroCustomersStatistic = {
  customersPercent: 0,
  customersToday: 0,
  totalCustomers: 0,
};

export const Staistics = () => {
  const [bookingsStatistick, setBookingsStatistic] =
    useState<BookingsStatisticData | null>(null);
  const [driversStatistic, setDriverStatistic] =
    useState<DriverStatisticData | null>(zeroDriverStatistic);

  const [customersStatistic, setCustomersStatistic] =
    useState<CustomersStatisticData>(zeroCustomersStatistic);
  const [isLoading, setIsLoading] = useState(false);
  const { period } = useParams();

  const bookingsTotal =
    bookingsStatistick?.bookings.reduce(
      (acc, bookingsGroup) => acc + bookingsGroup.totalOrders,
      0
    ) || 0;

  const getBookingsStatisticByPeriod = async () => {
    try {
      setIsLoading(true);
      const {
        data: { success, ...statisticData },
      } = await fetchBookingsStatisticByPeriod(period as StatisticPeriod);

      if (success) {
        setBookingsStatistic(statisticData);
      }
    } catch (error) {
      toastServerError(error, "Something went wrong with statistic loading");
    } finally {
      setIsLoading(false);
    }
  };

  const getDriversStatisticByPeriod = async () => {
    try {
      const {
        data: { success, ...statisticData },
      } = await fetchDriverStatisticByPeriod(period as StatisticPeriod);

      if (success) {
        setDriverStatistic(statisticData);
      }
    } catch (error) {
      toastServerError(
        error,
        "Something went wrong with drivers statistic loading"
      );
      setDriverStatistic(zeroDriverStatistic);
    } finally {
      setIsLoading(false);
    }
  };

  const getCustomersStatisticByPeriod = async () => {
    try {
      const {
        data: { success, ...statisticData },
      } = await fetchCustomersStatisticByPeriod(period as StatisticPeriod);

      if (success) {
        setCustomersStatistic(statisticData);
      }
    } catch (error) {
      toastServerError(
        error,
        "Something went wrong with drivers statistic loading"
      );

      setCustomersStatistic(zeroCustomersStatistic);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getBookingsStatisticByPeriod();
    getDriversStatisticByPeriod();
    getCustomersStatisticByPeriod();
  }, [period]);

  console.log("bookingsStatistick => ", bookingsStatistick);
  return (
    <div className={styles.statisticsPage}>
      <Header title="Statistics" />

      <PageContentContainer>
        <div className={styles.statisticsPage_content}>
          {bookingsStatistick && (
            <>
              <div className={styles.statisticsPage_mainData}>
                <div className={styles.statisticsPage_mainLeftBlock}>
                  <h3 className={styles.statisticsPage_blockTitle}>
                    Total Revenue (￡)
                  </h3>
                  <p className={styles.statisticsPage_totalRevenue}>
                    {separateNumberByComas(
                      Math.floor(bookingsStatistick.totalRevenue)
                    )}
                  </p>

                  <div className={styles.statisticsPage_totalSeparatorLine} />

                  <h4 className={styles.statisticsPage_blockSubTitle}>Today</h4>
                  <div
                    className={styles.statisticsPage_todayStatisticContainer}
                  >
                    <p className={styles.statisticsPage_todayTotal}>
                      {separateNumberByComas(
                        Math.floor(bookingsStatistick.revenueToday)
                      )}
                    </p>
                    <DailyChangingLabel
                      persentValue={bookingsStatistick.revenuePercent}
                    />
                  </div>
                </div>
                <div className={styles.statisticsPage_mainRigthBlock}>
                  <nav className={styles.statisticsPage_navigation}>
                    {ROUTES.statistics.map((routeItem) => (
                      <TabLink
                        route={`/${routeItem.route}`}
                        label={routeItem.label}
                        key={routeItem.label}
                      />
                    ))}
                  </nav>

                  <RevenueChart revenueData={bookingsStatistick.revenue} />
                </div>
              </div>

              <div className={styles.statisticsPage_midRow}>
                <div className={styles.statisticsPage_midRowColumn}>
                  <h3 className={styles.statisticsPage_blockTitle}>
                    Customers
                  </h3>
                  <p className={styles.statisticsPage_midRowTotal}>
                    {customersStatistic.totalCustomers}
                  </p>
                  <div className={styles.statisticsPage_midRowSeparatorLine} />
                  <h4 className={styles.statisticsPage_blockSubTitle}>Today</h4>
                  <div
                    className={styles.statisticsPage_todayStatisticContainer}
                  >
                    <p className={styles.statisticsPage_midRowTodayTotal}>
                      {separateNumberByComas(
                        Math.floor(customersStatistic.customersToday)
                      )}
                    </p>
                    <DailyChangingLabel
                      persentValue={customersStatistic?.customersPercent}
                    />
                  </div>
                </div>
                <div className={styles.statisticsPage_midRowColumn}>
                  <h3 className={styles.statisticsPage_blockTitle}>Drivers</h3>
                  <p className={styles.statisticsPage_midRowTotal}>
                    {separateNumberByComas(
                      Math.floor(driversStatistic?.totalDrivers || 0)
                    )}
                  </p>
                  <div className={styles.statisticsPage_midRowSeparatorLine} />
                  <h4 className={styles.statisticsPage_blockSubTitle}>Today</h4>
                  <div
                    className={styles.statisticsPage_todayStatisticContainer}
                  >
                    <p className={styles.statisticsPage_midRowTodayTotal}>
                      {separateNumberByComas(
                        Math.floor(driversStatistic?.driversToday || 0)
                      )}
                    </p>
                    <DailyChangingLabel
                      persentValue={driversStatistic?.driversPercent || 0}
                    />
                  </div>
                </div>
                <div className={styles.statisticsPage_midRowColumn}>
                  <h3 className={styles.statisticsPage_blockTitle}>Bookings</h3>
                  <p className={styles.statisticsPage_midRowTotal}>
                    {separateNumberByComas(Math.floor(bookingsTotal))}
                  </p>
                  <div className={styles.statisticsPage_midRowSeparatorLine} />
                  <h4 className={styles.statisticsPage_blockSubTitle}>Today</h4>
                  <div
                    className={styles.statisticsPage_todayStatisticContainer}
                  >
                    <p className={styles.statisticsPage_midRowTodayTotal}>
                      {separateNumberByComas(
                        Math.floor(bookingsStatistick.bookingsToday)
                      )}
                    </p>
                    <DailyChangingLabel
                      persentValue={bookingsStatistick.bookingsPercent}
                    />
                  </div>
                </div>
              </div>

              <h3 className={styles.statisticsPage_bottomBlockTitle}>
                {`Top Vehicle Categories `}
                <span
                  className={styles.statisticsPage_bottomBlockTitle__smallText}
                >
                  {`(by number of rides)`}
                </span>
              </h3>

              <div className={styles.statisticsPage_vehiclesRow}>
                {bookingsStatistick.bookings.map((vehcleBookings) => (
                  <VehiclesColumn
                    vehicleBookingsStat={vehcleBookings}
                    bookingsTotal={bookingsTotal}
                    key={vehcleBookings.vehicleCategory}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      </PageContentContainer>
    </div>
  );
};

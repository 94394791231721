import { ReactComponent as Check } from "./Check.svg";
import { ReactComponent as DoubleCheck } from "./DoubleCheck.svg";
import { ReactComponent as ClosedChat } from "./ClosedChat.svg";
import { ReactComponent as OngoingChat } from "./Ongoing.svg";
import { ReactComponent as OpenedChat } from "./OpenedChat.svg";
import { ReactComponent as LeaveChat } from "./LeaveChat.svg";
import { ReactComponent as CompleteChat } from "./CompleteChat.svg";
import { ReactComponent as Completed } from "./Completed.svg";
import { ReactComponent as SelectImage } from "./SelectImage.svg";
import { ReactComponent as SendMessage } from "./SendMessage.svg";

const ChatIcons = {
  Check,
  DoubleCheck,
  ClosedChat,
  OngoingChat,
  OpenedChat,
  LeaveChat,
  CompleteChat,
  Completed,
  SelectImage,
  SendMessage,
};

export default ChatIcons;

import { FC } from "react";
import DriverDocumentMainInputs from "../../../../../components/DriverDocumentMainInputs";
import { DriverDocumentFilesRows } from "./DriverDocumentFilesRows";

type Props = {
  isDriverActive: boolean;
  isCreate: boolean;
  driverDocumentsMainInputsClassnames?: string;
  drivingLicenceExpiryDate: string;
  handleDrivingLicenceExpiryDateChange: (value: string) => void;
  PCOLicenceType: string;
  handlePCOLicenceTypeChange: (value: string) => void;
  PCOLicenceNumber: string;
  PCOLicenceNumberError?: string;
  handlePCOLicenceNumberChange: (value: string) => void;
  PCOLicenceExpiryDate: string;
  handlePCOLicenceExpiryDateChange: (value: string) => void;
  driverId?: number;
};

export type Document = {
  title: string;
  link?: string;
  id?: number;
};

const Documents: FC<Props> = ({
  isCreate,
  isDriverActive,
  driverDocumentsMainInputsClassnames,
  drivingLicenceExpiryDate,
  handleDrivingLicenceExpiryDateChange,
  PCOLicenceType,
  handlePCOLicenceTypeChange,
  PCOLicenceNumber,
  PCOLicenceNumberError = "",
  handlePCOLicenceNumberChange,
  PCOLicenceExpiryDate,
  handlePCOLicenceExpiryDateChange,
  driverId,
}) => {
  return (
    <div>
      <DriverDocumentMainInputs
        isCreate={isCreate}
        driverDocumentsInputsClassnames={driverDocumentsMainInputsClassnames}
        drivingLicenceExpiryDate={drivingLicenceExpiryDate}
        handleDrivingLicenceExpiryDateChange={
          handleDrivingLicenceExpiryDateChange
        }
        PCOLicenceType={PCOLicenceType}
        handlePCOLicenceTypeChange={handlePCOLicenceTypeChange}
        PCOLicenceNumber={PCOLicenceNumber}
        PCOLicenceNumberError={PCOLicenceNumberError}
        handlePCOLicenceNumberChange={handlePCOLicenceNumberChange}
        PCOLicenceExpiryDate={PCOLicenceExpiryDate}
        handlePCOLicenceExpiryDateChange={handlePCOLicenceExpiryDateChange}
        isDriverActive={isDriverActive}
      />

      <DriverDocumentFilesRows driverId={driverId} />
    </div>
  );
};

export default Documents;

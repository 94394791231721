import React from "react";
import styles from "./NotFound.module.scss";
import PageImage from "assets/images/404.png";

export const NotFoundPage = () => {
  return (
    <div className={styles.page}>
      <img className={styles.page_image} src={PageImage} />
    </div>
  );
};

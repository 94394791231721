import { ChangeEvent, FC, useState } from "react";
import { IUser } from "types/user";
import styles from "./Staff.module.scss";
import { useFormik } from "formik";
import Avatar from "components/Avatar";
import { Button, Input } from "components";
import Select from "components/Select";
import { VariantButton } from "types/enums";
import { EmployeeFormData } from "types/staff";
import { useAvatar } from "hooks/useAvatar";
import { MaxFileSizeInBytes } from "utils/constants";
import { toast } from "react-hot-toast";
import { errorToastOptions } from "utils/toasterStyles";
import { createEmployee, updateEmployee } from "api/staff";
import { staffValidationSchema } from "utils/validation/Staff";
import LoaderScreen from "components/LoaderScreen";
import { toastServerError } from "utils/helpers";

interface Props {
  editableEmployee: IUser | null;
  refreshStafflist: () => void;
  closeForm: () => void;
}

export const StaffForm: FC<Props> = ({
  editableEmployee,
  refreshStafflist,
  closeForm,
}) => {
  const { uploadAvatar } = useAvatar();
  const [avatarFile, setAvatarFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const initialValues: EmployeeFormData = editableEmployee
    ? {
        avatar: editableEmployee.avatar,
        firstName: editableEmployee.firstName,
        lastName: editableEmployee.lastName,
        email: editableEmployee.email,
        password: "",
        role: editableEmployee.role,
      }
    : {
        avatar: null,
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        role: "Admin",
      };

  const { values, errors, setFieldValue, setFieldError, handleSubmit } =
    useFormik({
      validateOnChange: false,
      validationSchema: staffValidationSchema,
      initialValues,
      onSubmit: async (formData: EmployeeFormData) => {
        try {
          setIsLoading(true);
          const requestBody = {
            ...formData,
          };

          if (avatarFile) {
            if (avatarFile.size <= MaxFileSizeInBytes) {
              const uploadedAvatar = await uploadAvatar(
                avatarFile.name,
                avatarFile
              );
              requestBody.avatar = uploadedAvatar || null;
            } else {
              toast("Photo size is too large!", errorToastOptions);

              return;
            }
          }

          const { data } = editableEmployee
            ? await updateEmployee(editableEmployee?.id as number, requestBody)
            : await createEmployee(requestBody);

          if (data.admin) {
            refreshStafflist();
            closeForm();
          }
        } catch (error: any) {
          console.log("Staff form submiting error: ", error);
          if (error.response.status === 409) {
            toast(error.response.data.message, errorToastOptions);
          } else {
            toastServerError(
              error,
              "Something went wrong with form submission"
            );
          }
        } finally {
          setIsLoading(false);
        }
      },
    });

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setAvatarFile(e.target.files[0]);
    }
  };

  const handleFieldChange = (valueName: string, value: string) => {
    setFieldValue(valueName, value);
    if (value) {
      setFieldError(valueName, "");
    }
  };

  return (
    <div className={styles.form}>
      <h3 className={styles.form_title}>
        {editableEmployee ? "Edit Mannager" : "New Manager"}
      </h3>

      <div className={styles.form_avatarField}>
        <Avatar
          img={avatarFile ? URL.createObjectURL(avatarFile) : values.avatar}
          dimensions={{ width: 100, height: 100 }}
        />

        <label className={styles.form_changeAvatarBtn}>
          <input
            type="file"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          Change photo
        </label>
      </div>

      <div className={styles.form_fieldsContainer}>
        <div className={styles.form_multiFieldRow}>
          <div className={styles.form_multiFieldRowItem}>
            <Input
              label="First name"
              value={values.firstName}
              onChange={(value) => handleFieldChange("firstName", value)}
              error={errors.firstName}
            />
          </div>
          <div className={styles.form_multiFieldRowItem}>
            <Input
              label="Last name"
              value={values.lastName}
              onChange={(value) => handleFieldChange("lastName", value)}
              error={errors.lastName}
            />
          </div>
        </div>

        <Input
          label="Email"
          value={values.email}
          onChange={(value) => handleFieldChange("email", value)}
          error={errors.email}
        />

        <Input
          label="Password"
          isSucure
          value={values.password}
          onChange={(value) => handleFieldChange("password", value)}
          error={errors.password}
        />

        <Select
          label="Role"
          data={["Admin", "Moderator"]}
          selectedValue={values.role}
          handleSelect={(value) => handleFieldChange("role", value)}
          error={errors.role}
        />
      </div>

      <Button
        variant={VariantButton.BLACK}
        onClick={handleSubmit}
        title={editableEmployee ? "Update" : "Create"}
        extraStyles={{ width: "100%" }}
      />
      {isLoading && <LoaderScreen />}
    </div>
  );
};

import { getNames } from "country-list";
import { useFormik } from "formik";
import {
  ChangeEvent,
  FC,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { DriverSchema } from "utils/validation";
import {
  CreateDriverData,
  DrivingLicence,
  IDriver,
  PCODocument,
  UpdateDriverData,
} from "../../types/drivers";
import { VariantButton } from "../../types/enums";
import Avatar from "../Avatar";
import { Button, Input } from "../index";
import Select from "../Select";
import styles from "./styles.module.scss";
import DriverDocumentMainInputs from "../DriverDocumentMainInputs";
import { useAvatar } from "../../hooks/useAvatar";
import toast from "react-hot-toast";
import { useLoading } from "../../context/loading";

import { errorToastOptions, successToastOptions } from "utils/toasterStyles";
import { ExpiryAlertsContext } from "context/expiryAlerts";
import { MaxFileSizeInBytes } from "utils/constants";
import { toastServerError } from "utils/helpers";

type Props = {
  driverData?: DriverData;
  isCreate: boolean;
  onSubmit:
    | ((data: Omit<UpdateDriverData, "documentDetails">) => Promise<string>)
    | ((data: CreateDriverData) => Promise<void>);
  onCancel: () => void;
  setAvatarUrl?: (value: string | null) => void;
  setDrivers?: (drivers: IDriver[]) => void;
};

type DriverData = {
  id: number;
  avatar: string | null;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  homeAddress: {
    street: string;
    city: string;
    postalCode: string;
    country: string;
  };
  taxInformation: string;
  bankDetails: {
    accountHoldersName: string;
    sortCode: string;
    accountNumber: string;
  };
  createDriverDocumentDetails?: [
    Omit<DrivingLicence, "id">,
    Omit<PCODocument, "id">
  ];
};

const emptyFormData: DriverData = {
  id: -1,
  avatar: null,
  firstName: "",
  lastName: "",
  phoneNumber: "+44",
  email: "",
  homeAddress: {
    street: "",
    city: "",
    postalCode: "",
    country: "",
  },
  taxInformation: "",
  bankDetails: {
    accountHoldersName: "",
    sortCode: "",
    accountNumber: "",
  },
  createDriverDocumentDetails: [
    {
      category: "Driving",
      type: "TFL",
      expiryDate: "",
      number: null,
    },
    {
      category: "PCO",
      type: "",
      number: "",
      expiryDate: "",
    },
  ],
};

const countries = getNames();

const GeneralInformationForm: FC<Props> = ({
  driverData = emptyFormData,
  isCreate,
  onSubmit,
  onCancel,
  setAvatarUrl,
  setDrivers,
}) => {
  const { updateExpiryAlerts } = useContext(ExpiryAlertsContext);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { deleteAvatar, uploadAvatar } = useAvatar();
  const [file, setFile] = useState<File | null>(null);
  const [shouldDeleteAvatar, setShouldDeleteAvatar] = useState(false);
  const { setIsLoading } = useLoading();

  const { values, errors, setFieldValue, setFieldError, handleSubmit } =
    useFormik({
      initialValues: { ...driverData, newAvatarFile: null },
      validationSchema: DriverSchema,
      validateOnChange: false,
      onSubmit: async (values) => {
        let avatarUrl = null;
        setIsLoading(true);

        if (file) {
          if (file.size <= MaxFileSizeInBytes) {
            //@ts-ignore
            avatarUrl = await uploadAvatar(file.name, file, driverData.id);
          } else {
            toast("Photo size is too large!", errorToastOptions);
            return;
          }
        }

        if (shouldDeleteAvatar && driverData.avatar) {
          await deleteAvatar(driverData.avatar, driverData.id);
        }

        const avatarPath = shouldDeleteAvatar
          ? null
          : avatarUrl || driverData.avatar || null;

        const {
          avatar,
          firstName,
          lastName,
          phoneNumber,
          email,
          homeAddress,
          taxInformation,
          bankDetails,
        } = values;

        if (isCreate) {
          values.createDriverDocumentDetails &&
            onSubmit({
              avatar: avatarPath,
              firstName,
              lastName,
              phoneNumber,
              email,
              homeAddress,
              taxInformation,
              bankDetails,
              //@ts-ignore
              documentDetails: values.createDriverDocumentDetails,
            }).then((message: any) => {
              if (typeof message === "string") {
                toast(
                  message === "jwt expired" ? "Token expired" : message,
                  errorToastOptions
                );
              } else if (message?.success && setDrivers) {
                //@ts-ignore
                setDrivers((prevDrivers: any) => [
                  message.driver,
                  ...prevDrivers,
                ]);
                onCancel();

                toast("Driver successfully created!", successToastOptions);
              }
            });
        } else {
          //@ts-ignore
          onSubmit({
            avatar: avatarPath,
            firstName,
            lastName,
            phoneNumber,
            email,
            homeAddress,
            taxInformation,
            bankDetails,
          })
            .then((message: any) => {
              if (typeof message === "string") {
                console.log("message", message, errorToastOptions);
                toast(
                  message === "jwt expired" ? "Token expired" : message,
                  errorToastOptions
                );
              } else {
                toast("Driver successfully updated!", successToastOptions);
              }
            })
            .catch((error) => {
              toastServerError(
                error,
                "Something went wrong with driver updating!"
              );
            });
        }

        updateExpiryAlerts();
      },
    });

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const handleFileDelete = async () => {
    if (driverData.avatar) {
      setShouldDeleteAvatar(true);
    } else if (fileInputRef.current) {
      fileInputRef.current.value = "";
      setFile(null);
    }
  };

  const handleFieldChange = (fieldName: string, fieldValue: string) => {
    setFieldValue(fieldName, fieldValue);
    setFieldError(fieldName, "");
  };

  useEffect(() => {
    setAvatarUrl &&
      setAvatarUrl(
        shouldDeleteAvatar
          ? null
          : (file && URL.createObjectURL(file)) || driverData.avatar
      );
  }, [shouldDeleteAvatar, file, driverData.avatar]);
  console.log("values: ", values);
  console.log("errors: ", errors);

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_photo}>
        <Avatar
          img={
            shouldDeleteAvatar
              ? null
              : (file && URL.createObjectURL(file)) || driverData.avatar
          }
          dimensions={{ width: 100, height: 100 }}
        />
        <div className={styles.wrapper_photo_buttons}>
          <label htmlFor="image" className={styles.wrapper_photo_buttons_label}>
            Change photo
          </label>
          <input
            ref={fileInputRef}
            id="image"
            type="file"
            accept=".png, .jpg, .heic"
            className={styles.wrapper_photo_buttons_change}
            onChange={handleFileChange}
          />
          {!isCreate && (
            <button
              className={styles.wrapper_photo_buttons_delete}
              onClick={handleFileDelete}
            >
              Delete photo
            </button>
          )}
        </div>
      </div>
      <span className={styles.wrapper_line} />
      <div className={styles.wrapper_firstfields}>
        <Input
          value={values.firstName}
          label="First name"
          error={errors.firstName}
          onChange={(val) => handleFieldChange("firstName", val)}
        />
        <Input
          value={values.lastName}
          label="Last name"
          error={errors.lastName}
          onChange={(val) => handleFieldChange("lastName", val)}
        />
        <Input
          value={values.phoneNumber}
          label="Phone number"
          error={errors.phoneNumber}
          onChange={(val) => handleFieldChange("phoneNumber", val)}
        />
        <Input
          value={values.email}
          label="Email"
          error={errors.email}
          onChange={(val) => handleFieldChange("email", val)}
        />
      </div>
      <div className={styles.wrapper_homefields}>
        <h2 className={styles.wrapper_subtitle}>Home address</h2>
        <Input
          value={values.homeAddress.street}
          label="Street"
          error={errors.homeAddress?.street}
          onChange={(val) => handleFieldChange("homeAddress.street", val)}
        />
        <div className={styles.wrapper_homefields_citycode}>
          <Input
            value={values.homeAddress.city}
            label="City"
            error={errors.homeAddress?.city}
            onChange={(val) => handleFieldChange("homeAddress.city", val)}
          />
          <Input
            value={values.homeAddress.postalCode}
            label="Postal code"
            error={errors.homeAddress?.postalCode}
            onChange={(val) => handleFieldChange("homeAddress.postalCode", val)}
          />
        </div>
        <Select
          label="Country"
          data={countries}
          selectedValue={values.homeAddress.country}
          handleSelect={(val) => handleFieldChange("homeAddress.country", val)}
          error={errors?.homeAddress?.country}
        />
      </div>
      <div className={styles.wrapper_homefields}>
        <h2 className={styles.wrapper_subtitle}>Tax information</h2>
        <Input
          value={values.taxInformation}
          label="National insurance number"
          error={errors.taxInformation}
          onChange={(val) => handleFieldChange("taxInformation", val)}
        />
      </div>
      <div className={styles.wrapper_homefields}>
        <h2 className={styles.wrapper_subtitle}>Bank details</h2>
        <Input
          value={values.bankDetails.accountHoldersName}
          label="Account holder’s name"
          error={errors.bankDetails?.accountHoldersName}
          onChange={(val) =>
            handleFieldChange("bankDetails.accountHoldersName", val)
          }
        />
        <Input
          value={values.bankDetails.sortCode}
          label="Sort code"
          error={errors.bankDetails?.sortCode}
          onChange={(val) => handleFieldChange("bankDetails.sortCode", val)}
        />
        <Input
          value={values.bankDetails.accountNumber}
          label="Account number"
          error={errors.bankDetails?.accountNumber}
          onChange={(val) =>
            handleFieldChange("bankDetails.accountNumber", val)
          }
        />
      </div>

      {isCreate && values.createDriverDocumentDetails && (
        <div className={styles.wrapper_homefields}>
          <h2 className={styles.wrapper_subtitle}>Document details</h2>
          <DriverDocumentMainInputs
            isCreate={true}
            driverDocumentsInputsClassnames={styles.wrapper_homefields}
            drivingLicenceExpiryDate={
              values.createDriverDocumentDetails[0].expiryDate
            }
            // @ts-ignore
            validattionErrors={errors.createDriverDocumentDetails}
            handleDrivingLicenceExpiryDateChange={(val) =>
              handleFieldChange(
                "createDriverDocumentDetails[0].expiryDate",
                val
              )
            }
            PCOLicenceType={values.createDriverDocumentDetails[1].type}
            handlePCOLicenceTypeChange={(value) => {
              handleFieldChange("createDriverDocumentDetails[1].type", value);
            }}
            PCOLicenceNumber={values.createDriverDocumentDetails[1].number}
            handlePCOLicenceNumberChange={(val) => {
              handleFieldChange("createDriverDocumentDetails[1].number", val);
            }}
            PCOLicenceExpiryDate={
              values.createDriverDocumentDetails[1].expiryDate
            }
            handlePCOLicenceExpiryDateChange={(val) =>
              handleFieldChange(
                "createDriverDocumentDetails[1].expiryDate",
                val
              )
            }
          />
        </div>
      )}
      <span className={styles.wrapper_line} />
      <div className={styles.wrapper_buttons}>
        <Button
          title={isCreate ? "Create driver" : "Update details"}
          onClick={handleSubmit}
          variant={VariantButton.BLACK}
        />
        {isCreate && <Button title="Cancel" onClick={onCancel} />}
      </div>
    </div>
  );
};

export default GeneralInformationForm;

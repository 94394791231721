import { FC } from "react";
import styles from "./SearchInput.module.scss";

import { ICONS } from "assets";

type Props = {
  value: string;
  placeholder: string;
  onChange: (value: string) => void;
};

const SearchInput: FC<Props> = ({ value, placeholder, onChange }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_icon}>
        <ICONS.Search />
      </div>

      <input className={styles.wrapper_input} value={value} placeholder={placeholder} onChange={(e) => onChange(e.target.value)} />
    </div>
  );
};

export default SearchInput;

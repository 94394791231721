import { useEffect, useState } from "react";
import styles from "./Bookings.module.scss";
import Toggle from "components/Toggle";
import { fetchActualBookingMode, setBookingMode } from "api/bookings";
import LoaderScreen from "components/LoaderScreen";
import { toastServerError } from "utils/helpers";

export const ModeHandler = () => {
  const [isManualActive, setIsManualActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getActualMode = async () => {
    try {
      setIsLoading(true);

      const { data } = await fetchActualBookingMode();

      setIsManualActive(data.manualMode);
    } catch (error) {
      toastServerError(error, "Something went wrong with booking mode loading");
    } finally {
      setIsLoading(false);
    }
  };

  const changeMode = async () => {
    try {
      setIsLoading(true);

      const { data } = await setBookingMode({ manualMode: !isManualActive });

      setIsManualActive(data.manualMode);
    } catch (error) {
      toastServerError(
        error,
        "Something went wrong with booking mode updating"
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getActualMode();
  }, []);

  return (
    <div className={styles.modeHandler}>
      <div className={styles.modeHandler_label}>
        <p className={styles.modeHandler_title}>Manual mode</p>
        <p>{`is ${isManualActive ? "active" : "disabled"}`}</p>
      </div>
      <Toggle active={isManualActive} onToggle={changeMode} />
      {isLoading && <LoaderScreen />}
    </div>
  );
};

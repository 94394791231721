import { useEffect, useState } from "react";
import { IVehicle } from "../types/vehicles";
import { vehicleAllApi } from "../api/vehicles";

interface Sort {
  [key: string]: string;
}

export interface BodySortVehicle {
  sort: Sort;
  search?: string;
}

export const useVehicles = (
  sortType: "desc" | "asc" | null,
  sortedIndex: number | null,
  search: string,
  refreshToggler?: boolean
) => {
  const [vehicles, setVehicles] = useState<IVehicle[]>([]);
  const [totalVehicles, setTotalVehicles] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const sortVehicles = () => {
    const body: BodySortVehicle = {
      sort: {
        //   deleted: "ASC",
      },
      search: search || undefined,
    };
    const indexToKey: { [key: number]: string } = {
      1: "category",
    };

    if (sortType) {
      const keyToUpdate = indexToKey[sortedIndex ? sortedIndex - 1 : 0];
      console.log("keyToUpdate", sortedIndex);
      body.sort[keyToUpdate] = sortType.toUpperCase();
    }

    return body;
  };
  const getAllVehicles = async () => {
    try {
      setIsLoading(true);
      const newBody = sortVehicles();
      const { data } = await vehicleAllApi(currentPage, newBody);

      if (data.success) {
        setVehicles(data.vehicles);
        setTotalVehicles(data.totalVehicles);
        setIsLoading(false);
      }
    } catch (e) {
      console.log("error getting all vehicles", e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllVehicles();
  }, [currentPage, sortType, sortedIndex, refreshToggler]);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      getAllVehicles();
    }
  }, [search]);

  return {
    vehicles,
    setVehicles,
    totalVehicles,
    currentPage,
    setCurrentPage,
    isLoading,
  };
};

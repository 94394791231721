import { Pagination, TableHeader } from "components";
import Checkbox from "components/Checkbox";
import React, { FC, Fragment, useEffect, useMemo, useState } from "react";
import { CustomersItemRow } from "./CustomersItemRow";
import { CustomerItem } from "types/users";
import Skeleton from "react-loading-skeleton";
import { EmptyTablePlaceholder } from "components/EmptyTablePlaceholder";
import { fetchCustomers, fetchUsersBody } from "api/users";
import { USERS_LOADING_LIMIT } from "utils/constants";
import styles from "../Users.module.scss";
import { UserForBlockOrDelete } from "hooks/useUsersBlockAndDelete";
import { useLocation } from "react-router-dom";
import { toastServerError } from "utils/helpers";

const columnsFlexes = [undefined, 29.4, 29.8, 17.4, 23.2];

interface Props {
  selectedUsers: UserForBlockOrDelete[];
  onUserSelect: (user: UserForBlockOrDelete | UserForBlockOrDelete[]) => void;
  setCustomersTotal: React.Dispatch<React.SetStateAction<number>>;
  setPassengersTotal: React.Dispatch<React.SetStateAction<number>>;
  search: string;
  refreshToggler: boolean;
}

export const CustomersTable: FC<Props> = ({
  selectedUsers,
  onUserSelect,
  setCustomersTotal,
  setPassengersTotal,
  search,
  refreshToggler,
}) => {
  const { state } = useLocation();
  const [customers, setCustomers] = useState<CustomerItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(state?.page || 1);
  const [totalItems, setTotalItems] = useState(0);
  const [sortedIndex, setSortedIndex] = useState<number>(4);
  const [sortedType, setSortedType] = useState<"desc" | "asc">("desc");

  const isAllSelected = useMemo(() => {
    const notDeletedCustomer = customers.filter(
      (customer) => !customer.isDeleted
    );

    return (
      notDeletedCustomer.length !== 0 &&
      notDeletedCustomer.every((customer) =>
        selectedUsers.some((user) => user.id === customer.id)
      )
    );
  }, [customers, selectedUsers]);

  const handleHeaderCheckboxClick = () => {
    if (isAllSelected) {
      onUserSelect([]);
    } else {
      onUserSelect(
        customers.map(({ id, isBlocked, isDeleted }) => ({
          id,
          isBlocked,
          isDeleted,
        }))
      );
    }
  };

  const getCustomersList = async () => {
    try {
      setIsLoading(true);

      const requestBody: fetchUsersBody = {
        search: search || null,
        sorting: {
          sortBy: "createdAt",
          order: sortedType.toUpperCase() as "ASC" | "DESC",
        },
      };

      const { data } = await fetchCustomers(requestBody, page);

      if (data.success) {
        setCustomers(data.clients);
        setCustomersTotal(data.totalCustomers);
        setPassengersTotal(data.totalPassengers);
        setTotalItems(data.totalClients);
      }
    } catch (error) {
      toastServerError(error, "Something went wrong with customers loading");
      setCustomers([]);
      setCustomersTotal(0);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCustomersList();
  }, [page, sortedType, sortedIndex, refreshToggler]);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (page !== 1) {
      setPage(1);
    } else {
      getCustomersList();
    }
  }, [search]);

  return (
    <>
      <div className={styles.users_table}>
        <TableHeader
          items={[
            <Checkbox
              onPress={handleHeaderCheckboxClick}
              active={isAllSelected}
            />,
            "Customer",
            "Email",
            "Phone number",
            "Registration date",
          ]}
          flexes={columnsFlexes}
          selectedIndex={sortedIndex}
          sortedType={sortedType}
          onChangeSorted={(val) => {
            setSortedIndex((prev) => (val === null ? prev : val));
          }}
          onChangeType={(order) => {
            setSortedType(order === null ? "asc" : order);
          }}
          sortIndexes={[4]}
          gap={24}
        />

        {customers.length !== 0 && !isLoading ? (
          customers.map((customer) => (
            <Fragment key={customer.id}>
              <CustomersItemRow
                customer={customer}
                onSelect={onUserSelect}
                columsFlexes={columnsFlexes}
                isSelected={selectedUsers.some(
                  (user) => user.id === customer.id
                )}
                page={page}
              />
            </Fragment>
          ))
        ) : isLoading ? (
          <Skeleton
            height={54}
            style={{ marginBottom: 2, marginTop: 2, borderRadius: 10 }}
            count={10}
          />
        ) : (
          <EmptyTablePlaceholder text="There are no customers matching your choice" />
        )}
      </div>

      {totalItems !== 0 && (
        <div className={styles.users_pagination}>
          <Pagination
            currentPage={page}
            count={totalItems}
            limit={USERS_LOADING_LIMIT}
            onChange={setPage}
          />
        </div>
      )}
    </>
  );
};

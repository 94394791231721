import { FC, HTMLInputTypeAttribute, useEffect, useRef, useState } from "react";
import cls from "classnames";
import styles from "./styles.module.scss";
import { ICONS } from "../../assets";
import ReactDatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

type Props = {
  value: string;
  label?: string;
  error?: string;
  isSucure?: boolean;
  placeholder?: string;
  onChange: (value: string) => void;
  type?: HTMLInputTypeAttribute;
  isTextArea?: boolean;
  min?: string;
  expiring?: boolean;
  disabled?: boolean;
  dateFormat?: string;
  showTimeInput?: boolean;
  minDate?: Date;
  maxDate?: Date;
};

const Input: FC<Props> = ({
  value,
  placeholder,
  label,
  isSucure = false,
  error,
  onChange,
  type,
  isTextArea,
  min,
  expiring,
  disabled = false,
  dateFormat = "dd MMMM, yyyy",
  showTimeInput = false,
  minDate,
  maxDate,
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(!isSucure);
  const [expireError, setExpireError] = useState<boolean>(false);

  useEffect(() => {
    const selectedDate = new Date(value);
    const twoWeeksFromNow = new Date();
    twoWeeksFromNow.setDate(twoWeeksFromNow.getDate() + 14);

    if (expiring && selectedDate && selectedDate < twoWeeksFromNow) {
      setExpireError(true);
    } else {
      setExpireError(false);
    }
  }, [expiring]);

  if (expiring || expireError) {
    console.log("expiring:", expiring);
    console.log("expireError:", expireError);
  }

  return (
    <div className={styles.wrapper}>
      {label && <p className={styles.wrapper_label}>{label}</p>}

      <div className={styles.wrapper_input_wrapper}>
        {isTextArea ? (
          <textarea
            value={value}
            className={cls(styles.wrapper_input, styles.wrapper_textarea, {
              [styles.wrapper_input_focused]: isFocused,
              [styles.wrapper_textarea__error]: error,
            })}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onChange={(e) => onChange(e.target.value)}
            disabled={disabled}
          />
        ) : type === "date" ? (
          <div className={styles.wrapper_input_container}>
            {expiring || expireError ? (
              <>
                <ICONS.ExclamationMark className={styles.wrapper_input_icon} />
                <p className={styles.wrapper_error}>
                  Expiring in less than 2 weeks
                </p>
              </>
            ) : (
              <ICONS.Calendar className={styles.wrapper_input_icon} />
            )}

            <ReactDatePicker
              dateFormat={dateFormat}
              selected={value ? new Date(value) : null}
              onChange={(date) => {
                const twoWeeksFromNow = new Date();
                twoWeeksFromNow.setDate(twoWeeksFromNow.getDate() + 14);
                if (!showTimeInput) {
                  onChange(date?.toISOString().split("T")[0] || value);
                } else {
                  onChange(date?.toISOString() || value);
                }

                if (expiring && date && date < twoWeeksFromNow) {
                  setExpireError(true);
                } else {
                  setExpireError(false);
                }
              }}
              showTimeSelect={showTimeInput}
              filterTime={(time) => {
                const currentDate = new Date();
                const selectedDate = new Date(time);

                return currentDate.getTime() < selectedDate.getTime();
              }}
              customInput={
                <input
                  className={cls(styles.wrapper_input, {
                    [styles.wrapper_input_focused]: isFocused,
                    [styles.wrapper_input_error]: expiring || error,
                    [styles.wrapper_input_cursor]: type === "date",
                  })}
                  disabled={disabled}
                />
              }
              className={`${styles.wrapper_input_calendar} ${
                isFocused && styles.wrapper_input_focused
              }`}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              wrapperClassName={styles.wrapper_datepicker}
              calendarClassName={styles.wrapper_datepicker_calendar}
              dayClassName={(date) => {
                const selectedDate = new Date(value);

                return cls(styles.wrapper_datepicker_day, {
                  [styles.wrapper_datepicker_day__active]:
                    date?.getDate() === selectedDate.getDate() &&
                    date?.getMonth() === selectedDate.getMonth() &&
                    date?.getFullYear() === selectedDate.getFullYear(),
                });
              }}
              timeClassName={(date) => {
                const selectedDate = new Date(value);

                return cls(styles.wrapper_datepicker_time, {
                  [styles.wrapper_datepicker_time__active]:
                    date?.getTime() === selectedDate.getTime(),
                });
              }}
              minDate={minDate}
              maxDate={maxDate}
            />
          </div>
        ) : (
          <input
            value={value}
            placeholder={placeholder}
            className={cls(styles.wrapper_input, {
              [styles.wrapper_input_focused]: isFocused,
              [styles.wrapper_input_error]: error,
            })}
            style={{ paddingRight: isSucure ? 30 : 13 }}
            min={min}
            type={type || (showPassword ? "text" : "password")}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onChange={(e) => onChange(e.target.value)}
            disabled={disabled}
          />
        )}

        {isSucure && (
          <button
            className={styles.wrapper_input_secure}
            onClick={() => setShowPassword(!showPassword)}
            disabled={!value}
          >
            <p>{showPassword ? "Hide" : "Show"}</p>
          </button>
        )}
      </div>

      {error && <p className={styles.wrapper_error}>{error}</p>}
    </div>
  );
};

export default Input;
